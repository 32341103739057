import { push } from 'redux-first-history';
import io, { Socket } from 'socket.io-client';
import { MyAction } from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { EventChannel, eventChannel, Task } from 'redux-saga';
import {
  put,
  call,
  take,
  fork,
  select,
  cancel,
  takeEvery,
} from 'redux-saga/effects';

import { MyState } from '../../store';
import { Nullable } from '../../typings';
import { WEBSOCKET_URL } from '../../config';
import { WEBSOCKET_EVENT } from '../../utils/websockets';

interface ActionTypes {
  INITIALIZE_PARTICIPANT_WEBSOCKET: string;
  DISCONNECT_PARTICIPANT_WEBSOCKET: string;
  PARTICIPANT_WEBSOCKET_SEND: string;
  ASK_TO_JOIN_ROOM: string;
  JOIN_ROOM_DECLINED: string;
  JOIN_ROOM_APPROVED: string;
  SAVE_CURRENT_ROOM_NAME: string;
  SAVE_SUCCESS_NAVIGATE_URL: string;
}

type WebsocketSendPayload = {
  eventName: string;
  data: any;
};

type AskToJoinPayload = {
  role: string;
  email?: string;
};

type InitializeParticipantWebsocketPayload = {
  roomName: string;
  successNavigateUrl: string;
};

interface ActionCreators {
  initializeParticipantWebsocket: (
    payload: InitializeParticipantWebsocketPayload,
  ) => MyAction<InitializeParticipantWebsocketPayload>;
  disconnectParticipantWebsocket: () => MyAction<void>;
  participantWebsocketSend: (payload: WebsocketSendPayload) => MyAction<void>;
  askToJoinRoom: (payload: AskToJoinPayload) => MyAction<void>;
  joinRoomDeclined: () => MyAction<void>;
  joinRoomApproved: () => MyAction<void>;
  saveCurrentRoomName: (payload: string) => MyAction<string>;
  saveSuccessNavigateUrl: (payload: string) => MyAction<string>;
}

export interface PublicWaitingRoomState {
  isAskedToJoin: boolean;
  isApproved: Nullable<boolean>;
  isDeclined: Nullable<boolean>;
  currentRoomName: Nullable<string>;
  successNavigateUrl: Nullable<string>;
}

const initialState: PublicWaitingRoomState = {
  isAskedToJoin: false,
  isApproved: null,
  isDeclined: null,
  currentRoomName: null,
  successNavigateUrl: null,
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  initializeParticipantWebsocket: ['payload'],
  disconnectParticipantWebsocket: [],
  participantWebsocketSend: ['payload'],
  askToJoinRoom: ['payload'],
  joinRoomDeclined: [],
  joinRoomApproved: [],
  saveCurrentRoomName: ['payload'],
  saveSuccessNavigateUrl: ['payload'],
});

function askToJoinRoom(state: PublicWaitingRoomState) {
  return {
    ...state,
    isDeclined: false,
    isAskedToJoin: true,
  };
}

function joinRoomDeclined(state: PublicWaitingRoomState) {
  return {
    ...state,
    isAskedToJoin: false,
    isDeclined: true,
  };
}

function joinRoomApproved(state: PublicWaitingRoomState) {
  return {
    ...state,
    isAskedToJoin: false,
    isDeclined: false,
  };
}

function saveCurrentRoomName(
  state: PublicWaitingRoomState,
  { payload }: MyAction<string>,
) {
  return {
    ...state,
    currentRoomName: payload,
  };
}

function saveSuccessNavigateUrl(
  state: PublicWaitingRoomState,
  { payload }: MyAction<string>,
) {
  return {
    ...state,
    successNavigateUrl: payload,
  };
}

export const publicWaitingRoomReducer = createReducer<PublicWaitingRoomState>(
  initialState,
  {
    [Types.ASK_TO_JOIN_ROOM]: askToJoinRoom,
    [Types.JOIN_ROOM_DECLINED]: joinRoomDeclined,
    [Types.JOIN_ROOM_APPROVED]: joinRoomApproved,
    [Types.SAVE_CURRENT_ROOM_NAME]: saveCurrentRoomName,
    [Types.SAVE_SUCCESS_NAVIGATE_URL]: saveSuccessNavigateUrl,
  },
);

function* askToJoinHandler(action: MyAction<AskToJoinPayload>) {
  yield put(
    Creators.participantWebsocketSend({
      eventName: WEBSOCKET_EVENT.JOIN_REQUEST,
      data: action.payload,
    }),
  );
}

function* askToJoinRoomWatcher() {
  yield takeEvery(Types.ASK_TO_JOIN_ROOM, askToJoinHandler);
}

function createSocketConnection(url: string, roomName: string) {
  return io(url, {
    path: '/socket/',
    query: {
      roomName,
    },
  });
}

function createSocketChannel(socket: Socket) {
  return eventChannel((emit) => {
    socket.on(WEBSOCKET_EVENT.REQUEST_DECLINED, () => {
      emit({ type: Types.JOIN_ROOM_DECLINED });
    });

    socket.on(WEBSOCKET_EVENT.REQUEST_APPROVED, () => {
      emit({ type: Types.JOIN_ROOM_APPROVED });
    });

    //DON'T simplify, socket will lose "this"
    return () => socket.disconnect();
  });
}

function* websocketSendWatcher(socket: Socket) {
  while (true) {
    const { payload }: { payload: WebsocketSendPayload } = yield take(
      Types.PARTICIPANT_WEBSOCKET_SEND,
    );
    socket.emit(payload.eventName, payload.data);
  }
}

function* websocketListenWatcher(socketChannel: EventChannel<Socket>) {
  while (true) {
    try {
      const action = yield take(socketChannel);
      yield put(action);
    } catch (err) {
      console.log('socket error: ', err);
    }
  }
}

function* websocketDisconnectWatcher(
  channel: EventChannel<Socket>,
  sendTask: Task,
  listenTask: Task,
) {
  while (true) {
    yield take(Types.DISCONNECT_PARTICIPANT_WEBSOCKET);
    channel.close();
    yield cancel(sendTask);
    yield cancel(listenTask);
    yield cancel();
  }
}

function* initializeWebsocketHandler({
  payload,
}: MyAction<InitializeParticipantWebsocketPayload>) {
  yield put(Creators.saveCurrentRoomName(payload.roomName));
  yield put(Creators.saveSuccessNavigateUrl(payload.successNavigateUrl));

  const socket = yield call(
    createSocketConnection,
    WEBSOCKET_URL,
    payload.roomName,
  );

  const socketChannel = yield call(createSocketChannel, socket);

  const sendTask = yield fork(websocketSendWatcher, socket);
  const listenTask = yield fork(websocketListenWatcher, socketChannel);

  yield fork(websocketDisconnectWatcher, socketChannel, sendTask, listenTask);
}

function* initializeSocketWatcher() {
  yield takeEvery(
    Types.INITIALIZE_PARTICIPANT_WEBSOCKET,
    initializeWebsocketHandler,
  );
}

function* navigateAfterSuccessApproved() {
  while (yield take(Types.JOIN_ROOM_APPROVED)) {
    const { successNavigateUrl } = yield select(selectPublicWaitingRoomState);

    yield put(push(successNavigateUrl));
  }
}

export const waitingRoomSagas = [
  askToJoinRoomWatcher,
  initializeSocketWatcher,
  navigateAfterSuccessApproved,
];

export const selectPublicWaitingRoomState = (state: MyState) =>
  state.publicWaitingRoom;
