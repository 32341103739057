import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, MouseEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  className?: string;
  title: string;
  check: boolean;
  icon: IconProp;
  iconStyle?: object;
  titleClassName?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const MMDCheckableItem: FC<Props> = ({
  title,
  titleClassName,
  className,
  check,
  onClick,
  icon,
  iconStyle,
}) => (
  <Row
    onClick={onClick}
    className={`w-100 vcenter text-decoration-none ${className || ''}`}
  >
    <Col className="text-left">
      <h4 className={`affiliation-title ${titleClassName}`}>{title}</h4>
    </Col>
    <Col xs={3} className=" text-right pr-3">
      {check && (
        <FontAwesomeIcon
          size={'1x'}
          icon={icon}
          style={iconStyle || { color: '#F11B42' }}
        />
      )}
    </Col>
  </Row>
);

export default MMDCheckableItem;
