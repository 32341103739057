import React, { ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  title?: string;
  titleClassName?: string;
  show: boolean;
  handleClose: () => void;
  handleOk?: () => void;
  renderBody: () => ReactNode;
  renderFooter?: () => ReactNode;
  classNameContainer?: string;
  withoutFooter?: boolean;
  centered?: boolean;
  closeButton?: boolean;
  keyboard?: boolean;
  backdrop?: boolean | 'static';
}

const MMDModal = ({
  title,
  show,
  handleClose,
  handleOk,
  renderBody,
  classNameContainer,
  renderFooter,
  titleClassName,
  centered,
  withoutFooter = false,
  closeButton = true,
  keyboard,
  backdrop,
}: Props) => (
  <Modal
    show={show}
    centered={centered}
    onHide={handleClose}
    backdrop={backdrop}
    keyboard={keyboard}
    dialogClassName={classNameContainer}
  >
    <Modal.Header closeButton={closeButton} className="empty-border p-3">
      <Modal.Title className="modal-title">
        <h3 className={titleClassName}>{title}</h3>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>{renderBody()}</Modal.Body>

    {!withoutFooter && (
      <Modal.Footer className={'center-footer'}>
        {renderFooter ? (
          renderFooter()
        ) : (
          <Button variant="primary" className={'btn-medium'} onClick={handleOk}>
            Ok
          </Button>
        )}
      </Modal.Footer>
    )}
  </Modal>
);

export default MMDModal;
