import { FC } from 'react';

import { useBoundedActions } from '../../../hooks/useBoundedActions';
import {
  HostUser,
  Creators as HostPublicWaitingRoomAction,
} from '../../../modules/videocall-public/host-waiting-room.module';

const uppercaseFirstLetter = (role: string) =>
  `${role?.[0].toUpperCase()}${role?.slice(1)}`;

const PendingUser: FC<HostUser> = ({ role, email, id, name }) => {
  const { approveUserRequest, declineUserRequest } = useBoundedActions(
    HostPublicWaitingRoomAction,
  );

  const participantName = name ? `( ${name} )` : '';

  return (
    <div className="btnVideoCall__pendingParticipantsList-item">
      <span>
        {uppercaseFirstLetter(role)} {participantName} | email: {email ?? '-'}
      </span>

      <div className="btnVideoCall__pendingParticipantsList-actions">
        <div
          onClick={() => approveUserRequest(id)}
          className="btnVideoCall__pendingParticipantsList-actions__approve mr-4"
        >
          accept
        </div>

        <div
          onClick={() => declineUserRequest(id)}
          className="btnVideoCall__pendingParticipantsList-actions__decline"
        >
          decline
        </div>
      </div>
    </div>
  );
};

export default PendingUser;
