import { useSelector } from 'react-redux';
import { useLayoutEffect, useRef, useState } from 'react';

import PendingUser from './PendingUser';
import { useToggle } from '../../../hooks/useToggle';
import { selectHostPublicWaitingRoomState } from '../../../modules/videocall-public/host-waiting-room.module';

const PendingParticipantsList = () => {
  const { users } = useSelector(selectHostPublicWaitingRoomState);

  const [isOpenList, toggleOpenList] = useToggle();
  const [marginRight, setMarginRight] = useState('0');

  const menuRef = useRef(null);

  useLayoutEffect(() => {
    setMarginRight(`-${(menuRef.current?.clientWidth ?? 0) / 2}px`);
  }, [users]);

  const isPendingUserExist = users.length > 0;

  return (
    <div className="btnVideoCall__pendingParticipantsList-wrapper">
      <div className="btnVideoCall__pendingParticipantsList">
        <div
          onClick={toggleOpenList}
          className="btnVideoCall__grayTransparent btnVideoCall__outline"
        >
          <img src="/assets/participants.png" alt="micro icon" />
        </div>

        {isPendingUserExist && (
          <div className="btnVideoCall__pendingParticipantsList-count">
            {users.length}
          </div>
        )}
      </div>

      <div
        ref={menuRef}
        className="btnVideoCall__pendingParticipantsList-list"
        style={{
          marginRight,
          visibility: isOpenList ? 'visible' : 'hidden',
        }}
      >
        {isPendingUserExist ? (
          users.map((user) => <PendingUser key={user.id} {...user} />)
        ) : (
          <div>No pending users</div>
        )}
      </div>
    </div>
  );
};

export default PendingParticipantsList;
