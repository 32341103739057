import { Col, Container, Row } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';

import ActionsAppointment from '../pages/myappts/ActionsAppointment';
import DoctorProfile from '../components/DoctorProfile';

import { Creators as BookingsActions } from '../modules/bookings.module';
import { MyState } from '../store';
import { Booking, Provider } from '../typings';
import PatientProfile from '../components/PatientProfile';
import AnswerAndQuestion from './myappts/AnswerAndQuestion';
import PatientInfoAccordion from './myappts/PatientInfoAccordion';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { useLocation, useParams } from 'react-router-dom';
import { FC, useEffect } from 'react';

type Props = {
  provider: Provider;
  booking: Booking;
  sendSubscriberStudent: ({ id }: { id: string }) => void;
  requestAppointment: ({ id }: { id: string }) => void;
  sendUnsubscriberStudent: ({ id }: { id: string }) => void;
};

const UpcomingAppointment: FC<Props> = ({
  sendSubscriberStudent,
  requestAppointment,
  sendUnsubscriberStudent,
}) => {
  const { state } = useLocation();
  const params = useParams<{ id: string }>();

  const booking = useSelector(
    (state: MyState) => state.bookings.entities[params.id],
  );
  const provider = useSelector((state: MyState) => state.provider);

  //console.log("booking", booking)
  useEffect(() => {
    requestAppointment({ id: state.appointment.id });
  }, [state.appointment]);
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>Video Call</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <PatientProfile patientId={state.appointment.patientId} />
          <PatientInfoAccordion patientId={state.appointment.patientId} />
          <AnswerAndQuestion appointment={state.appointment} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={3}>
          <ActionsAppointment
            appointment={booking || state.appointment}
            provider={provider}
            sendSubscriberStudent={sendSubscriberStudent}
            sendUnsubscriberStudent={sendUnsubscriberStudent}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...BookingsActions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingAppointment);
