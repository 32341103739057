import { Container, Row, Col } from 'react-bootstrap';
import PrescriptionRefillRow from './PrescriptionRefillRow';
import { RefillRequest } from '../../typings';
import { FC } from 'react';

type Props = {
  prescriptionRefills: RefillRequest[];
};

const PrescriptionRefillList: FC<Props> = ({ prescriptionRefills }) => (
  <Container fluid>
    <Row style={{ display: 'flex', justifyContent: 'center' }}>
      <Col
        style={{ display: 'flex', justifyContent: 'center' }}
        className="mb-2"
        xs={12}
      >
        Your requests list
      </Col>
      {prescriptionRefills.length > 0 ? (
        prescriptionRefills.map((prescriptionRefill) => (
          <PrescriptionRefillRow
            key={prescriptionRefill.id}
            prescriptionRefill={prescriptionRefill}
          />
        ))
      ) : (
        <h6>You don't have any refill requests</h6>
      )}
    </Row>
  </Container>
);

export default PrescriptionRefillList;
