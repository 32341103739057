import { createSingleEventSaga, MyAction } from '@mrnkr/redux-saga-toolbox';
import { createActions } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import { ArgsWithHeaders } from '../utils/typings';
import { MMDError } from '../utils/MMDError';
import { Creators as LoadingActions } from './loading.module';
import { noOpAction } from '../utils/noOpAction';
import { Creators as ErrorActions } from './errors.module';

interface ActionTypes {
  ACCEPT_STUDENT_ATTENDANCE: string;
  DECLINE_STUDENT_ATTENDANCE: string;
}

interface StudentAttendancePayload {
  bookingId: string;
  patientId: string;
}

interface ActionCreators {
  acceptStudentAttendance: (
    payload: StudentAttendancePayload,
  ) => MyAction<object>;
  declineStudentAttendance: (
    payload: StudentAttendancePayload,
  ) => MyAction<object>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  acceptStudentAttendance: ['payload'],
  declineStudentAttendance: ['payload'],
});

async function acceptStudentAttendance(payload: StudentAttendancePayload) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const result = await fetch(
    `${AUTH_API_URL}/user-notifications/accept-student`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );

  if (!result.ok) {
    throw new MMDError('Your action could not be processed correctly');
  }
}

async function declineStudentAttendance(payload: StudentAttendancePayload) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const result = await fetch(
    `${AUTH_API_URL}/user-notifications/decline-student`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );

  if (!result.ok) {
    throw new MMDError('Your action could not be processed correctly');
  }
}

const acceptStudentAttendanceWatcher = createSingleEventSaga<
  StudentAttendancePayload,
  StudentAttendancePayload,
  MyAction<StudentAttendancePayload>
>({
  takeEvery: Types.ACCEPT_STUDENT_ATTENDANCE,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: acceptStudentAttendance,
});

const declineStudentAttendanceWatcher = createSingleEventSaga<
  StudentAttendancePayload,
  StudentAttendancePayload,
  MyAction<StudentAttendancePayload>
>({
  takeEvery: Types.DECLINE_STUDENT_ATTENDANCE,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: declineStudentAttendance,
});

export const notificationSagas = [
  acceptStudentAttendanceWatcher,
  declineStudentAttendanceWatcher,
];
