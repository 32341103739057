import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import DoctorProfile from '../../components/DoctorProfile';
import { MyState } from '../../store';
import PatientDetails from './PatientDetails';
import PrescriptionView from './PrescriptionView';

const PrescriptionRefillDetail: React.FC = () => {
  const params = useParams<{ id: string }>();

  const prescriptionRefill = useSelector((state: MyState) =>
    params.id ? state.prescriptionRefills.entities[params.id] : undefined,
  );

  if (!prescriptionRefill) {
    return null;
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h5>E-prescription Refill</h5>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle">
        <Col xs={12} sm={12} md={12} lg={3} className="mb-4 p-1">
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={5}>
          <PatientDetails patient={prescriptionRefill.patient} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <PrescriptionView
            id={prescriptionRefill.id}
            patient={prescriptionRefill.patient}
            prescription={prescriptionRefill.prescription}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(undefined, mapDispatchToProps)(PrescriptionRefillDetail);
