import { Col, Row, Container, Button } from 'react-bootstrap';
import { Field } from 'formik';

import FileDropzone from '../../../components/FileDropzone';
import SignatureField from './step-two/SignatureField';

const StepTwo = () => {
  const renderImage = (file: string, prompt: string) => (
    <>
      {file && (
        <img
          className="w-100 mb-2"
          style={{ height: 256, borderRadius: '22px', objectFit: 'cover' }}
          src={file}
          alt="Upload candidate"
        />
      )}

      {!file && (
        <div
          className="w-100 mb-2"
          style={{
            height: 256,
            borderColor: '#DB386C',
            borderRadius: '22px',
            borderStyle: 'solid',
            borderWidth: '1px',
          }}
        ></div>
      )}

      <Button className="w-100 mb-3">{prompt}</Button>
    </>
  );

  return (
    <Container>
      <Row>
        <Col md>
          <h6 className="text-center">Please upload your State ID photo</h6>

          <Col>
            <Field
              name="licenseFront"
              render={({ field }) => (
                <FileDropzone
                  name={field.name}
                  onChange={(value) =>
                    field.onChange({ target: { name: field.name, value } })
                  }
                  prompt="Upload document front"
                  renderer={renderImage}
                />
              )}
            />

            <Field
              name="licenseBack"
              render={({ field }) => (
                <FileDropzone
                  name={field.name}
                  onChange={(value) =>
                    field.onChange({ target: { name: field.name, value } })
                  }
                  prompt="Upload document back"
                  renderer={renderImage}
                />
              )}
            />
          </Col>
        </Col>

        <Col md>
          <h6 className="text-center">Write your electronic signature</h6>

          <Field
            name="signature"
            render={({ field }) => (
              <SignatureField
                name={field.name}
                onChange={(value) =>
                  field.onChange({ target: { name: field.name, value } })
                }
                height={256}
                className="w-100"
              />
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StepTwo;
