import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

import { FormControlProps } from '../../utils/formControlProps';

type PropTypes = {
  type?: string;
  showFieldError?: boolean;
  validate?: any;
  label?: string;
  autocomplete?: string;
  required?: boolean;
} & Omit<FormControlProps, 'label'>;

const MMDTextInput: React.FC<PropTypes> = ({
  values,
  fieldKey,
  label,
  placeholder,
  type = 'text',
  maxLength,
  validate,
  showFieldError,
  autocomplete,
  className,
  required,
}) => (
  <div className={className}>
    {label && (
      <FormLabel className="ml-3 form-label" htmlFor={fieldKey}>
        {label}

        {required && <span className="text-danger ml-1">*</span>}
      </FormLabel>
    )}
    {!label && <div className="spacer-input-base" />}
    <Field
      type={type}
      placeholder={placeholder || label}
      name={fieldKey}
      value={values[fieldKey]}
      maxLength={maxLength}
      className="form-control"
      validate={validate}
      autoComplete={autocomplete}
    />

    {showFieldError && (
      <ErrorMessage
        name={fieldKey}
        render={(msg) => (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {msg}
          </div>
        )}
      />
    )}
  </div>
);

export default MMDTextInput;
