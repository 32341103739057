import {
  getMobileOperatingSystem,
  MOBILE_OPERATING_SYSTEMS,
} from '../../utils/getMobileOperatingSystem';

export const IOS_MOBILE_APP_LINK =
  'https://apps.apple.com/ar/app/momentmd/id1440980389?l=en';

export const ANDROID_MOBILE_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.momentmd';

export const LANDING_PAGE_LINK = 'https://momentmd.com/';

export const getLinkForDownloadApp = () => {
  switch (getMobileOperatingSystem()) {
    case MOBILE_OPERATING_SYSTEMS.IOS:
      return IOS_MOBILE_APP_LINK;
    case MOBILE_OPERATING_SYSTEMS.ANDROID:
    case MOBILE_OPERATING_SYSTEMS.WINDOWS:
      return ANDROID_MOBILE_APP_LINK;
    case MOBILE_OPERATING_SYSTEMS.UNKNOWN:
    default:
      return LANDING_PAGE_LINK;
  }
};
