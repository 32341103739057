import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import { Language } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface LanguageIds {
  ids: number[];
}

interface ActionTypes {
  REQUEST_LANGUAGES: string;
  COMMIT_LANGUAGES: string;

  REQUEST_UPDATE_PROVIDER_LANGUAGES: string;
  COMMIT_UPDATE_PROVIDER_LANGUAGES: string;
}

interface ActionCreators {
  requestUpdateProviderLanguages: (
    payload: LanguageIds,
  ) => MyAction<LanguageIds>;
  commitUpdateProviderLanguages: () => MyAction<void>;

  requestLanguages: () => MyAction<void>;
  commitLanguages: (payload: Language[]) => MyAction<Language[]>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestUpdateProviderLanguages: ['payload'],
  commitUpdateProviderLanguages: [],

  requestLanguages: [],
  commitLanguages: ['payload'],
});

const entityAdapter = createEntityAdapter<Language>();
export interface LanguageState extends EntityState<Language> {}
const initialState = entityAdapter.getInitialState({});
export const languageSelectors = entityAdapter.getSelectors();

function commitUpdateProviderLanguages(state: LanguageState): LanguageState {
  return {
    ...state,
  };
}

function commitLanguages(
  state: LanguageState,
  action: MyAction<Language[]>,
): LanguageState {
  return {
    ...entityAdapter.addAll(action.payload, state),
  };
}

export const languageReducer = createReducer(initialState, {
  [Types.COMMIT_UPDATE_PROVIDER_LANGUAGES]: commitUpdateProviderLanguages,
  [Types.COMMIT_LANGUAGES]: commitLanguages,
});

async function downloadLanguages({
  headers,
}: ArgsWithHeaders<void>): Promise<Language[]> {
  const result = await fetch(`${AUTH_API_URL}/doctors/languages`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }

  return (await result.json()).languages;
}

async function updateProviderLanguage({
  headers,
  ...payload
}: ArgsWithHeaders<LanguageIds>): Promise<any> {
  const result = await fetch(`${AUTH_API_URL}/doctors/languages`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({ languages: payload.ids.map((id) => ({ id })) }),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
  return await result.json();
}

const requestUpdateProviderLanguageWatcher = createSingleEventSaga<
  object,
  void,
  MyAction<LanguageIds>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER_LANGUAGES,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitUpdateProviderLanguages,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: updateProviderLanguage,
  beforeAction: putAuthInfoInArgs,
});

const requestLanguageWatcher = createSingleEventSaga<
  object,
  Language[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_LANGUAGES,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitLanguages,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadLanguages,
  beforeAction: putAuthInfoInArgs,
});

export const languageSagas = [
  requestUpdateProviderLanguageWatcher,
  requestLanguageWatcher,
];
