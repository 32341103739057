import React from 'react';
import { FormGroup, Button, Col } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

import screenSizeReporter from '../../../../utils/screenSizeReporter';

type Props = {
  name?: string;
  height: number;
  className?: string;
  onChange?: (data: string) => void;
};

type State = {
  width: number;
};

export default class SignatureField extends React.PureComponent<Props, State> {
  private divRef = React.createRef<HTMLDivElement>();
  private canvasRef = React.createRef<SignatureCanvas>();
  private subscription;

  constructor(props: Props) {
    super(props);
    this.state = {
      width: props.height,
    };
  }

  componentDidMount() {
    const { divRef } = this;

    if (divRef && divRef.current) {
      this.setState({ width: divRef.current.clientWidth });
      this.subscription = screenSizeReporter.subscribe(() =>
        this.setState({ width: divRef.current.clientWidth }),
      );
    }
  }

  render() {
    const { handleOnEnd, handleClear } = this;
    const { height, className } = this.props;
    const { width } = this.state;

    return (
      <FormGroup>
        <Col>
          <div
            className={`doctor-signature mb-2 ${className}`}
            style={{ height }}
            ref={this.divRef}
          >
            <SignatureCanvas
              penColor="#000000"
              canvasProps={{ width, height }}
              ref={this.canvasRef}
              onEnd={handleOnEnd}
            />
          </div>
          <Button
            className={`${className}`}
            variant="secondary"
            onClick={handleClear}
          >
            Clear
          </Button>
        </Col>
      </FormGroup>
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  private handleClear = () => {
    const { canvasRef } = this;

    if (!canvasRef) {
      return;
    }

    canvasRef.current.clear();
  };

  private handleOnEnd = () => {
    const { canvasRef } = this;
    const { onChange = () => {} } = this.props;

    if (!canvasRef) {
      return;
    }

    onChange(canvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
  };
}
