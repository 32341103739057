import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Modal } from 'react-bootstrap';
import { Creators as LoadingActions } from '../../modules/loading.module';
import { MyState } from '../../store';

type Props = {
  loading?: boolean;
  forceLoading?: boolean;
};

const LoadingIndicator: React.FC<Props> = ({ loading, forceLoading }) => {
  return loading ? (
    <Modal show={loading || forceLoading} className="loader">
      <img
        alt="Loading indicator"
        src="/assets/loader.svg"
        style={{ height: '75px' }}
        className="loader"
      />
    </Modal>
  ) : null;
};

const mapStateToProps = (state: MyState) => ({
  loading: state.loading.loading || state.loading.app_loading,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...LoadingActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoadingIndicator);
