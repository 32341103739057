import { FC } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import { Booking } from '../../typings';

type PropsPrivate = {
  appointment: Booking;
  isStudent?: boolean;
  provider?: any;
};

const AppointmentRow: FC<PropsPrivate> = ({
  appointment,
  isStudent,
  provider,
}) => {
  const reserved = !!(
    appointment.studentId !== provider.id &&
    isStudent &&
    appointment.studentId
  );

  return (
    <Container fluid>
      <Row
        style={{ minWidth: 300 }}
        className="pt-2 pb-2 patient-border align-items-center"
      >
        <Col className="vcenter p-0" xs={2}>
          <Image
            roundedCircle
            style={{ height: 80, width: 80, objectFit: 'cover' }}
            src={
              appointment.avatar
                ? appointment.avatar
                : '/assets/default-child.png'
            }
            className="mx-auto"
          />
        </Col>
        <Col className="pl-0" xs={6}>
          <p className="mb-1 ml-1 text-normal-weight">{appointment.peerName}</p>
          <p className="mb-1 ml-1">
            <small>Appointment: </small>

            <small className="fw-bold">
              {moment(appointment.startDate).format('MMMM D, YYYY [at] h:mm A')}
            </small>
          </p>
          <p className="mb-1 ml-1 text-primary">
            <small>Video Call meeting </small>
          </p>
          {appointment.studentName && !isStudent && (
            <p className="mb-1 ml-1 text-primary">
              <small>Student subscribed: {appointment.studentName}</small>
            </p>
          )}
          {appointment.studentId === provider.id && isStudent && (
            <p className="mb-1 ml-1 text-primary">
              <small>Subscribed</small>
            </p>
          )}
          {appointment.studentId !== provider.id &&
            isStudent &&
            appointment.studentId && (
              <p className="mb-1 ml-1 text-primary">
                <small>Reserved</small>
              </p>
            )}
        </Col>
        <Col xs={4} className="vcenter">
          {!reserved && (
            <Link
              replace
              to={`/appointment/${appointment.id}`}
              state={{
                appointment,
              }}
              className="ml-auto"
            >
              <Button className="w-100 btn-blue pl-4 pr-4 pt-1 pb-1">
                <small>
                  {isStudent ? 'Appointment Info' : 'Start a Video Call'}
                </small>
              </Button>
            </Link>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  provider: state.provider,
});

export default connect(mapStateToProps, null)(AppointmentRow);
