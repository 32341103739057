import { push } from 'redux-first-history';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import EPrescribing from '../../components/EPrescribing';
import NotesTextArea, {
  getNotesString,
} from '../../components/notes/notesTextArea';
import { Creators as BookingsActions } from '../../modules/bookings.module';
import { Creators as TemplatesActions } from '../../modules/template.module';
import { MyState } from '../../store';
import { Booking } from '../../typings';
import LoadingIndicator from '../app/LoadingIndicator';
import Prompt from '../../components/Prompt';

const MAX_HOURS_TO_EDIT_NOTES = 24;

type Props = {
  doctorId: string;
  booking: Booking;
  bookingId: string;
  edit: boolean;
  push: (path: string) => void;
  templates: any;
  requestTemplates: any;
} & typeof BookingsActions &
  typeof TemplatesActions;

const isFeedbackEditable = (appointmentDate: Date): boolean => {
  return (
    moment().utc().diff(moment(appointmentDate), 'hours') <
    MAX_HOURS_TO_EDIT_NOTES
  );
};

const FeedbackComponent: React.FC<Props> = ({
  bookingId,
  requestAppointment,
  doctorId,
  booking,
  updateBookingNote,
  sendFeedback,
  push,
  edit,
  templates,
  requestTemplates,
}) => {
  const [step, setStep] = useState(1);
  const [prescribing, setPrescribing] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showFeedBack, setShowFeedback] = useState(true);
  const [notesObject, setNotesObject] = useState([
    {
      id: 0,
      name: '',
      value: booking && booking.bookingNote && booking.bookingNote.note,
    },
  ]);
  const [bookingHasNote, setBookingHasNote] = useState('');
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [locked, setLocked] = useState(true);
  const [noteEditableMode, setNoteEditableMode] = useState(false);
  const [feedbackViewOnly, setFeedbackViewOnly] = useState(false);
  const [noteTemplates, setNoteTemplates] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(
    noteTemplates && noteTemplates[0],
  );

  useMemo(() => {
    if (!templateSelected || templateSelected == 'loaded') {
      bookingHasNote &&
        setNotesObject([{ id: 0, name: '', value: bookingHasNote }]);
    }
  }, [bookingHasNote]);

  useMemo(() => {
    setBookingHasNote(
      booking && booking.bookingNote && booking.bookingNote.note,
    );
    if (!templateSelected || templateSelected == 'loaded') {
      bookingHasNote &&
        setNotesObject([{ id: 0, name: '', value: bookingHasNote }]);
    }
  }, [booking]);

  const handlerSelectTemplate = (value) => {
    switch (value) {
      case '-1':
        setTemplateSelected('loaded');
        break;
      case '0':
        setTemplateSelected('basic');
        break;
      default:
        try {
          setTemplateSelected(templates.entities[value]);
        } catch (e) {
          console.log(e);
          setTemplateSelected('basic');
        }
    }
  };

  const prescriptionPreviousStep = React.useCallback(() => {
    if (step === 1) {
      setPrescribing(false);
      setShowFeedback(true);
    } else {
      setStep(step - 1);
    }
  }, [setPrescribing, step, setStep]);

  const prescriptionNextStep = React.useCallback(
    (step) => {
      if (!prescribing) {
        setPrescribing(true);
        setShowNotes(false);
        setShowFeedback(false);
      } else {
        if (step === 0) {
          setPrescribing(false);
          setShowFeedback(true);
          setStep(1);
        } else {
          setStep(step);
        }
      }
    },
    [prescribing, setPrescribing, setStep],
  );

  const renderNotes = () => {
    setShowNotes(true);
    setPrescribing(false);
    setShowFeedback(false);
  };

  const updateNotes = () => {
    const noteString = getNotesString();
    updateBookingNote({
      id: booking.id,
      bookingNote: noteString,
    });
    setShowFeedback(true);
    setShowNotes(false);
    //To update SavedNotes
    setBookingHasNote(noteString);
  };

  const sendComment = () => {
    setLocked(false);
    setTimeout(() => {
      sendFeedback({ id: bookingId, rating, comment });
      push('/dashboard');
    }, 0);
  };

  useMemo(() => {
    if (templateSelected) {
      switch (templateSelected) {
        case 'loaded':
          bookingHasNote &&
            setNotesObject([{ id: 0, name: '', value: bookingHasNote }]);
          break;
        case 'basic':
          setNotesObject([{ id: 0, name: '', value: '' }]);
          break;
        default:
          setNotesObject(
            templateSelected &&
              templateSelected.sections.map((sec) => ({
                id: sec.id,
                name: sec.name,
                value: '',
              })),
          );
      }
    }
  }, [templateSelected]);

  useMemo(() => {
    if (booking) {
      setFeedbackViewOnly(!isFeedbackEditable(booking.startDate));
      if (edit) {
        setNoteEditableMode(edit && isFeedbackEditable(booking.startDate));
      }
    }
    setNoteTemplates(
      templates && templates.ids.map((id) => templates.entities[id]),
    );
  }, [templates, booking, edit]);

  useEffect(() => {
    requestTemplates();
    requestAppointment({ id: bookingId });
  }, [doctorId, booking && booking.bookingNote && booking.bookingNote.note]);

  if (!booking) {
    return <LoadingIndicator forceLoading />;
  }

  return (
    <Container className="d-flex flex-grow-1 h-100" fluid>
      <Row
        style={{ height: '116%' }}
        className="d-flex justify-content-center w-100"
      >
        <Col className="d-flex chat h-75" xs={12}>
          {!noteEditableMode && !feedbackViewOnly && (
            <Prompt
              when={locked}
              message="Feedback for patient is missing. Are you sure you want to leave?"
            />
          )}
          {prescribing && !noteEditableMode && !feedbackViewOnly && (
            <>
              <Row>
                <img
                  className="mx-2 my-3"
                  style={{ objectFit: 'contain', cursor: 'pointer' }}
                  height="32"
                  width="32"
                  src="/assets/left-arrow.png"
                  alt="Previous step"
                  onClick={prescriptionPreviousStep}
                />
              </Row>
              <EPrescribing
                isNewPrescription={true}
                className="my-3 px-0"
                step={step}
                onChangeStep={prescriptionNextStep}
                patientId={booking.patientId}
                bookingId={booking.id}
              />
            </>
          )}
          {showFeedBack && !noteEditableMode && !feedbackViewOnly && (
            <Row className="d-flex flex-column justify-content-between align-items-stretch flex-grow-1">
              <div style={{ height: '20%' }}>
                <Col xs={12}>
                  <h2 className="mt-3 mb-4 text-center">Feedback to patient</h2>
                </Col>
                <div>
                  <div className="d-flex ">
                    <div className=" margin-left-15 vcenter margin-right-30">
                      Your Feedback
                    </div>
                    <StarRatingComponent
                      name={'feedback'}
                      value={rating}
                      starCount={5}
                      onStarClick={(next) => {
                        setRating(next);
                      }}
                      renderStarIcon={() => {
                        return <i className="feedback-star">★</i>;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="complete-height" style={{ height: '50%' }}>
                <Col className="mt-2 mb-2" xs={12}>
                  Your comment
                </Col>
                <Col
                  className="mt-2 mb-2 complete-height"
                  xs={12}
                  style={{ height: '90%' }}
                >
                  <textarea
                    style={{ height: '100%' }}
                    className="form-control complete-height"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </Col>
              </div>
              <div style={{ height: '30%' }}>
                <Col className="my-5 text-center" xs={12}>
                  <h6 className="text-center text-muted">
                    You can choose recommendations
                  </h6>
                </Col>

                <Col xs={12}>
                  <span className="ml-3 form-label">Select template note:</span>
                  <select
                    onChange={(e) => handlerSelectTemplate(e.target.value)}
                    className="w-100 form-control"
                  >
                    {bookingHasNote && (
                      <option key={-1} value={-1} selected>
                        Saved Note
                      </option>
                    )}
                    <option key={0} value={0}>
                      Basic Template
                    </option>
                    {noteTemplates.map((template) => (
                      <option
                        key={template.id}
                        value={template.id}
                        selected={
                          templateSelected &&
                          templateSelected != 'basic' &&
                          templateSelected.id == template.id
                        }
                      >
                        {template.title}
                      </option>
                    ))}
                  </select>
                </Col>

                <Col xs={12}>
                  <Button
                    className="w-100 mb-2 mt-2"
                    variant="primary"
                    onClick={renderNotes}
                  >
                    Write down notes for patient
                  </Button>
                </Col>

                <Col xs={12}>
                  <Button
                    className="w-100 mb-2 mt-2"
                    variant="primary"
                    onClick={prescriptionNextStep}
                  >
                    E-Prescribe or Refill Prescription
                  </Button>
                </Col>

                <Col xs={12}>
                  <Button
                    className="w-100 mb-4 mt-2"
                    variant="outline-primary"
                    onClick={sendComment}
                  >
                    Send comment
                  </Button>
                </Col>
              </div>
            </Row>
          )}
          {(showNotes || noteEditableMode) && (
            <div className="w-100 d-flex flex-column flex-grow-1">
              {!noteEditableMode && (
                <Row>
                  <img
                    className="mx-2 my-3"
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                    height="32"
                    width="32"
                    src="/assets/left-arrow.png"
                    alt="Previous step"
                    onClick={() => {
                      setShowFeedback(true);
                      setShowNotes(false);
                      //Here because the templateSelect resets but not the templateSelectedState
                      setTemplateSelected(bookingHasNote ? 'loaded' : 'basic');
                    }}
                  />
                </Row>
              )}

              <Row className="h-75 mb-4">
                <Col className="d-flex text-center full-height">
                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    className="d-flex flex-column w-100"
                  >
                    <Form.Label
                      className={`mb-4 ${noteEditableMode && 'mt-4'}`}
                    >
                      Write notes for patient
                    </Form.Label>
                    {<h1>{templateSelected && templateSelected.title} </h1>}
                    <NotesTextArea notes={notesObject} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="w-100 mb-2"
                    variant="primary"
                    onClick={updateNotes}
                  >
                    Send note
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState, { bookingId }) => ({
  doctorId: state.provider.id,
  booking: state.bookings.entities[bookingId],
  templates: state.templates,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...BookingsActions,
      ...TemplatesActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackComponent);
