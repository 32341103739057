import { ErrorAction } from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';
import { take, race, delay, put } from 'redux-saga/effects';

import { MMDError } from '../utils/MMDError';
import { MyState } from '../store';

interface ActionTypes {
  SET_ERROR: string;
  CLEAR_ERROR: string;
}

interface ActionCreators {
  setError: <TError extends Error>(error: TError) => ErrorAction<TError>;
  clearError: () => Action;
}

export interface ErrorState {
  error?: MMDError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  setError: ['error'],
  clearError: [],
});

const initialState = {};

function setError(
  state: ErrorState,
  { error }: ErrorAction<MMDError>,
): ErrorState {
  if (error.message) {
    return { error };
  } else {
    return state;
  }
}

function clearError(): ErrorState {
  return initialState;
}

export const errorReducer = createReducer(initialState, {
  [Types.SET_ERROR]: setError,
  [Types.CLEAR_ERROR]: clearError,
});

function* clearErrorAfterFiveSeconds(): SagaIterator {
  while (yield take(Types.SET_ERROR)) {
    const { manualClear } = yield race({
      manualClear: take(Types.CLEAR_ERROR),
      timeout: delay(5000),
    });

    if (manualClear) {
      continue;
    }

    yield put(Creators.clearError());
  }
}

export const errorSagas = [clearErrorAfterFiveSeconds];

export const selectErrorState = (state: MyState) => state.error;
