import { Prescription } from '../../typings';
import { MMDError } from '../../utils/MMDError';
import { AUTH_API_URL } from '../../config';

export async function submitPrescription(
  code: number,
  p: Prescription & { patientId: string; bookingId: string },
  refillRequestId: string | null,
): Promise<any> {
  const authInfo = await JSON.parse(
    localStorage.getItem('moment.session') || '',
  );
  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);
  headers.append('Content-Type', 'application/json');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append(
    'Access-Control-Allow-Methods',
    'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  );
  headers.append(
    'Access-Control-Allow-Headers',
    'X-Requested-With,content-type',
  );
  headers.append('Access-Control-Allow-Credentials', 'true');
  console.log(p);
  const validationResult = await fetch(
    `${AUTH_API_URL}/doctors/validate-security-code/${code}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!validationResult.ok) {
    throw new MMDError('Your six digit code is not correct. Please try again!');
  }

  const jsonResult = await validationResult.json();

  if (!jsonResult.response) {
    throw new MMDError(jsonResult.message);
  }

  const sendPrescriptionResult = await fetch(`${AUTH_API_URL}/prescriptions/`, {
    headers,
    method: 'POST',
    body: JSON.stringify(p),
  });

  if (!sendPrescriptionResult.ok) {
    throw new MMDError(
      'There was an error processing your prescription. Please try again later...',
    );
  } else {
    if (refillRequestId) {
      const deleteRefillRequestResult = await fetch(
        `${AUTH_API_URL}/bookings/refill-request/${refillRequestId}`,
        {
          headers,
          method: 'DELETE',
        },
      );

      if (!deleteRefillRequestResult.ok) {
        throw new MMDError(
          'There was an error processing your prescription. Please try again later...',
        );
      }
    }

    return sendPrescriptionResult.json();
  }
}

export async function initializeSecurityNumber(): Promise<any> {
  const authInfo = await JSON.parse(
    localStorage.getItem('moment.session') || '',
  );
  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);
  headers.append('Content-Type', 'application/json');

  const validationResult = await fetch(
    `${AUTH_API_URL}/doctors/validate-security-code/`,
    {
      headers,
      method: 'GET',
    },
  );

  const jsonResult = await validationResult.json();
  if (jsonResult.response && jsonResult.isBrandNewCode) {
    throw new MMDError(jsonResult.message);
  }
}
