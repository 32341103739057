export class MMDError extends Error {
  originalError = null;

  constructor(
    public message: string,
    originalError?: any,
  ) {
    super();
    this.originalError = originalError;
  }
}
