import { connect } from 'react-redux';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { Col, Container, Row } from 'react-bootstrap';

import DoctorProfile from '../components/DoctorProfile';
import PatientProfile from '../components/PatientProfile';
import { Creators as favoritePharmacyActions } from '../modules/pharmacy.module';
import { Creators as visitHistoryActions } from '../modules/visitHistory.module';
import { MyState } from '../store';
import FavoritePharmacy from './visit-history/FavoritePharmacy';
import VisitHistoryDetails from './visit-history/VisitHistoryDetails';

type Props = {
  visitHistory: any;
  favoritePharmacy: any;
  isStudent: boolean;
} & typeof visitHistoryActions &
  typeof favoritePharmacyActions;

const VisitHistory: FC<Props> = ({
  requestVisitHistory,
  requestFavoritePharmacy,
  clearVisitHistory,
  visitHistory,
  favoritePharmacy,
  isStudent,
}) => {
  const params = useParams<{ id: string; visitId: string }>();
  useEffect(() => {
    requestVisitHistory({ patientId: params.id!, visitId: params.visitId! });
    requestFavoritePharmacy({ patientId: params.id! });
    return () => {
      clearVisitHistory();
    };
  }, [params, requestVisitHistory, requestFavoritePharmacy]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>Visit History</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Col className="w-100 padding-h-40" xs={12}>
            <PatientProfile addBorder={false} patientId={params.id} />
          </Col>
          <div className="w-100 padding-vertical-20 "></div>
          {visitHistory && visitHistory.id !== undefined && (
            <VisitHistoryDetails
              visitHistory={visitHistory}
              isStudent={isStudent}
            />
          )}
        </Col>
        <Col xs={12} sm={12} md={12} lg={3}>
          <FavoritePharmacy favoritePharmacy={favoritePharmacy} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  visitHistory: state.visitHistory,
  favoritePharmacy: state.pharmacy.favorite,
  isStudent: !!(state.provider && state.provider.teacher),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...visitHistoryActions,
      ...favoritePharmacyActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VisitHistory);
