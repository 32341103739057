import { FC, useEffect, useState } from 'react';
import MMDModal from '../../components/MMDModal';
import { Row, Col, Button } from 'react-bootstrap';
import { Language } from '../../typings';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import MMDCheckableItem from '../../components/MMDCheckableItem';

interface Props {
  handleClose: () => void;
  handleUpdateLanguages: (ids: number[]) => void;
  show: boolean;
  allLanguages: Language[];
  languages: Language[];
}

const ModalLanguages: FC<Props> = ({
  handleClose,
  handleUpdateLanguages,
  show,
  languages,
  allLanguages,
}) => {
  const [languageSelected, setLanguageSelected] = useState([]);

  useEffect(() => {
    const languageCheckable = allLanguages.map((alllang) => ({
      ...alllang,
      checked: languages.some((lang) => lang.name === alllang.name),
    }));
    setLanguageSelected(languageCheckable);
  }, [languages, allLanguages]);

  return (
    <MMDModal
      show={show}
      title={'Languages'}
      handleClose={handleClose}
      classNameContainer={'modal-container-padding'}
      renderBody={() => (
        <Row>
          <Col xs={12} className="mb-1 mt-1">
            {languageSelected.map((lang, i) => (
              <MMDCheckableItem
                key={lang.id}
                className={`short-bottom-separator cursor-pointer padding-vertical-10 ${
                  i === 0 ? 'short-top-separator' : ''
                }`}
                check={lang.checked}
                title={lang.name}
                icon={faCheck}
                onClick={() => {
                  const languageCheckable = languageSelected.map((selected) => {
                    if (selected.name === lang.name) {
                      return {
                        ...selected,
                        checked: !lang.checked,
                      };
                    } else {
                      return selected;
                    }
                  });
                  setLanguageSelected(languageCheckable);
                }}
              />
            ))}
          </Col>
        </Row>
      )}
      renderFooter={() => (
        <Row className="w-100 center-footer">
          <Col
            xs={12}
            className="text-center modal-footer-btn margin-bottom-10"
          >
            <Button
              className=" primary height-30 padding-h-50"
              variant="primary"
              onClick={() => {
                const languagesSelectedId = languageSelected
                  .filter((langSelected) => langSelected.checked)
                  .map((l) => l.id);
                handleUpdateLanguages(languagesSelectedId);
              }}
            >
              <h6>Close </h6>
            </Button>
          </Col>
        </Row>
      )}
    />
  );
};

export default ModalLanguages;
