export function getExtension(base64String) {
  let ext = '.png';
  if (base64String.includes('jpeg')) {
    ext = '.jpeg';
  }
  if (base64String.includes('jpg')) {
    ext = '.jpg';
  }
  return ext;
}
