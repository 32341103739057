import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware, { SagaIterator } from 'redux-saga';
import { all } from 'redux-saga/effects';
import {
  termAndConditionReducer as termsAndConditions,
  termAndConditionSagas,
  TermsAndConditionsState,
} from './modules/terms-and-conditions.module';
import { forkList } from './utils/forkList';
import {
  errorReducer as error,
  ErrorState,
  errorSagas,
} from './modules/errors.module';
import {
  loadingReducer as loading,
  loadingSagas,
  LoadingState,
} from './modules/loading.module';
import {
  authReducer as auth,
  authSagas,
  AuthState,
} from './modules/auth.module';
import {
  signupSagas,
  signupReducer as signup,
  SignupState,
} from './modules/signup.module';
import {
  PublicWaitingRoomState,
  publicWaitingRoomReducer as publicWaitingRoom,
  waitingRoomSagas,
} from './modules/videocall-public/waiting-room.module';
import {
  providersReducer as provider,
  providersSagas,
  ProvidersState,
} from './modules/provider.module';
import {
  patientsReducer as patients,
  patientsSagas,
  PatientsState,
} from './modules/patients.module';
import {
  visitHistoryReducer as visitHistory,
  visitHistorySagas,
  VisitHistoryState,
} from './modules/visitHistory.module';
import {
  favoritePharmacyReducer as favoritePharmacy,
  favoritePharmacySagas,
  FavoritePharmacyState,
} from './modules/pharmacy.module';
import {
  bookingsReducer as bookings,
  bookingsSagas,
  BookingsState,
} from './modules/bookings.module';
import {
  bookingsPublicReducer as bookingsPublic,
  bookingsPublicSagas,
  BookingsPublicState,
} from './modules/bookings-public.module';
import {
  timeslotsReducer as timeslots,
  timeslotsSagas,
  TimeslotsState,
} from './modules/timeslot.module';
import {
  patientInfoReducer as patientInfo,
  patientInfoSagas,
  PatientInfoState,
} from './modules/patient-info.module';
import {
  affiliationsReducer as affiliations,
  affiliationsSagas,
  AffiliationsState,
} from './modules/affiliations.module';
import {
  languageReducer as languages,
  languageSagas,
  LanguageState,
} from './modules/language.module';
import {
  specialitiesReducer as specialities,
  specialitiesSagas,
  SpecialitiesState,
} from './modules/specialities.module';
import {
  cardReducer as card,
  cardSaga,
  CardState,
} from './modules/cards.module';
import {
  prescriptionRefillsReducer as prescriptionRefills,
  prescriptionRefillsSagas,
  RefillsState,
} from './modules/prescription-refills.module';
import {
  chatroomsReducer as chatrooms,
  chatroomsSagas,
  ChatroomsState,
} from './modules/chatrooms.module';
import {
  selectedChatroomReducer as selectedChatroom,
  selectedChatroomSagas,
  SelectedChatroomState,
} from './modules/chatroom/selected-chatroom.module';
import {
  paymentsReducer as payments,
  paymentsSagas,
  PaymentState,
} from './modules/payments.module';
import {
  earningsReducer as earnings,
  earningsSagas,
  EarningsState,
} from './modules/payments/earnings.module';
import {
  videoCallReducer as videoCall,
  videoCallSagas,
  VideoCallState,
} from './modules/videocall.module';
import {
  videoCallFilesReducer as videoCallFiles,
  videoCallFilesSagas,
  VideoCallFilesState,
} from './modules/videocall/files.module';
import {
  videoCallFilesPublicReducer as videoCallFilesPublic,
  videoCallFilesPublicSagas,
  VideoCallFilesPublicState,
} from './modules/videocall-public/files-public.module';
import {
  videoCallNotesReducer as videoCallNotes,
  videoCallNotesSagas,
  VideoCallNotesState,
} from './modules/videocall/notes.module';
import {
  videoCallNotesPublicReducer as videoCallNotesPublic,
  videoCallNotesPublicSagas,
  VideoCallNotesPublicState,
} from './modules/videocall-public/notes-public.module';
import {
  videoCallPrescriptionsReducer as videoCallPrescriptions,
  videoCallPrescriptionsSagas,
  VideoCallPrescriptionsState,
} from './modules/videocall/prescriptions.module';
import {
  calendarBookingsReducer as calendarBookings,
  calendarBookingsSagas,
  CalendarBookingsState,
} from './modules/calendar.module';
import {
  TemplatesReducer as templates,
  templatesSagas,
  templatesState,
} from './modules/template.module';
import {
  videoCallPublicReducer as videoCallPublic,
  videoCallPublicSagas,
  VideoCallPublicState,
} from './modules/videocall-public.module';
import {
  videoCallClientPublicReducer as videoCallClientPublic,
  videoCallClientPublicSagas,
  VideoCallClientPublicState,
} from './modules/videocall-client-public.module';
import { createReduxHistoryContext, RouterState } from 'redux-first-history';
import { notificationSagas } from './modules/notifications.module';
import {
  hostPublicWaitingRoomReducer as hostPublicWaitingRoom,
  hostWaitingRoomSagas,
  HostPublicWaitingRoomState,
} from './modules/videocall-public/host-waiting-room.module';
import {
  patientConsentSagas,
  PatientConsentState,
  patientConsentReducer as patientConsent,
} from './modules/patient-consent.module';
import {
  publicVisitHistorySagas,
  PublicVisitHistoryState,
  publicVisitHistoryReducer as publicVisitHistory,
} from './modules/visit-history-public.module';
import {
  bankAccountSagas,
  BankAccountState,
  bankAccountReducer as bankAccount,
} from './modules/bank-account.module';

export interface MyState {
  error: ErrorState;
  loading: LoadingState;
  auth: AuthState;
  router: RouterState;
  termsAndConditions: TermsAndConditionsState;
  provider: ProvidersState;
  patients: PatientsState;
  bookings: BookingsState;
  bookingsPublic: BookingsPublicState;
  timeslots: TimeslotsState;
  prescriptionRefills: RefillsState;
  chatrooms: ChatroomsState;
  card: CardState;
  chatroom: {
    selectedChatroom: SelectedChatroomState;
  };
  patientInfo: PatientInfoState;
  payments: PaymentState;
  payment: {
    earnings: EarningsState;
  };
  videoCall: VideoCallState;
  videoCallPublic: VideoCallPublicState;
  videoCallClientPublic: VideoCallClientPublicState;
  videoCallExtras: {
    files: VideoCallFilesState;
    notes: VideoCallNotesState;
    prescriptions: VideoCallPrescriptionsState;
  };
  videoCallPublicExtras: {
    notes: VideoCallNotesPublicState;
    files: VideoCallFilesPublicState;
  };
  affiliations: AffiliationsState;
  specialities: SpecialitiesState;
  languages: LanguageState;
  signup: SignupState;
  visitHistory: VisitHistoryState;
  pharmacy: FavoritePharmacyState;
  calendarBookings: CalendarBookingsState;
  templates: templatesState;
  publicWaitingRoom: PublicWaitingRoomState;
  hostPublicWaitingRoom: HostPublicWaitingRoomState;
  patientConsent: PatientConsentState;
  publicVisitHistory: PublicVisitHistoryState;
  bankAccount: BankAccountState;
}

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const rootReducer = combineReducers({
  auth,
  error,
  loading,
  provider,
  router: routerReducer,
  termsAndConditions,
  patients,
  bookings,
  bookingsPublic,
  timeslots,
  prescriptionRefills,
  chatrooms,
  card,
  chatroom: combineReducers({
    selectedChatroom,
  }),
  patientInfo,
  payments,
  payment: combineReducers({
    earnings,
  }),
  videoCall,
  videoCallPublic,
  videoCallClientPublic,
  videoCallExtras: combineReducers({
    files: videoCallFiles,
    notes: videoCallNotes,
    prescriptions: videoCallPrescriptions,
  }),
  videoCallPublicExtras: combineReducers({
    files: videoCallFilesPublic,
    notes: videoCallNotesPublic,
  }),
  affiliations,
  specialities,
  languages,
  signup,
  visitHistory,
  pharmacy: favoritePharmacy,
  calendarBookings,
  templates,
  publicWaitingRoom,
  hostPublicWaitingRoom,
  patientConsent,
  publicVisitHistory,
  bankAccount,
});

const sagaMiddleware = createSagaMiddleware();
const enhancer = applyMiddleware(sagaMiddleware, routerMiddleware);

const store = createStore(
  rootReducer,
  compose(
    enhancer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f,
  ),
);

export const history = createReduxHistory(store);

function* rootSaga(): SagaIterator {
  yield all([
    ...forkList(termAndConditionSagas),
    ...forkList(authSagas),
    ...forkList(loadingSagas),
    ...forkList(errorSagas),
    ...forkList(signupSagas),
    ...forkList(providersSagas),
    ...forkList(patientsSagas),
    ...forkList(cardSaga),
    ...forkList(bookingsSagas),
    ...forkList(bookingsPublicSagas),
    ...forkList(timeslotsSagas),
    ...forkList(prescriptionRefillsSagas),
    ...forkList(chatroomsSagas),
    ...forkList(selectedChatroomSagas),
    ...forkList(patientInfoSagas),
    ...forkList(paymentsSagas),
    ...forkList(earningsSagas),
    ...forkList(videoCallSagas),
    ...forkList(videoCallPublicSagas),
    ...forkList(videoCallClientPublicSagas),
    ...forkList(videoCallFilesSagas),
    ...forkList(videoCallFilesPublicSagas),
    ...forkList(videoCallNotesSagas),
    ...forkList(videoCallNotesPublicSagas),
    ...forkList(videoCallPrescriptionsSagas),
    ...forkList(affiliationsSagas),
    ...forkList(specialitiesSagas),
    ...forkList(languageSagas),
    ...forkList(visitHistorySagas),
    ...forkList(favoritePharmacySagas),
    ...forkList(calendarBookingsSagas),
    ...forkList(templatesSagas),
    ...forkList(notificationSagas),
    ...forkList(waitingRoomSagas),
    ...forkList(hostWaitingRoomSagas),
    ...forkList(patientConsentSagas),
    ...forkList(publicVisitHistorySagas),
    ...forkList(bankAccountSagas),
  ]);
}

sagaMiddleware.run(rootSaga);

export default store;
