import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { MenuItem } from '../../../utils/typings';
import { FC, useCallback, MouseEvent } from 'react';

interface Props {
  menuItem: MenuItem;
  currentLocation: string;
  titleHelper: any;
}

const MMDMenuItem: FC<Props> = ({ menuItem, currentLocation, titleHelper }) => {
  const navigate = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      menuItem.onPress();
    },
    [menuItem],
  );

  const currentLocationSplit = currentLocation.split('/').filter((p) => p)[0];
  const menuItemSplit = menuItem.path.split('/').filter((p) => p)[0];

  return !menuItem.items ? (
    <Nav.Link
      className="pl-4 pr-4"
      active={currentLocationSplit === menuItemSplit}
    >
      <span {...(menuItem.items ? {} : { onClick: navigate })}>
        {titleHelper(menuItem.text)}
      </span>
    </Nav.Link>
  ) : (
    <NavDropdown
      title={menuItem.text}
      id="basic-nav-dropdown"
      active={currentLocationSplit === menuItemSplit}
    >
      {menuItem.items.map((item) => (
        <NavDropdown.Item key={item.text}>
          <Navbar.Text>
            <a onClick={item.onPress}>{item.text}</a>
          </Navbar.Text>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default MMDMenuItem;
