import moment from 'moment/moment';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Fullscreen from 'react-fullscreen-crossbrowser';
import { useNavigate, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';

import { MuteProps } from './videocall/MuteAudio';
import { useIsStudentCheck } from '../hooks/useIsStudentCheck';
import { useBoundedActions } from '../hooks/useBoundedActions';
import VideoCallViewPublic from './videocall/VideoCallViewPublic';
import SidePanelPublic from './videocall/video-call-view-public/SidePanelPublic';
import {
  selectBookingsPublicState,
  Creators as BookingsPublicActions,
} from '../modules/bookings-public.module';
import {
  selectVideoCallPublicState,
  Creators as VideoCallPublicActions,
} from '../modules/videocall-public.module';
import {
  filesPublicSelectors,
  Creators as FilesPublicActions,
} from '../modules/videocall-public/files-public.module';
import { Creators as HostWaitingRoomPublicActions } from '../modules/videocall-public/host-waiting-room.module';

const VideoCallPublic: FC = () => {
  const [isSidePanelVisible, setSidePanelVisible] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [isCallFinished, setIsCallFinished] = useState(false);
  const files = null;

  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const {
    clearBookingPublic,
    requestFilesPublic,
    requestBookingPublic,
    requestUploadFilePublic,
    disconnectHostWebsocket,
    initializeHostWebsocket,
    videoCallLogEventPublic,
    subscribeExtensionPublic,
    updateBookingNotePublic,
    requestDisconnectVideoPublic,
    requestMuteLocalAudioTrackPublic,
    requestUnmuteLocalAudioTrackPublic,
  } = useBoundedActions({
    ...BookingsPublicActions,
    ...VideoCallPublicActions,
    ...FilesPublicActions,
    ...HostWaitingRoomPublicActions,
  });

  const { booking } = useSelector(selectBookingsPublicState);

  const isStudent = useIsStudentCheck();

  const { room, extended, localTracks, isAudioMuted } = useSelector(
    selectVideoCallPublicState,
  );

  const documents = useSelector(filesPublicSelectors.selectDocuments);
  const pictures = useSelector(filesPublicSelectors.selectPictures);

  useEffect(() => {
    if (booking) {
      clearBookingPublic();
    }

    const bookingId = { eventId: params.id };
    requestBookingPublic(bookingId);
    if (!isStudent) {
      initializeHostWebsocket(params.id);
    }
  }, []);

  useEffect(() => {
    if (isCallFinished) {
      videoCallEvent('Leaving the meeting by disconnect');

      navigate(isStudent ? '/my-appts' : `/feedback/public/${params.id}`);

      if (!isStudent) {
        disconnectHostWebsocket();
      }
    }
  }, [isCallFinished]);

  useEffect(() => {
    if (!booking || !room) {
      return;
    }

    const intervalId = setInterval(() => {
      if (moment(moment()).isSameOrAfter(booking.endDate)) {
        setIsCallFinished(true);
        clearInterval(intervalId);
      }
    }, 1000);
    return () => {
      if (!isStudent) {
        disconnectHostWebsocket();
      }
      clearInterval(intervalId);
    };
  }, [booking, room]);

  useEffect(() => {
    if (isSidePanelVisible) {
      requestFilesPublic({ id: booking.eventId });
    }
  }, [isSidePanelVisible]);

  const requestSidePanel = useCallback(() => {
    setSidePanelVisible(!isSidePanelVisible);
  }, [isSidePanelVisible, setSidePanelVisible]);

  const videoCallEvent = useCallback(
    (event) => videoCallLogEventPublic({ event }),
    [videoCallLogEventPublic],
  );

  const sendNote = useCallback(
    (body: string) =>
      updateBookingNotePublic({ id: params.id!, bookingNotePublic: body }),
    [params.id, updateBookingNotePublic],
  );

  const saveFile = useCallback(
    (file: File) =>
      requestUploadFilePublic({
        bookingId: booking.eventId,
        doctorId: booking.doctorId,
        file,
      }),
    [requestUploadFilePublic, booking],
  );

  if (!booking) {
    return null;
  }

  const onFullScreenChange = (fullscreenChange: boolean) =>
    setFullScreen((prevState) =>
      prevState !== fullscreenChange ? fullscreenChange : prevState,
    );

  const muteProps: MuteProps = {
    onMute: requestMuteLocalAudioTrackPublic,
    onUnmute: requestUnmuteLocalAudioTrackPublic,
    isAudioMuted: isAudioMuted,
  };

  return (
    <Fullscreen enabled={fullScreen} onChange={onFullScreenChange}>
      <Row className="m-0 full-screen-component">
        <VideoCallViewPublic
          room={room}
          booking={booking}
          saveFile={saveFile}
          extended={extended}
          muteProps={muteProps}
          fullScreen={fullScreen}
          localTracks={localTracks}
          eventLogger={videoCallEvent}
          onRequestSidePanel={requestSidePanel}
          cleanup={requestDisconnectVideoPublic}
          shrinkHorizontally={isSidePanelVisible}
          isSidePanelVisible={isSidePanelVisible}
          setFullScreen={() => setFullScreen(!fullScreen)}
          subscribeExtensionPublic={subscribeExtensionPublic}
        />

        <SidePanelPublic
          withNotes
          files={files}
          booking={booking}
          pictures={pictures}
          documents={documents}
          sendNotePublic={sendNote}
          visible={isSidePanelVisible}
          onRequestClose={requestSidePanel}
          notes={booking.bookingNotePublic?.note ?? ''}
        />
      </Row>
    </Fullscreen>
  );
};

export default VideoCallPublic;
