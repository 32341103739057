import moment from 'moment';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import JoinForm from './JoinForm';
import WaitingModal from './WaitingModal';
import { useBoundedActions } from '../../../hooks/useBoundedActions';
import { Creators as PublicWaitingRoomActions } from '../../../modules/videocall-public/waiting-room.module';
import {
  selectVideoCallClientPublicState,
  Creators as VideoCallClientPublicActions,
} from '../../../modules/videocall-client-public.module';

const JoinPublicVideoCall: FC = () => {
  const { id } = useParams();

  const {
    initializeParticipantWebsocket,
    requestBookingClientPublic,
    requestJoinParticipantToTheCall,
  } = useBoundedActions({
    ...VideoCallClientPublicActions,
    ...PublicWaitingRoomActions,
  });

  const { booking } = useSelector(selectVideoCallClientPublicState);

  useEffect(() => {
    requestBookingClientPublic({ bookingId: id });
  }, []);

  const isVideoCallInFuture = moment(booking?.startDate).isAfter(moment());
  const isVideoCallInPast = moment(booking?.endDate).isBefore(moment());

  useEffect(() => {
    if (!booking || isVideoCallInPast || isVideoCallInFuture) {
      return;
    }

    initializeParticipantWebsocket({
      roomName: booking.eventId,
      successNavigateUrl: `/public-appointment/video-call/${booking.eventId}`,
    });
  }, [booking]);

  const renderContent = () => {
    if (!booking) {
      return null;
    }

    if (isVideoCallInFuture) {
      return (
        <div className="text-center fw-bold">
          You're too early! Your call starts&nbsp;
          {moment(booking.startDate).fromNow()}
        </div>
      );
    }

    if (isVideoCallInPast) {
      return (
        <div className="text-center fw-bold">
          You're too late! Call ended&nbsp;
          {moment(booking.endDate).fromNow()}
        </div>
      );
    }

    return <JoinForm onJoinParticipant={requestJoinParticipantToTheCall} />;
  };

  return (
    <Container className="pt-5">
      <Row>
        <Col className="mx-auto text-center justify-content-center">
          <img height="200" src="/assets/momentMDLogo.png" alt="MomentMD" />
        </Col>
      </Row>

      <Row className="border-bottom justify-content-center pb-4 mb-3">
        <Col xs="8" className="text-center mx-auto w-100">
          <h2>Video consultation</h2>
        </Col>
      </Row>

      {renderContent()}

      <div className="text-center mt-4">
        <a href="mailto:admin@momentmd.com">Contact us</a>
      </div>

      <WaitingModal />
    </Container>
  );
};

export default JoinPublicVideoCall;
