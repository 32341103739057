import {
  Button,
  Container,
  Row,
  Col,
  FormLabel,
  FormControl,
  Alert,
} from 'react-bootstrap';

import { auth } from '../utils/Firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';

const SignIn = () => {
  const [timeleft, setTimeleft] = useState(10);
  const [resendActive, setResendActive] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const resendPasswordVerification = async () => {
    try {
      await sendPasswordResetEmail(auth, forgotPasswordEmail);
    } catch (error) {
      setIsError(true);
      setMessage(error.message);
      return false;
    }
    return true;
  };

  const handleResend = async () => {
    const downloadTimer = setInterval(() => {
      let count = 10;
      setTimeleft((prevCount) => (count = prevCount - 1));
      if (count === 0) {
        setResendActive(false);
        clearInterval(downloadTimer);
        setTimeleft(10);
      }
    }, 1000);
    const res = await resendPasswordVerification();
    if (res) {
      setIsError(false);
      setMessage(
        'Email sended successfully, please check email to recover password',
      );
      setResendActive(true);
    }
  };

  return (
    <Container fluid>
      {message && (
        <Alert
          variant={isError ? 'danger' : 'success'}
          dismissible
          onClose={() => setMessage('')}
        >
          <strong>{isError ? 'Error:' : 'Success'}:</strong> {message}
        </Alert>
      )}
      <Row>
        <Col className="mb-5 mt-3">Breadcumb</Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12}>
          <h5 style={{ textAlign: 'center' }}>Forgot password</h5>
        </Col>
        <Col className="text-center mt-4" xs={12}>
          <Row className="justify-content-center">
            <Col xs={12} className="mb-3">
              <p>Please enter the email of the account you want to recover</p>
            </Col>
            <Col xs={12} className="mb-3">
              <FormLabel className="ml-3 form-label" htmlFor={'forgotEmail'}>
                Recover Email
              </FormLabel>
              <FormControl
                type="text"
                placeholder="jhondoe@email.com"
                name="forgotEmail"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
            </Col>
            {!resendActive ? (
              <Button className="mt-4" onClick={handleResend} variant="primary">
                SEND RECOVER EMAIL
              </Button>
            ) : (
              <p className="mt-4">{`You can resend email in ${timeleft}`}</p>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
