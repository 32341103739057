import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Col, Container, Row } from 'react-bootstrap';

import DebitCard from './DebitCard';
import AchAccount from './ACHAccount';

const API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

const stripePromise = loadStripe(API_KEY);

const PaymentMethod = () => (
  <Elements stripe={stripePromise}>
    <Container className="mb-3 mx-2">
      <Row className="align-items-stretch">
        <Col xs={5}>
          <DebitCard />
        </Col>
        <Col xs={2} className="col d-flex justify-content-center">
          <div className="vertical-line" />
        </Col>
        <Col xs={5}>
          <AchAccount />
        </Col>
      </Row>
      <Row></Row>
    </Container>
  </Elements>
);

export default PaymentMethod;
