import { FC } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { chatroomSelectors } from '../../modules/chatrooms.module';
import { Patient } from '../../typings';
import { MyState } from '../../store';
import { Link } from 'react-router-dom';

type Props = {
  chatId?: string;
  patient: Patient;
};

const PatientRow: FC<Props> = ({ patient }) => (
  <Container fluid>
    <Row className="p-2 patient-border">
      <Col
        xs={2}
        style={{ display: 'block', margin: 'auto' }}
        className="vcenter"
      >
        <Link to={patient.id} style={{ display: 'flex' }}>
          <Image
            roundedCircle
            style={{ height: 80, width: 80, objectFit: 'cover' }}
            src={
              patient.avatar ||
              (!patient.relativeType || patient.relativeType === 'children'
                ? '/assets/default-child.png'
                : '/assets/default-pet.png')
            }
            className="mx-auto"
          />
        </Link>
      </Col>
      <Col className="pt-4" lg={7}>
        <p className="mb-2 font-weight-bold">
          {patient.relativeType !== 'pets'
            ? `${patient.firstName} ${patient.lastName}`
            : `${patient.name}`}
        </p>
        <p className="mb-1 text-capitalize">
          {patient.gender}{' '}
          <span style={{ color: '#1FB9F1' }}>{patient.birthday}</span>
        </p>
        <p className="mb-1">{`${patient.city}, ${patient.state}`}</p>
      </Col>
      <Col lg={3} className="vcenter">
        {/* <Link to={`chats/${chatId}`} className="w-50 btn patient-button position-absolute" style={{right: '15px'}}> Chat </Link> */}
      </Col>
    </Row>
  </Container>
);

const mapStateToProps = (state: MyState, { patient }: Props) => ({
  chatId: chatroomSelectors.getLastChatroomForPatient(patient.id)(
    state.chatrooms,
  ),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PatientRow);
