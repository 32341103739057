import { Button, Container, Row } from 'react-bootstrap';
import SignatureField from './signup/signup-form/step-two/SignatureField';
import FileDropzone from '../components/FileDropzone';
import ItemWithNotification from './dashboard/ItemWithNotification';
import MessageBubble from './chat/MessageBubble';

const ComponentShowroom = () => (
  <Container>
    <h1>
      All our components are here!{' '}
      <span role="img" aria-label="smiley face">
        😃
      </span>
    </h1>

    <strong>Buttons</strong>
    <Row className="mb-2">
      <Button className="mr-1" variant="primary">
        Primary
      </Button>
      <Button className="mr-1" variant="secondary">
        Secondary
      </Button>
    </Row>

    <SignatureField height={256} />

    <FileDropzone
      name="hello"
      renderer={() => <Button>Upload your ID</Button>}
    />

    <ItemWithNotification attribute="Message" notification={3} url="/chats" />

    <MessageBubble
      className="mb-3"
      body="Hello there"
      received
      timestamp="2019-07-12T18:33:32.773Z"
    />
    <MessageBubble
      body="Hello there"
      received={false}
      timestamp="2019-07-12T18:33:32.773Z"
    />

    <p>
      To check out all the components offered by <code>react-bootstrap</code> go
      to{' '}
      <a href="https://react-bootstrap.github.io/components/alerts">this url</a>
    </p>
  </Container>
);

export default ComponentShowroom;
