import { FC, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import DocumentsDetails from './DocumentsDetails';
import LifestyleDetails from './LifestyleDetails';
import MMDAccordion from '../../components/MMDAccordion';
import FamilyHistoryDetails from './FamilyHistoryDetails';
import MedicalHistoryDetails from './MedicalHistoryDetails';
import { useBoundedActions } from '../../hooks/useBoundedActions';
import PatientPublicVisitHistoryTable from './patient-visit-history/PatientPublicVisitHistoryTable';
import {
  selectPatientInfoState,
  Creators as patientInfoActions,
} from '../../modules/patient-info.module';
import { Creators as patientsActions } from '../../modules/patients.module';
import PatientVisitHistoryTable from './patient-visit-history/PatientVisitHistoryTable';
import { PatientVisitHistory } from './patient-visit-history/BasePatientVisitHistoryTable';

type Props = {
  addBorder?: boolean;
  patientId: string;
  isPatientChart?: boolean;
  onVisitHistoryClick?: (visit: PatientVisitHistory) => void;
  onVisitHistoryPublicClick?: (visit: PatientVisitHistory) => void;
};

const accordionData = [
  {
    title: 'Medical History',
    type: 'medical-history',
  },
  {
    title: 'Family history',
    type: 'family-history',
  },
  {
    title: 'Lifestyle',
    type: 'lifestyle',
  },
  {
    title: 'Documents',
    type: 'documents',
  },
  {
    title: 'Visit history',
    type: 'visit-history',
  },
  {
    title: 'Public Visit History',
    type: 'public-visit-history',
  },
];

const PatientInfoAccordion: FC<Props> = ({
  addBorder = true,
  patientId,
  onVisitHistoryClick,
  onVisitHistoryPublicClick,
  isPatientChart = false,
}) => {
  const {
    requestPatientInfo,
    commitPatientPublicVisitHistory,
    commitPatientVisitHistory,
  } = useBoundedActions({ ...patientInfoActions, ...patientsActions });

  const { patientInfo } = useSelector(selectPatientInfoState);

  useEffect(() => {
    requestPatientInfo({ id: patientId });
    commitPatientVisitHistory({ data: [], count: 0 });
    commitPatientPublicVisitHistory({ data: [], count: 0 });
  }, [patientId]);

  if (!patientInfo) return null;

  const { files } = patientInfo;
  return (
    <Row className="pb-2">
      <MMDAccordion
        data={accordionData}
        renderToggle={({ title }, idx, _, expandedIdx) => (
          <>
            <span>{title}</span>

            <FontAwesomeIcon
              className="float-right"
              style={{ color: '#C5C5C5' }}
              icon={idx === expandedIdx ? faChevronDown : faChevronRight}
            />
          </>
        )}
        style="w-100"
        styleContainer={`pt-2 justify-content-center mb-2 xs-12 ${
          addBorder ? 'dashboard-border' : 'border-0'
        }`}
        defaultKey={-1}
        renderCollapse={({ type }) => {
          switch (type) {
            case 'medical-history':
              return <MedicalHistoryDetails patientId={patientId} />;
            case 'family-history':
              return <FamilyHistoryDetails patientId={patientId} />;
            case 'lifestyle':
              return <LifestyleDetails patientId={patientId} />;
            case 'documents':
              return <DocumentsDetails files={files} patientId={patientId} />;
            case 'visit-history':
              return (
                <PatientVisitHistoryTable
                  patientId={patientId}
                  disableFullScreen={isPatientChart}
                  onVisitHistoryClick={onVisitHistoryClick}
                />
              );
            case 'public-visit-history':
              return (
                <PatientPublicVisitHistoryTable
                  patientId={patientId}
                  disableFullScreen={isPatientChart}
                  onVisitHistoryPublicClick={onVisitHistoryPublicClick}
                />
              );
            default:
              return null;
          }
        }}
      />
    </Row>
  );
};

export default PatientInfoAccordion;
