import React from 'react';
import { Button, Row } from 'react-bootstrap';

import MessageBubble from './MessageBubble';
import { Chatroom, Message } from '../../typings';

type Props = {
  chatroom: Chatroom;
  messages: Message[];

  getMoreMessages: () => void;
};

const MessageList: React.FC<Props> = ({
  chatroom,
  messages,
  getMoreMessages,
}) => {
  const div = React.useRef();

  React.useLayoutEffect(() => {
    if (!div) {
      return;
    }

    if (messages.length) {
      const height = (div.current as HTMLDivElement).scrollHeight;
      setTimeout(() => {
        if (div.current as HTMLDivElement) {
          (div.current as HTMLDivElement).scrollTo({
            top: height,
            behavior: 'auto',
          });
        }
      }, 500);
    }
  }, [div, messages]);

  return (
    <div ref={div} className="msg-container">
      <div className="mb-2 align-self-stretch justify-content-center d-flex">
        <Button variant="secondary" onClick={getMoreMessages}>
          Get more messages
        </Button>
      </div>

      {messages.length ? (
        messages.map((msg) => (
          <MessageBubble
            key={msg.id}
            className="mb-3"
            body={msg.body}
            received={msg.sender === chatroom.otherParticipant.id}
            timestamp={msg.createdAt}
          />
        ))
      ) : (
        <p className="text-center text-muted">
          There are no messages to show at the moment
        </p>
      )}
    </div>
  );
};

export default MessageList;
