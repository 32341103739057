import { FC, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Creators as patientsActions } from '../../modules/patients.module';
import { MyState } from '../../store';
import ToggleAccordion from './ToggleAccordion';

type Props = {
  patientId: string;
  familyHistory: any[];
} & typeof patientsActions;

const FamilyHistoryDetails: FC<Props> = ({
  patientId,
  familyHistory,
  requestAppointmentFamilyHistory,
}) => {
  useEffect(() => {
    requestAppointmentFamilyHistory({ id: patientId });
  }, [patientId, requestAppointmentFamilyHistory]);

  if (!familyHistory) return null;

  return (
    <Row className="margin-left-15 w-100 padding-10">
      {Object.keys(familyHistory).map((key) =>
        familyHistory[key] ? (
          <ToggleAccordion
            key={key}
            title={key}
            className="w-100 border-bottom border-top padding-10"
          />
        ) : null,
      )}
    </Row>
  );
};

const mapStateToProps = (state: MyState) => ({
  familyHistory: state.patients.familyHistory,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...patientsActions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FamilyHistoryDetails);
