import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import DoctorProfile from '../components/DoctorProfile';
import { useBoundedActions } from '../hooks/useBoundedActions';
import VisitHistoryDetailsPublic from './visit-history-public/VisitHistoryDetailsPublic';
import {
  selectPublicVisitHistoryState,
  Creators as publicVisitHistoryActions,
} from '../modules/visit-history-public.module';

type Props = {
  eventId?: string;
};

const VisitHistoryPublic: FC<Props> = () => {
  const params = useParams<{ visitId: string }>();

  const { singlePublicVisitHistory: publicBooking } = useSelector(
    selectPublicVisitHistoryState,
  );

  const { requestSinglePublicVisitHistory, commitSinglePublicVisitHistory } =
    useBoundedActions(publicVisitHistoryActions);

  useEffect(() => {
    commitSinglePublicVisitHistory(null);
    requestSinglePublicVisitHistory({ eventId: params.visitId });
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>Visit History Public</h1>
        </Col>
      </Row>

      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <DoctorProfile />
        </Col>

        <Col xs={12} sm={12} md={12} lg={6}>
          <Container>
            {publicBooking?.id && (
              <VisitHistoryDetailsPublic {...publicBooking} />
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default VisitHistoryPublic;
