import { Formik, Form } from 'formik';
import { FC } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import MMDTextInput from '../forms/MMDTextInput';

type Props = {
  doctorInfo: any;
  setDea: (arg0: string) => void;
  Continue: () => void;
};

const VetDeaForm: FC<Props> = ({ setDea, Continue }) => (
  <Container fluid>
    <Formik
      initialValues={{
        DEACode: '',
      }}
      onSubmit={(values) => {
        setDea(values.DEACode);
        Continue();
      }}
    >
      {({ values }) => (
        <Row className="justify-content-md-center">
          <Col xs lg="12">
            <Form>
              <MMDTextInput
                label="Insert DEA"
                values={values}
                fieldKey="DEACode"
                showFieldError={true}
                validate={
                  false
                  //         (value) => {
                  //   if (value.length != 9) {
                  //     return "DEA must have 9 digits"
                  //   }
                  //     const letters: string = value.slice(0, 2)
                  //     if (!doctorInfo || !doctorInfo.lastName) {
                  //       return "DEA invalid, please insert last name to validate DEA"
                  //     }
                  //     if (!["A", "B", "F", "G", "M", "P", "R", "X"].includes(letters.charAt(0)) || letters.charAt(1)!= (doctorInfo.lastName as string).charAt(0).toUpperCase()) {
                  //       return "DEA invalid. remember to use all caps"
                  //     }
                  //       const numbers: string = value.slice(2,8)
                  //       try{
                  //       const check135 = parseInt(numbers.charAt(0)) + parseInt(numbers.charAt(2)) + parseInt(numbers.charAt(4))
                  //       const check246 = (parseInt(numbers.charAt(1)) + parseInt(numbers.charAt(3)) + parseInt(numbers.charAt(5)))*2
                  //       const check = (check135 + check246).toString()
                  //       if (check.charAt(check.length - 1) != value.charAt(8)) {
                  //         return "DEA invalid"
                  //       }
                  //       } catch (e) {
                  //         return "DEA invalid"
                  //       }
                  // }
                }
              />
              <Button type="submit" className="w-100 mt-5 mb-5">
                Continue
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  </Container>
);
export default VetDeaForm;
