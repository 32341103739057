import React from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { chatroomSelectors } from '../modules/chatrooms.module';
import { MyState } from '../store';
import { Chatroom, Provider } from '../typings';
import DoctorProfile from '../components/DoctorProfile';
import ChatroomCell from './chatrooms/ChatroomCell';
import moment from 'moment';

type Props = {
  chatrooms: Chatroom[];
  provider: Provider;
};

const Chatrooms: React.FC<Props> = ({ chatrooms, provider }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>Your chats</h1>
        </Col>
      </Row>
      <Row className="align-middle pt-1 pb-1">
        <Col lg={3}>
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={9}>
          <Container>
            <Row>
              <Col className="mb-2 sub-title" xs={12}>
                Chat list
              </Col>
            </Row>
            {chatrooms
              .sort(
                (a: Chatroom, b: Chatroom) =>
                  moment(b.updatedAt).unix() - moment(a.updatedAt).unix(),
              )
              .map((chatroom) => (
                <Link
                  className="text-decoration-none"
                  key={chatroom.id}
                  to={`/chats/${chatroom.id}`}
                >
                  <ChatroomCell
                    className="chatrooms"
                    chatroom={chatroom}
                    provider={provider}
                  />
                </Link>
              ))}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  chatrooms: chatroomSelectors.selectAll(state.chatrooms),
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Chatrooms);
