import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import { Speciality } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface ActionTypes {
  REQUEST_SPECIALITIES: string;
  COMMIT_SPECIALITIES: string;
  SUCCESS_SPECIALITIES: string;
  ERROR_SPECIALITIES: string;
}

interface ActionCreators {
  requestSpecialities: () => MyAction<void>;
  commitSpecialities: (payload: Speciality[]) => MyAction<Speciality[]>;
}

export interface SpecialitiesState extends EntityState<Speciality> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestSpecialities: [],
  commitSpecialities: ['payload'],
});

const entityAdapter = createEntityAdapter<any>();
const initialState = entityAdapter.getInitialState({});
export const specialitiesSelectors = entityAdapter.getSelectors();

function commitSpecialities(
  state: SpecialitiesState,
  action: MyAction<Speciality[]>,
): SpecialitiesState {
  return {
    ...entityAdapter.addAll(action.payload, state),
  };
}

export const specialitiesReducer = createReducer(initialState, {
  [Types.COMMIT_SPECIALITIES]: commitSpecialities,
});

async function downloadSpecialities({
  headers,
}: ArgsWithHeaders<any>): Promise<Speciality[]> {
  const result = await fetch(`${AUTH_API_URL}/specialities`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
  const temp = await result.json();
  return temp.data;
}

const requestSpecialitiesWatcher = createSingleEventSaga<
  object,
  Speciality[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_SPECIALITIES,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitSpecialities,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadSpecialities,
  beforeAction: putAuthInfoInArgs,
});

export const specialitiesSagas = [requestSpecialitiesWatcher];
