import React from 'react';
import { Button, Col, Row, Image } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import isEmpty from 'lodash/isEmpty';
import Switch from 'react-switch';

import { prescriptionFormValidator } from '../../modules/prescription-refills.module';
import MMDTextInput from '../forms/MMDTextInput';
import { Prescription } from '../../typings';
import MMDModal from '../MMDModal';
import { initializeSecurityNumber } from '../e-prescribing/submitPrescriptionHelper';

interface Props {
  prescription: Prescription;
  onClickPreview: () => void;
  onSubmitForm?: (values: any) => void;
  isNewPrescription: boolean;
  dea?: string;
}

const PrescriptionForm: React.FC<Props> = ({
  prescription,
  onClickPreview,
  onSubmitForm,
  isNewPrescription,
  dea,
}) => {
  const [isRefill, setIsRefill] = React.useState(
    isNewPrescription
      ? prescription
        ? !isEmpty(prescription.methodRefills)
        : false
      : true,
  );
  const [warning, setWarning] = React.useState<string>();
  const [errorMethodRefills, setErrorMethodRefills] = React.useState<
    string | undefined
  >(undefined);
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [initializeCodeMsg, setInitializeCodeMsg] = React.useState(null);

  const toggleIsRefill = React.useCallback(() => {
    setIsRefill(!isRefill);
  }, [isRefill, setIsRefill]);

  React.useEffect(() => {
    initializeSecurityNumber().catch((result) => {
      setInitializeCodeMsg(result.message);
    });
  });

  return (
    <>
      {initializeCodeMsg && (
        <div className="w-75 mx-auto text-center text-danger">
          {initializeCodeMsg}
        </div>
      )}
      <Formik
        initialValues={{
          name: prescription ? prescription.name : '',
          dosage: prescription ? prescription.dosage : '',
          quantity: prescription ? prescription.quantity : '',
          frequency: prescription ? prescription.frequency : '',
          unit: prescription ? prescription.unit : '',
          methodRefills: prescription ? prescription.methodRefills : '',
          generic: prescription ? prescription.generic : false,
          daysOfsupply: prescription ? prescription.daysOfSupply : '',
          autoRefillsDays: prescription ? prescription.autoRefillsDays : '',
        }}
        validate={(values) => {
          prescriptionFormValidator({ isNewPrescription, ...values })
            .then(() => setWarning(''))
            .catch((err) => {
              setWarning(err.name);
              setErrorMethodRefills(err.methodRefills);
            });
        }}
        onSubmit={(values, submitForm) => {
          if (isRefill && !values.methodRefills) {
            submitForm.setSubmitting(false);
            return;
          }
          if (warning && warning !== '') {
            setModalVisible(true);
            return;
          }

          onSubmitForm({ ...values, dea });
          onClickPreview();
        }}
      >
        {({ isSubmitting, values, submitForm }) => (
          <>
            <MMDModal
              show={isModalVisible}
              handleClose={() => {
                setModalVisible(false);
                setWarning('');
                submitForm();
              }}
              handleOk={() => {
                setModalVisible(false);
                setWarning('');
                submitForm();
              }}
              title="Warning"
              renderBody={() => (
                <p>
                  That medicine is not in our database. The admin will have to
                  approve your prescription before it is sent.
                </p>
              )}
            />

            <Row className="pb-4">
              <Col className="vcenter" xs={12}>
                <Image
                  src="/assets/alcohol.png"
                  className="mr-3"
                  width="25"
                  height="40"
                />
                <span>Medications</span>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="12">
                <Form>
                  <MMDTextInput
                    values={values}
                    fieldKey="name"
                    label="Medication"
                    showFieldError={true}
                  />
                  <div
                    className="invalid-feedback text-warning"
                    style={{ display: 'block' }}
                  >
                    {warning}
                  </div>

                  <MMDTextInput
                    values={values}
                    fieldKey="dosage"
                    label="Dosage"
                    showFieldError={true}
                  />

                  <MMDTextInput
                    values={values}
                    fieldKey="quantity"
                    label="Quantity"
                    showFieldError={true}
                  />

                  <MMDTextInput
                    values={values}
                    fieldKey="unit"
                    label="Unit"
                    showFieldError={true}
                  />

                  <MMDTextInput
                    values={values}
                    fieldKey="frequency"
                    label="Frequency"
                    showFieldError={true}
                  />

                  <Col className="vcenter mt-3" xs={12}>
                    <span className="mr-4">Refill:</span>
                    <Switch
                      onChange={toggleIsRefill}
                      onColor="#F81942"
                      checked={isRefill}
                    />
                  </Col>

                  {isRefill ? (
                    <div>
                      <MMDTextInput
                        values={values}
                        fieldKey="methodRefills"
                        label="Method Refills"
                        showFieldError={true}
                      />
                      <div
                        className="invalid-feedback text-warning"
                        style={{ display: 'block' }}
                      >
                        {errorMethodRefills}
                      </div>
                    </div>
                  ) : null}

                  <Col className="vcenter mt-3" xs={12}>
                    <span className="mr-4">D.A.W.</span>
                    <Field
                      name="generic"
                      render={({ field: { value, ...field }, form }) => (
                        <Switch
                          {...field}
                          onColor="#F81942"
                          checked={value}
                          onChange={() => form.setFieldValue('generic', !value)}
                        />
                      )}
                    />
                  </Col>

                  <Button
                    className="w-100 my-3"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Preview Prescription
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </>
  );
};

export default PrescriptionForm;
