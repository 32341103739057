import { MenuItem } from '../../utils/typings';
import Chat from '../Chat';
import Chatrooms from '../Chatrooms';
import ComponentShowroom from '../ComponentShowroom';
import Dashboard from '../Dashboard';
import EditProfile from '../EditProfile';
import FeedbackView from '../FeedbackView';
import FeedbackViewClientPublic from '../feedback-public';
import FeedbackViewPublic from '../FeedbackViewPublic';
import ForgotPassword from '../ForgotPassword';
import MyAppts from '../MyAppts';
import PatientDetails from '../PatientDetails';
import Patients from '../Patients';
import PaymentResponse from '../Payments';
import PrescriptionsRefill from '../PrescriptionRefills';
import JoinPublicVideoCall from '../public-appointment/join-videocall';
import Signup from '../Signup';
import TermsAndCondictions from '../TermsAndConditions';
import UpcomingAppointment from '../UpcomingAppointment';
import Verify from '../Verify';
import VideoCall from '../VideoCall';
import VideoCallClientPublic from '../VideoCallClientPublic';
import VideoCallPublic from '../VideoCallPublic';
import VisitHistory from '../VisitHistory';
import VisitHistoryPublic from '../VisitHistoryPublic';
import PrescriptionRefillDetail from '../prescriptions-refill/PrescriptionRefillDetail';
import SignIn from './../SignIn';
import StudentAttendance from '../StudentAttendance';
import PatientConsent from '../PatientConsent';
import { ComponentType } from 'react';

export interface Route {
  path: string;
  component: ComponentType<any>;
  showMenuItem: boolean;
  menuItem?: (navigate: (path: string) => void) => MenuItem;
  forStudent?: boolean;
}

export const routes: Route[] = [
  {
    path: '/',
    component: SignIn,
    showMenuItem: false,
    forStudent: true,
  },
  {
    path: '/signup',
    component: Signup,
    showMenuItem: false,
    forStudent: true,
  },
  {
    path: '/showroom',
    component: ComponentShowroom,
    showMenuItem: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    showMenuItem: true,
    forStudent: true,
    menuItem: (navigate) => ({
      text: 'Dashboard',
      onPress: () => navigate('/dashboard'),
      path: '/dashboard',
    }),
  },
  {
    path: '/patients',
    component: Patients,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'Patients',
      onPress: () => navigate('/patients'),
      path: '/patients',
    }),
  },
  {
    path: '/patients/:id',
    component: PatientDetails,
    showMenuItem: false,
  },
  {
    forStudent: true,
    path: '/patients/:id/visit-history/:visitId',
    component: VisitHistory,
    showMenuItem: false,
  },
  {
    forStudent: true,
    path: '/patients/:id/public-visit-history/:visitId',
    component: VisitHistoryPublic,
    showMenuItem: false,
  },
  {
    path: '/chats',
    component: Chatrooms,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'Chats',
      onPress: () => navigate('/chats'),
      path: '/chats',
    }),
  },
  {
    path: '/chats/:id',
    component: Chat,
    showMenuItem: false,
  },
  {
    path: '/my-health',
    component: SignIn,
    showMenuItem: false,
    menuItem: (navigate) => ({
      text: 'My Health',
      onPress: () => navigate('/my-health'),
      path: '/my-health',
      items: [
        {
          text: 'Conditions',
          onPress: () => navigate('/my-health/conditions'),
        },
        {
          text: 'Allergies ',
          onPress: () => navigate('/my-health/allergies'),
        },
        {
          text: 'Pharmacies',
          onPress: () => navigate('/my-health/pharmacies'),
        },
      ],
    }),
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndCondictions,
    showMenuItem: false,
  },
  {
    path: '/patient-consent',
    component: PatientConsent,
    showMenuItem: false,
  },
  {
    path: '/verify',
    component: Verify,
    showMenuItem: false,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    showMenuItem: false,
  },
  {
    path: '/refills',
    component: PrescriptionsRefill,
    showMenuItem: false,
  },
  {
    path: '/refills/:id',
    component: PrescriptionRefillDetail,
    showMenuItem: false,
  },
  {
    path: '/payments',
    component: PaymentResponse,
    showMenuItem: false,
  },
  {
    path: '/edit-profile',
    component: EditProfile,
    showMenuItem: false,
  },
  {
    path: '/my-appts',
    component: MyAppts,
    forStudent: true,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'My Appts',
      onPress: () => navigate('/my-appts'),
      path: '/my-appts',
    }),
  },
  {
    path: '/appointment/:id',
    forStudent: true,
    component: UpcomingAppointment,
    showMenuItem: false,
  },
  {
    forStudent: true,
    path: '/video-call/:id',
    component: VideoCall,
    showMenuItem: false,
  },
  {
    path: '/feedback/:id/:booking/:edit?',
    component: FeedbackView,
    showMenuItem: false,
  },
  // TODO postponed by client
  // {
  //   path: '/my-appts-publics',
  //   component: MyPublicsAppts,
  //   forStudent: true,
  //   showMenuItem: true,
  //   menuItem: (navigate) => ({
  //     text: 'My Publics Appts',
  //     onPress: () => navigate('/my-appts-publics'),
  //     path: '/my-appts-publics',
  //   }),
  // },
  {
    forStudent: true,
    path: '/my-appts/public-visit-history/:visitId',
    component: VisitHistoryPublic,
    showMenuItem: false,
  },
  {
    path: '/public-appointment/feedback',
    component: FeedbackViewClientPublic,
    showMenuItem: false,
    forStudent: true,
  },
  {
    path: '/public-appointment/:id',
    component: JoinPublicVideoCall,
    showMenuItem: false,
    forStudent: true,
  },
  {
    path: '/public-appointment/video-call/:id',
    component: VideoCallClientPublic,
    showMenuItem: false,
    forStudent: true,
  },
  {
    forStudent: true,
    path: '/video-call-public/:id',
    component: VideoCallPublic,
    showMenuItem: false,
  },
  {
    path: '/feedback/public/:id',
    component: FeedbackViewPublic,
    showMenuItem: false,
  },
  {
    path: '/student-attendance',
    component: StudentAttendance,
    showMenuItem: false,
  },
];
