import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DoctorProfile from '../components/DoctorProfile';
import PatientProfile from '../components/PatientProfile';
import PatientInfoAccordion from './myappts/PatientInfoAccordion';
import { useParams } from 'react-router-dom';

const PatientDetails: React.FC = () => {
  const params = useParams<{ id: string }>();

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h5>Patient Details</h5>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle">
        <Col xs={12} sm={12} md={12} lg={3} className="p-1">
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={9}>
          <PatientProfile addBorder={false} patientId={params.id} />

          <PatientInfoAccordion patientId={params.id!} />
        </Col>
      </Row>
    </Container>
  );
};

export default PatientDetails;
