import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { replace } from 'redux-first-history';
import { put, select, take } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  composeSagas,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from '../store';
import { MMDError } from '../utils/MMDError';
import { noOpAction } from '../utils/noOpAction';
import { API_URL, AUTH_API_URL } from '../config';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import { Creators as TimeslotsActions } from './timeslot.module';
import { BookingPublic, BookingNotePublic, Nullable } from '../typings';
import { ProvidersState, selectProviderState } from './provider.module';
import { Types as ProviderActionsTypes } from '../modules/provider.module';
import { ArgsWithHeaders, LocationChangeActionPayload } from '../utils/typings';

export interface BookingDate {
  numberParticipants: string;
  startDate: string;
  duration: string;
  timeZone: string;
  patientEmail: string;
  patientPhone?: string;
}

type CreateBookingPayload = {
  bookingData: BookingDate;
  onSuccess: () => void;
};

interface BookingId {
  eventId: string;
}

interface Valoration {
  rating: string;
  comments: string;
}

interface Feedback {
  id: string;
  rating: number;
  comment: string;
}

interface UpdateBookingNotePayload {
  id: string;
  bookingNotePublic: string;
  redirectURL?: string;
}

interface ActionTypes {
  REQUEST_CREATE_BOOKING_PUBLIC: string;
  REQUEST_BOOKING_PUBLIC: string;
  REQUEST_BOOKINGS_PUBLIC: string;
  REQUEST_CREATE_VALORATION: string;
  COMMIT_BOOKING_PUBLIC: string;
  CLEAR_BOOKING_PUBLIC: string;
  COMMIT_BOOKINGS_PUBLIC: string;
  UPDATE_BOOKING_NOTE_PUBLIC: string;
  SEND_FEEDBACK_PUBLIC: string;
  COMMIT_UPDATE_BOOKING_NOTE: string;
  CANCEL_PUBLIC_BOOKING: string;
  REQUEST_EDIT_BOOKING_PUBLIC: string;
  REQUEST_PUBLIC_BOOKING_DURATIONS: string;
  COMMIT_PUBLIC_BOOKING_DURATIONS: string;
}

interface ActionCreators {
  requestCreateBookingPublic: (
    payload: CreateBookingPayload,
  ) => MyAction<CreateBookingPayload>;
  requestEditBookingPublic: (
    payload: CreateBookingPayload,
  ) => MyAction<CreateBookingPayload>;
  requestBookingPublic: (payload: BookingId) => MyAction<BookingId>;
  requestBookingsPublic: () => MyAction<void>;
  requestCreateValoration: (payload: Valoration) => MyAction<Valoration>;
  commitBookingPublic: (payload: BookingPublic) => MyAction<BookingPublic>;
  clearBookingPublic: () => MyAction<void>;
  commitBookingsPublic: (payload: BookingPublic[]) => MyAction<BookingPublic[]>;
  updateBookingNotePublic: (
    payload: UpdateBookingNotePayload,
  ) => MyAction<BookingPublic>;
  sendFeedbackPublic: (payload: Feedback) => MyAction<void>;
  commitUpdateBookingNote: (
    payload: BookingNotePublic,
  ) => MyAction<BookingNotePublic>;
  cancelPublicBooking: (payload: { id: string }) => MyAction<{ id: string }>;
  requestPublicBookingDurations: () => MyAction<void>;
  commitPublicBookingDurations: (
    payload: PublicBookingDuration[],
  ) => MyAction<PublicBookingDuration[]>;
}

export const RECURRENCE_ERROR = 'There is already booked event';

export const INVALID_PHONE_ERROR = 'Invalid phone';

export type PublicBookingDuration = {
  id: number;
  duration: number;
  createdAt: string;
  deletedAt: Nullable<string>;
  updatedAt: Nullable<string>;
};

export type BookingsPublicState = {
  booking: Nullable<BookingPublic>;
  bookings: Nullable<BookingPublic[]>;
  publicBookingDurations: Nullable<PublicBookingDuration[]>;
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateBookingPublic: ['payload'],
  requestEditBookingPublic: ['payload'],
  requestBookingPublic: ['payload'],
  requestBookingsPublic: ['payload'],
  commitBookingPublic: ['payload'],
  clearBookingPublic: [],
  commitBookingsPublic: ['payload'],
  commitUpdateBookingNote: ['payload'],
  requestCreateValoration: ['payload'],
  updateBookingNotePublic: ['payload'],
  sendFeedbackPublic: ['payload'],
  cancelPublicBooking: ['payload'],
  requestPublicBookingDurations: [],
  commitPublicBookingDurations: ['payload'],
});

const initialState: BookingsPublicState = {
  booking: null,
  bookings: null,
  publicBookingDurations: null,
};

function commitBookingPublic(
  state: BookingsPublicState,
  action: MyAction<BookingPublic>,
): BookingsPublicState {
  return {
    ...state,
    booking: action.payload,
  };
}

function commitBookingsPublic(
  state: BookingsPublicState,
  action: MyAction<BookingPublic[]>,
): BookingsPublicState {
  return {
    ...state,
    bookings: action.payload,
  };
}

function clearBookingsPublic(state: BookingsPublicState): BookingsPublicState {
  return {
    ...state,
    booking: null,
  };
}

function commitUpdateBookingNote(
  state: BookingsPublicState,
  action: MyAction<BookingNotePublic>,
): BookingsPublicState {
  if (!state.booking) {
    return state;
  }

  return {
    ...state,
    booking: {
      ...state.booking,
      bookingNotePublic: action.payload,
    },
  };
}

function commitPublicBookingDurations(
  state: BookingsPublicState,
  action: MyAction<PublicBookingDuration[]>,
): BookingsPublicState {
  return {
    ...state,
    publicBookingDurations: action.payload,
  };
}

export const bookingsPublicReducer = createReducer(initialState, {
  [Types.COMMIT_BOOKING_PUBLIC]: commitBookingPublic,
  [Types.COMMIT_BOOKINGS_PUBLIC]: commitBookingsPublic,
  [Types.CLEAR_BOOKING_PUBLIC]: clearBookingsPublic,
  [Types.COMMIT_UPDATE_BOOKING_NOTE]: commitUpdateBookingNote,
  [Types.COMMIT_PUBLIC_BOOKING_DURATIONS]: commitPublicBookingDurations,
});

async function getPublicBookingDurations({
  headers,
}: ArgsWithHeaders<unknown>) {
  const result = await fetch(`${API_URL}/booking-duration`, {
    headers,
  });

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const { bookingDurations } = await result.json();

  return bookingDurations;
}

async function sendFeedbackPublic({
  headers,
  ...payload
}: ArgsWithHeaders<Feedback>): Promise<void> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.id}/booking-feedback-public`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({
        rating: payload.rating,
        comment: payload.comment,
        fromDoctor: true,
      }),
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
}

async function cancelPublicBooking({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<void> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.id}/cancel`,
    {
      headers,
      method: 'DELETE',
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
}

async function updateBookingNotePublic({
  headers,
  ...payload
}: ArgsWithHeaders<UpdateBookingNotePayload>): Promise<BookingPublic> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.id}/notes-public`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({ note: payload.bookingNotePublic }),
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
  const response = await result.json();

  return response.data;
}

async function createBookingPublic({
  headers,
  bookingData,
}: ArgsWithHeaders<CreateBookingPayload>): Promise<BookingDate> {
  const result = await fetch(`${AUTH_API_URL}/bookings-public`, {
    headers,
    method: 'POST',
    body: JSON.stringify(bookingData),
  });

  if (!result.ok) {
    const body = await result.json();

    if (body.msg === RECURRENCE_ERROR) {
      throw new MMDError(RECURRENCE_ERROR);
    }

    if (body.msg.toLowerCase().includes('phone')) {
      throw new MMDError(INVALID_PHONE_ERROR);
    }

    throw new MMDError('An error has occurred');
  }

  const { booking } = await result.json();

  return booking;
}

async function getBookingPublic({
  headers,
  ...payload
}: ArgsWithHeaders<BookingId>): Promise<BookingId> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.eventId}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const { booking } = await result.json();

  return booking;
}

async function getBookingsPublic({
  headers,
  isStudent,
}: ArgsWithHeaders<BookingId & { isStudent: boolean }>): Promise<BookingId> {
  const bookingEndpoint = isStudent
    ? '/bookings-public/for-students'
    : '/bookings-public';

  const result = await fetch(`${AUTH_API_URL}${bookingEndpoint}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const { bookings } = await result.json();

  return bookings;
}

async function editBookingPublic({
  headers,
  eventId,
  bookingData,
}: ArgsWithHeaders<
  CreateBookingPayload & { eventId: string }
>): Promise<BookingDate> {
  const result = await fetch(`${AUTH_API_URL}/bookings-public/${eventId}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(bookingData),
  });

  if (!result.ok) {
    const body = await result.json();

    if (body.msg === RECURRENCE_ERROR) {
      throw new MMDError(RECURRENCE_ERROR);
    }

    if (body.msg.toLowerCase().includes('phone')) {
      throw new MMDError(INVALID_PHONE_ERROR);
    }

    throw new MMDError('An error has occurred');
  }

  return bookingData;
}

function* onSuccessPublicBookingsCreation(
  publicBooking: BookingPublic,
  { onSuccess }: CreateBookingPayload,
) {
  yield put(Creators.requestBookingsPublic());

  const { id } = yield select(selectProviderState);

  yield put(
    TimeslotsActions.requestTimeslots({
      id,
      query: `?month=${moment(publicBooking.startDate).format('YYYY-MM-DD')}`,
    }),
  );

  onSuccess();
}

const createBookingPublicWatcher = createSingleEventSaga<
  CreateBookingPayload,
  BookingPublic,
  MyAction<CreateBookingPayload>
>({
  takeEvery: Types.REQUEST_CREATE_BOOKING_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: createBookingPublic,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  afterAction: onSuccessPublicBookingsCreation,
});

const editBookingPublicWatcher = createSingleEventSaga<
  CreateBookingPayload,
  BookingPublic,
  MyAction<CreateBookingPayload>
>({
  takeEvery: Types.REQUEST_EDIT_BOOKING_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: editBookingPublic,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  afterAction: onSuccessPublicBookingsCreation,
});

const cancelBookingPublicWatcher = createSingleEventSaga<
  { id: string },
  void,
  MyAction<{ id: string }>
>({
  takeEvery: Types.CANCEL_PUBLIC_BOOKING,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: Creators.requestBookingsPublic,
  errorAction: ErrorActions.setError,
  action: cancelPublicBooking,
  beforeAction: putAuthInfoInArgs,
});

const getBookingPublicWatcher = createSingleEventSaga<
  BookingId,
  BookingPublic,
  MyAction<BookingId>
>({
  takeEvery: Types.REQUEST_BOOKING_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitBookingPublic,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: getBookingPublic,
  beforeAction: putAuthInfoInArgs,
});

const getBookingsPublicWatcher = createSingleEventSaga<
  void,
  BookingPublic[],
  MyAction<void>
>({
  takeEvery: Types.REQUEST_BOOKINGS_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitBookingsPublic,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: getBookingsPublic,
  beforeAction: composeSagas<
    void,
    object,
    ArgsWithHeaders<{ isStudent: boolean }>
    // @ts-ignore
  >(putAuthInfoInArgs, function* (args): SagaIterator {
    const provider: ProvidersState = yield select(selectProviderState);

    if (Object.keys(provider).length) {
      return {
        ...args,
        isStudent: !!provider.teacher,
      };
    }

    if (yield take(ProviderActionsTypes.COMMIT_PROVIDER)) {
      const { teacher }: ProvidersState = yield select(selectProviderState);

      return {
        ...args,
        isStudent: !!teacher,
      };
    }
  }),
});

const requestUpdateNotePublicWatcher = createSingleEventSaga<
  UpdateBookingNotePayload,
  BookingNotePublic,
  MyAction<UpdateBookingNotePayload>
>({
  takeEvery: Types.UPDATE_BOOKING_NOTE_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitUpdateBookingNote,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: updateBookingNotePublic,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  *afterAction(commitData: BookingNotePublic, payload): SagaIterator {
    if (payload.redirectURL) {
      yield put(replace(payload.redirectURL));
    }

    return commitData;
  },
});

const sendFeedbackPublicWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  BookingPublic,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.SEND_FEEDBACK_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: sendFeedbackPublic,
  beforeAction: putAuthInfoInArgs,
});

const requestPublicBookingDurationsWatcher = createSingleEventSaga<
  void,
  PublicBookingDuration[],
  MyAction<void>
>({
  takeEvery: Types.REQUEST_PUBLIC_BOOKING_DURATIONS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPublicBookingDurations,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: getPublicBookingDurations,
  beforeAction: putAuthInfoInArgs,
});

// const requestAppointmentForVideoCallPublicWatcher = createSingleEventSaga<
//   LocationChangeActionPayload,
//   BookingPublic,
//   MyAction<LocationChangeActionPayload>
// >({
//   takeEvery: onRoute('/video-call-public/:id'),
//   loadingAction: LoadingActions.setLoading,
//   commitAction: Creators.commitAppointmentPublic,
//   successAction: noOpAction,
//   errorAction: ErrorActions.setError,
//   action: downloadAppointmentPublic,
//   beforeAction: composeSagas<
//     LocationChangeActionPayload,
//     { id: string },
//     ArgsWithHeaders<{ id: string }>
//   >(
//     // @ts-ignore
//     extractRouteParams('/video-call-public/:id'),
//     putAuthInfoInArgs,
//   ),
// });

export const bookingsPublicSagas = [
  getBookingPublicWatcher,
  editBookingPublicWatcher,
  getBookingsPublicWatcher,
  sendFeedbackPublicWatcher,
  createBookingPublicWatcher,
  cancelBookingPublicWatcher,
  requestUpdateNotePublicWatcher,
  requestPublicBookingDurationsWatcher,
];

export const selectBookingsPublicState = (state: MyState) =>
  state.bookingsPublic;
