import moment from 'moment';
import { Col, Button, Image } from 'react-bootstrap';
import { push as reduxPush } from 'redux-first-history';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { LocalTracks } from '../../typings';
import MuteAudio, { MuteProps } from './MuteAudio';
import FileInput from './video-call-view/FileInput';
import { useIsStudentCheck } from '../../hooks/useIsStudentCheck';
import { useBoundedActions } from '../../hooks/useBoundedActions';
import { Creators as BookingsPublicActions } from '../../modules/bookings-public.module';
import PendingParticipantsList from './video-call-view-public/PendingParticipantsList';

type Props = {
  room: any;
  booking?: any;
  saveFile: any;
  extended: boolean;
  cleanup: () => void;
  muteProps: MuteProps;
  fullScreen: boolean;
  localTracks: LocalTracks;
  setFullScreen: () => void;
  shrinkHorizontally: boolean;
  isSidePanelVisible: boolean;
  subscribeExtensionPublic: any;
  onRequestSidePanel: () => void;
  eventLogger: (event: string) => void;
};

const VideoCallViewPublic: FC<Props> = ({
  room,
  booking,
  cleanup,
  extended,
  saveFile,
  muteProps,
  localTracks,
  fullScreen,
  eventLogger,
  setFullScreen,
  isSidePanelVisible,
  onRequestSidePanel,
  subscribeExtensionPublic,
  shrinkHorizontally = false,
}) => {
  const [isParticipantConnected, setIsParticipantConnected] = useState(false);

  const localTrackRef = useRef(null);
  const remoteTrackRef = useRef(null);

  const timerRef = useRef(null);

  const isStudent = useIsStudentCheck();

  const { push } = useBoundedActions({
    push: reduxPush,
    ...BookingsPublicActions,
  });

  const participantConnected = useCallback(
    (participant) => {
      setIsParticipantConnected(true);

      eventLogger(`Participant added to the meeting`);

      const trackSubscribed = (track) => {
        if (track && track.attach) {
          remoteTrackRef.current?.appendChild(track.attach());
        }
      };
      const trackUnsubscribed = (track) => {
        if (track?.detach) {
          track.detach().forEach((element) => element.remove());
        }
      };
      participant.on('trackSubscribed', trackSubscribed);
      participant.on('trackUnsubscribed', trackUnsubscribed);
      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          trackSubscribed(publication.track);
        }
      });
    },
    [eventLogger, setIsParticipantConnected],
  );

  const participantDisconnected = useCallback(
    () => eventLogger('Patient removed from the meeting'),
    [eventLogger, setIsParticipantConnected],
  );

  const leave = useCallback(() => {
    eventLogger('Leaving the meeting by disconnect');
    push(isStudent ? '/my-appts' : `/feedback/public/${booking.eventId}`);
  }, [eventLogger, booking, push]);

  const formatTimer = () => {
    const endTime = moment(booking.event.end.dateTime);
    const min = moment.duration(endTime.diff(moment())).minutes();
    const sec = moment.duration(endTime.diff(moment())).seconds();
    return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
  };

  const startTimer = () =>
    setInterval(() => {
      timerRef.current.innerText = formatTimer();
    }, 1000);

  useEffect(() => {
    if (!room || !localTracks?.video) {
      return;
    }

    subscribeExtensionPublic({ booking });
    eventLogger('onRoomDidConnect');
    room.participants.forEach(participantConnected);
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    localTrackRef.current?.appendChild(localTracks.video.attach());

    const closeCall = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    const timerIntervalId = startTimer();
    window.addEventListener('beforeunload', closeCall);
    window.addEventListener('unload', cleanup);

    return () => {
      eventLogger('onRoomDidDisconnect');
      cleanup();
      clearInterval(timerIntervalId);
      window.removeEventListener('beforeunload', closeCall);
    };
  }, [
    room,
    cleanup,
    eventLogger,
    localTracks?.video,
    participantConnected,
    participantDisconnected,
  ]);

  return (
    <Col
      className={`${
        shrinkHorizontally ? 'd-none d-sm-block' : ''
      } p-0 full-height`}
      sm={shrinkHorizontally ? 6 : 12}
    >
      <div
        id="remote-track"
        ref={remoteTrackRef}
        className="remote-track remote-black"
      />

      <div id="local-track" className="local-track" ref={localTrackRef} />

      <div className="btn-leave-background" onClick={leave}>
        <div className="btn-leave" />
      </div>

      <h6 className="video-call-title text-center">
        {!isParticipantConnected &&
          !isSidePanelVisible &&
          'Waiting for participants...'}
      </h6>

      {room && localTracks && (
        <>
          <div className="btn-countdown-background" ref={timerRef} />

          <div className="btnVideoCall__actions">
            <MuteAudio {...muteProps} />

            {!isStudent && <PendingParticipantsList />}
          </div>
        </>
      )}

      {!isStudent && (
        <FileInput saveFile={saveFile}>
          <div
            className={`btn-plus-background ${
              shrinkHorizontally ? 'btn-plus-background__left' : ''
            }`}
          >
            <Image src="/assets/plus-icon.png" alt="plus icon" />
          </div>
        </FileInput>
      )}

      <Button
        variant="secondary"
        onClick={setFullScreen}
        className={`btn-full-view-background ${
          shrinkHorizontally ? 'btn-full-view-background__left' : ''
        }`}
      >
        {fullScreen ? 'Small View' : 'Full View'}
      </Button>

      {!isStudent && (
        <div
          className={`right-panel-arrow ${
            shrinkHorizontally ? 'right-panel-arrow__side-open' : ''
          }`}
          onClick={onRequestSidePanel}
        >
          <div />
        </div>
      )}
    </Col>
  );
};

export default VideoCallViewPublic;
