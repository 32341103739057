import {
  createSingleEventSaga,
  MyAction,
  EntityState,
  createEntityAdapter,
} from '@mrnkr/redux-saga-toolbox';
import { AnyAction } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { AUTH_API_URL } from '../config';
import { Booking } from '../typings';
import { ArgsWithHeaders } from '../utils/typings';
import { noOpAction } from '../utils/noOpAction';
import { Creators as LoadingActions } from './loading.module';
import { Creators as ErrorActions } from './errors.module';
import { firestore } from '../utils/Firebase';
import parseQueryParams from '../utils/parseQueryParams';
import { MMDError } from '../utils/MMDError';
import uniqBy from 'lodash/uniqBy';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import { doc, getDoc } from 'firebase/firestore';

interface BookingQuery {
  from: string;
  to: string;
}

interface ActionTypes {
  REQUEST_CALENDAR_APPOINTMENTS: string;
  COMMIT_CALENDAR_APPOINTMENTS: string;
}

const requestUserFromFirestore = async (uid) => {
  const res = await getDoc(doc(firestore, 'users', uid));
  return res.data();
};

interface ActionCreators {
  requestCalendarAppointments: (
    payload: BookingQuery,
  ) => MyAction<BookingQuery>;
  commitCalendarAppointments: (payload: Booking[]) => MyAction<Booking[]>;
}

export interface CalendarBookingsState extends EntityState<Booking> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCalendarAppointments: ['payload'],
  commitCalendarAppointments: ['payload'],
});

const entityAdapter = createEntityAdapter<Booking>();
const initialState = entityAdapter.getInitialState();
export const calendarBookingsSelectors = entityAdapter.getSelectors();

function commitBookings(
  state: CalendarBookingsState,
  action: MyAction<Booking[]>,
): CalendarBookingsState {
  return entityAdapter.addAll(action.payload, state);
}

export const calendarBookingsReducer = createReducer<
  CalendarBookingsState,
  AnyAction
>(initialState, {
  [Types.COMMIT_CALENDAR_APPOINTMENTS]: commitBookings,
});

async function downloadAppointments({
  headers,
  ...payload
}: ArgsWithHeaders<BookingQuery>): Promise<Booking[]> {
  const query = parseQueryParams(payload);

  const result = await fetch(
    `${AUTH_API_URL}/doctors/appointments/month?${query}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const { appointments }: { appointments: Booking[] } = await result.json();

  const patientsInAppointments = keyBy(
    uniqBy(
      appointments.map((appt) => ({ id: appt.patientId, peerName: '' })),
      (patient) => patient.id,
    ),
    (patient) => patient.id,
  );

  for (const patientId of keys(patientsInAppointments)) {
    const patientInfo = await requestUserFromFirestore(patientId);
    patientsInAppointments[patientId].peerName = patientInfo
      ? `${patientInfo.firstName} ${patientInfo.lastName}`
      : 'No peer Name';
  }

  for (const appointment of appointments) {
    appointment.peerName =
      patientsInAppointments[appointment.patientId].peerName;
  }

  return [...appointments];
}

const requestAppointmentsWatcher = createSingleEventSaga<
  BookingQuery,
  Booking[],
  MyAction<BookingQuery>
>({
  takeEvery: Types.REQUEST_CALENDAR_APPOINTMENTS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitCalendarAppointments,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadAppointments,
  beforeAction: putAuthInfoInArgs,
});

export const calendarBookingsSagas = [requestAppointmentsWatcher];
