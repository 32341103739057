import { FC, useEffect } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';

import {
  Creators as AuthActions,
  AuthState,
  authFormValidator,
} from '../../modules/auth.module';
import { MyState } from '../../store';
import MMDTextInput from '../../components/forms/MMDTextInput';
import { Link } from 'react-router-dom';

type PropTypes = {
  auth: AuthState;
} & typeof AuthActions;

const LoginForm: FC<PropTypes> = ({
  auth,
  requestAuth,
  requestLastEmailLogged,
}) => {
  useEffect(() => {
    requestLastEmailLogged();
  }, []);

  return (
    <Formik
      initialValues={{ email: auth.lastEmailLogged, password: '' }}
      enableReinitialize
      validate={authFormValidator}
      onSubmit={(values) => {
        requestAuth({
          email: values.email,
          password: values.password,
        });
      }}
    >
      {({ errors, values, touched }) => (
        <Row className="justify-content-md-center">
          <Col xs lg="5">
            <Form>
              <MMDTextInput
                values={values}
                fieldKey="email"
                type="email"
                label=""
                placeholder="Email"
              />

              <MMDTextInput
                values={values}
                fieldKey="password"
                type="password"
                label=""
                placeholder="Password"
              />

              <p className="text-center pt-3">
                <Link
                  className="text-primary text-decoration-none"
                  to="forgot-password"
                >
                  <span>Forgot your password?</span>
                </Link>
              </p>

              {errors &&
                Object.keys(errors).length > 0 &&
                Object.keys(touched).length > 0 && (
                  <div className="alert alert-danger">
                    <ul style={{ marginBottom: 0 }}>
                      {Object.keys(errors).map((key) => (
                        <li key={key}>
                          <span>{errors[key] as string}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              <button className="my-3 w-100 btn btn-primary" type="submit">
                Login
              </button>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: MyState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...AuthActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
