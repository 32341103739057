import { FC } from 'react';
import { FormLabel } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { PatternFormat } from 'react-number-format';

type Props = {
  name: string;
  label?: string;
  required?: boolean;
  className?: string;
  placeholder?: string;
};

const MMDPhoneInput: FC<Props> = ({
  name,
  label,
  required,
  className,
  placeholder = 'Enter your phone number',
}) => (
  <div className={className}>
    {label && (
      <FormLabel className="ml-3 form-label" htmlFor={name}>
        {label}

        {required && <span className="text-danger ml-1">*</span>}
      </FormLabel>
    )}
    {!label && <div className="spacer-input-base" />}

    <Field name={name}>
      {({ field }) => (
        <PatternFormat
          mask="_"
          allowEmptyFormatting
          format="(###) ###-####"
          className="form-control"
          placeholder={placeholder}
          {...field}
          type="tel"
        />
      )}
    </Field>

    <ErrorMessage
      name={name}
      render={(msg) => <div className="invalid-feedback d-block">{msg}</div>}
    />
  </div>
);

export default MMDPhoneInput;
