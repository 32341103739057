import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useBoundedActions } from '../../../hooks/useBoundedActions';
import {
  selectPatientsState,
  Creators as patientsActions,
} from '../../../modules/patients.module';
import BasePatientVisitHistoryTable, {
  TableProps,
  PatientVisitHistory,
} from './BasePatientVisitHistoryTable';

type Props = {
  patientId: string;
  onVisitHistoryPublicClick?: (visit: PatientVisitHistory) => void;
} & TableProps;

const PatientPublicVisitHistoryTable: FC<Props> = ({
  patientId,
  onVisitHistoryPublicClick,
  ...tableProps
}) => {
  const { requestPatientPublicVisitHistory } =
    useBoundedActions(patientsActions);

  const { publicVisitHistory } = useSelector(selectPatientsState);

  return (
    <BasePatientVisitHistoryTable
      patientId={patientId}
      request={requestPatientPublicVisitHistory}
      onVisitHistoryClick={onVisitHistoryPublicClick}
      getVisitHistoryPageLink={({ eventId }) =>
        `public-visit-history/${eventId}`
      }
      {...publicVisitHistory}
      {...tableProps}
    />
  );
};

export default PatientPublicVisitHistoryTable;
