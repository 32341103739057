import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { useCallbackPrompt } from '../hooks/useCallbackPrompt';

type PromptProps = {
  when: boolean;
  message?: string;
};

// unstable_usePrompt and unstable_useBlocker from react-router not working because of another way of initializing routes
const Prompt: FC<PromptProps> = ({
  when,
  message = 'Are you sure you want to leave?',
}) => {
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(when);

  return (
    <Modal show={showPrompt}>
      <Modal.Header>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>{message}</b>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={cancelNavigation}>
          No
        </Button>
        <Button variant="danger" onClick={confirmNavigation}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default Prompt;
