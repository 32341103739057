import { Col, FormControl, Row } from 'react-bootstrap';
import { FC } from 'react';

type Props = {
  title?: string;
  titleClassName?: string;
  text: string;
  setText: (text: string | undefined) => void;
  placeHolder?: string;
  textAreaClassName?: string;
};

const TextProfileInfo: FC<Props> = ({
  title,
  titleClassName,
  text,
  setText,
  textAreaClassName,
}) => (
  <Row>
    <Col xs={12} className="mb-1 mt-1 short-top-separator">
      <h6 className={titleClassName}>{title}</h6>
    </Col>
    <Col xs={12}>
      <Row>
        <FormControl
          className={
            textAreaClassName
              ? `${textAreaClassName} modal-textarea`
              : `modal-textarea`
          }
          as="textarea"
          value={text}
          /* placeHolder={placeHolder ? 'placeHolder' : "Your Notes"} */ onChange={(
            e,
          ) => setText(e.currentTarget.value)}
        />
      </Row>
    </Col>
  </Row>
);

export default TextProfileInfo;
