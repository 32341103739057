import React, { useEffect, useState } from 'react';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import DoctorProfile from '../components/DoctorProfile';
import MMDAccordion from '../components/MMDAccordion';
import { Creators as paymentActions } from '../modules/payments.module';
import { Creators as cardActions } from '../modules/cards.module';
import { Creators as earningsActions } from '../modules/payments/earnings.module';
import { PatientTransaction, CardInformation, Earnings } from '../typings';
import { MyState } from '../store';
import Transactions from './payments/Transactions';
import PaymentMethod from './payments/PaymentMethod';
import EarningsFieldCell from './payments/EarningsFieldCell';
import ModalExpressPay from './payments/ModalExpressPay';
import isEmpty from 'lodash/isEmpty';

type Props = {
  transactions: PatientTransaction[];
  cardInformation: CardInformation;
  earnings: Earnings;
} & typeof paymentActions &
  typeof cardActions &
  typeof earningsActions;

const Payments: React.FC<Props> = ({
  transactions,
  cardInformation,
  earnings,
  requestGetPaid,
  requestCreditCards,
}) => {
  const [showExpressPay, setShowExpressPay] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(cardInformation)) return;

    requestCreditCards();
  }, [cardInformation]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} className="text-center align-middle mb-3 mt-5">
            <h5>Payment Info</h5>
          </Col>
        </Row>
        <Row className="justify-content-center align-middle">
          <Col xs={12} sm={12} md={12} lg={3} className="p-1">
            <DoctorProfile />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8}>
            <MMDAccordion
              data={['Payment method', 'Transaction History', 'Billing']}
              renderToggle={(item, idx, _, expandedIdx) => (
                <>
                  {item}
                  {idx === expandedIdx && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="float-right"
                      style={{ color: 'red' }}
                    />
                  )}

                  {idx !== expandedIdx && (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="float-right"
                      style={{ color: 'red' }}
                    />
                  )}
                </>
              )}
              styleContainer="padding-10 pt-2 justify-content-center mb-2 xs-12 item-transactions dashboard-border"
              renderCollapse={(item, idx) => (
                <>
                  {idx === 0 && <PaymentMethod key={idx} />}
                  {idx === 1 && (
                    <Transactions key={idx} transactions={transactions} />
                  )}
                  {idx === 2 && (
                    <Col>
                      <EarningsFieldCell
                        label="Today"
                        cash={(+earnings.dailyEarnings || 0).toFixed(2)}
                        lastUpdate={earnings.lastUpdate}
                      />

                      <EarningsFieldCell
                        label={`Period: ${moment()
                          .startOf('isoWeek')
                          .format('MMM DD')} - ${moment()
                          .endOf('isoWeek')
                          .format('MMM DD')}`}
                        cash={(+earnings.weeklyEarnings || 0).toFixed(2)}
                        lastUpdate={earnings.lastUpdate}
                      />

                      <EarningsFieldCell
                        label={`Since ${moment(earnings.sinceDate).format(
                          'MMM DD, YYYY',
                        )}`}
                        cash={(+earnings.overallEarnings || 0).toFixed(2)}
                        lastUpdate={earnings.lastUpdate}
                      />

                      <p className="text-center">
                        Instant Payouts cost 1.5% of the payout amount, with a
                        minimum fee of 50¢.
                      </p>
                      <div className="row d-flex justify-content-center pb-2">
                        {cardInformation?.default_for_currency ? (
                          <Button
                            className="text-center"
                            variant="primary"
                            onClick={() => setShowExpressPay(true)}
                          >
                            Express Pay
                          </Button>
                        ) : (
                          <span className="text-center text-danger fw-bold">
                            You must set default card payment method to access
                            express pay
                          </span>
                        )}
                      </div>
                      <p className="text-center mb-3 text-muted">
                        Automatic deposits every week, free of charge, tipically
                        takes up to 3 business days to show on your bank
                        statement.
                      </p>
                    </Col>
                  )}
                </>
              )}
            />
          </Col>
          <ModalExpressPay
            show={showExpressPay}
            handleClose={() => setShowExpressPay(false)}
            handleCancel={() => setShowExpressPay(false)}
            handlePayOut={() => {
              requestGetPaid();
              setShowExpressPay(false);
            }}
            debitCard={cardInformation?.last4}
            currentEarnings={earnings.available}
          />
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  transactions: state.payments.transactions,
  cardInformation: state.card.card,
  earnings: state.payment.earnings,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...paymentActions,
      ...cardActions,
      ...earningsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
