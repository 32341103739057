import {
  createSingleEventSaga,
  MyAction,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { SagaIterator } from 'redux-saga';
import { createActions, createReducer } from 'reduxsauce';

import { Provider } from '../typings';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { noOpAction } from '../utils/noOpAction';
import { AUTH_API_URL } from '../config';
import { Creators as LoadingActions } from './loading.module';
import { Creators as ErrorActions } from './errors.module';
import {
  firestore,
  storage,
  getFirebaseImage,
  updateUserInFirestore,
} from '../utils/Firebase';
import { MMDError } from '../utils/MMDError';
import { Action } from 'redux';
import { Types as TypesLanguage } from './language.module';
import moment from 'moment';
import { ref, uploadString } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { MyState } from '../store';

const requestUserFromFirestore = async (uid) => {
  const res = await getDoc(doc(firestore, 'users', uid));
  return res.data();
};

interface ProviderPayload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  birthday: string;
  city: string;
  state: string;
  phone: string;
  npi: string;
  dea?: string;
  pln: string;
  gender: string;
  speciality: string;
  credential: string;
  monthlyPrice: string;
  oneTimePrice: string;
  avatarApproved: boolean;
  approved: boolean;
}

interface CustomPlanPayload {
  description: string;
  planType: string;
}
export interface UpdateProfilePayload {
  diff: object;
}

export interface UpdateProviderAvatarPayload {
  id: string;
  image: string;
}

export interface UpdateProviderSmsNotificationActivePayload {
  id: string;
  smsNotificationActive?: boolean;
}

interface ActionTypes {
  REQUEST_PROVIDER: string;
  REQUEST_UPDATE_PROVIDER: string;
  COMMIT_PROVIDER: string;
  SUCCESS_PROVIDER: string;

  REQUEST_UPDATE_PROVIDER_PROFILE: string;
  COMMIT_UPDATE_PROVIDER_PROFILE: string;

  REQUEST_CUSTOM_PRICE: string;
  COMMIT_CUSTOM_PRICE: string;

  REQUEST_UPDATE_PROVIDER_AVATAR: string;
  COMMIT_UPDATE_PROVIDER_AVATAR: string;

  REQUEST_UPDATE_PROVIDER_SMS_NOTIFICATION_ACTIVE: string;
  COMMIT_UPDATE_PROVIDER_SMS_NOTIFICATION_ACTIVE: string;
}

interface ActionCreators {
  requestProvider: () => MyAction<Provider>;
  requestUpdateProvider: (
    payload: ProviderPayload,
  ) => MyAction<ProviderPayload>;
  commitProvider: (payload: Provider) => MyAction<Provider>;
  successProvider: (payload: Provider) => MyAction<Provider>;

  requestUpdateProviderProfile: (
    payload: UpdateProfilePayload,
  ) => MyAction<UpdateProfilePayload>;
  commitUpdateProviderProfile: () => MyAction<any>;

  requestCustomPrice: (
    payload: CustomPlanPayload,
  ) => MyAction<CustomPlanPayload>;
  commitCustomPrice: () => MyAction<void>;

  requestUpdateProviderAvatar: (
    payload: UpdateProviderAvatarPayload,
  ) => MyAction<UpdateProviderAvatarPayload>;
  commitUpdateProviderAvatar: () => Action;

  requestUpdateProviderSmsNotificationActive: (
    payload: UpdateProviderSmsNotificationActivePayload,
  ) => MyAction<UpdateProviderSmsNotificationActivePayload>;
  commitUpdateProviderSmsNotificationActive: (
    payload: UpdateProviderSmsNotificationActivePayload,
  ) => MyAction<UpdateProviderSmsNotificationActivePayload>;
}

export type ProvidersState = Provider;

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestProvider: ['payload'],
  requestUpdateProvider: ['payload'],
  requestUpdateProviderProfile: ['payload'],
  commitProvider: ['payload'],
  commitUpdateProviderProfile: ['payload'],
  successProvider: ['payload'],

  requestCustomPrice: ['payload'],
  commitCustomPrice: ['payload'],

  requestUpdateProviderAvatar: ['payload'],
  commitUpdateProviderAvatar: ['payload'],

  requestUpdateProviderSmsNotificationActive: ['payload'],
  commitUpdateProviderSmsNotificationActive: ['payload'],
});

const initialState = {};

function commitProvider(
  _: ProvidersState,
  action: MyAction<Provider>,
): ProvidersState {
  return action.payload;
}

function commitProviderProfile(
  state: ProvidersState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: MyAction<Provider>,
): ProvidersState {
  return state;
}

function commitCustomPrice(state: ProvidersState): ProvidersState {
  return state;
}

function commitUpdateProviderAvatar(
  state: ProvidersState,
  action: MyAction<string>,
): ProvidersState {
  return {
    ...state,
    image: action.payload,
    avatarApproved: false,
  };
}

function commitUpdateProviderSmsNotificationActive(
  state: ProvidersState,
  action: MyAction<UpdateProviderSmsNotificationActivePayload>,
): ProvidersState {
  return {
    ...state,
    smsNotificationActive: action.payload.smsNotificationActive,
  };
}

export const providersReducer = createReducer(initialState, {
  [Types.COMMIT_PROVIDER]: commitProvider,
  [Types.COMMIT_UPDATE_PROVIDER_PROFILE]: commitProviderProfile,
  [Types.COMMIT_CUSTOM_PRICE]: commitCustomPrice,
  [Types.COMMIT_UPDATE_PROVIDER_AVATAR]: commitUpdateProviderAvatar,
  [Types.COMMIT_UPDATE_PROVIDER_SMS_NOTIFICATION_ACTIVE]:
    commitUpdateProviderSmsNotificationActive,
});

async function updateProvider({
  headers,
  ...payload
}: ArgsWithHeaders<ProviderPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    throw new MMDError('Your update could not be processed correctly');
  }
}

async function downloadProvider({
  headers,
}: ArgsWithHeaders<object>): Promise<Provider> {
  const result = await fetch(`${AUTH_API_URL}/doctors/own-details`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const postgresUser = await result.json();
  const firestoreUser = await requestUserFromFirestore(postgresUser.doctor.id);
  const doctorImage = await getFirebaseImage(
    `profile/${postgresUser.doctor.id}.jpeg`,
  );

  if (firestoreUser.birthday && firestoreUser.birthday.seconds) {
    const seconds =
      firestoreUser.birthday.seconds +
      firestoreUser.birthday.nanoseconds / 1000000000;
    firestoreUser.birthday = moment(new Date(seconds * 1000)).format(
      'DD-MM-YYYY',
    );
  }
  if (postgresUser && postgresUser.doctor && postgresUser.doctor.teacherId) {
    const teachareFirebaseData = await requestUserFromFirestore(
      postgresUser.doctor.teacherId,
    );
    postgresUser.doctor.subType = 'Student';
    postgresUser.doctor.teacher = teachareFirebaseData;
  }
  //console.log({postgresUser});
  return { ...postgresUser.doctor, ...firestoreUser, image: doctorImage };
}

async function updateProviderProfile({
  headers,
  ...payload
}: ArgsWithHeaders<UpdateProfilePayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/profile`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload.diff),
  });

  return await result.json();
}

async function sendCustonPrice({
  headers,
  ...payload
}: ArgsWithHeaders<ProviderPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/request-custom-price`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    throw new MMDError('Your update could not be processed correctly');
  }

  const res = await result.json();
  alert(res.msg);
}

async function updateProviderAvatar({
  headers,
  ...payload
}: ArgsWithHeaders<UpdateProviderAvatarPayload>): Promise<string> {
  await uploadString(
    ref(storage, `profile/${payload.id}.jpeg`),
    payload.image,
    'data_url',
  );
  await updateDoc(doc(firestore, 'users', payload.id), {
    avatarApproved: false,
  });
  await fetch(`${AUTH_API_URL}/doctors/upload-image`, {
    headers,
    method: 'GET',
  });

  return payload.image;
}

async function updateProviderSmsNotificationActive({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  headers,
  ...payload
}: ArgsWithHeaders<UpdateProviderSmsNotificationActivePayload>): Promise<UpdateProviderSmsNotificationActivePayload> {
  updateUserInFirestore(payload.id, {
    smsNotificationActive: payload.smsNotificationActive || false,
  });
  return payload;
}

const requestUpdateProviderWatcher = createSingleEventSaga<
  Partial<Provider>,
  Partial<Provider>,
  MyAction<Partial<Provider>>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitProvider,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: updateProvider,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(
    _,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { headers, ...args }: ArgsWithHeaders<Partial<Provider>>,
  ): SagaIterator {
    return args;
  },
});

const requestUpdateProviderProfileWatcher = createSingleEventSaga<
  UpdateProfilePayload,
  void,
  MyAction<UpdateProfilePayload>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER_PROFILE,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitUpdateProviderProfile,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: updateProviderProfile,
  beforeAction: putAuthInfoInArgs,
});

const requestCustomPriceWatcher = createSingleEventSaga<
  CustomPlanPayload,
  void,
  MyAction<CustomPlanPayload>
>({
  takeEvery: Types.REQUEST_CUSTOM_PRICE,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitCustomPrice,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: sendCustonPrice,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateProviderAvatarWatcher = createSingleEventSaga<
  UpdateProviderAvatarPayload,
  ProvidersState,
  MyAction<UpdateProviderAvatarPayload>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER_AVATAR,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitUpdateProviderAvatar,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: updateProviderAvatar,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateProviderSmsNotificationActiveWatcher = createSingleEventSaga<
  UpdateProviderSmsNotificationActivePayload,
  ProvidersState,
  MyAction<UpdateProviderSmsNotificationActivePayload>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER_SMS_NOTIFICATION_ACTIVE,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitUpdateProviderSmsNotificationActive,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: updateProviderSmsNotificationActive,
  beforeAction: putAuthInfoInArgs,
});

const requestProviderWatcher = createSingleEventSaga<
  object,
  Provider,
  MyAction<object>
>({
  takeEvery: (action: any) =>
    action.type === Types.COMMIT_UPDATE_PROVIDER_PROFILE ||
    action.type === TypesLanguage.COMMIT_UPDATE_PROVIDER_LANGUAGES ||
    action.type === Types.REQUEST_PROVIDER,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitProvider,
  successAction: Creators.successProvider,
  errorAction: ErrorActions.setError,
  action: downloadProvider,
  beforeAction: putAuthInfoInArgs,
});

export const providersSagas = [
  requestUpdateProviderWatcher,
  requestProviderWatcher,
  requestUpdateProviderProfileWatcher,
  requestCustomPriceWatcher,
  requestUpdateProviderAvatarWatcher,
  requestUpdateProviderSmsNotificationActiveWatcher,
];

// eslint-disable-next-line
const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function providersFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    email: true,
  };

  if (!EMAIL_REGEX.test(values['email'])) {
    result.email = false;
  }

  return Promise.resolve(result);
}

export const selectProviderState = (state: MyState) => state.provider;
