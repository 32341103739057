import { faEdit, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'redux-first-history';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Col, Container, Image, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import FileDropzone from '../components/FileDropzone';
import {
  Creators as LanguagesActions,
  languageSelectors,
} from '../modules/language.module';
import { Creators as ProviderActions } from '../modules/provider.module';
import AttributeInfo from '../pages/dashboard/AttributeInfo';
import ModalLanguages from '../pages/edit-profile/ModalLanguages';
import { MyState } from '../store';
import { Language, Provider } from '../typings';
import MMDCheckableItem from './MMDCheckableItem';
import { AUTH_API_URL } from '../config';

type Props = {
  provider: Provider;
  allLanguages: Language[];
  editable?: boolean;
  push: typeof push;
  isStudent: boolean;
} & typeof ProviderActions &
  typeof LanguagesActions;

const DoctorProfile: React.FC<Props> = ({
  isStudent,
  provider,
  allLanguages,
  editable = false,
  requestUpdateProviderAvatar,
  requestLanguages,
  requestUpdateProviderLanguages,
  requestUpdateProviderSmsNotificationActive,
}) => {
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  React.useEffect(() => {
    requestLanguages();
  }, []);

  if (isEmpty(provider)) {
    return null;
  }

  const generateNewCode = async () => {
    const authInfo = await JSON.parse(localStorage.getItem('moment.session'));
    const headers = new Headers();
    headers.append('Authorization', authInfo.firebase_token);
    headers.append('Content-Type', 'application/json');
    const newCodeResult = await fetch(
      `${AUTH_API_URL}/doctors/generate-new-security-code`,
      {
        headers,
        method: 'GET',
      },
    );
    const jsonResult = await newCodeResult.json();
    alert(jsonResult.message);
  };
  const renderImage = (base64Image) => (
    <div className="wrapper mx-auto">
      <Image
        src={base64Image}
        style={{ height: 128, width: 128, objectFit: 'cover' }}
        roundedCircle
      />
      {editable && <FontAwesomeIcon icon={faEdit} color="#F11B42" />}
    </div>
  );

  setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 100);

  const getProviderCredentials = () =>
    isStudent ? `(${provider.credential} Student)` : provider.credential;

  return (
    <Container>
      <Row className="pt-4 dashboard-border">
        <Col className="text-center mb-3" xs={12}>
          <h2>{isStudent ? 'Student profile' : 'Doctor profile'}</h2>
        </Col>
        <Col className="text-center mb-3" xs={12}>
          {editable || isStudent ? (
            <FileDropzone
              name="avatar-chooser"
              onChange={(base64File: string) => {
                requestUpdateProviderAvatar({
                  id: provider.id,
                  image: base64File,
                });
              }}
              renderer={renderImage}
              initialFile={provider.image}
            />
          ) : (
            renderImage(provider.image)
          )}
          {!provider.avatarApproved && (
            <small>
              <i className="mx-auto pt-2">(Waiting for approval)</i>
            </small>
          )}
        </Col>
        <Col className="text-center mb-4" xs={12}>
          {provider.firstName} {provider.lastName} {getProviderCredentials()}
        </Col>
        <Col className="attrs pt-4 pl-2 pr-2 pb-4 division-border">
          <AttributeInfo attribute="Birthday" value={provider.birthday} />
          <AttributeInfo
            attribute="Address"
            value={`${provider.address}, ${provider.city}`}
          />
          <AttributeInfo attribute="Phone" value={provider.phone} />
          <AttributeInfo
            onClick={editable ? () => setShowLanguageModal(true) : null}
            attribute="Language"
            editable={editable}
            value={
              !provider.profile ||
              (provider.profile && isEmpty(provider.profile.languages))
                ? 'None'
                : `${provider.profile.languages
                    .map((lang) => lang.name)
                    .join(', ')}`
            }
          />
          <AttributeInfo attribute="PLN" value={provider.pln} />
          <AttributeInfo attribute="NPI" value={provider.npi} />
          {provider.dea && (
            <AttributeInfo attribute="DEA" value={provider.dea} />
          )}
          <Col xs={12}>
            <p>{'SMS'}:</p>

            <MMDCheckableItem
              check={true}
              className="cursor-pointer"
              title={'I want to receive notifications by SMS'}
              titleClassName="sms-value"
              icon={faCheckSquare}
              iconStyle={
                provider.smsNotificationActive
                  ? { color: '#F11B42' }
                  : { color: 'gray' }
              }
              onClick={() => {
                if (isStudent || editable)
                  requestUpdateProviderSmsNotificationActive({
                    id: provider.id,
                    smsNotificationActive: provider.smsNotificationActive
                      ? !provider.smsNotificationActive
                      : true,
                  });
              }}
            />
          </Col>
          {!isStudent && !editable && (
            <Col xs={12} className="text-center mt-4">
              <Link to={'/edit-profile'} className="w-100 btn btn-outline-dark">
                {' '}
                Edit profile{' '}
              </Link>
            </Col>
          )}
        </Col>
        <Col
          xs={12}
          className="text-center mt-4"
          style={{ marginBottom: '15px' }}
        >
          <Button variant="primary" onClick={() => generateNewCode()}>
            Recovery 6 digit code
          </Button>
        </Col>
      </Row>
      <ModalLanguages
        show={showLanguageModal}
        handleClose={() => setShowLanguageModal(false)}
        handleUpdateLanguages={(ids: number[]) => {
          requestUpdateProviderLanguages({ ids });
          setShowLanguageModal(false);
        }}
        allLanguages={allLanguages}
        languages={
          provider.profile && provider.profile.languages
            ? provider.profile.languages
            : []
        }
      />
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  provider: state.provider,
  allLanguages: languageSelectors.selectAll(state.languages),
  isStudent: !!(state.provider && state.provider.teacher),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...ProviderActions,
      ...LanguagesActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProfile);
