import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import store, { history } from '../store';
import Routes from './routes';
import { routes } from './routes/routes';
import ErrorHandler from './app/ErrorHandler';
import LoadingIndicator from './app/LoadingIndicator';
import { Container } from 'react-bootstrap';
import MMDHeader from './app/MMDHeader';
import ScrollToTop from './app/ScrollToTop';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY });

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App: FC = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={history}>
        <Container fluid className="pb-5 px-0 m-0">
          <MMDHeader routes={routes} />
          <ErrorHandler />
          <Container
            fluid
            className="mx-auto page-max-width"
            style={{
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
          >
            <ScrollToTop>
              <Routes routes={routes} />
            </ScrollToTop>
            <LoadingIndicator />
          </Container>
        </Container>
      </Router>
    </Provider>
  </ErrorBoundary>
);

export default App;
