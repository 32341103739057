import { createSingleEventSaga, MyAction } from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import { MMDError } from '../utils/MMDError';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface FavoritePharmacyPayload {
  patientId: string;
}

interface ActionTypes {
  REQUEST_FAVORITE_PHARMACY: string;
  COMMIT_FAVORITE_PHARMACY: string;
}

interface ActionCreators {
  requestFavoritePharmacy: (
    payload: FavoritePharmacyPayload,
  ) => MyAction<FavoritePharmacyPayload>;
  commitFavoritePharmacy: (payload: any) => MyAction<any>;
}

export type FavoritePharmacyState = {
  favorite: any;
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestFavoritePharmacy: ['payload'],
  commitFavoritePharmacy: ['payload'],
});

const initialState = {};

function commitFavoritePharmacy(
  _: FavoritePharmacyState,
  action: MyAction<any>,
): FavoritePharmacyState {
  return {
    favorite: action.payload,
  };
}

export const favoritePharmacyReducer = createReducer(initialState, {
  [Types.COMMIT_FAVORITE_PHARMACY]: commitFavoritePharmacy,
});

async function downloadFavoritePharmacy({
  headers,
  ...payload
}: ArgsWithHeaders<FavoritePharmacyPayload>): Promise<any> {
  const result = await fetch(
    `${AUTH_API_URL}/pharmacies/${payload.patientId}/favorite`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const favoritePharmacy = await result.json();
  return favoritePharmacy.favorite;
}

const requestFavoritePharmacyWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<any>
>({
  takeEvery: Types.REQUEST_FAVORITE_PHARMACY,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitFavoritePharmacy,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadFavoritePharmacy,
  beforeAction: putAuthInfoInArgs,
});

export const favoritePharmacySagas = [requestFavoritePharmacyWatcher];
