import io, { Socket } from 'socket.io-client';
import { MyAction } from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { EventChannel, eventChannel, Task } from 'redux-saga';
import { call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects';

import { MyState } from '../../store';
import { Nullable } from '../../typings';
import { WEBSOCKET_URL } from '../../config';
import { WEBSOCKET_EVENT } from '../../utils/websockets';

export type HostUser = {
  id: string;
  role: string;
  name?: string;
  email?: string;
};

interface ActionTypes {
  INITIALIZE_HOST_WEBSOCKET: string;
  DISCONNECT_HOST_WEBSOCKET: string;
  HOST_WEBSOCKET_SEND: string;
  SET_USER_LIST: string;
  APPROVE_USER_REQUEST: string;
  DECLINE_USER_REQUEST: string;
  GET_USER_LIST: string;
}

type WebsocketSendPayload = {
  eventName: string;
  data: any;
};

interface ActionCreators {
  initializeHostWebsocket: (
    payload: MyAction<string>,
  ) => MyAction<{ bookingId: string }>;
  disconnectHostWebsocket: () => MyAction<void>;
  hostWebsocketSend: (payload: WebsocketSendPayload) => MyAction<void>;
  setUserList: (payload: HostUser[]) => MyAction<HostUser[]>;
  approveUserRequest: (payload: string) => MyAction<string>;
  declineUserRequest: (payload: string) => MyAction<string>;
  getUserList: () => MyAction<any>;
}

export interface HostPublicWaitingRoomState {
  users: HostUser[];
  socket: Nullable<Socket>;
}

const initialState: HostPublicWaitingRoomState = {
  users: [],
  socket: null,
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  initializeHostWebsocket: ['payload'],
  disconnectHostWebsocket: [],
  hostWebsocketSend: ['payload'],
  setUserList: ['payload'],
  approveUserRequest: ['payload'],
  declineUserRequest: ['payload'],
  getUserList: [],
});

function setUserList(
  state: HostPublicWaitingRoomState,
  action: MyAction<HostUser[]>,
) {
  return {
    ...state,
    users: action.payload ?? [],
  };
}

function saveWebsocket(
  state: HostPublicWaitingRoomState,
  action: MyAction<Socket>,
) {
  return {
    ...state,
    socket: action.payload,
  };
}

export const hostPublicWaitingRoomReducer =
  createReducer<HostPublicWaitingRoomState>(initialState, {
    [Types.SET_USER_LIST]: setUserList,
    [Types.HOST_WEBSOCKET_SEND]: saveWebsocket,
  });

function* declineUserRequestWatcher() {
  while (true) {
    const action: MyAction<string> = yield take(Types.DECLINE_USER_REQUEST);
    yield put(
      Creators.hostWebsocketSend({
        eventName: WEBSOCKET_EVENT.DECLINE_REQUEST,
        data: action.payload,
      }),
    );
  }
}

function* approveUserRequestWatcher() {
  while (true) {
    const action: MyAction<string> = yield take(Types.APPROVE_USER_REQUEST);
    yield put(
      Creators.hostWebsocketSend({
        eventName: WEBSOCKET_EVENT.ACCEPT_REQUEST,
        data: action.payload,
      }),
    );
  }
}

function* getUserListWatcher() {
  while (true) {
    const action: MyAction<string> = yield take(Types.GET_USER_LIST);
    yield put(
      Creators.hostWebsocketSend({
        eventName: WEBSOCKET_EVENT.GET_USER_LIST,
        data: action.payload,
      }),
    );
  }
}

function createSocketConnection(url: string, roomName: string) {
  return io(url, {
    path: '/socket/',
    forceNew: true,
    query: {
      roomName,
    },
  });
}

function createSocketChannel(socket: Socket) {
  return eventChannel((emit) => {
    socket.on(WEBSOCKET_EVENT.USER_LIST, (payload) => {
      emit({ type: Types.SET_USER_LIST, payload });
    });

    //DON'T simplify, socket will lose "this"
    return () => socket.disconnect();
  });
}

function* websocketSendWatcher(socket: Socket) {
  while (true) {
    const { payload }: { payload: WebsocketSendPayload } = yield take(
      Types.HOST_WEBSOCKET_SEND,
    );
    socket.emit(payload.eventName, payload.data);
  }
}

function* websocketListenWatcher(socketChannel) {
  while (true) {
    try {
      const action = yield take(socketChannel);
      yield put(action);
    } catch (err) {
      console.log('socket error: ', err);
    }
  }
}

function* websocketDisconnectWatcher(
  channel: EventChannel<Socket>,
  sendTask: Task,
  listenTask: Task,
) {
  while (true) {
    yield take(Types.DISCONNECT_HOST_WEBSOCKET);
    Creators.setUserList([]);
    channel.close();
    yield cancel(sendTask);
    yield cancel(listenTask);
    yield cancel();
  }
}

function* initializeWebsocketHandler(action: MyAction<string>) {
  const socket = yield call(
    createSocketConnection,
    WEBSOCKET_URL,
    action.payload,
  );

  const socketChannel = yield call(createSocketChannel, socket);

  const sendTask = yield fork(websocketSendWatcher, socket);
  const listenTask = yield fork(websocketListenWatcher, socketChannel);
  yield fork(websocketDisconnectWatcher, socketChannel, sendTask, listenTask);

  yield put(Creators.getUserList());
}

function* initializeSocketWatcher() {
  yield takeEvery(Types.INITIALIZE_HOST_WEBSOCKET, initializeWebsocketHandler);
}

export const hostWaitingRoomSagas = [
  getUserListWatcher,
  initializeSocketWatcher,
  approveUserRequestWatcher,
  declineUserRequestWatcher,
];

export const selectHostPublicWaitingRoomState = (state: MyState) =>
  state.hostPublicWaitingRoom;
