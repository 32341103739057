import { Image } from 'react-bootstrap';
import { FC, useRef, useState } from 'react';

type Props = {
  className?: string;
  bookingEventId: string;
};

const CopyLinkButton: FC<Props> = ({ bookingEventId, className }) => {
  const intervalRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const onCopyLink = () =>
    navigator.clipboard
      .writeText(
        `${window.location.origin}/public-appointment/${bookingEventId}`,
      )
      .then(() => {
        setCopySuccess(true);
        clearInterval(intervalRef.current);
        intervalRef.current = setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(() => alert('Failed to copy text. Please try again.'));

  return (
    <div
      onClick={onCopyLink}
      className={`cursor-pointer d-flex align-items-center ${className}`}
    >
      <small className="public-booking-row__copy-link mr-2">
        {copySuccess ? 'Copied!' : 'Copy Link'}
      </small>

      {!copySuccess && (
        <Image
          src="/assets/copy-icon-blue.png"
          style={{ height: 20, width: 20 }}
        />
      )}
    </div>
  );
};

export default CopyLinkButton;
