import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  className?: string;
  title: string;
  icon?: IconDefinition;
  value?: string;
  onclick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ToggleAccordion: React.FC<Props> = ({
  title,
  className,
  icon,
  value,
  onclick,
}) => {
  return (
    <Row
      onClick={onclick}
      className={`w-100 vcenter text-decoration-none empty-border ${
        className || ''
      }`}
    >
      <Col className="text-left pl-3">{title}</Col>
      <Col className=" text-right pr-3">
        {icon && <FontAwesomeIcon icon={icon} style={{ color: '#F11B42' }} />}
        {value}
      </Col>
    </Row>
  );
};

export default ToggleAccordion;
