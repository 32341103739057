import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FC, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, Formik, FormikProps } from 'formik';

import MMDSelect from '../../../components/forms/MMDSelect';
import MMDCheckbox from '../../../components/forms/MMDCheckbox';
import { selectErrorState } from '../../../modules/errors.module';
import MMDTextInput from '../../../components/forms/MMDTextInput';
import {
  ParticipantsTypes,
  JoinParticipantPayload,
} from '../../../modules/videocall-client-public.module';
import {
  isPatientType,
  validationSchema,
  isCustomParticipantType,
  VIDEO_CALL_PARTICIPANT_TYPES,
  isEmailRequiredForParticipantType,
} from './config';
import { selectPublicWaitingRoomState } from '../../../modules/videocall-public/waiting-room.module';

type Props = {
  onJoinParticipant: (
    payload: Omit<JoinParticipantPayload, 'bookingId'>,
  ) => void;
};

type JoinFormValues = {
  email: string;
  customType: string;
  terms: 'false' | 'true';
  patientConsent: 'false' | 'true';
  type: ParticipantsTypes;
};

const JoinForm: FC<Props> = ({ onJoinParticipant }) => {
  const { error } = useSelector(selectErrorState);
  const { isDeclined } = useSelector(selectPublicWaitingRoomState);

  const formikRef = useRef<FormikProps<JoinFormValues> | null>(null);

  useEffect(() => {
    if (error) {
      formikRef.current.setSubmitting(false);
      formikRef.current.setFieldError('type', error.message);
    }
  }, [error]);

  useEffect(() => {
    if (isDeclined) {
      formikRef.current.setSubmitting(false);
    }
  }, [isDeclined]);

  const onSubmit = ({ type, email, customType }: JoinFormValues) => {
    const payload = new Map([
      [true, { type }],
      [isCustomParticipantType(type), { type: customType }],
      [isEmailRequiredForParticipantType(type), { type, email }],
    ]).get(true);

    onJoinParticipant(payload);
  };

  const initialValues: JoinFormValues = {
    type: undefined,
    email: '',
    terms: 'false',
    patientConsent: 'false',
    customType: '',
  };

  return (
    <Formik<JoinFormValues>
      onSubmit={onSubmit}
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <Row className="justify-content-md-center">
            <Col xs lg="5">
              <MMDSelect
                required
                name="type"
                label="Type"
                options={VIDEO_CALL_PARTICIPANT_TYPES}
              />

              {isEmailRequiredForParticipantType(values.type) && (
                <MMDTextInput
                  required
                  label="Email"
                  showFieldError
                  values={values}
                  className="my-3"
                  fieldKey="email"
                  placeholder="E-mail"
                />
              )}

              {isCustomParticipantType(values.type) && (
                <MMDTextInput
                  required
                  showFieldError
                  values={values}
                  className="my-3"
                  label="Custom type"
                  fieldKey="customType"
                  placeholder="Type..."
                />
              )}

              <MMDCheckbox
                required
                values={values}
                className="my-4"
                fieldKey="terms"
                showFieldError
                label={
                  <>
                    <label className="mb-0">I agree to MomentMD</label>
                    <br />
                    <Link
                      target="_blank"
                      className="link-color"
                      to="/terms-and-conditions"
                    >
                      <u>Terms of Service & Informed Consent</u>
                    </Link>
                  </>
                }
              />

              {isPatientType(values.type) && (
                <MMDCheckbox
                  required
                  values={values}
                  className="my-4"
                  fieldKey="patientConsent"
                  showFieldError
                  label={
                    <>
                      <label className="mb-0">I agree to MomentMD</label>
                      <br />
                      <Link
                        target="_blank"
                        className="link-color"
                        to="/patient-consent"
                      >
                        <u>Patient Consent</u>
                      </Link>
                    </>
                  }
                />
              )}

              <Row className="justify-content-center">
                <Col xs={5} className="text-center">
                  <Button
                    type="submit"
                    className="primary"
                    disabled={isSubmitting || !isValid}
                  >
                    <h6 className="mb-0">Join</h6>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default JoinForm;
