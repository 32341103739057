import { MMDError } from './MMDError';

const baseURL = 'https://maps.googleapis.com/maps/api/geocode';
const GOOGLE_PLACES_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

type GoogleGeolocationResponse = {
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  results: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
    formatted_address: string;
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
      location_type: string;
      viewport: {
        northeast: {
          lat: number;
          lng: number;
        };
        southwest: {
          lat: number;
          lng: number;
        };
      };
      place_id: string;
      plus_code: {
        compound_code: string;
        global_code: string;
      };
      types: string[];
    };
  }[];
};

export async function getAddressForCoordinates(
  lat: number,
  lng: number,
): Promise<string> {
  const result = await fetch(
    `${baseURL}/json?v=3&key=${GOOGLE_PLACES_KEY}&latlng=${lat},${lng}`,
  );

  if (!result.ok) {
    throw new MMDError('There was an error processing your coordinates...');
  }

  const {
    results: [{ formatted_address }],
  } = (await result.json()) as GoogleGeolocationResponse;
  return formatted_address;
}

export async function getCoordinatesForAddress(
  address: string,
): Promise<{ lat: number; lng: number }> {
  const result = await fetch(
    `${baseURL}/json?v=3&key=${GOOGLE_PLACES_KEY}&address=${encodeURIComponent(
      address,
    )}`,
  );

  if (!result.ok) {
    throw new MMDError('There was an error processing your address...');
  }

  const {
    results: [
      {
        geometry: { location },
      },
    ],
  } = (await result.json()) as GoogleGeolocationResponse;
  return location;
}
