import { FC } from 'react';
import moment from 'moment';
import { useField } from 'formik';
import TimePicker from 'rc-time-picker';
import { FormLabel } from 'react-bootstrap';

type Props = {
  name: string;
  required?: boolean;
};

const ModalTimePicker: FC<Props> = ({ name, required }) => {
  const [field, meta, helpers] = useField(name);

  const isTodaySelected = field.value.isSame(moment(), 'day');

  const now = moment();

  const disabledHours = () => {
    if (!isTodaySelected) {
      return [];
    }

    const hours = [];

    for (let i = 0; i < 24; i++) {
      if (i < now.hour()) {
        hours.push(i);
      }
    }

    return hours;
  };

  const disabledMinutes = (hour: number) => {
    if (hour > now.hour() || !isTodaySelected) {
      return [];
    }

    const minutes = [];

    for (let i = 0; i < 60; i++) {
      if (i < now.minute() || hour < now.hour()) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  return (
    <div className="w-25">
      <FormLabel className="ml-3 form-label" htmlFor={name}>
        Start time
        {required && <span className="text-danger ml-1">*</span>}
      </FormLabel>

      <TimePicker
        use12Hours
        inputReadOnly
        format="h:mm A"
        showSecond={false}
        allowEmpty={false}
        value={field.value}
        className="time-picker"
        onChange={helpers.setValue}
        disabledHours={disabledHours}
        disabledMinutes={disabledMinutes}
      />

      {meta.error && meta.touched && (
        <span className="invalid-feedback d-block">{meta.error}</span>
      )}
    </div>
  );
};

export default ModalTimePicker;
