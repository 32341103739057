import {
  createSingleEventSaga,
  MyAction,
  EntityState,
  createEntityAdapter,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { AUTH_API_URL } from '../config';
import { RefillRequest } from '../typings';
import { ArgsWithHeaders, LocationChangeActionPayload } from '../utils/typings';
import { onRoute } from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { Creators as LoadingActions } from './loading.module';
import { Creators as ErrorActions } from './errors.module';
import { firestore, getFirebaseImage } from '../utils/Firebase';
import { MMDError } from '../utils/MMDError';
import { INVALID_METHOD_REFILL_INPUT } from '../utils/constants';
import { doc, getDoc } from 'firebase/firestore';

const requestUserFromFirestore = async (uid) => {
  const res = await getDoc(doc(firestore, 'users', uid));
  return res.data();
};

interface ActionTypes {
  REQUEST_PRESCRIPTION_REFILLS: string;
  COMMIT_PRESCRIPTION_REFILLS: string;
}

interface ActionCreators {
  commitPrescriptionRefills: (
    payload: RefillRequest[],
  ) => MyAction<RefillRequest[]>;
  requestPrescriptionRefills: () => MyAction<void>;
}

export interface RefillsState extends EntityState<RefillRequest> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  commitPrescriptionRefills: ['payload'],
  requestPrescriptionRefills: [],
});

const entityAdapter = createEntityAdapter<RefillRequest>({
  selectId: (e) => e.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState();
export const prescriptionRefillSelectors = entityAdapter.getSelectors();

function commitPrescriptionRefills(
  state: RefillsState,
  action: MyAction<RefillRequest[]>,
): RefillsState {
  return entityAdapter.addAll(action.payload, state);
}

export const prescriptionRefillsReducer = createReducer(initialState, {
  [Types.COMMIT_PRESCRIPTION_REFILLS]: commitPrescriptionRefills,
});

async function downloadPrescriptionsRefills({
  headers,
}: ArgsWithHeaders<LocationChangeActionPayload>): Promise<RefillRequest[]> {
  const result = await fetch(`${AUTH_API_URL}/doctors/request-refills`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError('There was an error fetching your refill requests');
  }

  const { refillRequests: res } = await result.json();

  await Promise.all(
    res.map(async (refillRequest) => {
      const patient = await requestUserFromFirestore(refillRequest.patientId);
      refillRequest.patient = patient;

      const pictureUri = await getFirebaseImage(
        `profile/${refillRequest.patientId}.jpeg`,
      );
      refillRequest.patient.avatar = pictureUri;

      delete refillRequest.patientId;
      delete refillRequest.doctorId;
    }),
  );

  return res;
}

// The backend does not offer an endpoint to get only one refill request
const requestPrescriptionRefillsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  RefillRequest[],
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: (action) =>
    onRoute('/refills')(action) ||
    onRoute('/refills/:id')(action) ||
    action.type === Types.REQUEST_PRESCRIPTION_REFILLS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPrescriptionRefills,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPrescriptionsRefills,
  beforeAction: putAuthInfoInArgs,
});

export const prescriptionRefillsSagas = [requestPrescriptionRefillsWatcher];

export function securityCodeFieldValidator() {
  return (values) => {
    if (values.length !== 6) {
      return 'Security code should be 6 digits';
    } else {
      return null;
    }
  };
}

export function securityCodeFormValidator(values: any): Dictionary<string> {
  const errors: Dictionary<string> = {};

  if (values['securityCode'].length !== 6) {
    errors.securityCode = 'Security code should be 6 digits';
  }

  return errors;
}

export async function prescriptionFormValidator(
  values: Dictionary<any>,
): Promise<Dictionary<string>> {
  const errors: Dictionary<string> = {};

  if (!values.isNewPrescription && !values.methodRefills) {
    errors.methodRefills = INVALID_METHOD_REFILL_INPUT;
  }

  const authInfo = await JSON.parse(localStorage.getItem('moment.session'));
  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);

  const result = await fetch(
    `${AUTH_API_URL}/bookings/medications/${values.name}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    errors.name =
      'There has been an error retrieving the medicine from our servers';
  }

  const { medications }: { result: true; medications: any[] } =
    await result.json();

  if (!medications.length) {
    errors.name =
      'That medicine is not in our database. The admin will have to approve your prescription before it is sent.';
  }

  throw errors;
}
