import { AUTH_API_URL } from '../../../config';
import { Provider, RefillRequest } from '../../../typings';
import { MMDError } from '../../../utils/MMDError';

export async function requestConsultation(
  req: RefillRequest,
  provider: Provider,
): Promise<void> {
  const { booking } = req.prescription;

  // If you add support for pets by making the
  // patient be of type any I fill find you... And it won't be nice
  const consultationRequest = {
    refillRequestId: req.id,
    patientName: req.patient.firstName,
    doctorName: provider.firstName,
    medicineName: req.prescription.name,
    patientEmail: req.patient.email,
  };

  const authInfo = await JSON.parse(localStorage.getItem('moment.session'));
  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(
    `${AUTH_API_URL}/bookings/${booking.id}/request-consultation`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(consultationRequest),
    },
  );

  if (!response.ok) {
    throw new MMDError(
      'There was an error processing your request. Please try again later...',
    );
  }
}
