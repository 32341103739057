import {
  MyAction,
  composeSagas,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';
import { SagaIterator } from 'redux-saga';
import { createActions, createReducer } from 'reduxsauce';
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
  Room,
} from 'twilio-video';
import { Observable, PromiseQueue } from '@mrnkr/promise-queue';
import { call, put, race, select, take } from 'redux-saga/effects';

import { MyState } from '../store';
import { Booking, LocalTracks } from '../typings';
import { API_URL, AUTH_API_URL } from '../config';
import { MMDError } from '../utils/MMDError';
import { firestore } from '../utils/Firebase';
import { noOpAction } from '../utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { doc, onSnapshot } from 'firebase/firestore';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import { extractRouteParams, onRoute } from '../utils/onRoute';
import { ArgsWithHeaders, LocationChangeActionPayload } from '../utils/typings';
import { selectBookingsPublicState } from './bookings-public.module';

interface ActionTypes {
  REQUEST_CONNECT_VIDEO_PUBLIC: string;
  CONNECT_VIDEO_PUBLIC: string;
  COMMIT_LOCAL_TRACKS_PUBLIC: string;
  COMMIT_LOCAL_VIDEO_TRACK_PUBLIC: string;
  COMMIT_IS_AUDIO_MUTED_PUBLIC: string;
  REQUEST_MUTE_LOCAL_AUDIO_TRACK_PUBLIC: string;
  REQUEST_UNMUTE_LOCAL_AUDIO_TRACK_PUBLIC: string;
  COMMIT_TWILIO_TOKEN_PUBLIC: string;
  REQUEST_DISCONNECT_VIDEO_PUBLIC: string;
  CLEAR_VIDEO_STATE_PUBLIC: string;
  VIDEO_CALL_LOG_EVENT_PUBLIC: string;
  EXTEND_VIDEO_CALL_PUBLIC: string;
  SUBSCRIBE_EXTENSION_PUBLIC: string;
}
interface ActionCreators {
  requestConnectVideoPublic: () => MyAction<object>;
  connectVideoPublic: (payload: { room: any }) => MyAction<{ room: any }>;
  commitLocalTracksPublic: (payload: LocalTracks) => MyAction<LocalTracks>;
  commitLocalVideoTrackPublic: (
    payload: Pick<LocalTracks, 'video'>,
  ) => MyAction<Pick<LocalTracks, 'video'>>;
  commitIsAudioMutedPublic: (payload: boolean) => MyAction<boolean>;
  requestMuteLocalAudioTrackPublic: () => MyAction<void>;
  requestUnmuteLocalAudioTrackPublic: () => MyAction<void>;
  commitTwilioTokenPublic: (payload: {
    token: string;
    bookingId: string;
  }) => MyAction<{ token: string; bookingId: string }>;
  requestDisconnectVideoPublic: () => MyAction<object>;
  clearVideoStatePublic: () => MyAction<object>;
  videoCallLogEventPublic: (payload: {
    event: string;
  }) => MyAction<{ event: string }>;
  extendVideoCallPublic: () => MyAction<object>;
  subscribeExtensionPublic: (payload: { booking: Booking }) => MyAction<object>;
}
export type VideoCallPublicState = {
  bookingId?: string;
  token?: string;
  room?: any;
  localTracks: LocalTracks;
  isAudioMuted: boolean;
  extended?: boolean;
};
export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestConnectVideoPublic: ['payload'],
  connectVideoPublic: ['payload'],
  commitLocalTracksPublic: ['payload'],
  commitLocalVideoTrackPublic: ['payload'],
  commitIsAudioMutedPublic: ['payload'],
  requestMuteLocalAudioTrackPublic: [],
  requestUnmuteLocalAudioTrackPublic: [],
  commitTwilioTokenPublic: ['payload'],
  requestDisconnectVideoPublic: [],
  clearVideoStatePublic: [],
  videoCallLogEventPublic: ['payload'],
  extendVideoCallPublic: [],
  subscribeExtensionPublic: ['payload'],
});

const initialState = {
  localTracks: null,
  isAudioMuted: false,
};

function commitTwilioTokenPublic(
  state: VideoCallPublicState,
  action: MyAction<{ token: string; bookingId: string }>,
): VideoCallPublicState {
  return {
    ...state,
    ...action.payload,
  };
}
function connectVideoPublic(
  state: VideoCallPublicState,
  action: MyAction<any>,
): VideoCallPublicState {
  return {
    ...state,
    room: action.payload,
  };
}
function commitLocalTracksPublic(
  state: VideoCallPublicState,
  action: MyAction<LocalTracks>,
): VideoCallPublicState {
  return {
    ...state,
    localTracks: action.payload,
  };
}
function extendVideoCallPublic(
  state: VideoCallPublicState,
): VideoCallPublicState {
  return {
    ...state,
    extended: true,
  };
}
function clearStatePublic(): VideoCallPublicState {
  return initialState;
}

function commitIsAudioMutedPublic(
  state: VideoCallPublicState,
  action: MyAction<boolean>,
): VideoCallPublicState {
  return {
    ...state,
    isAudioMuted: action.payload,
  };
}

export const videoCallPublicReducer = createReducer<VideoCallPublicState>(
  initialState,
  {
    [Types.CONNECT_VIDEO_PUBLIC]: connectVideoPublic,
    [Types.COMMIT_LOCAL_TRACKS_PUBLIC]: commitLocalTracksPublic,
    [Types.COMMIT_IS_AUDIO_MUTED_PUBLIC]: commitIsAudioMutedPublic,
    [Types.COMMIT_TWILIO_TOKEN_PUBLIC]: commitTwilioTokenPublic,
    [Types.EXTEND_VIDEO_CALL_PUBLIC]: extendVideoCallPublic,
    [Types.CLEAR_VIDEO_STATE_PUBLIC]: clearStatePublic,
  },
);

async function downloadTwilioToken({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string; numberParticipant: string }>): Promise<
  { response: true; token: string } | { response: false }
> {
  const allParticipantCount = Number(payload.numberParticipant) + 1;

  const result = await fetch(
    `${AUTH_API_URL}/twilio/token-public/${payload.id}/${allParticipantCount}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong connecting you to your conference',
    );
  }
  return result.json();
}

function* selectPublicBookingParticipantCount(
  payload: LocationChangeActionPayload,
) {
  const bookingEventId = yield call(
    extractRouteParams('/video-call-public/:id'),
    payload,
  );

  const { bookings } = yield select(selectBookingsPublicState);

  const currentBooking = bookings.find(
    ({ eventId }) => eventId === bookingEventId.id,
  );

  return {
    ...bookingEventId,
    ...payload,
    numberParticipant: currentBooking?.numberParticipant,
  };
}

const requestVideoCallTokenPublicWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  { token: string; bookingId: string },
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/video-call-public/:id'),
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTwilioTokenPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadTwilioToken,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: string },
    ArgsWithHeaders<{ id: string }>
    // @ts-ignore
  >(selectPublicBookingParticipantCount, putAuthInfoInArgs),
  // @ts-ignore
  *afterAction(
    res: { response: true; token: string } | { response: false },
    payload: LocationChangeActionPayload,
  ): SagaIterator {
    if (res.response) {
      const params = yield call(
        extractRouteParams('/video-call-public/:id'),
        payload,
      );
      return { token: res.token, bookingId: params.id };
    }
  },
});

function* connect({ bookingId, token }): SagaIterator {
  try {
    return yield call([Video, 'connect'], token, { name: bookingId });
  } catch (err) {
    console.log('ERROR!', err);
    yield put(
      Creators.videoCallLogEventPublic({ event: 'onRoomDidFailToConnect' }),
    );
    throw new MMDError(err.message);
  }
}
const requestConnectVideoPublicWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<object>
>({
  takeEvery: Types.COMMIT_TWILIO_TOKEN_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.connectVideoPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: connect,
  // @ts-ignore
  *beforeAction(payload: { bookingId: string }): SagaIterator {
    const token = yield select((state: MyState) => state.videoCallPublic.token);
    return { ...payload, token };
  },
});
const requestLocalTrackPublicWatcher = createSingleEventSaga<
  object,
  { localTrack: any },
  MyAction<object>
>({
  takeEvery: Types.CONNECT_VIDEO_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitLocalTracksPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  // eslint-disable-next-line require-yield
  *action({ headers, ...room }: ArgsWithHeaders<Room>): SagaIterator {
    const booking = yield select(
      (state: MyState) => state.bookingsPublic.booking,
    );

    if (!booking.doctorAttended) {
      yield call(fetch, `${API_URL}/bookings-public/${booking.eventId}`, {
        headers,
        method: 'PUT',
        body: JSON.stringify({ doctorAttended: true }),
      });
    }
    const localVideoTracks = Array.from(
      room.localParticipant.videoTracks.values(),
    ).map((track) => track.track);

    const localAudioTrack = Array.from(
      room.localParticipant.audioTracks.values(),
    ).map((track) => track.track);

    return {
      video: localVideoTracks[0],
      audio: localAudioTrack[0],
    };
  },
  beforeAction: putAuthInfoInArgs,
});

const requestDisconnectPublicWatcher = createSingleEventSaga<
  object,
  void,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_DISCONNECT_VIDEO_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.clearVideoStatePublic,
  successAction: noOpAction,
  errorAction: ErrorActions.clearError,
  *action(): SagaIterator {
    const { room, localTracks }: VideoCallPublicState = yield select(
      (state: MyState) => state.videoCallPublic,
    );
    console.log('room.disconnect()');
    try {
      room.disconnect();
    } catch {
      console.log('error');
    }

    localTracks.audio.stop();
    localTracks.video.stop();
    localTracks.video.detach().forEach((detachedElement) => {
      detachedElement.remove();
    });
  },
});

const requestMuteLocalAudioTrackPublicWatcher = createSingleEventSaga<
  boolean,
  void,
  MyAction<boolean>
>({
  takeEvery: Types.REQUEST_MUTE_LOCAL_AUDIO_TRACK_PUBLIC,
  loadingAction: noOpAction,
  commitAction: Creators.commitIsAudioMutedPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.clearError,
  *action(): SagaIterator {
    const { audio }: LocalTracks = yield select(
      (state: MyState) => state.videoCallPublic.localTracks,
    );

    audio.disable();

    return true;
  },
});

const requestUnmuteLocalAudioTrackPublicWatcher = createSingleEventSaga<
  boolean,
  void,
  MyAction<boolean>
>({
  takeEvery: Types.REQUEST_UNMUTE_LOCAL_AUDIO_TRACK_PUBLIC,
  loadingAction: noOpAction,
  commitAction: Creators.commitIsAudioMutedPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.clearError,
  *action(): SagaIterator {
    const { audio }: LocalTracks = yield select(
      (state: MyState) => state.videoCallPublic.localTracks,
    );

    audio.enable();

    return false;
  },
});

function* logEventPublic(): SagaIterator {
  while (true) {
    try {
      const {
        payload: { event: action },
      } = yield take(Types.VIDEO_CALL_LOG_EVENT_PUBLIC);
      const actor = 'Doctor';
      const { bookingId } = yield select(
        (state: MyState) => state.videoCallPublic,
      );
      const { headers } = yield call(putAuthInfoInArgs, {});
      const result = yield call(
        fetch,
        `${AUTH_API_URL}/bookings-public/${bookingId}/timeTracking`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify({ actor, action }),
        },
      );
      if (!result.ok) {
        throw new MMDError('An error has occurred');
      }
    } catch (err) {
      yield put(ErrorActions.setError(err));
    }
  }
}

function extensionStatus(args: {
  bookingId: number;
  doctorId: string;
  patientId: string;
}): Observable<boolean> {
  return {
    subscribe: (next, error) => {
      const bookingExtensionsRef = doc(
        firestore,
        'bookingExtensions',
        `${args.bookingId}`,
        'doctors',
        args.doctorId,
        'patients',
        args.patientId,
      );

      const unsubscribe = onSnapshot(
        bookingExtensionsRef,
        (snap) => {
          if (snap.exists()) {
            next(snap.exists());
          }
        },
        error,
      );

      return { unsubscribe };
    },
  };
}

function* extensionStatusPublicWatcher(): SagaIterator {
  while (true) {
    const action = yield take(Types.SUBSCRIBE_EXTENSION_PUBLIC);
    const booking: Booking = action.payload.booking;
    const { id: bookingId, doctorId } = booking;
    const publicParticipant: string = 'publicParticipant';

    try {
      // Will emit only once - REMEMBER
      const promiseQueue = new PromiseQueue<boolean>(
        extensionStatus({
          bookingId: parseInt(bookingId, 10),
          doctorId,
          patientId: publicParticipant,
        }),
      );
      const { leave } = yield race({
        leave: take(
          (action: MyAction<{ event: string }>) =>
            action.type === Types.CLEAR_VIDEO_STATE_PUBLIC,
        ),
      });
      if (leave) {
        promiseQueue.cancel(new Error());
        continue;
      }
      // if (extend && extend.value) {
      //   yield put(Creators.extendVideoCallPublic());
      //   promiseQueue.cancel(new Error());
      // }
    } catch (err) {
      console.log('error', err);
    }
  }
}

export const videoCallPublicSagas = [
  logEventPublic,
  extensionStatusPublicWatcher,
  requestLocalTrackPublicWatcher,
  requestDisconnectPublicWatcher,
  requestConnectVideoPublicWatcher,
  requestVideoCallTokenPublicWatcher,
  requestMuteLocalAudioTrackPublicWatcher,
  requestUnmuteLocalAudioTrackPublicWatcher,
];

export const selectVideoCallPublicState = (state: MyState) =>
  state.videoCallPublic;
