import moment from 'moment';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import { bindActionCreators, Dispatch } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import { FC, JSX, useEffect, useMemo, useState } from 'react';

import {
  calendarBookingsSelectors,
  Creators as CalendarBookingsActions,
} from '../../modules/calendar.module';
import { MyState } from '../../store';
import { Booking } from '../../typings';
import { shouldTriggerOnChange, checkValueForCurrentMonth } from './helpers';

type Props = {
  bottomComponentSlot?: JSX.Element;
  monthlyAppointments: Booking[];
  requestCalendarAppointments: ({
    from,
    to,
  }: {
    from: string;
    to: string;
  }) => void;
};

const ScheduleCalendar: FC<Props> = ({
  bottomComponentSlot = null,
  monthlyAppointments,
  requestCalendarAppointments,
}) => {
  const [appointmentsByDay, setAppointmentsByDay] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [selectedMonth, setSelectedMonth] = useState(
    moment().toDate().getMonth(),
  );

  useEffect(() => {
    setAppointmentsByDay(
      monthlyAppointments.filter(
        (appt) =>
          moment(appt.startDate).format('YYYY-MM-DD') ===
          moment(selectedDate).format('YYYY-MM-DD'),
      ),
    );
  }, [selectedDate, monthlyAppointments]);

  useEffect(() => {
    if (!selectedMonth) return;

    requestCalendarAppointments({
      from: moment()
        .month(selectedMonth)
        .startOf('month')
        .format('YYYY-MM-DD HH:mm'),
      to: moment()
        .month(selectedMonth)
        .endOf('month')
        .format('YYYY-MM-DD HH:mm'),
    });
  }, [requestCalendarAppointments, selectedMonth]);

  const markedDates = useMemo(() => {
    if (!monthlyAppointments?.length) {
      return [];
    }

    return monthlyAppointments.reduce(
      (dates, appointment) => [
        ...dates,
        moment(appointment.startDate).format('YYYY-MM-DD'),
      ],
      [],
    );
  }, [monthlyAppointments]);

  const updateOnCalendarSelection = (val) => {
    setSelectedDate(val);
    if (selectedMonth !== val.getMonth()) {
      setSelectedMonth(val.getMonth());
    }
  };

  const getTitleClassName = ({ date }: { date: Date }) =>
    markedDates.includes(moment(date).format('YYYY-MM-DD'))
      ? 'react-calendar__marked-date'
      : '';

  const onActiveStartDateChange = ({ activeStartDate, action, view }) => {
    if (shouldTriggerOnChange(action, view)) {
      updateOnCalendarSelection(checkValueForCurrentMonth(activeStartDate));
    }
  };

  return (
    <Container fluid className="dashboard-border h-100">
      <Row className="pt-4">
        <Col className="text-center mb-3" xs={12}>
          <h2>Scheduled appointments</h2>
        </Col>
        <Col
          xs={12}
          className="text-center justify-content-center vcenter flex-column"
        >
          <Calendar
            value={selectedDate}
            calendarType="gregory"
            tileClassName={getTitleClassName}
            onChange={updateOnCalendarSelection}
            onActiveStartDateChange={onActiveStartDateChange}
          />

          {bottomComponentSlot}
        </Col>
        <Col className="mt-2 mb-2" xs={12}>
          <Row className="mt-2 mb-2">
            {appointmentsByDay.map((appointment) => (
              <Col
                xs={12}
                key={appointment.id}
                className="appointment-item pt-2 pb-2"
              >
                <strong>
                  {appointment.peerName}{' '}
                  {moment(appointment.startDate).format('LT')}
                </strong>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  monthlyAppointments: calendarBookingsSelectors.selectAll(
    state.calendarBookings,
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...CalendarBookingsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCalendar);
