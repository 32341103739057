import { connect, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import VideoCallView from './videocall/VideoCallView';
import { Creators as VideoCallActions } from '../modules/videocall.module';
import { Creators as patientInfoActions } from '../modules/patient-info.module';
import {
  filesSelectors,
  Creators as FilesActions,
} from '../modules/videocall/files.module';
import { prescriptionsSelectors } from '../modules/videocall/prescriptions.module';
import { Creators as NoteActions } from '../modules/videocall/notes.module';
import { FileReference } from '../typings';
import { MyState } from '../store';
import SidePanel from './videocall/video-call-view/SidePanel';
import Fullscreen from 'react-fullscreen-crossbrowser';
import { FC, useCallback, useEffect, useState } from 'react';
import { useIsStudentCheck } from '../hooks/useIsStudentCheck';

type Props = {
  state: any;
  room?: any;
  localTrack?: any;
  documents: FileReference[];
  pictures: FileReference[];
  notes: string;
  extended: boolean;
  patientInfo: any;
} & typeof VideoCallActions &
  typeof FilesActions &
  typeof NoteActions &
  typeof patientInfoActions;

const VideoCall: FC<Props> = ({
  extended,
  subscribeExtension,
  documents,
  pictures,
  room,
  localTrack,
  notes,
  patientInfo,
  videoCallLogEvent,
  requestUpsertNotesVideoCall,
  requestDisconnectVideo,
  requestUploadFile,
  requestFiles,
}) => {
  const params = useParams<{ id: string }>();
  const booking = useSelector(
    (state: MyState) => state.bookings.entities[params.id!],
  );
  const [isSidePanelVisible, setSidePanelVisible] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [callState, setCallState] = useState(true);
  const [finishCall, setFinishCall] = useState(false);
  const bookingId = booking && booking.id;
  const patientIdAux = booking && booking.patientId;
  const dataBookingFinishHour =
    booking && booking.event && booking.event.end.dateTime;

  const navigate = useNavigate();

  const isStudent = useIsStudentCheck();

  useEffect(() => {
    if (!booking) {
      return;
    }

    if (isStudent && !booking.allowStudent) {
      navigate('/my-appts');
    }
  }, [booking, isStudent]);

  useEffect(() => {
    const interval = setInterval(() => {
      const checkCallTime = () => {
        const dateNowAux = new Date().toISOString();
        const finishCallAux =
          dataBookingFinishHour &&
          dataBookingFinishHour.toString &&
          Date.parse(dataBookingFinishHour.toString());
        const timeToFinish = finishCallAux - Date.parse(dateNowAux);
        if (dataBookingFinishHour < dateNowAux) {
          setFinishCall(true);
        }
        if (timeToFinish < 0) {
          setCallState(false);
        }
      };
      checkCallTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const requestSidePanel = useCallback(() => {
    setSidePanelVisible(!isSidePanelVisible);
  }, [isSidePanelVisible, setSidePanelVisible]);

  const sendNote = useCallback(
    (body: string) => {
      requestUpsertNotesVideoCall({ id: params.id!, body });
    },
    [params.id, requestUpsertNotesVideoCall],
  );

  const saveFile = useCallback(
    (file: File) => {
      requestUploadFile({ booking, file });
    },
    [booking, requestUploadFile],
  );

  const videoCallEvent = useCallback(
    (event) => videoCallLogEvent({ event }),
    [videoCallLogEvent],
  );

  useEffect(() => {
    if (isSidePanelVisible) {
      requestFiles({ id: bookingId });
    }
  }, [isSidePanelVisible]);

  if (finishCall || !callState) {
    return (
      <Navigate
        to={isStudent ? 'dashboard' : `/feedback/${patientIdAux}/${bookingId}`}
      />
    );
  } else {
    return (
      <Fullscreen enabled={fullScreen}>
        <Row className="m-0 full-screen-component">
          <VideoCallView
            shrinkHorizontally={isSidePanelVisible}
            onRequestSidePanel={requestSidePanel}
            booking={booking}
            room={room}
            localTrack={localTrack}
            cleanup={requestDisconnectVideo}
            saveFile={saveFile}
            eventLogger={videoCallEvent}
            subscribeExtension={subscribeExtension}
            extended={extended}
            setFullScreen={() => setFullScreen(!fullScreen)}
            fullScreen={fullScreen}
            isSidePanelVisible={isSidePanelVisible}
          />

          <div className="close-panel-arrow" onClick={requestSidePanel}>
            <img src="/assets/down-arrow-light.png" alt="close icon" />
          </div>

          <SidePanel
            booking={booking}
            patientId={booking && booking.patientId}
            visible={isSidePanelVisible}
            documents={documents}
            pictures={pictures}
            notes={notes}
            sendNote={sendNote}
            files={patientInfo?.files ?? []}
          />
        </Row>
      </Fullscreen>
    );
  }
};

export const mapStateToProps = (state: MyState) => ({
  state: state,
  room: state.videoCall.room,
  extended: state.videoCall.extended,
  localTrack: state.videoCall.localTrack,
  documents: filesSelectors.selectDocuments(state.videoCallExtras.files),
  pictures: filesSelectors.selectPictures(state.videoCallExtras.files),
  notes: state.videoCallExtras.notes,
  prescriptions: prescriptionsSelectors.selectAll(
    state.videoCallExtras.prescriptions,
  ),
  patientInfo: state.patientInfo.patientInfo,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...VideoCallActions,
      ...FilesActions,
      ...NoteActions,
      ...patientInfoActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VideoCall);
