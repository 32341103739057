import { createActions, createReducer } from 'reduxsauce';
import { createSingleEventSaga, MyAction } from '@mrnkr/redux-saga-toolbox';

import { MyState } from '../store';
import { AUTH_API_URL } from '../config';
import { onRoute } from '../utils/onRoute';
import { CardInformation, Nullable } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import { ArgsWithHeaders, LocationChangeActionPayload } from '../utils/typings';

interface ActionTypes {
  COMMIT_REQUEST_CREDIT_CARDS: string;
  REQUEST_CREDIT_CARDS: string;
  REQUEST_SWITCH_CREDIT_CARDS: string;
}

interface ActionCreators {
  commitRequestCreditCards: (
    payload: CardInformation[],
  ) => MyAction<CardInformation>;
  requestCreditCards: () => MyAction<object>;
  requestSwitchCreditCards: (payload: {
    id: string;
  }) => MyAction<{ id: string }>;
}

export type CardState = {
  card: Nullable<CardInformation>;
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreditCards: [],
  requestSwitchCreditCards: ['payload'],
  commitRequestCreditCards: ['payload'],
});

const initialState = {
  card: null,
};

function commitRequestCreditCards(
  state: CardState,
  action: MyAction<CardInformation>,
): CardState {
  return {
    card: action.payload || state.card,
  };
}

export const cardReducer = createReducer(initialState, {
  [Types.COMMIT_REQUEST_CREDIT_CARDS]: commitRequestCreditCards,
});

async function requestCredictcardsProvider({
  headers,
}: ArgsWithHeaders<object>): Promise<CardInformation[]> {
  const result = await fetch(`${AUTH_API_URL}/payments/cards`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.message);
  }

  return result.json();
}

const requestCardWatcher = createSingleEventSaga<
  object | LocationChangeActionPayload,
  CardInformation,
  MyAction<object>
>({
  takeEvery: (action) =>
    onRoute('/payments')(action) || action.type === Types.REQUEST_CREDIT_CARDS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitRequestCreditCards,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: requestCredictcardsProvider,
  beforeAction: putAuthInfoInArgs,
  // eslint-disable-next-line require-yield
  *afterAction(res) {
    return res && res.data && res.data.length && res.data[0];
  },
});

async function requestSwitchCredictcardsProvider({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string; makeDefault: boolean }>): Promise<void> {
  //console.log({ card: { tokenId: payload.id } })
  const result = await fetch(`${AUTH_API_URL}/payments/cards`, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      card: { tokenId: payload.id },
      makeDefault: payload.makeDefault,
    }),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

const switchCardWatcher = createSingleEventSaga<
  { id: string },
  void,
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_SWITCH_CREDIT_CARDS,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: Creators.requestCreditCards as any,
  errorAction: ErrorActions.setError,
  action: requestSwitchCredictcardsProvider,
  beforeAction: putAuthInfoInArgs,
});

export const cardSaga = [requestCardWatcher, switchCardWatcher];

export const selectCardState = (state: MyState) => state.card;
