import { createSingleEventSaga, MyAction } from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';
import { take, call, put } from 'redux-saga/effects';

import { putAuthInfoInArgs } from '../auth.module';
import { noOpAction } from '../../utils/noOpAction';
import { Earnings } from '../../typings';
import { Creators as LoadingActions } from '../loading.module';
import { Creators as ErrorActions } from '../errors.module';
import { MMDError } from '../../utils/MMDError';
import { onRoute } from '../../utils/onRoute';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from '../../utils/typings';
import { AUTH_API_URL } from '../../config';

interface ActionTypes {
  REQUEST_EARNINGS: string;
  COMMIT_EARNINGS: string;
  REQUEST_GET_PAID: string;
}

interface ActionCreators {
  requestEarnings: () => MyAction<object>;
  commitEarnings: (payload: Earnings) => MyAction<Earnings>;
  requestGetPaid: () => MyAction<object>;
}

export type EarningsState = Earnings | object;

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestEarnings: [],
  commitEarnings: ['payload'],
  requestGetPaid: [],
});

const initialState = {};

function commitEarnings(
  state: EarningsState,
  action: MyAction<Earnings>,
): EarningsState {
  return action.payload || state;
}

export const earningsReducer = createReducer(initialState, {
  [Types.COMMIT_EARNINGS]: commitEarnings,
});

async function downloadEarnings({
  headers,
}: ArgsWithHeaders<LocationChangeActionPayload>): Promise<
  { response: true; earnings: Earnings } | { response: false }
> {
  const result = await fetch(`${AUTH_API_URL}/transactions/earnings`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError('An error has ocurred retrieving your earnings');
  }

  return result.json();
}

const requestEarningsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Earnings,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: (action) =>
    onRoute('/payments')(action) || action.type === Types.REQUEST_EARNINGS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitEarnings,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadEarnings,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(
    res: { response: true; earnings: Earnings } | { response: false },
  ): SagaIterator {
    if (res.response) {
      return res.earnings;
    }
  },
});

function* getPaidWatcher(): SagaIterator {
  while (yield take(Types.REQUEST_GET_PAID)) {
    yield put(LoadingActions.setLoading());

    const { headers } = yield call(putAuthInfoInArgs, {});

    const response = yield call(
      fetch,
      `${AUTH_API_URL}/transactions/get-paid`,
      {
        headers,
        method: 'POST',
      },
    );

    if (!response.ok) {
      yield put(
        ErrorActions.setError(
          new MMDError(
            'There was an error processing your payout request. Contact the administrator.',
          ),
        ),
      );
      continue;
    }
    yield put(LoadingActions.setNotLoading());

    yield put(Creators.requestEarnings());
  }
}

export const earningsSagas = [requestEarningsWatcher, getPaidWatcher];
