import React from 'react';
import MMDModal from '../../components/MMDModal';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';

interface Props {
  handleClose: () => void;
  handleCancel: () => void;
  handlePayOut: () => void;
  show: boolean;
  currentEarnings: string;
  debitCard: string;
}

const ModalExpressPay: React.FC<Props> = ({
  handleClose,
  handlePayOut,
  handleCancel,
  show,
  currentEarnings,
  debitCard,
}) => {
  return (
    <MMDModal
      show={show}
      title={'Get Paid'}
      handleClose={handleClose}
      classNameContainer={'modal-container-padding'}
      renderBody={() => (
        <Row>
          <Col xs={12} className="border-bottom-solid"></Col>
          <Col xs={12} className=" padding-h-0 vcenter border-bottom-solid">
            <Col xs={6} className=" padding-h-0">
              {'Current Earnings:'}
            </Col>
            <Col xs={6} className="text-right">
              <span className="price-value price-money"> {`$`}</span>
              <span className="price-value"> {` ${currentEarnings || 0}`}</span>
              <span className="price-value"> </span>
            </Col>
          </Col>
          <Col xs={12} className="border-bottom-solid padding-vertical-20">
            <div>
              {debitCard
                ? `Debit card: **** **** **** ${debitCard}`
                : 'No cards available'}
            </div>
          </Col>

          <Col xs={12} className="border-bottom-solid padding-vertical-20">
            {
              'Instant Payouts cost 1.5% of the payout amount, with a minimum fee of 50¢.'
            }
          </Col>

          <Col
            xs={12}
            className=" padding-h-0 vcenter border-bottom-solid padding-vertical-20"
          >
            <Col xs={6} className=" padding-h-0">
              {`Period:`}
            </Col>
            <Col xs={6} className="text-right">
              {`${moment()
                .startOf('isoWeek')
                .format('MM/DD')} -- ${moment().format('MM/DD')}`}
            </Col>
          </Col>
        </Row>
      )}
      renderFooter={() => (
        <Row className="w-100 center-footer">
          <Col
            xs={12}
            className="text-center modal-footer-btn margin-bottom-10"
          >
            <Button
              className=" primary height-30 padding-h-50"
              variant="primary"
              onClick={handlePayOut}
            >
              <h6>Pay Out </h6>
            </Button>
          </Col>
          <Col xs={12} className="text-center modal-footer-btn">
            <Button
              variant="secondary"
              className="padding-h-50 height-30"
              onClick={handleCancel}
            >
              <h6>Cancel </h6>
            </Button>
          </Col>
        </Row>
      )}
    />
  );
};

export default ModalExpressPay;
