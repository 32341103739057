import React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';

import FileInput from './message-compose/FileInput';

type Props = {
  chatroomId: string;
  doctorId: string;
  patientId: string;
  sendMessage?: (body: string) => void;
};

const MessageCompose: React.FC<Props> = ({
  chatroomId,
  patientId,
  doctorId,
  sendMessage = () => {},
}) => {
  const [msg, setMsg] = React.useState<string>('');
  const onRequestSend = React.useCallback(() => {
    if (!msg.length) {
      return;
    }

    sendMessage(msg);
    setMsg('');
  }, [msg, sendMessage, setMsg]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onRequestSend();
      }
    },
    [onRequestSend],
  );

  const onChange = React.useCallback(
    (e: React.FormEvent) => {
      const target = e.target as HTMLInputElement;
      setMsg(target.value);
    },
    [setMsg],
  );

  return (
    <div className="mx-2 w-100 msg-row ">
      <FileInput
        chatroomId={chatroomId}
        sendMessage={sendMessage}
        patientId={patientId}
        doctorId={doctorId}
      />
      <Col xs={10} className="text-area">
        <FormControl
          className="my-3"
          type="text"
          placeholder="Write something nice"
          name="msg-compose"
          value={msg}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </Col>
      <img
        onClick={onRequestSend}
        className="send-btn"
        src="/assets/send-button.png"
        alt="Send button"
      />
    </div>
  );
};

export default MessageCompose;
