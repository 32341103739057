import { Col, Container, Row } from 'react-bootstrap';

import { Answer, Booking } from '../../typings';
import { FC } from 'react';

type Props = {
  appointment: Booking;
};

const AnswerAndQuestion: FC<Props> = ({ appointment }) => {
  if (!appointment) return null;
  return (
    <Row className="pt-2 justify-content-center">
      <Container>
        {appointment.answers.map((answer) => (
          <ShowAnswerQuestion key={answer.id} answer={answer} />
        ))}
      </Container>
    </Row>
  );
};

export default AnswerAndQuestion;

const ShowAnswerQuestion: FC<{ answer: Answer }> = ({ answer }) => (
  <Row className="pl-2">
    <Col className="border-bottom-solid ">
      <p className="border-bottom vertical-padding-body">
        {answer.question.body}:
      </p>
      <p className="text-ellipsis">{answer.body}</p>
    </Col>
  </Row>
);
