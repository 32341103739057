import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import { Affiliation } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface ActionTypes {
  REQUEST_AFFILIATIONS: string;
  COMMIT_AFFILIATIONS: string;
  SUCCESS_AFFILIATIONS: string;
  ERROR_AFFILIATIONS: string;
}

interface ActionCreators {
  requestAffiliations: () => MyAction<void>;
  commitAffiliations: (payload: Affiliation[]) => MyAction<Affiliation[]>;
}

export interface AffiliationsState extends EntityState<Affiliation> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAffiliations: [],
  commitAffiliations: ['payload'],
});

const entityAdapter = createEntityAdapter<any>();
const initialState = entityAdapter.getInitialState({});
export const affiliationSelectors = entityAdapter.getSelectors();

function commitAffiliations(
  state: AffiliationsState,
  action: MyAction<Affiliation[]>,
): AffiliationsState {
  return {
    ...entityAdapter.addAll(action.payload, state),
  };
}

export const affiliationsReducer = createReducer(initialState, {
  [Types.COMMIT_AFFILIATIONS]: commitAffiliations,
});

async function downloadAffiliations({
  headers,
}: ArgsWithHeaders<any>): Promise<Affiliation[]> {
  const result = await fetch(`${AUTH_API_URL}/affiliations`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
  const temp = await result.json();
  return temp.data;
}

const requestAffiliationsWatcher = createSingleEventSaga<
  object,
  Affiliation[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_AFFILIATIONS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAffiliations,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadAffiliations,
  beforeAction: putAuthInfoInArgs,
});

export const affiliationsSagas = [requestAffiliationsWatcher];
