import { FC } from 'react';

import { getIsMobileOrTablet } from '../../utils/getIsMobileOrTablet';

const isMobileOrTablet = getIsMobileOrTablet();

type Props = {
  changeVideoTrackClientPublic: () => void;
};
const SwitchVideoDevice: FC<Props> = ({ changeVideoTrackClientPublic }) => {
  if (!isMobileOrTablet) {
    return null;
  }

  return (
    <div
      onClick={changeVideoTrackClientPublic}
      className="btnVideoCall__grayTransparent btnVideoCall__outline btnVideoCall__flipCamera"
    >
      <img src="/assets/flip-camera-icon.png" alt="flip icon" />
    </div>
  );
};

export default SwitchVideoDevice;
