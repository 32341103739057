import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ItemWithNotification from '../dashboard/ItemWithNotification';
import { Patient } from '../../typings';

type Props = {
  patient: Patient;
};

const PatientDetails: React.FC<Props> = ({ patient }) => {
  if (!patient) {
    return null;
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={4} className="text-center">
          <Image src={patient.avatar} roundedCircle width="120" height="120" />
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: 'space-between' }}
          xs={8}
        >
          <Row>
            <Col xs={12}>
              <p className="mb-1">
                <strong>{`${patient.firstName} ${patient.lastName}`}</strong>
              </p>
            </Col>
            <Col xs={5}>Gender:</Col>
            <Col xs={7}>{patient.gender}</Col>
            <Col xs={5}>Birthday:</Col>
            <Col xs={7}>{patient.birthday}</Col>
            <Col xs={5}>SSN:</Col>
            <Col xs={7}>{patient.ssn}</Col>
          </Row>
        </Col>
        <Col className="mt-3 justify-content-center" xs={12}>
          <Row className="mt-4 pl-4 justify-content-center">
            <Col xs={3}>
              <p className="mb-1">Address</p>
            </Col>
            <Col xs={9}>
              <p className="mb-1">{patient.address}</p>
            </Col>
          </Row>
          <Row className="mt-4 pl-4 justify-content-center">
            <Col xs={3}>
              <p className="mb-1">Insurance</p>
            </Col>
            <Col xs={9}>
              <p className="mb-1">{patient.subscriber}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4 text-center justify-content-center">
        <ItemWithNotification noBorder attribute="Medications" />
        <ItemWithNotification noBorder attribute="Allergies" />
        <ItemWithNotification noBorder attribute="Medical History" />
        <ItemWithNotification noBorder attribute="Lifestyle" />
        <ItemWithNotification noBorder attribute="Documents" />
      </Row>
    </Container>
  );
};

export default PatientDetails;
