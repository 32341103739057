import { Dictionary } from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { LocationChangeActionPayload, Predicate } from './typings';

export function onRoute<T extends Action = Action>(path: string): Predicate<T> {
  return (action: T) =>
    action.type === '@@router/LOCATION_CHANGE' &&
    matchRoute(path, (action as any).payload.location.pathname);
}

function matchRoute(expectedPattern: string, actualPath: string): boolean {
  let match = true;
  const splitExpectedPattern = expectedPattern.split('/');
  const splitActualPath = actualPath.split('/');

  if (splitExpectedPattern.length !== splitActualPath.length) {
    return false;
  }

  for (let i = 0; i < splitExpectedPattern.length; i = i + 1) {
    if (splitExpectedPattern[i].includes(':')) {
      continue;
    }

    match = match && splitExpectedPattern[i] === splitActualPath[i];
  }

  return match;
}

export function extractRouteParams(
  pattern: string,
): (payload: LocationChangeActionPayload) => SagaIterator {
  const worker = (path: string) => {
    const result: Dictionary<string> = {};
    const splitPattern = pattern.split('/');
    const splitPath = path.split('/');

    if (!matchRoute(pattern, path)) {
      return result;
    }
    for (let i = 0; i < splitPattern.length; i += 1) {
      if (splitPattern[i].includes(':')) {
        result[splitPattern[i].replace(':', '')] = splitPath[i];
      }
    }

    return result;
  };

  return function* (payload: LocationChangeActionPayload): SagaIterator {
    return yield call(worker, payload.location.pathname);
  };
}
