import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';

import MMDTextInput from '../../components/forms/MMDTextInput';
import { useStripe } from '@stripe/react-stripe-js';
import { Creators as ErrorActions } from '../../modules/errors.module';
import { Creators as BankAccountActions } from '../../modules/bank-account.module';
import { useBoundedActions } from '../../hooks/useBoundedActions';
import { MMDError } from '../../utils/MMDError';
import { FC } from 'react';

const validationSchema = Yup.object().shape({
  holderName: Yup.string()
    .trim()
    .required('Field is required')
    .matches(/^[a-zA-Z ]+$/, 'Enter only letters'),
  routingNumber: Yup.string()
    .trim()
    .length(9, 'Should be 9 digits')
    .required('Field is required')
    .matches(/^[0-9]*$/, 'Enter only numbers'),
  accountNumber: Yup.string()
    .trim()
    .min(4, 'Minimum 4 digits')
    .max(17, 'Maximum 17 digits')
    .required('Field is required')
    .matches(/^[0-9]*$/, 'Enter only numbers'),
});

interface ACHFormValues {
  holderName: string;
  routingNumber: string;
  accountNumber: string;
}

type Props = {
  onHideForm: () => void;
  isACHAccountDefaultPaymentMethod: boolean;
};

const AchForm: FC<Props> = ({
  onHideForm,
  isACHAccountDefaultPaymentMethod,
}) => {
  const { setError, requestSwitchBankAccount } = useBoundedActions({
    ...ErrorActions,
    ...BankAccountActions,
  });
  const stripe = useStripe();

  const getToken = async (values: ACHFormValues) => {
    const { token, error } = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: values.routingNumber,
      account_number: values.accountNumber,
      account_holder_name: values.holderName,
      account_holder_type: 'individual',
    });

    if (!token) {
      setError(new MMDError(error.message ?? 'Unknown Stripe error'));
      return;
    }

    requestSwitchBankAccount({
      id: token.id,
      makeDefault: isACHAccountDefaultPaymentMethod,
    });

    onHideForm();
  };

  return (
    <Formik<ACHFormValues>
      initialValues={{
        holderName: '',
        routingNumber: '',
        accountNumber: '',
      }}
      onSubmit={getToken}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <MMDTextInput
            required
            showFieldError
            values={values}
            fieldKey="holderName"
            className="mb-2"
            placeholder="..."
            label="Account Holder Name"
          />

          <MMDTextInput
            required
            showFieldError
            values={values}
            fieldKey="routingNumber"
            className="mb-2"
            placeholder="..."
            label="Routing number"
          />

          <MMDTextInput
            required
            showFieldError
            values={values}
            fieldKey="accountNumber"
            className="mb-2"
            placeholder="..."
            label="Account number"
          />

          <Button type="submit" className="mt-2 mb-5">
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AchForm;
