import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ItemWithNotification from './ItemWithNotification';

type Props = {
  todayUpAppoints: number;
  unreadMessages: number;
  prescriptionsRefillsCount: number;
  isStudent: boolean;
};

const DashboardMenu: React.FC<Props> = ({
  todayUpAppoints,
  unreadMessages,
  prescriptionsRefillsCount,
  isStudent,
}) => {
  return (
    <Container>
      <Row className="justify-content-center">
        <h2 className="sub-title mb-4 center-text-align">Your dashboard</h2>
        <ItemWithNotification
          attribute="Video consultations"
          notification={todayUpAppoints}
          url="/my-appts"
        />
        {!isStudent && (
          <ItemWithNotification
            attribute="Prescription Refill"
            notification={prescriptionsRefillsCount}
            url="/refills"
          />
        )}
        {!isStudent && (
          <ItemWithNotification
            attribute="Messages"
            notification={unreadMessages}
            url="/chats"
          />
        )}
        {!isStudent && (
          <ItemWithNotification attribute="Billing" url="/payments" />
        )}
      </Row>
    </Container>
  );
};

export default DashboardMenu;
