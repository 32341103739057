import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <Col xs={12}>
      <Row className="border-bottom justify-content-center vertical-buffer">
        <Col className="text-center font-weight-bold" xs={12}>
          <Link to="/terms-and-conditions" replace>
            <p className={'margin-item-footer text-break doctor-attr-value'}>
              Terms and Conditions
            </p>
          </Link>
        </Col>
        <Col className="text-center font-weight-bold" xs={12}>
          <p className="margin-item-footer text-break doctor-attr-value">
            <a href="mailto:admin@momentmd.com">Contact Us</a>
          </p>
        </Col>
        <Col className="text-center doctor-attr-name" xs={12}>
          Version: {process.env.REACT_APP_WEB_VERSION || '1.0'}
        </Col>
      </Row>
    </Col>
  );
};

export default Footer;
