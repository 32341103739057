import * as Yup from 'yup';
import moment from 'moment';
import { Options } from 'react-select';

import { BookingPublic, Nullable } from '../../../../typings';
import { PublicBookingDuration } from '../../../../modules/bookings-public.module';
import {
  PHONE_REG_EXP,
  PHONE_REG_EXP_ERROR,
} from '../../../../modules/signup.module';

export type BaseOption = {
  value: string;
  label: string;
};

const REQUIRED_ERROR = 'This field is required';

export const PARTICIPANTS_OPTIONS: Options<BaseOption> = Array.from(
  { length: 5 },
  (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }),
);

const getIsPreviousDurationDeleted = (
  editedPublicBooking: Nullable<BookingPublic>,
  publicBookingDurations: Nullable<PublicBookingDuration[]>,
) => {
  if (!editedPublicBooking || !publicBookingDurations) {
    return false;
  }

  const editedPublicBookingDuration = getBookingDuration(editedPublicBooking);

  return (
    publicBookingDurations.findIndex(
      ({ duration }) => duration === editedPublicBookingDuration,
    ) === -1
  );
};

const mapPublicBookingDurationOptions = <T extends { duration: number }>(
  publicBookingDurations: Nullable<T>[],
): Options<BaseOption> =>
  publicBookingDurations?.map(({ duration }) => ({
    value: duration.toString(),
    label: `${duration} min`,
  })) ?? [];

const insertDeletedDurationOption = (
  existingDurationOptions: Options<BaseOption>,
  editedPublicBooking: Nullable<BookingPublic>,
): Options<BaseOption> => [
  ...existingDurationOptions,
  ...mapPublicBookingDurationOptions([
    { duration: getBookingDuration(editedPublicBooking) },
  ]),
];

export const getBookingDurationOptions = (
  publicBookingDurations: Nullable<PublicBookingDuration[]>,
  editedPublicBooking: Nullable<BookingPublic>,
) => {
  const defaultOptions = mapPublicBookingDurationOptions(
    publicBookingDurations,
  );

  const isPreviousDurationDeleted = getIsPreviousDurationDeleted(
    editedPublicBooking,
    publicBookingDurations,
  );

  return isPreviousDurationDeleted
    ? insertDeletedDurationOption(defaultOptions, editedPublicBooking)
    : defaultOptions;
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Not valid email').required(REQUIRED_ERROR),
  phone: Yup.string().matches(PHONE_REG_EXP, PHONE_REG_EXP_ERROR),
  startDate: Yup.object().required(REQUIRED_ERROR),
  duration: Yup.string().required(REQUIRED_ERROR),
  amountOfParticipants: Yup.string().required(REQUIRED_ERROR),
});

export const formatAppointmentTime = (startDate: string, endDate: string) => {
  const formattedStartTime = moment(startDate).format('h:mm a');

  const formattedEndTime = moment(endDate).format('h:mm a');

  return `${formattedStartTime} - ${formattedEndTime}`;
};

export const formatAppointmentDate = (startDate: string) => {
  const isStartDateToday = moment(startDate).isSame(moment(), 'day');

  return isStartDateToday ? 'Today' : moment(startDate).format('DD MMMM YYYY');
};

export const getBookingDuration = (publicAppointment: BookingPublic) =>
  moment(publicAppointment.endDate).diff(
    moment(publicAppointment.startDate),
    'minute',
  );

export const getInitialVideoLinkValues = (
  publicAppointment: Nullable<BookingPublic>,
  publicBookingDurations: Nullable<PublicBookingDuration[]>,
) => {
  if (publicAppointment) {
    return {
      email: publicAppointment.patientEmail,
      phone: publicAppointment.patientPhone ?? '',
      startDate: moment(publicAppointment.startDate),
      duration: String(getBookingDuration(publicAppointment)),
      amountOfParticipants: PARTICIPANTS_OPTIONS.find(
        ({ value }) => value === publicAppointment.numberParticipant,
      ).value,
    };
  }

  const defaultBookingDurationOptions = mapPublicBookingDurationOptions(
    publicBookingDurations,
  );

  return {
    email: '',
    phone: '',
    startDate: moment().set('second', 0),
    duration: defaultBookingDurationOptions[0]?.value,
    amountOfParticipants: PARTICIPANTS_OPTIONS[0].value,
  };
};
