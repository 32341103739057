import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';

import {
  Creators as AuthActions,
  AuthState,
  verifyValidator,
} from '../../modules/auth.module';
import { MyState } from '../../store';
import MMDTextInput from '../../components/forms/MMDTextInput';
import { FC, useCallback, useState } from 'react';

type PropTypes = {
  auth: AuthState;
} & typeof AuthActions;

const VerifyForm: FC<PropTypes> = ({ resendCode, verifyAccount }) => {
  const [timeleft, setTimeleft] = useState(10);
  const [resendActive, setResendActive] = useState(false);

  const handleResend = useCallback(() => {
    setResendActive(true);
    const downloadTimer = setInterval(() => {
      let count = 10;
      setTimeleft((prevCount) => (count = prevCount - 1));
      if (count === 0) {
        setResendActive(false);
        clearInterval(downloadTimer);
        setTimeleft(10);
      }
    }, 1000);
    resendCode();
  }, [resendCode]);

  return (
    <Formik
      initialValues={{ verificationCode: '' }}
      validate={verifyValidator}
      onSubmit={(values) => {
        verifyAccount({
          verificationCode: values['verificationCode'],
        });
      }}
    >
      {({ values }) => (
        <>
          <Col xs={12} className="mb-3">
            <MMDTextInput
              values={values}
              fieldKey="verificationCode"
              label="4 digit verification code"
              placeholder="1234"
              maxLength={4}
              showFieldError={true}
            />
          </Col>
          <Col xs={12} className="mb-2">
            {!resendActive ? (
              <Button className="mt-4" variant="link" onClick={handleResend}>
                RESEND CODE
              </Button>
            ) : (
              <p className="mt-4">{`RESEND CODE in ${timeleft}`}</p>
            )}
          </Col>
          <Button className="mt-4" type="submit" variant="primary">
            {' '}
            VERIFY ACCOUNT{' '}
          </Button>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: MyState) => ({
  auth: state.auth,
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...AuthActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VerifyForm);
