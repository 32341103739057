import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(config);

export const getFirebaseImage = async (url: string): Promise<string> => {
  try {
    const pathReference = ref(storage, url);
    if (pathReference) {
      return await getDownloadURL(pathReference);
    }
  } catch (e) {
    // TODO fix this error
    // console.log({ e });
  }
};

export const deleteProviderImage = async (url: string) => {
  try {
    const pathReference = ref(storage, url);
    const image = await deleteObject(pathReference);
    alert('image succesfully deleted');
    return image;
  } catch (e) {
    console.log({ e });
  }
};

export const updateUserInFirestore = (uid, userData) =>
  updateDoc(doc(firestore, 'users', uid), userData);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
