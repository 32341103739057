import some from 'lodash/some';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { PaginationPayload } from '../typings';

export const appendPaginationParams = (
  paginationPayload: PaginationPayload,
) => {
  if (some(paginationPayload, isNil) || isEmpty(paginationPayload)) {
    return '';
  }

  const { limit, offset, search } = paginationPayload;

  return `?limit=${limit}&offset=${offset}&search=${search}`;
};
