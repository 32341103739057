import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import EPrescribing from '../../components/EPrescribing';
import { Prescription, Patient, RefillRequest } from '../../typings';

type Props = {
  patient: Patient;
  prescription: Prescription;
  id: string;
};

const PrescriptionView: React.FC<Props> = ({ patient, prescription, id }) => {
  const [step, setStep] = React.useState(1);

  return (
    <Container className="dashboard-border">
      <Row className="pt-4 pr-2 pl-2 text-center vcenter justify-content-center">
        <Col className="mb-4" xs={12}>
          <Row>
            {step !== 1 && (
              <Col
                onClick={() => setStep((prevStep) => prevStep - 1)}
                style={{ cursor: 'pointer' }}
                xs={1}
              >
                <Image width="8" height="15" src="../assets/left-arrow.png" />
              </Col>
            )}
            <Col xs={step === 1 ? 12 : 10}>E-Prescription</Col>
          </Row>
        </Col>
      </Row>
      <EPrescribing
        isNewPrescription={false}
        step={step}
        onChangeStep={setStep}
        refillRequest={{ patient, prescription, id } as RefillRequest}
      />
    </Container>
  );
};

export default PrescriptionView;
