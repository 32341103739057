export enum MOBILE_OPERATING_SYSTEMS {
  IOS,
  ANDROID,
  WINDOWS,
  UNKNOWN,
}

export const getMobileOperatingSystem = () => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return MOBILE_OPERATING_SYSTEMS.WINDOWS;
  }

  if (/android/i.test(userAgent)) {
    return MOBILE_OPERATING_SYSTEMS.ANDROID;
  }

  // @ts-ignore The !window.MSStream is to not incorrectly detect IE11
  // http://www.neowin.net/news/ie11-fakes-user-agent-to-fool-gmail-in-windows-phone-81-gdr1-update
  // https://msdn.microsoft.com/en-us/library/hh869301(v=vs.85).aspx
  if (/iPad|iPhone|webOS|iPod/.test(userAgent) && !window.MSStream) {
    return MOBILE_OPERATING_SYSTEMS.IOS;
  }

  return MOBILE_OPERATING_SYSTEMS.UNKNOWN;
};
