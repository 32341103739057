import { FC } from 'react';
import { Button, Col } from 'react-bootstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

interface Props {
  requestSwitchCreditCards: (values: {
    id: string;
    makeDefault: boolean;
  }) => void;
  onClose: () => void;
  isDebitCardDefaultPaymentMethod: boolean;
}

const DebitCardForm: FC<Props> = ({
  requestSwitchCreditCards,
  onClose,
  isDebitCardDefaultPaymentMethod,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const getToken = async () => {
    const cardElement = elements.getElement(CardElement);

    const { token, error } = await stripe.createToken(cardElement, {
      currency: 'usd',
    });

    if (!token) {
      throw new Error(error.message ?? 'Unknown Stripe error');
    }

    requestSwitchCreditCards({
      id: token.id,
      makeDefault: isDebitCardDefaultPaymentMethod,
    });
  };

  return (
    <div className="checkout">
      <CardElement />
      <Col className="justify-content-center text-center w-100 my-3" xs={12}>
        <Button variant="primary" onClick={getToken}>
          Save
        </Button>
      </Col>
    </div>
  );
};

export default DebitCardForm;
