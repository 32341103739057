import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

interface Props {
  className?: string;
  title: string;
  check: boolean;
  onclick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const AffiliationItem: React.FC<Props> = ({
  title,
  className,
  check,
  onclick,
}) => (
  <Row
    onClick={onclick}
    className={`w-100 vcenter text-decoration-none ${
      className || ''
    } cursor-pointer`}
  >
    <Col className="text-left">
      <h4 className="affiliation-title">{title}</h4>
    </Col>
    <Col className=" text-right pr-3">
      {check && (
        <FontAwesomeIcon
          size={'1x'}
          icon={faCheck}
          style={{ color: '#F11B42' }}
        />
      )}
    </Col>
  </Row>
);

export default AffiliationItem;
