import {
  createSingleEventSaga,
  MyAction,
  createEntityAdapter,
  EntityState,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';

import { AUTH_API_URL } from '../../config';
import { BookingPublic } from '../../typings';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from '../../utils/typings';
import { Creators as LoadingActions } from '../loading.module';
import { Creators as ErrorActions } from '../errors.module';
import { MMDError } from '../../utils/MMDError';
import { putAuthInfoInArgs, putAuthPublicInfoInArgs } from '../auth.module';
import { noOpAction } from '../../utils/noOpAction';
import { storage } from '../../utils/Firebase';
import {
  isAnImage,
  isADocument,
  isAVideo,
} from '../../utils/extractFileExtension';
import { FileReference } from '../../typings';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';
import { MyState } from '../../store';

interface ActionTypes {
  REQUEST_FILES_PUBLIC: string;
  REQUEST_UPLOAD_FILE_PUBLIC: string;
  COMMIT_FILES_VIDEO_CALL_PUBLIC: string;
  COMMIT_NEW_FILE_PUBLIC: string;
}

interface ActionCreators {
  requestFilesPublic: ({ id }) => MyAction<{ booking: BookingPublic }>;
  requestUploadFilePublic: (payload: {
    bookingId: string;
    doctorId: string;
    file: File;
  }) => MyAction<{ bookingId: string; doctorId: string; file: File }>;
  commitFilesVideoCallPublic: (
    payload: FileReference[],
  ) => MyAction<FileReference[]>;
  commitNewFilePublic: (payload: FileReference) => MyAction<FileReference>;
}

export interface VideoCallFilesPublicState extends EntityState<FileReference> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestFilesPublic: ['payload'],
  requestUploadFilePublic: ['payload'],
  commitFilesVideoCallPublic: ['payload'],
  commitNewFilePublic: ['payload'],
});

const entityAdapter = createEntityAdapter<FileReference>();
const initialState = entityAdapter.getInitialState();

const selectFilesPublicState = (state: MyState) => state.videoCallPublicExtras;

const selectPublicFiles = createSelector(
  selectFilesPublicState,
  (videCallPublicState) =>
    entityAdapter.getSelectors().selectAll(videCallPublicState.files),
);

const selectPictures = createSelector(selectPublicFiles, (files) =>
  files.filter((file) => isAnImage(file.fileNamePublic)),
);

const selectDocuments = createSelector(selectPublicFiles, (files) =>
  files.filter(
    (file) => isADocument(file.fileNamePublic) || isAVideo(file.fileNamePublic),
  ),
);

const selectVideos = createSelector(
  entityAdapter.getSelectors().selectAll,
  (files) => files.filter((file) => isAVideo(file.fileNamePublic)),
);

export const filesPublicSelectors = {
  ...entityAdapter.getSelectors(),
  selectDocuments,
  selectPictures,
  selectVideos,
};

function commitFilesVideoCallPublic(
  state: VideoCallFilesPublicState,
  action: MyAction<FileReference[]>,
): VideoCallFilesPublicState {
  return entityAdapter.addAll(action.payload, state);
}

function commitNewFilePublic(
  state: VideoCallFilesPublicState,
  action: MyAction<FileReference>,
): VideoCallFilesPublicState {
  return entityAdapter.addOne(action.payload, state);
}

export const videoCallFilesPublicReducer =
  createReducer<VideoCallFilesPublicState>(initialState, {
    [Types.COMMIT_FILES_VIDEO_CALL_PUBLIC]: commitFilesVideoCallPublic,
    [Types.COMMIT_NEW_FILE_PUBLIC]: commitNewFilePublic,
  });

async function downloadFilesForVideoCallPublic(payload: {
  id: string;
}): Promise<{ response: true; data: FileReference[] } | { response: false }> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.id}/files-public`,
  );

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong downloading files associated to this call',
    );
  }

  return result.json();
}

const requestVideoCallFilesPublicWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  FileReference[],
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_FILES_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitFilesVideoCallPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadFilesForVideoCallPublic,
  // eslint-disable-next-line require-yield
  *afterAction(res: { response: boolean; data?: FileReference[] }) {
    if (res.response) {
      return res.data;
    }
  },
});

async function uploadFilePublic({
  headers,
  bookingId,
  doctorId,
  file,
}: ArgsWithHeaders<{
  bookingId: string;
  doctorId: string;
  file: File;
}>): Promise<FileReference> {
  const storageRef = ref(
    storage,
    `bookings/${bookingId}/${doctorId}/public/${file.name}`,
  );
  await uploadBytes(storageRef, file);
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${bookingId}/files-public`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({ fileName: file.name }),
    },
  );

  if (!result.ok) {
    await deleteObject(
      ref(storage, `bookings/${bookingId}/${doctorId}/public/${file.name}`),
    );
    throw new MMDError('An error has occurred uploading your file');
  }

  return result.json();
}

const requestUploadFilePublicWatcher = createSingleEventSaga<
  { booking: BookingPublic; file: File },
  FileReference,
  MyAction<{ booking: BookingPublic; file: File }>
>({
  takeEvery: Types.REQUEST_UPLOAD_FILE_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitNewFilePublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: uploadFilePublic,
  beforeAction: putAuthPublicInfoInArgs,
  // eslint-disable-next-line require-yield
  *afterAction(res: { response: boolean; data?: FileReference }) {
    if (res.response) {
      return res.data;
    }
  },
});

export const videoCallFilesPublicSagas = [
  requestVideoCallFilesPublicWatcher,
  requestUploadFilePublicWatcher,
];
