import { Col } from 'react-bootstrap';
import moment from 'moment';

import { PatientTransaction } from '../../typings';
import { FC } from 'react';

type Props = {
  transactions: PatientTransaction[];
};

const Transactions: FC<Props> = ({ transactions }) => (
  <>
    {transactions.map((item) => (
      <div key={item.id} className={`xs-12 item-transactions`}>
        <div className={`xs-12 vcenter p-2`}>
          <Col xs="10">
            <span
              className={
                item.subscriptionId ? 'subscription-color' : 'video-call-color'
              }
            >
              {item.subscriptionId
                ? 'Subscription'
                : item.bookingId
                ? 'Video Call'
                : ''}
            </span>{' '}
            with {item.patient.firstName} {item.patient.lastName}
          </Col>
          <Col xs="2" className="text-right">
            <span className="text-success font-weight-bold">$</span>
            {(item.amount - (item.appfee * item.amount) / 100).toFixed(2)}
          </Col>
        </div>
        <hr />
        <div className={`xs-12 vcenter p-2`}>
          <Col xs="4">#{item.transactionId}</Col>
          <Col xs="8" className="text-right">
            {moment(item.createdAt).format('MMM DD, YYYY  h:mm A')}
          </Col>
        </div>
      </div>
    ))}
  </>
);

export default Transactions;
