import { useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import DebitCardForm from './DebitCardForm';
import { useToggle } from '../../hooks/useToggle';
import { useBoundedActions } from '../../hooks/useBoundedActions';
import { Creators as PaymentActions } from '../../modules/payments.module';
import {
  selectCardState,
  Creators as CardActions,
} from '../../modules/cards.module';

const DebitCard = () => {
  const { requestSwitchCreditCards, requestSetDefaultPaymentMethod } =
    useBoundedActions({
      ...CardActions,
      ...PaymentActions,
    });

  const [isEditCardVisible, toggleCardVisible] = useToggle();

  const { card } = useSelector(selectCardState);

  const renderCardInformation = () => {
    if (!card) {
      return null;
    }

    return (
      <>
        <Container className="dashboard-border">
          <Row>
            <Col className="text my-3" xs={12}>
              {card.brand}
            </Col>
          </Row>
          <Row>
            <Col className="text-muted mb-3" xs={12}>
              CARD NUMBER
            </Col>
          </Row>
          <Row>
            <Col className="text mb-3" xs={2}>
              ****
            </Col>
            <Col className="text mb-3" xs={2}>
              ****
            </Col>
            <Col className="text mb-3" xs={2}>
              ****
            </Col>
            <Col className="text mb-3" xs={2}>
              {card.last4}
            </Col>
          </Row>
          <Row>
            <Col className="text-muted mb-3" xs={6}>
              GOOD THRU
            </Col>
            <Col className="text-muted mb-3" xs={6}>
              CVV
            </Col>
          </Row>
          <Row>
            <Col className="text mb-3" xs={6}>
              {card.exp_month}/{card.exp_year}
            </Col>
            <Col className="text mb-3" xs={6}>
              ***
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const isDebitCardDefaultPaymentMethod = card?.default_for_currency;

  const onSetDefaultCard = (e) => {
    e.preventDefault();

    if (isDebitCardDefaultPaymentMethod) {
      return;
    }
    if (!card) {
      alert('Add card first');
      return;
    }

    requestSetDefaultPaymentMethod({ id: card.id, method: 'card' });
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Form.Check
          reverse
          type="radio"
          id="check-debit"
          className="ml-2 mb-2 custom-radio"
        >
          <Form.Check.Label>Debit card</Form.Check.Label>
          <Form.Check.Input
            type="radio"
            onClick={onSetDefaultCard}
            checked={isDebitCardDefaultPaymentMethod}
          />
        </Form.Check>

        {isDebitCardDefaultPaymentMethod && (
          <span className="text-grey">Default</span>
        )}
      </div>
      {renderCardInformation()}
      <Button variant="primary" onClick={toggleCardVisible} className="my-3">
        {card ? 'Edit' : 'Add Card'}
      </Button>
      {isEditCardVisible && (
        <Col className="m-3" xs={12}>
          <DebitCardForm
            onClose={toggleCardVisible}
            isDebitCardDefaultPaymentMethod={isDebitCardDefaultPaymentMethod}
            requestSwitchCreditCards={requestSwitchCreditCards}
          />
        </Col>
      )}
    </div>
  );
};

export default DebitCard;
