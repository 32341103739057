import { FC } from 'react';

export interface MuteProps {
  onMute: () => void;
  onUnmute: () => void;
  isAudioMuted: boolean;
}

const MuteAudio: FC<MuteProps> = ({ onMute, onUnmute, isAudioMuted }) => {
  const onMuteClick = () => {
    if (isAudioMuted) {
      onUnmute();
    } else {
      onMute();
    }
  };

  return (
    <div
      onClick={onMuteClick}
      className={`btnVideoCall__grayTransparent btnVideoCall__outline btnVideoCall__micro ${
        isAudioMuted ? 'btnVideoCall__micro-off' : ''
      }`}
    >
      <img src="/assets/micro-icon.png" alt="micro icon" />
    </div>
  );
};

export default MuteAudio;
