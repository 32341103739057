import { Col, Row, Button, Image } from 'react-bootstrap';
import { getFirebaseImage } from '../../../../utils/Firebase';
import { truncateText } from '../../../../utils/helper';
import File from './attachments/File';
import { FileReference, Booking } from '../../../../typings';
import moment from 'moment';
import { TRUNCATE_FILE_NAME } from '../../../../utils/constants';
import { FC } from 'react';

type Props = {
  booking: Booking;
  documents: FileReference[];
  pictures: FileReference[];
  onRequestNotes: () => void;
  onRequestPatientChart: () => void;
  onRequestEPrescription: () => void;
};

const Attachments: FC<Props> = ({
  booking,
  pictures,
  documents,
  onRequestNotes = () => {},
  onRequestPatientChart = () => {},
  onRequestEPrescription = () => {},
}) => {
  const getPath = (doc: any) => `bucket/${booking.patientId}/${doc.fileName}`;
  const getExt = (doc: any) => doc.fileName.split('.').pop() || 'jpg';

  return (
    <Col xs={12} sm={6} className="p-3 gray-background">
      <Col xs={12} className="padding-h-0 padding-vertical-20">
        {booking && booking.peerName && (
          <h6>
            <img
              height="25"
              width="25"
              src="/assets/app-icon.png"
              alt="MomentMD"
              className={'margin-right-20'}
            />
            {`${booking.peerName} files`}
          </h6>
        )}
      </Col>

      <h6>Pictures</h6>

      {pictures.length ? (
        <div className="documents-container py-3">
          {pictures.map((pic) => (
            <File
              key={pic.id}
              fileName={pic.fileName}
              booking={booking}
              isPicture
              showFileName={false}
              className="border-image"
            />
          ))}
        </div>
      ) : (
        <p className="text-muted">No pictures to show</p>
      )}

      <h6>Documents</h6>

      {documents.length ? (
        <div className="documents-container py-3">
          {documents.map((doc) => (
            <File key={doc.id} fileName={doc.fileName} booking={booking} />
          ))}
        </div>
      ) : (
        <p className="text-muted">No documents to show</p>
      )}

      <Row className="mt-3 w-100">
        <Col xs={12} md={6}>
          <Button
            className="w-100 mb-2"
            variant="primary"
            onClick={onRequestNotes}
          >
            Notes
          </Button>
        </Col>

        <Col xs={12} md={6}>
          <Button
            className="w-100"
            variant="primary"
            onClick={onRequestEPrescription}
          >
            E-Prescription
          </Button>
        </Col>

        <Col xs={12}>
          <Button
            className="w-100 mt-2"
            variant="primary"
            onClick={onRequestPatientChart}
          >
            Patient Chart
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default Attachments;
