import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PatientRow from './PatientRow';
import { Patient } from '../../typings';

type Props = {
  patients: Patient[];
};

const PatientsList: React.FC<Props> = ({ patients }) => (
  <Container>
    <Row>
      <Col className="mb-2 sub-title" xs={12}>
        Your Patient list
      </Col>
      {patients.length > 0 ? (
        patients.map((patient) => (
          <PatientRow patient={patient} key={patient.id} />
        ))
      ) : (
        <h6>You don't have patients</h6>
      )}
    </Row>
  </Container>
);

export default PatientsList;
