import React from 'react';
import { Row } from 'react-bootstrap';

type Props = {
  title: string;
  body: string;
};

const AnswerAndQuestion: React.FC<Props> = ({ title, body }) => {
  return (
    <>
      <Row className="padding-h-40 ">
        <h2 className="mb-3 w-100 font-weight-normal">{title}</h2>
        <div className="w-100 padding-vertical-10 division-border"></div>
      </Row>
      <Row className="mb-3 padding-h-40">{body}</Row>
      <div className="w-100 padding-vertical-10 division-border-items"></div>
    </>
  );
};

export default AnswerAndQuestion;
