import { storage } from '../../../utils/Firebase';

import { FILE_MSG_PREFIX, IMAGE_MSG_PREFIX } from './constants';
import { getDownloadURL, ref } from 'firebase/storage';

export function isFile(msg: string): boolean {
  return msg.startsWith(FILE_MSG_PREFIX);
}

export function isImage(msg: string): boolean {
  return msg.startsWith(IMAGE_MSG_PREFIX);
}

function extractRef(msg: string): string {
  if (isFile(msg)) {
    return msg.split(FILE_MSG_PREFIX).pop();
  }

  if (isImage(msg)) {
    return msg.split(IMAGE_MSG_PREFIX).pop();
  }
}

export async function getSrc(msg: string): Promise<string> {
  const reference = extractRef(msg);
  return getDownloadURL(ref(storage, reference));
}
