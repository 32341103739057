import { Action } from 'redux';
import { put } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator as SagaIteratorToolbox } from '@mrnkr/redux-saga-toolbox/dist/typings';
import {
  MyAction,
  EntityState,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from '../store';
import { AUTH_API_URL } from '../config';
import { MMDError } from '../utils/MMDError';
import { noOpAction } from '../utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { VisitHistoryState } from './visitHistory.module';
import { Creators as ErrorActions } from './errors.module';
import { ArgsWithHeaders, Paginated } from '../utils/typings';
import {
  BookingPublic,
  PaginationPayload,
  VisitHistoryBookingPublicInList,
} from '../typings';

type PublicVisitHistoryPayload = PaginationPayload;

interface BookingId {
  eventId: string;
}

interface ActionTypes {
  REQUEST_PUBLIC_VISIT_HISTORY: string;
  COMMIT_PUBLIC_VISIT_HISTORY: string;
  REQUEST_SINGLE_PUBLIC_VISIT_HISTORY: string;
  COMMIT_SINGLE_PUBLIC_VISIT_HISTORY: string;
  CLEAR_PUBLIC_VISIT_HISTORY: string;
  TOGGLE_LOADING_PUBLIC_VISIT_HISTORY: string;
}

interface ActionCreators {
  requestPublicVisitHistory: (
    payload: PublicVisitHistoryPayload,
  ) => MyAction<PublicVisitHistoryPayload>;
  commitPublicVisitHistory: (
    payload: Paginated<VisitHistoryBookingPublicInList>,
  ) => MyAction<Paginated<VisitHistoryBookingPublicInList>>;
  requestSinglePublicVisitHistory: (
    payload: BookingId,
  ) => MyAction<BookingPublic>;
  commitSinglePublicVisitHistory: (
    payload: BookingPublic,
  ) => MyAction<BookingPublic>;
  toggleLoadingPublicVisitHistory: () => Action;
}

export interface PublicVisitHistoryState
  extends EntityState<VisitHistoryBookingPublicInList> {
  count: number;
  isLoading: boolean;
  singlePublicVisitHistory: BookingPublic;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestPublicVisitHistory: ['payload'],
  commitPublicVisitHistory: ['payload'],
  requestSinglePublicVisitHistory: ['payload'],
  commitSinglePublicVisitHistory: ['payload'],
  toggleLoadingPublicVisitHistory: [],
});

const entityAdapter = createEntityAdapter<VisitHistoryBookingPublicInList>({
  selectId: (e) => e.id.toString(),
  sortComparer: false,
});

const publicVisitHistorySelection = entityAdapter.getSelectors();

const initialState = entityAdapter.getInitialState({
  count: 0,
  isLoading: false,
});

function toggleLoadingPublicVisitHistory(
  state: PublicVisitHistoryState,
): VisitHistoryState {
  return {
    ...state,
    isLoading: !state.isLoading,
  };
}

function commitPublicVisitHistory(
  state: PublicVisitHistoryState,
  action: MyAction<Paginated<VisitHistoryBookingPublicInList>>,
): VisitHistoryState {
  return {
    ...entityAdapter.addAll(
      action.payload.data,
      entityAdapter.removeAll(state),
    ),
    count: action.payload.count,
  };
}

function commitSinglePublicVisitHistory(
  state: PublicVisitHistoryState,
  action: MyAction<BookingPublic>,
) {
  return {
    ...state,
    singlePublicVisitHistory: action.payload,
  };
}

export const publicVisitHistoryReducer = createReducer(initialState, {
  [Types.TOGGLE_LOADING_PUBLIC_VISIT_HISTORY]: toggleLoadingPublicVisitHistory,
  [Types.COMMIT_PUBLIC_VISIT_HISTORY]: commitPublicVisitHistory,
  [Types.COMMIT_SINGLE_PUBLIC_VISIT_HISTORY]: commitSinglePublicVisitHistory,
});

async function downloadPublicVisitHistory({
  headers,
  limit,
  offset,
  search,
}: ArgsWithHeaders<PublicVisitHistoryPayload>) {
  const response = await fetch(
    `${AUTH_API_URL}/bookings-public/doctor-visit-history?limit=${limit}&offset=${offset}&search=${search}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!response.ok) {
    throw new MMDError(
      'There was an error fetching public visit history requests',
    );
  }

  const data = await response.json();

  return {
    data: data.bookings,
    count: data.count,
  };
}

async function downloadSinglePublicVisitHistory({
  headers,
  eventId,
}: ArgsWithHeaders<BookingId>) {
  const response = await fetch(`${AUTH_API_URL}/bookings-public/${eventId}`, {
    headers,
    method: 'GET',
  });

  if (!response.ok) {
    throw new MMDError(
      'There was an error fetching public visit history requests',
    );
  }

  const data = await response.json();

  return data.booking;
}

const requestPublicVisitHistory = createSingleEventSaga<
  PublicVisitHistoryPayload,
  Paginated<VisitHistoryBookingPublicInList>,
  MyAction<PublicVisitHistoryPayload>
>({
  takeEvery: Types.REQUEST_PUBLIC_VISIT_HISTORY,
  loadingAction: Creators.toggleLoadingPublicVisitHistory,
  commitAction: Creators.commitPublicVisitHistory,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPublicVisitHistory,
  beforeAction: putAuthInfoInArgs,
  *afterAction(data): SagaIteratorToolbox<any> {
    yield put(Creators.toggleLoadingPublicVisitHistory());
    return data;
  },
});

const requestSinglePublicVisitHistory = createSingleEventSaga<
  BookingId,
  BookingPublic,
  MyAction<BookingId>
>({
  takeEvery: Types.REQUEST_SINGLE_PUBLIC_VISIT_HISTORY,
  loadingAction: Creators.toggleLoadingPublicVisitHistory,
  commitAction: Creators.commitSinglePublicVisitHistory,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadSinglePublicVisitHistory,
  *afterAction(data): SagaIteratorToolbox<any> {
    yield put(Creators.toggleLoadingPublicVisitHistory());
    return data;
  },
});

export const publicVisitHistorySagas = [
  requestPublicVisitHistory,
  requestSinglePublicVisitHistory,
];

export const selectPublicVisitHistoryState = (state: MyState) =>
  state.publicVisitHistory;

export const selectPublicVisitHistory = createSelector(
  selectPublicVisitHistoryState,
  (publicVisitHistoryState) =>
    publicVisitHistorySelection.selectAll(publicVisitHistoryState),
);
