import React from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import moment from 'moment';

import { requestConsultation } from './prescription-refill-row/requestConsultationHelper';
import { Creators as PrescriptionsRefillActions } from '../../modules/prescription-refills.module';
import { Creators as LoadingActions } from '../../modules/loading.module';
import { Creators as ErrorActions } from '../../modules/errors.module';
import { Provider, RefillRequest } from '../../typings';

type Props = {
  prescriptionRefill: RefillRequest;
  provider: Provider;
} & typeof PrescriptionsRefillActions &
  typeof LoadingActions &
  typeof ErrorActions;

const PrescriptionRefillRow: React.FC<Props> = ({
  provider,
  prescriptionRefill,
  requestPrescriptionRefills,
  setLoading,
  setNotLoading,
  setError,
}) => {
  const reqConsultation = React.useCallback(() => {
    setLoading();

    requestConsultation(prescriptionRefill, provider)
      .then(() => {
        requestPrescriptionRefills();
      })
      .catch(setError);
  }, [provider, prescriptionRefill, setLoading, setNotLoading, setError]);

  const { patient } = prescriptionRefill;

  if (!prescriptionRefill.prescription.booking) {
    return null;
  }

  return (
    <Container fluid>
      <Row className="pt-1 pr-1 pb-1 pl-0 patient-border">
        <Col xs={2} className="wrapper vcenter mx-auto p-2">
          <Image
            roundedCircle
            width="80"
            height="80"
            src={patient.avatar}
            style={{ display: 'block', margin: 'auto' }}
          />
        </Col>
        <Col className="pt-1 pl-0" xs={6}>
          <p className="mb-1 font-weight-bold">{`${patient.firstName} ${patient.lastName}`}</p>
          <p className="mb-1">
            <span className="text-capitalize">{patient.gender}</span>{' '}
            <span className="refil-date">{patient.birthday}</span>
          </p>
          <p className="mb-1">{`${patient.city}, ${patient.state}`}</p>
          <p className="mb-1">
            <span className="text-muted">Medication:</span>{' '}
            {`${prescriptionRefill.prescription.name}`}
          </p>
          <p className="mb-1">
            <span className="text-muted">Appointment:</span>{' '}
            {`${moment(
              prescriptionRefill.prescription.booking.startDate,
            ).format('LLL')}`}
          </p>
        </Col>
        <Col
          xs={4}
          className="vcenter"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={reqConsultation}
            className="w-100 mb-2 pb-1 pt-0"
            variant="primary"
          >
            <small>Request consultation</small>
          </Button>
          <Button
            href={`/refills/${prescriptionRefill.id}`}
            className="w-100 pt-0"
            style={{ paddingBottom: '0.21rem' }}
            variant="primary"
          >
            <small>Refill E-Prescription</small>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...PrescriptionsRefillActions,
      ...LoadingActions,
      ...ErrorActions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrescriptionRefillRow);
