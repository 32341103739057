import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

type Props = {
  attribute: string;
  value: string;
  onClick?: () => void;
  editable?: boolean;
};

const AttributeInfo: React.FC<Props> = ({
  attribute,
  value,
  onClick,
  editable = false,
}) => {
  return (
    <Col xs={12}>
      <p
        onClick={onClick}
        style={editable ? { cursor: 'pointer' } : null}
        className="doctor-attr-name mb-1"
      >
        {attribute}
        {editable ? <FontAwesomeIcon icon={faEdit} color="#F11B42" /> : null}:
      </p>
      <p className={'text-break doctor-attr-value'}>{value}</p>
    </Col>
  );
};

export default AttributeInfo;
