import { useSelector } from 'react-redux';
import { FC, useCallback, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Notes from './side-panel/Notes';
import { MyState } from '../../../store';
import Attachments from './side-panel/Attachments';
import { FileReference, Booking } from '../../../typings';
import EPrescribing from '../../../components/EPrescribing';
import { useBoundedActions } from '../../../hooks/useBoundedActions';
import PatientInfoAccordion from '../../myappts/PatientInfoAccordion';
import VisitHistoryDetails from '../../visit-history/VisitHistoryDetails';
import { Creators as visitHistoryActions } from '../../../modules/visitHistory.module';
import VisitHistoryDetailsPublic from '../../visit-history-public/VisitHistoryDetailsPublic';
import { PatientVisitHistory } from '../../myappts/patient-visit-history/BasePatientVisitHistoryTable';
import {
  selectPublicVisitHistoryState,
  Creators as publicVisitHistoryActions,
} from '../../../modules/visit-history-public.module';

type Props = {
  booking: Booking;
  patientId?: string;
  doctorId?: string;
  visible: boolean;
  documents: FileReference[];
  pictures: FileReference[];
  notes: string;
  files: any;
  sendNote: (note: string) => void;
};

const SidePanel: FC<Props> = ({ sendNote, patientId, ...props }) => {
  const [currentPage, setCurrentPage] = useState('attachments');
  const [step, setStep] = useState(1);

  const { singlePublicVisitHistory: publicBooking } = useSelector(
    selectPublicVisitHistoryState,
  );

  const {
    requestVisitHistory,
    clearVisitHistory,
    requestSinglePublicVisitHistory,
    commitSinglePublicVisitHistory,
  } = useBoundedActions({
    ...visitHistoryActions,
    ...publicVisitHistoryActions,
  });

  const visitHistory = useSelector((state: MyState) => state.visitHistory);
  const provider = useSelector((state: MyState) => state.provider);

  const isStudent = !!provider?.teacher;

  const prescriptionPreviousStep = useCallback(() => {
    if (step === 1) {
      setCurrentPage('attachments');
    } else {
      setStep(step - 1);
    }
  }, [setCurrentPage, step, setStep]);

  const prescriptionNextStep = useCallback(
    (step) => {
      if (currentPage !== 'eprescribing') {
        setCurrentPage('eprescribing');
      } else {
        if (step === 0) {
          setCurrentPage('attachments');
          setStep(1);
        } else {
          setStep(step);
        }
      }
    },
    [currentPage, setCurrentPage, setStep],
  );

  const gotoAttachments = useCallback(
    () => setCurrentPage('attachments'),
    [setCurrentPage],
  );

  const gotoNotes = useCallback(
    () => setCurrentPage('notes'),
    [setCurrentPage],
  );

  const gotoEprescription = useCallback(
    () => setCurrentPage('eprescribing'),
    [setCurrentPage],
  );

  const gotoPatientChart = useCallback(
    () => setCurrentPage('patientChart'),
    [setCurrentPage],
  );

  const closeVisitHistoryItem = () => {
    gotoAttachments();
    clearVisitHistory();
  };

  const getOnVisitHistoryClick =
    () =>
    ({ id }: PatientVisitHistory) => {
      requestVisitHistory({
        patientId,
        visitId: id,
      });

      setCurrentPage('visitHistoryItem');
    };

  const getOnVisitHistoryPublicClick =
    () =>
    ({ eventId }: PatientVisitHistory) => {
      commitSinglePublicVisitHistory(null);
      requestSinglePublicVisitHistory({ eventId });
      setCurrentPage('publicVisitHistoryItem');
    };

  switch (currentPage) {
    case 'attachments':
      return (
        <Attachments
          {...props}
          onRequestNotes={gotoNotes}
          onRequestPatientChart={gotoPatientChart}
          onRequestEPrescription={gotoEprescription}
        />
      );
    case 'patientChart':
      return (
        <Col className="gray-background">
          <Row>
            <img
              width="32"
              height="32"
              alt="Previous step"
              className="mx-2 my-3"
              onClick={gotoAttachments}
              src="/assets/left-arrow.png"
              style={{ objectFit: 'contain', cursor: 'pointer', width: '64px' }}
            />
          </Row>

          <div className="notes-videochat-wrapper">
            <PatientInfoAccordion
              isPatientChart
              patientId={patientId}
              onVisitHistoryClick={getOnVisitHistoryClick()}
              onVisitHistoryPublicClick={getOnVisitHistoryPublicClick()}
            />
          </div>
        </Col>
      );
    case 'visitHistoryItem':
      return (
        <Col className="gray-background">
          <Row>
            <img
              width="32"
              height="32"
              alt="Previous step"
              className="mx-2 my-3"
              onClick={closeVisitHistoryItem}
              src="/assets/left-arrow.png"
              style={{ objectFit: 'contain', cursor: 'pointer', width: '64px' }}
            />
          </Row>

          <div className="notes-videochat-wrapper">
            <VisitHistoryDetails
              isStudent={isStudent}
              visitHistory={visitHistory}
            />
          </div>
        </Col>
      );
    case 'publicVisitHistoryItem':
      return (
        <Col className="gray-background">
          <Row>
            <img
              width="32"
              height="32"
              alt="Previous step"
              className="mx-2 my-3"
              onClick={closeVisitHistoryItem}
              src="/assets/left-arrow.png"
              style={{ objectFit: 'contain', cursor: 'pointer', width: '64px' }}
            />
          </Row>

          <div className="notes-videochat-wrapper">
            <Container>
              {publicBooking?.id && (
                <VisitHistoryDetailsPublic {...publicBooking} />
              )}
            </Container>
          </div>
        </Col>
      );
    case 'notes':
      return (
        <Notes
          {...props}
          commitNote={sendNote}
          onRequestClose={gotoAttachments}
        />
      );
    case 'eprescribing':
      return (
        <Col className="gray-background">
          <Row>
            <img
              className="mx-2 my-3"
              style={{ objectFit: 'contain', cursor: 'pointer', width: '64px' }}
              height="32"
              width="32"
              src="/assets/left-arrow.png"
              alt="Previous step"
              onClick={prescriptionPreviousStep}
            />
          </Row>
          <EPrescribing
            isNewPrescription={true}
            className="my-3 px-0"
            step={step}
            onChangeStep={prescriptionNextStep}
            bookingId={props.booking.id}
            patientId={patientId}
          />
        </Col>
      );
  }
};

export default SidePanel;
