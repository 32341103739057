import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { MyState } from '../store';
import { AUTH_API_URL } from '../config';
import { Patient } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface PatientId {
  id: string;
}

interface ActionTypes {
  REQUEST_PATIENT_INFO: string;
  COMMIT_PATIENT_INFO: string;
  SUCCESS_PATIENT_INFO: string;
  ERROR_PATIENT_INFO: string;
}

interface ActionCreators {
  requestPatientInfo: (payload: PatientId) => MyAction<any>;
  commitPatientInfo: (payload: any) => MyAction<any>;
  successPatientInfo: (payload: any) => MyAction<any>;
}

export interface PatientInfoState extends EntityState<Patient> {
  patientInfo: any;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestPatientInfo: ['payload'],
  commitPatientInfo: ['payload'],
  successPatientInfo: ['payload'],
});

const entityAdapter = createEntityAdapter<any>();
const initialState = entityAdapter.getInitialState({});
export const patientSelectors = entityAdapter.getSelectors();

function commitPatientInfo(
  state: PatientInfoState,
  action: MyAction<any>,
): PatientInfoState {
  return {
    ...state,
    patientInfo: action.payload,
  };
}

export const patientInfoReducer = createReducer(initialState, {
  [Types.COMMIT_PATIENT_INFO]: commitPatientInfo,
});

async function downloadPatientInfo({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<any> {
  const result = await fetch(
    `${AUTH_API_URL}/doctors/patient-information/${payload.id}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
  return await result.json();
}

const requestPatientInfoWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_PATIENT_INFO,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPatientInfo,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPatientInfo,
  beforeAction: putAuthInfoInArgs,
});

export const patientInfoSagas = [requestPatientInfoWatcher];

export const selectPatientInfoState = (state: MyState) => state.patientInfo;
