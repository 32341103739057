import {
  MyAction,
  EntityState,
  createEntityAdapter,
  createSingleEventSaga,
  SagaIterator as SagaIteratorToolbox,
} from '@mrnkr/redux-saga-toolbox';
import moment from 'moment';
import { createSelector } from 'reselect';
import { put, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';

import { Timeslot } from '../typings';
import { AUTH_API_URL } from '../config';
import { auth } from '../utils/Firebase';
import store, { MyState } from '../store';
import { putAuthInfoInArgs } from './auth.module';
import { ArgsWithHeaders } from '../utils/typings';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface TimeslotId {
  id: string;
}

interface TimeslotQuery {
  id?: string;
  query?: string;
  newTimeslotsCount?: number;
}

interface TimeslotPayload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  birthday: string;
  city: string;
  state: string;
  phone: string;
  gender: string;
  avatarApproved: boolean;
}

interface CreateTimeslotPayload {
  startDate: string;
  endDate: string;
  type: string;
  isFirstTimeConsultationSlot: boolean;
  bookingDuration: number;
  freq?: string;
  week?: string[];
}

interface ActionTypes {
  REQUEST_CREATE_TIMESLOT: string;
  REQUEST_UPDATE_TIMESLOT: string;
  REQUEST_DELETE_TIMESLOT: string;
  REQUEST_CLEAR_TIMESLOT: string;
  REQUEST_DELETE_ALL_TIMESLOT: string;
  REQUEST_ALL_TIMESLOTS: string;
  REQUEST_TIMESLOTS: string;
  REQUEST_TIMESLOT: string;
  COMMIT_TIMESLOTS: string;
  COMMIT_ALL_TIMESLOTS: string;
  COMMIT_TIMESLOT: string;
  SUCCESS_TIMESLOTS: string;
  REQUEST_TIMESLOT_REPORT: string;
  COMMIT_TIMESLOT_REPORT: string;
  REQUEST_TIMESLOT_FILES: string;
  COMMIT_TIMESLOT_FILES: string;
  SET_MONTH_REDUX: number;
}

interface ActionCreators {
  requestTimeslot: (payload: TimeslotId) => MyAction<Timeslot>;
  requestTimeslots: (payload: TimeslotQuery) => MyAction<Timeslot[]>;
  requestAllTimeslots: (payload: TimeslotQuery) => MyAction<Timeslot[]>;
  requestTimeslotReport: (payload: TimeslotId) => MyAction<void>;
  requestTimeslotFiles: (payload: TimeslotId) => MyAction<void>;
  requestUpdateTimeslot: (
    payload: TimeslotPayload,
  ) => MyAction<TimeslotPayload>;
  requestCreateTimeslot: (
    payload: CreateTimeslotPayload,
  ) => MyAction<CreateTimeslotPayload>;
  requestDeleteTimeslot: (payload: TimeslotId) => MyAction<TimeslotPayload>;
  requestClearTimeslot: () => MyAction<TimeslotPayload>;
  requestDeleteAllTimeslot: (
    payload: TimeslotId[],
  ) => MyAction<TimeslotPayload>;
  commitTimeslots: (payload: Timeslot[]) => MyAction<Timeslot[]>;
  commitAllTimeslots: (payload: Timeslot[]) => MyAction<Timeslot[]>;
  commitTimeslot: (payload: Timeslot) => MyAction<Timeslot>;
  commitTimeslotReport: (payload: string) => MyAction<string>;
  commitTimeslotFiles: (payload: string) => MyAction<File[]>;
  successTimeslots: (payload: Timeslot[]) => MyAction<Timeslot[]>;
  setMonthRedux: (payload) => MyAction<number>;
}

export interface TimeslotsState extends EntityState<Timeslot> {
  count: number;
  report?: string;
  loadingReport: boolean;
  files: File[];
  loadingFiles: boolean;
  versionReport: number;
  timeslot: Timeslot | null;
  stateDelete: boolean;
  allTimeSlots: Timeslot[];
  monthRedux: number;
}

const NOT_ALL_TIMESLOTS_CREATED_ERROR =
  'Not all timeslots were created, some of them intersect with existing appointments or timeslots.';

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestTimeslots: ['payload'],
  requestAllTimeslots: ['payload'],
  requestTimeslot: ['payload'],
  requestTimeslotReport: ['payload'],
  requestTimeslotFiles: ['payload'],
  loadingTimeslots: [],
  loadingTimeslotReport: [],
  loadingTimeslotFiles: [],
  commitTimeslots: ['payload'],
  commitAllTimeslots: ['payload'],
  commitTimeslot: ['payload'],
  commitTimeslotReport: ['payload'],
  commitTimeslotFiles: ['payload'],
  successTimeslots: ['payload'],
  requestUpdateTimeslot: ['payload'],
  requestCreateTimeslot: ['payload'],
  requestDeleteTimeslot: ['payload'],
  requestClearTimeslot: ['payload'],
  requestDeleteAllTimeslot: ['payload'],
  setMonthRedux: ['payload'],
});

const entityAdapter = createEntityAdapter<Timeslot>();

const initialState = entityAdapter.getInitialState({
  count: 0,
  loadingReport: false,
  loadingFiles: false,
  files: [],
  versionReport: 0,
  timeslot: null,
  stateDelete: false,
  allTimeSlots: [],
  monthRedux: moment().month(),
});
export const timeslotsSelectors = entityAdapter.getSelectors();

function commitTimeslots(
  state: TimeslotsState,
  action: MyAction<Timeslot[]>,
): TimeslotsState {
  return entityAdapter.addAll(action.payload, state);
}
function commitAllTimeslots(
  state: TimeslotsState,
  action: MyAction<Timeslot[]>,
): TimeslotsState {
  return {
    ...state,
    allTimeSlots: action.payload,
  };
}

function commitTimeslot(
  state: TimeslotsState,
  action: MyAction<Timeslot>,
): TimeslotsState {
  return {
    ...state,
    timeslot: action.payload.timeslot,
  };
}

function commitTimeslotReport(
  state: TimeslotsState,
  action: MyAction<string>,
): TimeslotsState {
  return {
    ...state,
    report: action.payload,
    versionReport: state.versionReport + 1,
  };
}

function commitTimeslotFiles(
  state: TimeslotsState,
  action: MyAction<File[]>,
): TimeslotsState {
  return {
    ...state,
    files: action.payload,
  };
}

function setMonthRedux(state, action) {
  return {
    ...state,
    monthRedux: action.payload,
  };
}

export const timeslotsReducer = createReducer(initialState, {
  [Types.COMMIT_TIMESLOTS]: commitTimeslots,
  [Types.COMMIT_ALL_TIMESLOTS]: commitAllTimeslots,
  [Types.COMMIT_TIMESLOT_REPORT]: commitTimeslotReport,
  [Types.COMMIT_TIMESLOT_FILES]: commitTimeslotFiles,
  [Types.COMMIT_TIMESLOT]: commitTimeslot,
  [Types.SET_MONTH_REDUX]: setMonthRedux,
});

async function downloadTimeslots({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotQuery>): Promise<Timeslot[]> {
  const selectedMonth = store.getState().timeslots.monthRedux;

  const isNewYear = selectedMonth < moment().month();

  const date = moment()
    .month(selectedMonth)
    .year(moment().year() + (isNewYear ? 1 : 0));

  payload.query = payload.query || `?month=${date.format('YYYY-MM-DD')}`;
  payload.id = payload.id || auth.currentUser.uid;

  const result = await fetch(
    `${AUTH_API_URL}/doctors/${payload.id}/availability${payload.query}`,
    {
      headers,
      method: 'GET',
    },
  );
  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
  const { timeslots } = await result.json();

  return timeslots;
}

async function downloadAllTimeslots({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotQuery>): Promise<Timeslot[]> {
  payload.query = payload.query || `?all=0`;
  payload.id = payload.id || auth.currentUser.uid;
  const result = await fetch(
    `${AUTH_API_URL}/doctors/${payload.id}/availability${payload.query}`,
    {
      headers,
      method: 'GET',
    },
  );
  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
  const { timeslots } = await result.json();
  const allTimeslots = timeslots;
  return allTimeslots;
}

async function downloadTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotId>): Promise<Timeslot> {
  const result = await fetch(`${AUTH_API_URL}/doctors/timeslot/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }

  return result.json();
}

async function updateTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/timeslot/${payload.id}`, {
    headers,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

async function createTimeslot({
  headers,
  bookingDuration,
  ...payload
}: ArgsWithHeaders<CreateTimeslotPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/timeslot/range`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

async function deleteTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/timeslot/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

async function clearTimeslot({
  headers,
}: ArgsWithHeaders<TimeslotPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/timeslots/clear`, {
    headers,
    method: 'POST',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

async function deleteAllTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/doctors/timeslots/all`, {
    headers,
    method: 'DELETE',
    body: JSON.stringify({ timeSlotsIds: Object.values(payload) }),
  });
  await result.json();

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

async function downloadTimeslotReport({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotId>): Promise<string> {
  const result = await fetch(
    `${AUTH_API_URL}/timeslots/${payload.id}/report/lifestyle`,
    {
      headers,
      method: 'GET',
    },
  );

  const data = await result.blob();
  if (!data) {
    const error = await result.json();
    throw Error(error);
  }
  const fileURL = URL.createObjectURL(data);
  return fileURL;
}

async function downloadTimeslotFiles({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotId>): Promise<string> {
  const result = await fetch(`${AUTH_API_URL}/timeslots/${payload.id}/files`, {
    headers,
    method: 'GET',
  });

  return result.json();
}

// eslint-disable-next-line require-yield
function* getTimeTimeslotsCount(
  _,
  { bookingDuration, startDate, endDate }: CreateTimeslotPayload,
): SagaIteratorToolbox<any> {
  const minutesForBookings = moment(endDate).diff(moment(startDate), 'minutes');

  return {
    newTimeslotsCount: minutesForBookings / bookingDuration,
  };
}

function* checkCreatedTimeslots(
  timeslots: Timeslot[],
  payload: TimeslotQuery,
): SagaIteratorToolbox<any> {
  // DO NOT SIMPLIFY ( newTimeslotsCount = number type )
  if (payload?.newTimeslotsCount !== undefined) {
    const currentTimeslots = yield select(selectTimeslots);
    if (
      // @ts-ignore
      payload?.newTimeslotsCount + currentTimeslots.length !==
      timeslots.length
    ) {
      yield put(
        ErrorActions.setError(new Error(NOT_ALL_TIMESLOTS_CREATED_ERROR)),
      );
    }
  }

  return timeslots;
}

const requestCreateTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_CREATE_TIMESLOT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: createTimeslot,
  beforeAction: putAuthInfoInArgs,
  afterAction: getTimeTimeslotsCount,
});

const requestUpdateTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_UPDATE_TIMESLOT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: updateTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestDeleteTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_DELETE_TIMESLOT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: deleteTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestClearTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_CLEAR_TIMESLOT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: clearTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestDeleteAllTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_DELETE_ALL_TIMESLOT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: deleteAllTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestTimeslotsWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_TIMESLOTS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: downloadTimeslots,
  beforeAction: putAuthInfoInArgs,
  afterAction: checkCreatedTimeslots,
});
const requestAllTimeslotsWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_ALL_TIMESLOTS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAllTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: downloadAllTimeslots,
  beforeAction: putAuthInfoInArgs,
});

const requestTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_TIMESLOT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTimeslot,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: downloadTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestTimeslotReportWatcher = createSingleEventSaga<
  string,
  Timeslot[],
  MyAction<string>
>({
  takeEvery: Types.REQUEST_TIMESLOT_REPORT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTimeslotReport,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: downloadTimeslotReport,
  beforeAction: putAuthInfoInArgs,
});

const requestTimeslotFilesWatcher = createSingleEventSaga<
  object | string,
  Timeslot[],
  MyAction<File[]>
>({
  takeEvery: Types.REQUEST_TIMESLOT_FILES,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTimeslotFiles,
  successAction: Creators.successTimeslots,
  errorAction: ErrorActions.setError,
  action: downloadTimeslotFiles,
  beforeAction: putAuthInfoInArgs,
});

export const timeslotsSagas = [
  requestCreateTimeslotWatcher,
  requestTimeslotsWatcher,
  requestAllTimeslotsWatcher,
  requestUpdateTimeslotWatcher,
  requestDeleteTimeslotWatcher,
  requestClearTimeslotWatcher,
  requestDeleteAllTimeslotWatcher,
  requestTimeslotWatcher,
  requestTimeslotReportWatcher,
  requestTimeslotFilesWatcher,
];

export const selectTimeslotState = (state: MyState) => state.timeslots;

export const selectTimeslots = createSelector(
  selectTimeslotState,
  timeslotsSelectors.selectAll,
);
