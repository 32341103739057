import React from 'react';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import DocumentsDetails from '../myappts/DocumentsDetails';
import PrescriptionItem from './PrescriptionItem';
import VisitHistorySimpleItem from './AnswerAndQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment, { Moment } from 'moment';
import { push } from 'redux-first-history';

const MAX_HOURS_TO_EDIT_NOTES = 24;

type Props = {
  visitHistory: any;
  push: (path: string) => void;
  isStudent?: boolean;
};

const areNotesEditable = (visitDate: Moment): boolean => {
  return (
    moment().utc().diff(moment(visitDate), 'hours') < MAX_HOURS_TO_EDIT_NOTES
  );
};

const VisitHistoryDetails: React.FC<Props> = ({
  visitHistory,
  push,
  isStudent,
}) => {
  const notes = visitHistory.notes ? visitHistory.notes.note : undefined;
  const { files, prescriptions, answers } = visitHistory;
  return (
    <Col className=" w-100 padding-10">
      {answers &&
        answers.length > 0 &&
        answers.map((answer) => (
          <VisitHistorySimpleItem
            key={answer.id}
            title={answer.question.body}
            body={answer.body}
          />
        ))}
      <>
        <Row className="padding-h-40 ">
          <h2 className="mb-3 w-100 font-weight-normal">
            {'Notes and Diagnosis'}
          </h2>
          <div className="w-100 padding-vertical-10 division-border"></div>
        </Row>
        <Row className="mb-3 padding-h-40">
          <Col className="p-0">{notes ? notes : 'N/A'}</Col>
          {areNotesEditable(visitHistory.startDate) && (
            <Col className="text-right p-0">
              <button
                style={{ border: 'none' }}
                onClick={() =>
                  push(
                    `/feedback/${visitHistory.patientId}/${visitHistory.id}/edit`,
                  )
                }
              >
                <FontAwesomeIcon icon={faEdit} color="#F11B42" />
              </button>
            </Col>
          )}
        </Row>
        <div className="w-100 padding-vertical-10 division-border-items"></div>
      </>

      <>
        <Row className="padding-h-40 ">
          <p className="mb-3 w-100">{'Medications'}</p>
          <div className="w-100 padding-vertical-10 division-border"></div>
        </Row>
        {prescriptions && prescriptions.length > 0 ? (
          <Row className="mb-3 padding-h-40">
            {prescriptions.map((item) => (
              <PrescriptionItem key={item.id} prescription={item} />
            ))}
          </Row>
        ) : (
          <Row className="mb-3 padding-h-40">"N/A"</Row>
        )}

        <div className="w-100 padding-vertical-10 division-border-items"></div>
      </>
      {!isStudent && (
        <>
          <Row className="padding-h-40 ">
            <p className="mb-3 w-100">{'Documents'}</p>
            <div className="w-100 padding-vertical-10 division-border"></div>
          </Row>
          {files && files.length > 0 ? (
            <Row>
              <DocumentsDetails
                files={files}
                doctorId={visitHistory.doctorId}
                patientId={visitHistory.patientId}
              />
            </Row>
          ) : (
            <Row className="mb-3 padding-h-40"> "N/A"</Row>
          )}
        </>
      )}
    </Col>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      push,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisitHistoryDetails);
