import { useMemo, useEffect, useState, FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Booking, RefillRequest } from '../../typings';
import moment from 'moment';
import ApptNotification from './ApptNotification';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { MyState } from '../../store';
import {
  Creators as prescriptionRefillActions,
  prescriptionRefillSelectors,
} from '../../modules/prescription-refills.module';
import RefillNotification from './RefillNotification';

type Props = {
  todayUpAppoints: Booking[];
  prescriptionsRefills: RefillRequest[];
} & typeof prescriptionRefillActions;

const NotificationCenter: FC<Props> = ({
  todayUpAppoints,
  prescriptionsRefills,
  requestPrescriptionRefills,
}) => {
  const [notificationsHidden, setNotificationsHidden] = useState([]);

  useEffect(() => {
    requestPrescriptionRefills();
  }, [requestPrescriptionRefills]);

  const notifications = useMemo<any[]>(() => {
    const notifications = [];
    const onClose = (id) => {
      const newHidden = [...notificationsHidden];
      newHidden.push(id);
      setNotificationsHidden(newHidden);
    };
    const notifyAppoints = todayUpAppoints
      .filter(({ id }) => !notificationsHidden.some((item) => item === id))
      .map((item) => ({
        type: 'appt',
        createdAt: moment(item.startDate),
        title: 'Upcoming Appointments',
        patient: item.peerName,
        apptDateTime: moment(item.event.start.dateTime).format('h:mm A'),
        conditions: item.conditions,
        linkTo: {
          path: `/appointment/${item.id}`,
          state: { appointment: item },
        },
        onClose: () => onClose(item.id),
      }));
    const notifyRefills = prescriptionsRefills
      .filter(({ id }) => !notificationsHidden.some((item) => item === id))
      .map((item) => ({
        type: 'refill',
        id: item.id,
        createdAt: moment(item.prescription.createdAt),
        title: 'Prescriptions Refills Request',
        patient: `${item.patient.firstName} ${item.patient.lastName}`,
        medications: item.prescription.name,
        linkTo: {
          path: `/refills`,
        },
        onClose: () => onClose(item.id),
      }));

    return notifications
      .concat(notifyAppoints.sort((a, b) => a.createdAt.diff(b.createdAt)))
      .concat(notifyRefills.sort((a, b) => a.createdAt.diff(b.createdAt)));
  }, [todayUpAppoints, prescriptionsRefills, notificationsHidden]);
  return (
    <Container>
      <Row className="pt-5 pr-4 pl-4 dashboard-border">
        <Col className="text-center mb-5" xs={12}>
          <h2>Notification center</h2>
        </Col>
        {notifications.length > 0 ? (
          notifications.map((item) => {
            if (item.type === 'refill') {
              return (
                <RefillNotification {...item} key={`${item.id}notCenter`} />
              );
            } else {
              return <ApptNotification {...item} key={`${item.id}notCenter`} />;
            }
          })
        ) : (
          <Col
            className="text-center vcenter justify-content-center mb-5"
            xs={12}
          >
            You don't have any notifications
          </Col>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => {
  const prescriptionRefills = prescriptionRefillSelectors.selectAll(
    state.prescriptionRefills,
  );
  return {
    prescriptionsRefills: prescriptionRefills.filter(
      (item) => item.prescription.booking,
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...prescriptionRefillActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
