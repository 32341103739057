import moment from 'moment';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Fullscreen from 'react-fullscreen-crossbrowser';
import { useNavigate, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';

import { MuteProps } from './videocall/MuteAudio';
import { useBoundedActions } from '../hooks/useBoundedActions';
import VideoCallViewClientPublic from './videocall/VideoCallViewClientPublic';
import SidePanelPublic from './videocall/video-call-view-public/SidePanelPublic';
import { Creators as NotePublicActions } from '../modules/videocall-public/notes-public.module';
import { Creators as PublicWaitingRoomActions } from '../modules/videocall-public/waiting-room.module';
import {
  Creators as VideoCallClientPublicActions,
  ParticipantsTypes,
  selectVideoCallClientPublicState,
} from '../modules/videocall-client-public.module';
import {
  Creators as FilesPublicActions,
  filesPublicSelectors,
} from '../modules/videocall-public/files-public.module';

const VideoCallClientPublic: FC = () => {
  const [isSidePanelVisible, setSidePanelVisible] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [isCallFinished, setIsCallFinished] = useState(false);
  const files = null;

  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const documents = useSelector(filesPublicSelectors.selectDocuments);
  const pictures = useSelector(filesPublicSelectors.selectPictures);

  const { booking, room, extended, localTracks, isAudioMuted, clientType } =
    useSelector(selectVideoCallClientPublicState);

  const {
    requestFilesPublic,
    requestUploadFilePublic,
    changeVideoTrackClientPublic,
    videoCallLogEventClientPublic,
    requestVideoTokenClientPublic,
    subscribeExtensionClientPublic,
    disconnectParticipantWebsocket,
    requestUpsertNotesVideoCallPublic,
    requestDisconnectVideoClientPublic,
    requestMuteLocalAudioTrackClientPublic,
    requestUnmuteLocalAudioTrackClientPublic,
  } = useBoundedActions({
    ...VideoCallClientPublicActions,
    ...PublicWaitingRoomActions,
    ...NotePublicActions,
    ...FilesPublicActions,
  });

  useEffect(() => {
    if (!booking) {
      navigate(`/public-appointment/${params.id}`);
      return;
    }

    requestVideoTokenClientPublic({
      id: params.id,
      numberParticipant: booking.numberParticipant,
    });
  }, []);

  useEffect(() => {
    if (isCallFinished) {
      videoCallEvent('Leaving the meeting by disconnect');
      navigate('/public-appointment/feedback', { state: { fromCall: true } });
    }
  }, [isCallFinished]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (moment(moment()).isSameOrAfter(booking.endDate)) {
        setIsCallFinished(true);
        clearInterval(intervalId);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
      disconnectParticipantWebsocket();
    };
  }, []);

  useEffect(() => {
    if (isSidePanelVisible) {
      requestFilesPublic({ id: booking.eventId });
    }
  }, [isSidePanelVisible]);

  const videoCallEvent = useCallback(
    (event) => videoCallLogEventClientPublic({ event }),
    [videoCallLogEventClientPublic],
  );

  const requestSidePanel = useCallback(() => {
    setSidePanelVisible(!isSidePanelVisible);
  }, [isSidePanelVisible, setSidePanelVisible]);

  const sendNote = useCallback(
    (body: string) => {
      requestUpsertNotesVideoCallPublic({ id: params.id!, body });
    },
    [params.id, requestUpsertNotesVideoCallPublic],
  );

  const saveFile = useCallback(
    (file: File) =>
      requestUploadFilePublic({
        bookingId: booking.eventId,
        doctorId: booking.doctorId,
        file,
      }),
    [requestUploadFilePublic],
  );

  const onFullScreenChange = (fullscreenChange: boolean) =>
    setFullScreen((prevState) =>
      prevState !== fullscreenChange ? fullscreenChange : prevState,
    );

  const muteProps: MuteProps = {
    onMute: requestMuteLocalAudioTrackClientPublic,
    onUnmute: requestUnmuteLocalAudioTrackClientPublic,
    isAudioMuted: isAudioMuted,
  };

  const isPatientClient = clientType === ParticipantsTypes.PATIENT;

  return (
    <Fullscreen enabled={fullScreen} onChange={onFullScreenChange}>
      <Row className="m-0 full-screen-component">
        <VideoCallViewClientPublic
          room={room}
          booking={booking}
          extended={extended}
          saveFile={saveFile}
          muteProps={muteProps}
          fullScreen={fullScreen}
          localTracks={localTracks}
          eventLogger={videoCallEvent}
          isPatientClient={isPatientClient}
          onRequestSidePanel={requestSidePanel}
          shrinkHorizontally={isSidePanelVisible}
          isSidePanelVisible={isSidePanelVisible}
          cleanup={requestDisconnectVideoClientPublic}
          setFullScreen={() => setFullScreen(!fullScreen)}
          changeVideoTrackClientPublic={changeVideoTrackClientPublic}
          subscribeExtensionClientPublic={subscribeExtensionClientPublic}
        />
        {isPatientClient && (
          <SidePanelPublic
            forPatient
            files={files}
            notes={null}
            booking={booking}
            pictures={pictures}
            documents={documents}
            sendNotePublic={sendNote}
            visible={isSidePanelVisible}
            onRequestClose={requestSidePanel}
          />
        )}
      </Row>
    </Fullscreen>
  );
};

export default VideoCallClientPublic;
