import React, { ReactNode } from 'react';

const CircledText: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div
      className="mx-1"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#DB386C',
        borderStyle: 'solid',
        borderRadius: '50%',
        borderWidth: '1px',
        height: '30px',
        width: '30px',
      }}
    >
      {children}
    </div>
  );
};

export default CircledText;
