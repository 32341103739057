import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Col, Row } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import { MyState } from '../store';
import { TermsAndConditions } from '../typings';
import {
  termAndConditionSelectors,
  Creators as TermsAndConditionsActions,
} from '../modules/terms-and-conditions.module';

type Props = {
  termsAndConditions: TermsAndConditions;
} & typeof TermsAndConditionsActions & { sendLive?: string };

const TermsAndCondition: FC<Props> = ({
  termsAndConditions,
  requestTermAndCondition,
}) => {
  useEffect(() => {
    requestTermAndCondition();
  }, []);

  return (
    <Row className="justify-content-center">
      <Col xs={12} className="text-center">
        <img
          height="200"
          width="350"
          src="/assets/momentMDLogo.png"
          alt="MomentMD"
        />
      </Col>
      <Col xs={12}>
        {termsAndConditions && (
          <span
            className="terms-and-conditions w-100"
            dangerouslySetInnerHTML={{
              __html: termsAndConditions.content || '',
            }}
          />
        )}
      </Col>
    </Row>
  );
};
const mapStateToProps = (state: MyState) => ({
  termsAndConditions: termAndConditionSelectors.selectAll(
    state.termsAndConditions,
  )[0],
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...TermsAndConditionsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);
