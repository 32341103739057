import React from 'react';
import moment from 'moment';

import { getSrc } from './helpers';

type Props = {
  body: string;
  className?: string;
  received: boolean;
  timestamp: string;
};

const ImgMsgBubble: React.FC<Props> = ({
  body,
  className,
  received,
  timestamp,
}) => {
  const [src, setSrc] = React.useState<string>();

  React.useEffect(() => {
    getSrc(body).then(setSrc);
  }, [body]);

  return (
    <div
      className={`msg-bubble${src ? '-img' : ''} ${
        received ? 'received' : 'sent'
      } ${className}`}
    >
      {src ? (
        <a
          className="mb-0 text-reset"
          href={src}
          target="_blank"
          rel="noreferrer"
        >
          <img className="mb-0" src={src} alt="Attachment" />
        </a>
      ) : (
        <p className="mb-0">
          <span role="img" aria-label="Camera">
            📷
          </span>{' '}
          Loading image...
        </p>
      )}

      <div className="msg-details">
        <span>{moment(timestamp).fromNow()}</span>
      </div>
    </div>
  );
};

export default ImgMsgBubble;
