import QRCode from 'react-qr-code';

import { ANDROID_MOBILE_APP_LINK, IOS_MOBILE_APP_LINK } from './config';

const QR_CODE_STYLE = { height: 'auto', maxWidth: '60%' };
const LOGO_STYLE = { width: 20, height: 20 };

const MobileAppQrCodes = () => (
  <div className="d-flex justify-content-center">
    <div className="d-flex align-items-center flex-column">
      <div className="d-flex mb-1">
        <span>IOS</span>

        <img
          className="ml-1"
          alt="apple-logo"
          style={LOGO_STYLE}
          src="/assets/apple-logo.png"
        />
      </div>

      <QRCode style={QR_CODE_STYLE} value={IOS_MOBILE_APP_LINK} />
    </div>

    <div className="d-flex align-items-center flex-column">
      <div className="d-flex mb-1">
        <span>Android</span>

        <img
          className="ml-1"
          style={LOGO_STYLE}
          alt="android-logo"
          src="/assets/android-logo.png"
        />
      </div>

      <QRCode style={QR_CODE_STYLE} value={ANDROID_MOBILE_APP_LINK} />
    </div>
  </div>
);

export default MobileAppQrCodes;
