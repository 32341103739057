import * as Yup from 'yup';

import { ParticipantsTypes } from '../../../modules/videocall-client-public.module';

const REQUIRED_ERROR = 'This field is required';

export const isEmailRequiredForParticipantType = (type: ParticipantsTypes) =>
  [ParticipantsTypes.PATIENT, ParticipantsTypes.STUDENT].includes(type);

export const isCustomParticipantType = (type: ParticipantsTypes) =>
  ParticipantsTypes.OTHER === type;

export const isPatientType = (type: ParticipantsTypes) =>
  ParticipantsTypes.PATIENT === type;

export const validationSchema = Yup.object().shape({
  type: Yup.string().required(REQUIRED_ERROR).default(undefined),
  email: Yup.string()
    .email('Not valid email')
    .when('type', ([type], schema) =>
      isEmailRequiredForParticipantType(type)
        ? schema.required(REQUIRED_ERROR)
        : schema,
    ),
  customType: Yup.string().when('type', ([type], schema) =>
    isCustomParticipantType(type) ? schema.required(REQUIRED_ERROR) : schema,
  ),
  terms: Yup.string()
    .required(REQUIRED_ERROR)
    .test(
      'termsAgreed',
      'You must agree to the terms and conditions in order to use the application',
      (value) => value === 'true',
    ),
  patientConsent: Yup.string().when('type', ([type], schema) =>
    isPatientType(type)
      ? schema
          .required(REQUIRED_ERROR)
          .test(
            'consentAgreed',
            'You must agree to the patient consent in order to use the application',
            (value) => value === 'true',
          )
      : schema,
  ),
});

export const VIDEO_CALL_PARTICIPANT_TYPES = [
  {
    value: ParticipantsTypes.PATIENT,
    label: 'Patient',
  },
  {
    value: ParticipantsTypes.RELATIVE,
    label: 'Relative',
  },
  {
    value: ParticipantsTypes.STUDENT,
    label: 'Student',
  },
  {
    value: ParticipantsTypes.TRANSLATOR,
    label: 'Translator',
  },
  {
    value: ParticipantsTypes.OTHER,
    label: 'Other',
  },
];
