import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BookingPublic } from '../../../../../typings';
import { storage } from '../../../../../utils/Firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { FC, useEffect, useState } from 'react';

type Props = {
  booking: BookingPublic;
  fileName: string;
  showFileName?: boolean;
  isPicture?: boolean;
  className?: string;
};

const FilePublic: FC<Props> = ({
  booking,
  fileName,
  isPicture = false,
  showFileName = true,
  className,
}) => {
  const [src, setSrc] = useState('/assets/image.png');

  useEffect(() => {
    getDownloadURL(
      ref(
        storage,
        `/bookings/${booking.eventId}/${booking.doctorId}/public/${fileName}`,
      ),
    ).then(setSrc);
    console.log(src);
  }, [booking, fileName]);

  return isPicture ? (
    <a
      className={` ${className} document-view`}
      href={src}
      target="_blank"
      rel="noreferrer"
    >
      <img
        width="128"
        height="128"
        src={src}
        alt=""
        style={{ objectFit: 'cover' }}
      />
      {showFileName && <span className="filename-text">{fileName}</span>}
    </a>
  ) : (
    <a
      className={` ${className} document-view`}
      href={src}
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon
        icon={faFilePdf}
        style={{ color: '#F11B42' }}
        className={'bound-image-medium'}
      />
      <span className="filename-text">{fileName}</span>
    </a>
  );
};

export default FilePublic;
