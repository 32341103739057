import {
  createSingleEventSaga,
  MyAction,
  EntityState,
  createEntityAdapter,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';
import { createSelector } from 'reselect';

import { AUTH_API_URL } from '../../config';
import { Booking } from '../../typings';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from '../../utils/typings';
import { Creators as LoadingActions } from '../loading.module';
import { Creators as ErrorActions } from '../errors.module';
import { MMDError } from '../../utils/MMDError';
import { putAuthInfoInArgs } from '../auth.module';
import { noOpAction } from '../../utils/noOpAction';
import { storage } from '../../utils/Firebase';
import {
  isAnImage,
  isADocument,
  isAVideo,
} from '../../utils/extractFileExtension';
import { FileReference } from '../../typings';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';

interface ActionTypes {
  REQUEST_FILES: string;
  REQUEST_UPLOAD_FILE: string;
  COMMIT_FILES_VIDEO_CALL: string;
  COMMIT_NEW_FILE: string;
}

interface ActionCreators {
  requestFiles: ({ id }) => MyAction<{ booking: Booking }>;
  requestUploadFile: (payload: {
    booking: Booking;
    file: File;
  }) => MyAction<{ booking: Booking; file: File }>;
  commitFilesVideoCall: (payload: FileReference[]) => MyAction<FileReference[]>;
  commitNewFile: (payload: FileReference) => MyAction<FileReference>;
}

export interface VideoCallFilesState extends EntityState<FileReference> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestFiles: ['payload'],
  requestUploadFile: ['payload'],
  commitFilesVideoCall: ['payload'],
  commitNewFile: ['payload'],
});

const entityAdapter = createEntityAdapter<FileReference>();
const initialState = entityAdapter.getInitialState();

const selectPictures = createSelector(
  entityAdapter.getSelectors().selectAll,
  (files) => files.filter((file) => isAnImage(file.fileName)),
);

const selectDocuments = createSelector(
  entityAdapter.getSelectors().selectAll,
  (files) =>
    files.filter(
      (file) => isADocument(file.fileName) || isAVideo(file.fileName),
    ),
);

const selectVideos = createSelector(
  entityAdapter.getSelectors().selectAll,
  (files) => files.filter((file) => isAVideo(file.fileName)),
);

export const filesSelectors = {
  ...entityAdapter.getSelectors(),
  selectDocuments,
  selectPictures,
  selectVideos,
};

function commitFilesVideoCall(
  state: VideoCallFilesState,
  action: MyAction<FileReference[]>,
): VideoCallFilesState {
  return entityAdapter.addAll(action.payload, state);
}

function commitNewFile(
  state: VideoCallFilesState,
  action: MyAction<FileReference>,
): VideoCallFilesState {
  return entityAdapter.addOne(action.payload, state);
}

export const videoCallFilesReducer = createReducer<VideoCallFilesState>(
  initialState,
  {
    [Types.COMMIT_FILES_VIDEO_CALL]: commitFilesVideoCall,
    [Types.COMMIT_NEW_FILE]: commitNewFile,
  },
);

async function downloadFilesForVideoCall({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<
  { response: true; data: FileReference[] } | { response: false }
> {
  const result = await fetch(`${AUTH_API_URL}/bookings/${payload.id}/files`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong downloading files associated to this call',
    );
  }

  return result.json();
}

const requestVideoCallFilesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  FileReference[],
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_FILES,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitFilesVideoCall,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadFilesForVideoCall,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(
    res: { response: true; data: FileReference[] } | { response: false },
  ): SagaIterator {
    if (res.response) {
      return res.data;
    }
  },
});

async function uploadFile({
  headers,
  booking,
  file,
}: ArgsWithHeaders<{ booking: Booking; file: File }>): Promise<FileReference> {
  await uploadBytes(
    ref(
      storage,
      `bookings/${booking.id}/${booking.doctorId}/${booking.patientId}/${file.name}`,
    ),
    file,
  );

  const result = await fetch(`${AUTH_API_URL}/bookings/${booking.id}/files`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ fileName: file.name }),
  });

  if (!result.ok) {
    await deleteObject(
      ref(
        storage,
        `bookings/${booking.id}/${booking.doctorId}/${booking.patientId}/${file.name}`,
      ),
    );
    throw new MMDError('An error has occurred uploading your file');
  }

  return result.json();
}

const requestUploadFileWatcher = createSingleEventSaga<
  { booking: Booking; file: File },
  FileReference,
  MyAction<{ booking: Booking; file: File }>
>({
  takeEvery: Types.REQUEST_UPLOAD_FILE,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitNewFile,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: uploadFile,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(
    res: { response: true; data: FileReference } | { response: false },
  ): SagaIterator {
    if (res.response) {
      return res.data;
    }
  },
});

export const videoCallFilesSagas = [
  requestVideoCallFilesWatcher,
  requestUploadFileWatcher,
];
