import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import DoctorProfile from '../components/DoctorProfile';
import { Patient } from '../typings';
import PatientsList from './patients/PatientsList';
import {
  Creators as PatientsActions,
  patientsSelectors,
} from '../modules/patients.module';
import { MyState } from '../store';

type Props = {
  patients: Patient[];
};

const Patients: React.FC<Props> = ({ patients }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>Patients</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={9}>
          <PatientsList patients={patients} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  patients: patientsSelectors.selectAll(state.patients),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...PatientsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
