import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FormControlProps } from '../../utils/formControlProps';
import { Field, ErrorMessage } from 'formik';

type Props = {
  label: React.JSX.Element;
  showFieldError?: boolean;
  validate?: any;
  required?: boolean;
} & Omit<FormControlProps, 'label'>;

const MMDCheckbox: FC<Props> = ({
  values,
  fieldKey,
  label,
  validate,
  showFieldError,
  className = '',
  required,
}) => {
  const getOnChange = (form) => () => {
    const nextValue = values[fieldKey] === 'false' ? 'true' : 'false';
    form.setFieldValue(fieldKey, nextValue);
  };

  return (
    <Row className={`align-middle ${className}`}>
      <Field
        type="checkbox"
        name={fieldKey}
        value={values[fieldKey]}
        validate={validate}
      >
        {({ form }) => (
          <>
            <div
              onClick={getOnChange(form)}
              className="d-flex align-items-center"
            >
              <input
                type="checkbox"
                checked={values[fieldKey] === 'true'}
                onChange={getOnChange(form)}
              />
              <Col xs="auto" className="pl-3">
                {label}

                {required && <span className="text-danger ml-1">*</span>}
              </Col>
            </div>
            {showFieldError && (
              <ErrorMessage
                name={fieldKey}
                render={(msg) => (
                  <div className="invalid-feedback d-block">{msg}</div>
                )}
              />
            )}
          </>
        )}
      </Field>
    </Row>
  );
};

export default MMDCheckbox;
