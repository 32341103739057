import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import MMDTextInput from '../forms/MMDTextInput';
import {
  securityCodeFieldValidator,
  securityCodeFormValidator,
} from '../../modules/prescription-refills.module';
import { AUTH_API_URL } from '../../config';

type Props = {
  onSubmitPrescription: (values: any) => void;
};

const VerificationStep: React.FC<Props> = ({ onSubmitPrescription }) => {
  const generateNewCode = async () => {
    const authInfo = await JSON.parse(localStorage.getItem('moment.session'));
    const headers = new Headers();
    headers.append('Authorization', authInfo.firebase_token);
    headers.append('Content-Type', 'application/json');
    const newCodeResult = await fetch(
      `${AUTH_API_URL}/doctors/generate-new-security-code`,
      {
        headers,
        method: 'GET',
      },
    );
    const jsonResult = await newCodeResult.json();
    alert(jsonResult.message);
  };
  return (
    <Container fluid>
      <Formik
        initialValues={{
          securityCode: '',
        }}
        validate={securityCodeFormValidator}
        onSubmit={(values) => {
          onSubmitPrescription(values);
        }}
      >
        {({ values }) => (
          <Row className="justify-content-md-center">
            <Col xs lg="12">
              <Form>
                <MMDTextInput
                  label="Enter the 6 digit security code"
                  values={values}
                  fieldKey="securityCode"
                  showFieldError={true}
                  validate={securityCodeFieldValidator()}
                />
                <Col
                  xs={12}
                  className="text-center mt-4"
                  style={{ marginBottom: '15px' }}
                >
                  <Button variant="primary" onClick={() => generateNewCode()}>
                    Recovery 6 digit code
                  </Button>
                </Col>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 mt-5 mb-5"
                >
                  Send E-Prescription
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Formik>
    </Container>
  );
};

export default VerificationStep;
