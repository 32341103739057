import {
  createSingleEventSaga,
  MyAction,
  composeSagas,
  EntityState,
  createEntityAdapter,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';

import { AUTH_API_URL } from '../../config';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from '../../utils/typings';
import { Creators as LoadingActions } from '../loading.module';
import { Creators as ErrorActions } from '../errors.module';
import { MMDError } from '../../utils/MMDError';
import { putAuthInfoInArgs } from '../auth.module';
import { onRoute, extractRouteParams } from '../../utils/onRoute';
import { noOpAction } from '../../utils/noOpAction';
import { Prescription } from '../../typings';

interface ActionTypes {
  COMMIT_PRESCRIPTIONS_VIDEO_CALL: string;
}

interface ActionCreators {
  commitPrescriptionsVideoCall: (
    payload: Prescription[],
  ) => MyAction<Prescription[]>;
}

export interface VideoCallPrescriptionsState
  extends EntityState<Prescription> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  commitPrescriptionsVideoCall: ['payload'],
});

const entityAdapter = createEntityAdapter<Prescription>();
const initialState = entityAdapter.getInitialState();

export const prescriptionsSelectors = {
  ...entityAdapter.getSelectors(),
};

function commitPrescriptionsVideoCall(
  state: VideoCallPrescriptionsState,
  action: MyAction<Prescription[]>,
): VideoCallPrescriptionsState {
  return entityAdapter.addAll(action.payload, state);
}

export const videoCallPrescriptionsReducer = createReducer(initialState, {
  [Types.COMMIT_PRESCRIPTIONS_VIDEO_CALL]: commitPrescriptionsVideoCall,
});

async function downloadPrescriptionsForVideoCall({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<
  { response: true; prescriptions: Prescription[] } | { response: false }
> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings/${payload.id}/prescriptions`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong downloading prescriptions associated to this call',
    );
  }

  return result.json();
}

const requestVideoCallPrescriptionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Prescription[],
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/video-call/:id'),
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPrescriptionsVideoCall,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPrescriptionsForVideoCall,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: string },
    ArgsWithHeaders<{ id: string }>
    // @ts-ignore
  >(extractRouteParams('/video-call/:id'), putAuthInfoInArgs),
  // eslint-disable-next-line require-yield
  *afterAction(
    res:
      | { response: true; prescriptions: Prescription[] }
      | { response: false },
  ) {
    if (res.response) {
      return res.prescriptions;
    }
  },
});

export const videoCallPrescriptionsSagas = [
  requestVideoCallPrescriptionsWatcher,
];
