import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

type Props = {
  favoritePharmacy: any;
};

const FavoritePharmacy: React.FC<Props> = ({ favoritePharmacy }) => {
  return (
    <Container>
      <Row className="pt-4 pr-4 pl-4 dashboard-border">
        <Col className="justify-content-center w-100" xs={12}>
          <Row className="border-bottom justify-content-center pb-4">
            <Col xs="8" className="text-center mx-auto w-100">
              <h1>Favorite Pharmacy</h1>
            </Col>
          </Row>
          {favoritePharmacy && (
            <Row className="border-bottom justify-content-center vertical-buffer">
              <Col className="text-center mb-1 font-weight-bold" xs={12}>
                {favoritePharmacy.name}
              </Col>
              <Col className="text-center mb-3" xs={12}>
                {favoritePharmacy.address}
              </Col>
              <a
                target="_blank"
                className="w-100 vcenter-column"
                href={`https://www.google.com/maps/search/?api=1&query=${favoritePharmacy.lat},${favoritePharmacy.lng}`}
                rel="noreferrer"
              >
                <Button className="w-75 btn-tertiary" variant="primary">
                  <small>Pharmacy Location</small>
                </Button>
              </a>
            </Row>
          )}
          {!favoritePharmacy && (
            <Row className="border-bottom justify-content-center vertical-buffer">
              <Col className="text-center mb-3" xs={12}>
                There is no favorite pharmacy
              </Col>
            </Row>
          )}
          <Row className="border-bottom vertical-buffer justify-content-center"></Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FavoritePharmacy;
