import {
  createSingleEventSaga,
  MyAction,
  composeSagas,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';

import { AUTH_API_URL } from '../../config';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from '../../utils/typings';
import { Creators as LoadingActions } from '../loading.module';
import { Creators as ErrorActions } from '../errors.module';
import { MMDError } from '../../utils/MMDError';
import { putAuthInfoInArgs } from '../auth.module';
import { onRoute, extractRouteParams } from '../../utils/onRoute';
import { noOpAction } from '../../utils/noOpAction';

interface ActionTypes {
  COMMIT_NOTES_VIDEO_CALL: string;
  REQUEST_UPSERT_NOTES_VIDEO_CALL: string;
}

interface ActionCreators {
  commitNotesVideoCall: (payload: {
    body: string;
  }) => MyAction<{ body: string }>;
  requestUpsertNotesVideoCall: (payload: {
    id: string;
    body: string;
  }) => MyAction<{ id: string; body: string }>;
}

export type VideoCallNotesState = string;

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  commitNotesVideoCall: ['payload'],
  requestUpsertNotesVideoCall: ['payload'],
});

const initialState = '';

function commitNotesVideoCall(
  state: VideoCallNotesState,
  action: MyAction<{ body: string }>,
): VideoCallNotesState {
  return action.payload.body || state;
}

export const videoCallNotesReducer = createReducer(initialState, {
  [Types.COMMIT_NOTES_VIDEO_CALL]: commitNotesVideoCall,
});

async function downloadNotesForVideoCall({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<
  { response: true; data: { note: string } } | { response: false }
> {
  const result = await fetch(`${AUTH_API_URL}/bookings/${payload.id}/notes`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong downloading notes associated to this call',
    );
  }

  return result.json();
}

const requestVideoCallNotesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  { body: string },
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/video-call/:id'),
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitNotesVideoCall,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadNotesForVideoCall,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: string },
    ArgsWithHeaders<{ id: string }>
  >(
    // @ts-ignore
    extractRouteParams('/video-call/:id'),
    putAuthInfoInArgs,
  ),
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(
    res: { response: true; data: { note: string } } | { response: false },
  ): SagaIterator {
    if (res.response && res.data) {
      return { body: res.data.note };
    }

    return {};
  },
});

async function upsertNotesForVideoCall({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string; body: string }>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/bookings/${payload.id}/notes`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ note: payload.body }),
  });

  if (!result.ok) {
    throw new MMDError(
      'An error has occurred processing your notes. Try again later...',
    );
  }
}

const upsertVideoCallNotesWatcher = createSingleEventSaga<
  object,
  { id: string; body: string },
  MyAction<object>
>({
  takeEvery: Types.REQUEST_UPSERT_NOTES_VIDEO_CALL,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitNotesVideoCall,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: upsertNotesForVideoCall,
  beforeAction: putAuthInfoInArgs,
  // eslint-disable-next-line require-yield
  *afterAction(
    _,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { headers, ...payload }: ArgsWithHeaders<{ id: string; body: string }>,
  ) {
    return payload;
  },
});

export const videoCallNotesSagas = [
  requestVideoCallNotesWatcher,
  upsertVideoCallNotesWatcher,
];
