import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { selectErrorState } from '../modules/errors.module';
import { selectLoadingState } from '../modules/loading.module';
import { useBoundedActions } from '../hooks/useBoundedActions';
import { Creators as NotificationsActions } from '../modules/notifications.module';

enum AttendanceActions {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

const StudentAttendance = () => {
  const [searchParams] = useSearchParams();

  const { acceptStudentAttendance, declineStudentAttendance } =
    useBoundedActions(NotificationsActions);

  const { error } = useSelector(selectErrorState);
  const { loading } = useSelector(selectLoadingState);

  const action = searchParams.get('action');
  const bookingId = searchParams.get('bookingId');
  const patientId = searchParams.get('patientId');

  const isAcceptAction = action === AttendanceActions.ACCEPT;

  useEffect(() => {
    const payload = {
      bookingId,
      patientId,
    };

    if (isAcceptAction) {
      acceptStudentAttendance(payload);
    } else {
      declineStudentAttendance(payload);
    }
  }, []);

  const isActionTextVisible = !loading && !error;

  const actionText = `You've successfully ${action}${
    isAcceptAction ? 'ed' : 'd'
  } student attendance`;

  return (
    <Row className="justify-content-center">
      <Col xs={12} className="text-center">
        <a href="/">
          <img
            width="350"
            height="200"
            alt="MomentMD"
            src="/assets/momentMDLogo.png"
          />
        </a>
      </Col>

      {isActionTextVisible && <h1 className="text-center">{actionText}</h1>}
    </Row>
  );
};

export default StudentAttendance;
