import { FC } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BookingPublic } from '../../../typings';
import ActionsMenu from './component/ActionsMenu';
import CopyLinkButton from './component/CopyLinkButton';
import { useIsStudentCheck } from '../../../hooks/useIsStudentCheck';
import { useBoundedActions } from '../../../hooks/useBoundedActions';
import { selectProviderState } from '../../../modules/provider.module';
import {
  formatAppointmentDate,
  formatAppointmentTime,
} from './component/helpers';
import {
  selectPublicWaitingRoomState,
  Creators as PublicWaitingRoomActions,
} from '../../../modules/videocall-public/waiting-room.module';
import { Creators as VideoCallClientPublicActions } from '../../../modules/videocall-client-public.module';

type PropsPublic = {
  publicAppointment: BookingPublic;
  onEditPublicAppointment: () => void;
};

const PublicAppointmentRow: FC<PropsPublic> = ({
  publicAppointment,
  onEditPublicAppointment,
}) => {
  const navigate = useNavigate();

  const isStudent = useIsStudentCheck();

  const { currentRoomName } = useSelector(selectPublicWaitingRoomState);

  const { firstName, lastName, email } = useSelector(selectProviderState);

  const {
    initializeParticipantWebsocket,
    disconnectParticipantWebsocket,
    requestJoinParticipantToTheCall,
  } = useBoundedActions({
    ...VideoCallClientPublicActions,
    ...PublicWaitingRoomActions,
  });

  const startVideoCallAsStudent = () => {
    if (currentRoomName !== publicAppointment.eventId) {
      disconnectParticipantWebsocket();
      initializeParticipantWebsocket({
        roomName: publicAppointment.eventId,
        successNavigateUrl: `/video-call-public/${publicAppointment.eventId}`,
      });
    }

    requestJoinParticipantToTheCall({
      bookingId: publicAppointment.id,
      type: 'student',
      email,
      name: `${firstName} ${lastName}`,
    });
  };

  const onStartVideoCall = () => {
    if (moment(publicAppointment.startDate).isAfter(moment())) {
      alert(
        `You're too early! Your call starts ${moment(
          publicAppointment.startDate,
        ).fromNow()}`,
      );
      return;
    }

    if (isStudent) {
      startVideoCallAsStudent();
      return;
    }

    navigate(`/video-call-public/${publicAppointment.eventId}`);
  };

  const isVideCallInProgress = moment().isBetween(
    moment(publicAppointment.startDate),
    moment(publicAppointment.endDate),
  );

  return (
    <div
      style={{ minWidth: 300 }}
      className="d-flex p-4 public-booking-row align-items-center"
    >
      <Col xs={8}>
        <p className="ml-0 mb-0 text-normal-weight">
          Public Appointment
          <small className="ml-2 text-primary small">
            {Number(publicAppointment.numberParticipant) > 1
              ? `${publicAppointment.numberParticipant} Participants`
              : '1 Participant'}
          </small>
        </p>

        <div className="d-flex flex-column">
          <small className="public-booking-row__date-time">
            {formatAppointmentDate(publicAppointment.startDate)}
          </small>

          <small className="public-booking-row__date-time">
            {formatAppointmentTime(
              publicAppointment.startDate,
              publicAppointment.endDate,
            )}
          </small>
        </div>

        <CopyLinkButton
          bookingEventId={publicAppointment.eventId}
          className="mt-2"
        />
      </Col>

      <Col
        xs={4}
        className="h-100 d-flex align-items-center justify-content-between"
      >
        <div
          className={`${
            !isVideCallInProgress ? 'cursor-not-allowed' : ''
          } mr-2`}
        >
          <Button
            onClick={onStartVideoCall}
            disabled={!isVideCallInProgress}
            className="w-100 pl-4 pr-4 pt-1 pb-1"
          >
            <small>Start Video Call</small>
          </Button>
        </div>

        {!isStudent && (
          <ActionsMenu
            publicAppointment={publicAppointment}
            isVideCallInProgress={isVideCallInProgress}
            onEditPublicAppointment={onEditPublicAppointment}
          />
        )}
      </Col>
    </div>
  );
};

export default PublicAppointmentRow;
