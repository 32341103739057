import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  Dictionary,
  EntityState,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { AUTH_API_URL } from '../config';
import { MMDError } from '../utils/MMDError';
import { TermsAndConditions } from '../typings';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import { LocationChangeActionPayload } from '../utils/typings';
import { compareByCreationTimestamp } from '../utils/compareByCreationTimestamp';

interface ActionTypes {
  COMMIT_TERM_AND_CONDITION: string;
  REQUEST_TERM_AND_CONDITION: string;
}

interface ActionCreators {
  commitTermAndCondition: (
    payload: TermsAndConditions,
  ) => MyAction<TermsAndConditions>;
  requestTermAndCondition: () => Action;
}

export interface TermsAndConditionsState
  extends EntityState<TermsAndConditions> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  commitTermAndCondition: ['payload'],
  requestTermAndCondition: [],
});

const entityAdapter = createEntityAdapter<TermsAndConditions>({
  selectId: (item) => item.id.toString(),
  sortComparer: compareByCreationTimestamp,
});
const initialState = entityAdapter.getInitialState();
export const termAndConditionSelectors = entityAdapter.getSelectors();

function commitTermAndCondition(
  state: TermsAndConditionsState,
  action: MyAction<TermsAndConditions>,
): TermsAndConditionsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
  };
}

export const termAndConditionReducer = createReducer(initialState, {
  [Types.COMMIT_TERM_AND_CONDITION]: commitTermAndCondition,
});

async function downloadTermAndCondition(): Promise<TermsAndConditions> {
  const result = await fetch(
    `${AUTH_API_URL}/terms-and-conditions?&userType=${'Doctor'}`,
    {
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong downloading your terms and conditions',
    );
  }
  const { termsAndConditions } = await result.json();
  return termsAndConditions;
}

const requestTermAndConditionWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  TermsAndConditions,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_TERM_AND_CONDITION,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTermAndCondition,
  successAction: Creators.commitTermAndCondition,
  errorAction: ErrorActions.setError as any,
  action: downloadTermAndCondition,
});

export const termAndConditionSagas = [requestTermAndConditionWatcher];

export function termAndConditionFormValidator(): Promise<Dictionary<boolean>> {
  const result = {
    content: true,
    userType: true,
  };

  return Promise.resolve(result);
}
