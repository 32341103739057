import { FC, forwardRef } from 'react';
import { Dropdown, Image, Modal } from 'react-bootstrap';

import { BookingPublic } from '../../../../typings';
import { useToggle } from '../../../../hooks/useToggle';
import { useBoundedActions } from '../../../../hooks/useBoundedActions';
import { Creators as BookingsPublicActions } from '../../../../modules/bookings-public.module';

// eslint-disable-next-line react/display-name
const CustomToggle = forwardRef<HTMLDivElement, { onClick: () => void }>(
  ({ onClick }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className="d-flex align-items-center justify-content-center cursor-pointer"
    >
      <Image src="/assets/dropdown.png" style={{ height: 24, width: 24 }} />
    </div>
  ),
);

type Props = {
  isVideCallInProgress: boolean;
  publicAppointment: BookingPublic;
  onEditPublicAppointment: () => void;
};

const ActionsMenu: FC<Props> = ({
  publicAppointment,
  isVideCallInProgress,
  onEditPublicAppointment,
}) => {
  const [isConfirmCancelModalOpen, toggleConfirmCancelModal] = useToggle();

  const { cancelPublicBooking } = useBoundedActions(BookingsPublicActions);

  const onCancelAppointment = () => {
    toggleConfirmCancelModal();
    cancelPublicBooking({ id: publicAppointment.id });
  };

  const isActionDisabled = isVideCallInProgress;

  return (
    <>
      <Dropdown drop="down-centered" className="public-booking-row__actions">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          Custom toggle
        </Dropdown.Toggle>

        <Dropdown.Menu className={isActionDisabled ? 'cursor-not-allowed' : ''}>
          <Dropdown.Item
            eventKey="1"
            disabled={isActionDisabled}
            onClick={onEditPublicAppointment}
          >
            Edit Public Appointment
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            disabled={isActionDisabled}
            onClick={toggleConfirmCancelModal}
          >
            Cancel Public Appointment
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal
        centered
        show={isConfirmCancelModalOpen}
        onHide={toggleConfirmCancelModal}
      >
        <div className="p-4 d-flex flex-column align-items-center justify-content-center">
          <p>Are you sure you want to cancel this public appointment?</p>

          <div className="d-flex">
            <div
              className="cursor-pointer p-2 mr-3"
              onClick={toggleConfirmCancelModal}
            >
              Go back
            </div>

            <div
              onClick={onCancelAppointment}
              className="cursor-pointer p-2 text-danger"
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ActionsMenu;
