import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Creators as TemplatesActions } from '../../../../modules/template.module';
import { MyState } from '../../../../store';
import { push } from 'redux-first-history';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotesTextArea, {
  getNotesString,
  noteSections,
} from '../../../../components/notes/notesTextArea';
import { Nullable } from '../../../../typings';

type Props = {
  notes: Nullable<string>;
  booking: any;
  commitNotePublic: (value: string) => void;
  onRequestClose: () => void;
  templates: any;
  requestTemplates: any;
} & typeof TemplatesActions;

const NotesPublic: FC<Props> = ({
  notes,
  commitNotePublic = () => {},
  onRequestClose = () => {},
  templates,
  requestTemplates,
}) => {
  const [noteTemplates, setNoteTemplates] = useState([]);
  const [notesObject, setNotesObject] = useState<noteSections[]>([
    {
      id: 0,
      name: notes ? 'Saved Note' : 'Basic',
      value: notes,
      noTitle: true,
    },
  ]);
  const [templateSelected, setTemplateSelected] = useState(
    noteTemplates && noteTemplates[0],
  );

  const close = useCallback(() => {
    const noteString = getNotesString();

    if (noteString !== notes) {
      commitNotePublic(noteString);
    }
    onRequestClose();
  }, [commitNotePublic, onRequestClose]);

  const handlerSelectTemplate = (value) => {
    switch (value) {
      case '-1':
        setTemplateSelected('loaded');
        break;
      case '0':
        setTemplateSelected('basic');
        break;
      default:
        try {
          setTemplateSelected(templates.entities[value]);
        } catch (e) {
          console.log(e);
          setTemplateSelected('basic');
        }
    }
  };
  useEffect(() => {
    if (templateSelected) {
      switch (templateSelected) {
        case 'loaded':
          setNotesObject([
            { id: 0, name: 'Saved Note', value: notes, noTitle: true },
          ]);
          break;
        case 'basic':
          setNotesObject([{ id: 0, name: 'Basic', value: '', noTitle: true }]);
          break;
        default:
          setNotesObject(
            templateSelected &&
              templateSelected.sections.map((sec) => ({
                id: sec.id,
                name: sec.name,
                value: '',
              })),
          );
      }
    }
  }, [templateSelected]);

  useEffect(() => {
    setNoteTemplates(
      templates && templates.ids.map((id) => templates.entities[id]),
    );
  }, [templates]);

  useEffect(() => {
    requestTemplates();
  }, []);
  return (
    <Col className="gray-background">
      <Row className="mb-3">
        <img
          className="mx-2 my-3"
          style={{ objectFit: 'contain', cursor: 'pointer' }}
          height="32"
          width="32"
          src="/assets/left-arrow.png"
          alt="Previous step"
          onClick={close}
        />
      </Row>
      <Col xs={12}>
        <span className="ml-3 form-label">Select template note:</span>
        <select
          onChange={(e) => handlerSelectTemplate(e.target.value)}
          className="w-100 form-control"
        >
          {notes && (
            <option key={-1} value={-1}>
              Saved Note
            </option>
          )}
          <option key={0} value={0}>
            Basic Template
          </option>
          {noteTemplates.map((template) => (
            <option
              key={template.id}
              value={template.id}
              selected={
                templateSelected &&
                templateSelected != 'basic' &&
                templateSelected.id == template.id
              }
            >
              {template.title}
            </option>
          ))}
        </select>
      </Col>
      <div className="notes-videochat-wrapper">
        <NotesTextArea notes={notesObject} />
      </div>
    </Col>
  );
};
const mapStateToProps = (state: MyState) => ({
  templates: state.templates,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...TemplatesActions,
      push,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(NotesPublic);
