import { FC } from 'react';
import moment from 'moment';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import { FormLabel } from 'react-bootstrap';

type Props = {
  name: string;
  required?: boolean;
};

const ModalDatePicker: FC<Props> = ({ name, required }) => {
  const [field, meta, helpers] = useField(name);

  const getDayClassName = (date: Date) =>
    moment(field.value).isSame(moment(date), 'day')
      ? 'selected-date'
      : undefined;

  const onChangeDate = (date: Date) => {
    const selectedDate = moment(date);

    helpers.setValue(
      moment()
        .year(selectedDate.year())
        .month(selectedDate.month())
        .date(selectedDate.date()),
    );
  };

  return (
    <div className="w-25">
      <FormLabel className="ml-3 form-label" htmlFor={name}>
        Date
        {required && <span className="text-danger ml-1">*</span>}
      </FormLabel>

      <DatePicker
        name={name}
        showYearDropdown
        autoComplete="off"
        dateFormat="MM/dd"
        minDate={new Date()}
        className="form-control"
        onChange={onChangeDate}
        disabledKeyboardNavigation
        selected={field.value.toDate()}
        dayClassName={getDayClassName}
        onKeyDown={(e) => e.preventDefault()}
      />

      {meta.error && meta.touched && (
        <span className="invalid-feedback d-block">{meta.error}</span>
      )}
    </div>
  );
};

export default ModalDatePicker;
