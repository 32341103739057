import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

import MobileAppQrCodes from './MobileAppQrCodes';
import { getLinkForDownloadApp, LANDING_PAGE_LINK } from './config';
import { getIsMobileOrTablet } from '../../utils/getIsMobileOrTablet';

const FeedbackViewClientPublic: FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.fromCall) {
      window.location.href = LANDING_PAGE_LINK;
    }
  }, [location.state]);

  const isMobile = getIsMobileOrTablet();

  const openMobileAppInStore = () =>
    window.open(getLinkForDownloadApp(), '_blank');

  return (
    <Container className="pt-5">
      <Col className="mx-auto text-center">
        <img
          height="200"
          alt="MomentMD"
          className="ml-auto"
          src="/assets/momentMDLogo.png"
        />
      </Col>

      <Row className="border-bottom " />

      <Col xs="8" className="text-center mx-auto w-100 mt-4">
        <Col xs="8" className="w-100 mt-4">
          <p>Keeping track of your medical visits is easy</p>
        </Col>

        <Col xs="8" className="w-100 mt-4">
          {!isMobile && <MobileAppQrCodes />}
        </Col>

        <Col xs="8" className="w-100 mt-5">
          <Button
            variant="primary"
            className="w-30 btn-green "
            onClick={openMobileAppInStore}
          >
            Create an account
          </Button>
        </Col>
      </Col>

      <p className="text-center mt-5">
        <a href="mailto:admin@momentmd.com">Contact us</a>
      </p>
    </Container>
  );
};

export default FeedbackViewClientPublic;
