import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useBoundedActions } from '../../../hooks/useBoundedActions';
import {
  selectPatientsState,
  Creators as patientsActions,
} from '../../../modules/patients.module';
import BasePatientVisitHistoryTable, {
  TableProps,
  PatientVisitHistory,
} from './BasePatientVisitHistoryTable';

type Props = {
  patientId: string;
  onVisitHistoryClick?: (visit: PatientVisitHistory) => void;
} & TableProps;

const PatientVisitHistoryTable: FC<Props> = ({
  patientId,
  onVisitHistoryClick,
  ...tableProps
}) => {
  const { visitHistory } = useSelector(selectPatientsState);

  const { requestPatientVisitHistory } = useBoundedActions(patientsActions);

  return (
    <BasePatientVisitHistoryTable
      patientId={patientId}
      request={requestPatientVisitHistory}
      onVisitHistoryClick={onVisitHistoryClick}
      getVisitHistoryPageLink={({ id }) => `visit-history/${id}`}
      {...visitHistory}
      {...tableProps}
    />
  );
};

export default PatientVisitHistoryTable;
