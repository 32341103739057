import {
  faBandAid,
  faCapsules,
  faExclamationTriangle,
  faFlag,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Creators as patientsActions } from '../../modules/patients.module';
import { MyState } from '../../store';

import { Patient } from '../../typings';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

type Props = {
  patientId: string;
  medicalHistory: any[];
  patients: Dictionary<Patient>;
} & typeof patientsActions;

const mapSections = (section: string) => {
  switch (section) {
    case 'conditions':
      return {
        title: 'Pre-Existing Conditions',
        icon: faCapsules,
      };
    case 'procedures':
      return {
        title: 'Procedures',
        icon: faBandAid,
      };
    case 'medications':
      return {
        title: 'Medications',
        icon: faFlag,
      };
    case 'allergies':
      return {
        title: 'Allergies',
        icon: faExclamationTriangle,
      };
  }
};

const MedicalHistoryDetails: FC<Props> = ({
  patients,
  patientId,
  medicalHistory,
  requestAppointmentMedicalHistory,
}) => {
  useEffect(() => {
    if (!patients) return null;
    requestAppointmentMedicalHistory({ id: patientId });
  }, [patientId, requestAppointmentMedicalHistory, patients]);

  if (!medicalHistory) return null;

  const medicalHistorySections = medicalHistory.map((item) => ({
    ...mapSections(item.section),
    values: item.values,
  }));

  return (
    <Row className="pt-2 justify-content-center p-3">
      <Container>
        {medicalHistorySections.map((item, index) => (
          <MedicalItem
            key={item.title}
            title={item.title}
            values={item.values}
            icon={item.icon}
            className={index === 0 ? 'border-top' : ''}
          />
        ))}
      </Container>
    </Row>
  );
};

const MedicalItem: FC<{
  title: string;
  values: string[];
  icon: IconDefinition;
  className: string;
}> = ({ title, values, icon, className }) => (
  <Col className={`border-bottom ${className}`}>
    <p className="mb-0 ">
      <FontAwesomeIcon
        className=" margin-right-10"
        icon={icon}
        style={{ color: '#F11B42' }}
      />
      {title}
    </p>
    {values.map((value) => (
      <li className=" padding-10 margin-left-15" key={value}>
        {value}
      </li>
    ))}
  </Col>
);

const mapStateToProps = (state: MyState) => ({
  medicalHistory: state.patients.medicalHistory,
  patients: state.patients.entities,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...patientsActions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicalHistoryDetails);
