import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import DoctorProfile from '../components/DoctorProfile';
import { Provider, Speciality } from '../typings';
import { MyState } from '../store';
import ProfileInformationList from './edit-profile/ProfileInformationList';
import { Creators as ProviderActions } from '../modules/provider.module';
import ProfilePriceItem from './edit-profile/ProfilePriceItem';
import MMDModal from '../components/MMDModal';
import TextProfileInfo from './edit-profile/TextProfileInfo';
import { PSYCHOLOGY } from '../utils/constants';

type Props = {
  provider: Provider;
} & typeof ProviderActions;

const EditProfile: FC<Props> = ({
  provider,
  requestUpdateProviderProfile,
  requestCustomPrice,
}) => {
  const [pricePage, setPricePage] = useState(false);
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [textRequestPlan, setTextRequestPlan] = useState<string | undefined>();
  const [planType, setPlanType] = useState<string | undefined>();

  const specialty: Speciality | object = provider.profile
    ? provider.profile.specialities && provider.profile.specialities.length
      ? provider.profile.specialities[0]
      : {}
    : {};

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>My Profile</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <DoctorProfile editable />
        </Col>
        <Col xs={12} sm={12} md={12} lg={9}>
          {!pricePage && (
            <ProfileInformationList
              provider={provider.id ? provider : null}
              requestUpdateProviderProfile={requestUpdateProviderProfile}
              setPricePage={() => setPricePage(true)}
            />
          )}
          {pricePage && (
            <div>
              <ProfilePriceItem
                id={1}
                title="Video Consultations"
                description={`${
                  (specialty as Speciality).timeSlotLength
                } minute consultation with a patient`}
                type="oneTime"
                includes={[
                  'notes',
                  'e-prescribing',
                  'file sharing',
                  'one week of messaging',
                ]}
                price={provider.oneTimePrice}
                onClick={() => {
                  setPlanType('oneTime');
                  setShowModalPlan(true);
                }}
              />
              {provider.isPsychiatrist ? (
                <ProfilePriceItem
                  id={2}
                  title={`${
                    (specialty as Speciality).doctorType === PSYCHOLOGY
                      ? 'Extended time consultation'
                      : 'First time consultation'
                  } (applicable only to psychiatric specialties)`}
                  description={`${
                    (specialty as Speciality).firstTimeLength
                  } minute consultation with a patient`}
                  type="firstTime"
                  includes={[
                    'notes',
                    'e-prescribing',
                    'file sharing',
                    'one week of messaging',
                  ]}
                  price={provider.firstTimePrice}
                  onClick={() => {
                    setPlanType('firstTime');
                    setShowModalPlan(true);
                  }}
                />
              ) : null}
              <ProfilePriceItem
                id={provider.isPsychiatrist ? 3 : 2}
                title="Monthly Subscription"
                description="Unlimited video consultations"
                type="monthly"
                includes={[
                  'messaging',
                  'e-prescribing',
                  'includes notes',
                  'file sharing',
                ]}
                price={provider.monthlyPrice}
                onClick={() => {
                  setPlanType('monthly');
                  setShowModalPlan(true);
                }}
              />
              <Button
                variant="outline-dark"
                className="height-30 margin-top-35 padding-h-50"
                onClick={() => setPricePage(false)}
              >
                <h6>Back</h6>
              </Button>
            </div>
          )}
          <MMDModal
            show={showModalPlan}
            classNameContainer={'modal-container-padding'}
            title="Payment Plan Request"
            titleClassName="payment-plan-pequest"
            handleClose={() => setShowModalPlan(false)}
            renderBody={() => (
              <TextProfileInfo
                textAreaClassName={'border-solid'}
                text={textRequestPlan}
                setText={setTextRequestPlan}
              />
            )}
            renderFooter={() => (
              <Row className="w-100 center-footer">
                <Col xs={5} className="text-center modal-footer-btn">
                  <Button
                    className="w-100 primary height-30"
                    onClick={() => {
                      requestCustomPrice({
                        description: textRequestPlan,
                        planType: planType,
                      });
                      setShowModalPlan(false);
                      setPlanType('');
                      setTextRequestPlan('');
                    }}
                  >
                    <h6>Send request</h6>
                  </Button>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ ...ProviderActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
