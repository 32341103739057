import moment from 'moment';
import { Row, Image } from 'react-bootstrap';
import { getFirebaseImage } from '../../utils/Firebase';
import { truncateText } from '../../utils/helper';
import { TRUNCATE_FILE_NAME } from '../../utils/constants';
import { FC } from 'react';

type Props = {
  files: any[];
  doctorId?: string;
  eventId: string;
};

const DocumentsDetailsPublic: FC<Props> = ({ eventId, files, doctorId }) => {
  if (!files) return null;

  const getPath = (doc: any) =>
    `bookings/${eventId}/${doctorId}/public/${doc.fileNamePublic}`;

  const getExt = (doc: any) => doc.fileNamePublic.split('.').pop() || 'jpg';

  return (
    <Row className="margin-left-15 w-100 padding-10 ">
      {files.length === 0 && (
        <div className="vcenter-column">
          <p>No documents</p>
          <p>Here you will find: lab tests, surgeries, x-rays, ct scans, etc</p>
        </div>
      )}
      {files.length > 0 &&
        files.map((doc) => (
          <a
            key={doc.id}
            className="margin-10 vcenter-column"
            style={{ cursor: 'pointer' }}
            target="_blank"
            onClick={async () => {
              const result = await getFirebaseImage(getPath(doc));
              window.open(result);
            }}
          >
            <Image
              style={{ color: '#F11B42' }}
              src="/assets/file.png"
              className="mr-3"
              width="80"
              height="80"
            />
            <small>
              {truncateText(doc.fileNamePublic, TRUNCATE_FILE_NAME).concat(
                getExt(doc),
              )}
            </small>
            {doc.date ? (
              <small>{moment(doc.date).format('MM-DD-YYYY')}</small>
            ) : null}
          </a>
        ))}
    </Row>
  );
};

export default DocumentsDetailsPublic;
