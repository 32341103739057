import {
  EntityState,
  MyAction,
  createEntityAdapter,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { PromiseQueue } from '@mrnkr/promise-queue';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';
import {
  take,
  spawn,
  fork,
  put,
  call,
  cancelled,
  cancel,
  select,
} from 'redux-saga/effects';
import { Location } from 'react-router-dom';

import { Types as ChatroomsTypes } from '../chatrooms.module';
import { Creators as ErrorActions } from '../errors.module';
import { Creators as LoadingActions } from '../loading.module';
import { Message, Provider, Chatroom } from '../../typings';
import { firestore } from '../../utils/Firebase';
import { generatePushID } from '../../utils/generatePushId';
import { extractRouteParams, onRoute } from '../../utils/onRoute';
import { LocationChangeActionPayload } from '../../utils/typings';
import { MMDError } from '../../utils/MMDError';
import { MyState } from '../../store';
import { AUTH_API_URL } from '../../config';
import {
  collection,
  CollectionReference,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  Query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

interface ActionTypes {
  ACTIVATE_CONNECTION_SELECTED_CHATROOM: string;
  COMMIT_MESSAGE_TO_SELECTED_CHATROOM: string;
  COMMIT_MESSAGES_TO_SELECTED_CHATROOM: string;
  CLOSE_CONNECTION_SELECTED_CHATROOM: string;
  CLEAR_SELECTED_CHATROOM: string;
  REQUEST_OLD_MESSAGES_FOR_CHATROOM: string;
  SEND_MESSAGE: string;
  REQUEST_CHATROOM: string;
}

interface ActionCreators {
  activateConnectionSelectedChatroom: (payload: {
    chatroomId: string;
  }) => MyAction<{ chatroomId: string }>;
  commitMessageToSelectedChatroom: (payload: Message) => MyAction<Message>;
  commitMessagesToSelectedChatroom: (payload: Message[]) => MyAction<Message[]>;
  closeConnectionSelectedChatroom: () => Action;
  clearSelectedChatroom: () => Action;
  requestOldMessagesForChatroom: (payload: {
    chatroomId: string;
  }) => MyAction<{ chatroomId: string }>;
  sendMessage: (payload: string) => MyAction<string>;
  requestChatroom: (payload: { location: Location }) => Action;
}

export interface SelectedChatroomState extends EntityState<Message> {
  connectionActive: boolean;
  chatroomId?: string;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  activateConnectionSelectedChatroom: ['payload'],
  commitMessageToSelectedChatroom: ['payload'],
  commitMessagesToSelectedChatroom: ['payload'],
  closeConnectionSelectedChatroom: [],
  clearSelectedChatroom: [],

  sendMessage: ['payload'],
  requestChatroom: ['payload'],

  requestOldMessagesForChatroom: ['payload'],
});

const entityAdapter = createEntityAdapter<Message>({
  selectId: (e) => e.id,
  sortComparer: (a, b) => (a.id < b.id ? -1 : 1),
});
const initialState = entityAdapter.getInitialState({
  connectionActive: false,
});
export const selectedChatroomSelectors = entityAdapter.getSelectors();

function activateConnectionSelectedChatroom(
  state: SelectedChatroomState,
  action: MyAction<{ chatroomId: string }>,
): SelectedChatroomState {
  return {
    ...state,
    connectionActive: true,
    chatroomId: action.payload.chatroomId,
  };
}

function commitMessageToSelectedChatroom(
  state: SelectedChatroomState,
  action: MyAction<Message>,
): SelectedChatroomState {
  return entityAdapter.addOne(action.payload, state);
}

function commitMessagesToSelectedChatroom(
  state: SelectedChatroomState,
  action: MyAction<Message[]>,
): SelectedChatroomState {
  return entityAdapter.addMany(action.payload, state);
}

function closeConnectionSelectedChatroom(
  state: SelectedChatroomState,
): SelectedChatroomState {
  return {
    ...state,
    connectionActive: false,
  };
}

function clearSelectedChatroom(): SelectedChatroomState {
  return initialState;
}

export const selectedChatroomReducer = createReducer<SelectedChatroomState>(
  initialState,
  {
    [Types.ACTIVATE_CONNECTION_SELECTED_CHATROOM]:
      activateConnectionSelectedChatroom,
    [Types.COMMIT_MESSAGE_TO_SELECTED_CHATROOM]:
      commitMessageToSelectedChatroom,
    [Types.COMMIT_MESSAGES_TO_SELECTED_CHATROOM]:
      commitMessagesToSelectedChatroom,
    [Types.CLOSE_CONNECTION_SELECTED_CHATROOM]: closeConnectionSelectedChatroom,
    [Types.CLEAR_SELECTED_CHATROOM]: clearSelectedChatroom,
  },
);

const getOldMessages = async (
  chatroomId: string,
  firstAvailableId?: string,
): Promise<Message[]> => {
  let q: Query | CollectionReference = collection(
    doc(firestore, 'chatrooms', chatroomId),
    'messages',
  );

  if (firstAvailableId) {
    q = query(q, where('id', '<', firstAvailableId));
  }

  const snaps = await getDocs(query(q, orderBy('id', 'desc'), limit(20)));

  return snaps.docs.map((snap) => snap.data() as Message);
};

const listenToChatroomHelper = (chatroomId: string, lastMessageId: string) => ({
  subscribe: (next, error) => {
    const messagesRef = collection(
      doc(firestore, 'chatrooms', chatroomId),
      'messages',
    );

    const q = query(messagesRef, where('id', '>', lastMessageId));
    const unsubscribe = onSnapshot(
      q,
      (snap) => {
        snap.docChanges().forEach((change) => {
          if (change.type === 'added') {
            next(change.doc.data());
          }
        });
      },
      error,
    );

    return {
      unsubscribe,
    };
  },
});

const sendMessageToChatroom = async (
  chatroomId: string,
  body: string,
  sender: string,
): Promise<Message> => {
  const authInfo = await JSON.parse(localStorage.getItem('moment.session'));
  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);

  await fetch(`${AUTH_API_URL}/chats/send-message-user/${chatroomId}`, {
    headers,
    method: 'POST',
  });

  const id = generatePushID();
  const createdAt = new Date().toISOString();
  const message = {
    id,
    body,
    sender,
    createdAt,
  };

  await updateDoc(doc(firestore, 'chatrooms', chatroomId), {
    updatedAt: createdAt,
    lastMessage: message,
    unreadCount: 1,
  });

  await setDoc(
    doc(collection(doc(firestore, 'chatrooms', chatroomId), 'messages'), id),
    message,
  );

  return message;
};

const setUnreadCountToZeroHelper = (chatroomId: string) =>
  updateDoc(doc(firestore, 'chatrooms', chatroomId), { unreadCount: 0 });

function* requestListenToChatroomWatcher(): SagaIterator {
  while (true) {
    const action = yield take(
      (action) =>
        onRoute('/chats/:id')(action) || action.type === Types.REQUEST_CHATROOM,
    );
    const { id } = yield call(
      extractRouteParams('/chats/:id'),
      action.payload as LocationChangeActionPayload,
    );
    const chatroom = yield select(
      (state: MyState) => state.chatrooms.entities[id],
    );

    if (!chatroom) {
      yield take(ChatroomsTypes.ACTIVATE_CONNECTION_CHATROOMS);
    }

    const task = yield spawn(listenToChatroomHandler, { id });
    yield take('@@router/LOCATION_CHANGE');
    yield fork(stopListeningToChatroomHandler, task);
  }
}

function* listenToChatroomHandler({
  id: chatroomId,
}: Dictionary<string>): SagaIterator {
  let lastId = '';

  try {
    yield put(LoadingActions.setLoading());
    const oldMessages: Message[] = yield call(getOldMessages, chatroomId);
    if (oldMessages && oldMessages.length > 0) {
      lastId = oldMessages[oldMessages.length - 1].id;
      yield put(Creators.commitMessagesToSelectedChatroom(oldMessages));
    }
    yield put(LoadingActions.setNotLoading());
  } catch (err) {
    console.error(err);
    yield put(
      ErrorActions.setError(
        new MMDError(
          `An error has occurred in the connection to chatroom ${chatroomId}`,
        ),
      ),
    );
  }

  const promiseQueue = new PromiseQueue<Message>(
    listenToChatroomHelper(chatroomId, lastId),
  );

  try {
    yield put(Creators.activateConnectionSelectedChatroom({ chatroomId }));

    while (true) {
      const { value }: { value: Message } = yield call(promiseQueue.next);
      yield fork(messageReceivedHandler, { chatroomId, message: value });
    }
  } catch (err) {
    console.error(err);
    yield put(
      ErrorActions.setError(
        new MMDError(
          `An error has occurred in the connection to chatroom ${chatroomId}`,
        ),
      ),
    );
  } finally {
    if (yield cancelled()) {
      if (!promiseQueue.cancelled)
        promiseQueue.cancel(new MMDError('Cancelled'));
    }
  }
}

function* getOlderMessages() {
  while (true) {
    const {
      payload: { chatroomId },
    } = yield take(Types.REQUEST_OLD_MESSAGES_FOR_CHATROOM);

    try {
      yield put(LoadingActions.setLoading());
      const messageIds = yield select(
        (state: MyState) => state.chatroom.selectedChatroom.ids,
      );

      if (!messageIds.length) {
        throw Error();
      }

      const oldMessages = yield call(getOldMessages, chatroomId, messageIds[0]);

      yield put(Creators.commitMessagesToSelectedChatroom(oldMessages));
      yield put(LoadingActions.setNotLoading());
    } catch (err) {
      yield put(LoadingActions.setNotLoading());
      yield put(
        ErrorActions.setError(
          new MMDError('An error has occurred retrieving your messages'),
        ),
      );
    }
  }
}

function* messageReceivedHandler({
  chatroomId,
  message,
}: {
  chatroomId: string;
  message: Message;
}) {
  try {
    yield put(Creators.commitMessageToSelectedChatroom(message));
    const profile = yield call(getLoggedUser);

    if (
      message.sender &&
      profile.id &&
      message.sender.toString() !== profile.id.toString()
    ) {
      const theRoom: Chatroom = yield select(
        (state: MyState) => state.chatrooms.entities[chatroomId],
      );

      if (theRoom.unreadCount > 0) {
        yield call(setUnreadCountToZeroHelper, chatroomId);
      }
    }
  } catch (err) {
    yield put(
      ErrorActions.setError(
        new MMDError('An error has occurred processing an incoming message'),
      ),
    );
  }
}

function* getLoggedUser(): SagaIterator {
  const provider: Provider = yield select((state: MyState) => state.provider);
  return provider;
}

function* stopListeningToChatroomHandler(task) {
  yield cancel(task);
  yield put(Creators.closeConnectionSelectedChatroom());
  yield put(Creators.clearSelectedChatroom());
}

function* sendMessageWatcher(): SagaIterator {
  while (true) {
    const { payload: body }: MyAction<string> = yield take(Types.SEND_MESSAGE);

    try {
      const { id: loggedUserId }: Provider = yield call(getLoggedUser);
      const chatroomId: string = yield select(
        (state: MyState) => state.chatroom.selectedChatroom.chatroomId,
      );
      yield call(sendMessageToChatroom, chatroomId, body, loggedUserId);
    } catch (err) {
      yield put(
        ErrorActions.setError(
          new MMDError('An error has ocurred processing your message'),
        ),
      );
    }
  }
}

export const selectedChatroomSagas = [
  requestListenToChatroomWatcher,
  sendMessageWatcher,
  getOlderMessages,
];
