import { Col, Row, Image } from 'react-bootstrap';
import { Prescription } from '../../typings';
import { FC } from 'react';

type Props = {
  prescription: Prescription;
};

const PrescriptionItem: FC<Props> = ({ prescription }) => (
  <Row className="w-100">
    <Col xs={12}>
      <p className=" vertical-margin-body">
        <Image
          src="/assets/alcohol.png"
          className="mr-3"
          width="25"
          height="40"
        />
        Medications
      </p>
    </Col>
    <Col className="vcenter-column" xs={12}>
      <Row className="w-100">
        <Col className="padding-left-0" xs={6}>
          <p className=" margin-bottom-0">{`${prescription.name}, ${prescription.dosage}, ${prescription.frequency}`}</p>
          <p className=" mb-3 margin-bottom-0">{`Quantity: ${prescription.quantity} Refill: ${prescription.autoRefillsDays}`}</p>
        </Col>
      </Row>
    </Col>
    <Col xs={12}>
      <div className="w-100 padding-vertical-10 division-border"></div>
    </Col>
  </Row>
);

export default PrescriptionItem;
