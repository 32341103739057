import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  attribute: string;
  notification?: number;
  link?: string;
  url?: string;
  noBorder?: boolean;
  style?: string;
};

const ItemWithNotification: React.FC<Props> = ({
  style,
  attribute,
  noBorder = false,
  notification,
  url,
}) => {
  return (
    <Link
      className={`mb-3 pl-4 vcenter ${
        noBorder ? 'item-notification-no-border' : 'item-notification'
      } ${style}`}
      to={url || '#'}
    >
      <Col xs="9" className="vcenter">
        {attribute}
      </Col>
      <Col xs="1" className="vcenter">
        {notification > 0 && (
          <span className="ml-4 justify-content-center vcenter pink-notification">
            {notification}
          </span>
        )}
      </Col>

      <Col xs="2" className="vcenter text-right justify-content-end">
        <FontAwesomeIcon
          className="margin-right-10"
          icon={faChevronRight}
          style={{ color: '#F11B42' }}
        />
      </Col>
    </Link>
  );
};

export default ItemWithNotification;
