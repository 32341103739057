import moment from 'moment';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import { FC, JSX, useCallback, useEffect, useState } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import {
  timeslotsSelectors,
  Creators as timeslotsActions,
} from '../../modules/timeslot.module';
import { Provider, Timeslot } from '../../typings';
import TimeslotButton from './TimeslotButton';
import TimeslotDetail from './TimeslotDetail';
import MMDAccordion from '../../components/MMDAccordion';
import { checkValueForCurrentMonth, shouldTriggerOnChange } from './helpers';

type Props = {
  timeslots: Timeslot[];
  allTimeslots: any;
  provider: Provider;
  bottomComponentSlot?: JSX.Element;
} & typeof timeslotsActions;

const TimeSlotCalendar: FC<Props> = ({
  provider,
  timeslots,
  allTimeslots,
  setMonthRedux,
  requestTimeslots,
  requestAllTimeslots,
  bottomComponentSlot,
  requestClearTimeslot,
  requestDeleteAllTimeslot,
}) => {
  const [timeslotsByDay, setTimeslotsByDay] = useState<Timeslot[]>(timeslots);
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [showVideoCall, setShowVideoCall] = useState(false);
  const [timeslotDetail, setTimeslotDetail] = useState(false);
  const [selectedTimeslot, setSelectedTimeslot] = useState<
    Timeslot | undefined
  >();
  const [actionType, setActionType] = useState('add');
  const [showModal, setShowModal] = useState(false);

  const formatDate = (date?: string | Date) =>
    moment(date).format('YYYY-MM-DD');

  useEffect(() => {
    setTimeslotsByDay(
      timeslots.filter((timeslot) =>
        moment(timeslot.start.dateTime).isSame(selectedDate, 'day'),
      ),
    );
  }, [selectedDate, timeslots]);

  const onChangeTimeslots = useCallback(
    (val) => {
      const nextMonth = moment(val).month();
      if (selectedMonth !== nextMonth) {
        setSelectedMonth(nextMonth);
        setMonthRedux(nextMonth);
        requestTimeslots({
          id: provider.id,
          query: `?month=${formatDate(val)}`,
        });
      }

      setTimeslotDetail(false);
      setSelectedDate(val);
    },
    [provider, requestTimeslots, selectedMonth],
  );

  const addVideoCallHandler = useCallback(() => {
    setShowVideoCall(!showVideoCall);
    setTimeslotDetail(false);
  }, [setTimeslotDetail, setShowVideoCall, showVideoCall]);

  const addTimeslotHandler = useCallback((timeslot) => {
    setSelectedTimeslot(timeslot);
    setShowVideoCall(false);
    setTimeslotDetail(true);
    setActionType('add');
  }, []);

  const deleteTimeslots = () => {
    if (!timeslotsByDay?.length) {
      alert('There are no time slots to delete.');
      return;
    }

    requestDeleteAllTimeslot(timeslotsByDay.map((slot) => slot.id));
  };

  const deleteAllTimeslots = () => {
    if (!allTimeslots?.length) {
      alert('There are no time slots to delete.');
      return;
    }
    requestClearTimeslot();
    requestAllTimeslots({ id: provider.id, query: `?all=0` });
  };

  const editTimeslotHandler = useCallback((timeslot) => {
    if (timeslot.recurringEventId) {
      setShowModal(true);
    } else {
      setActionType('only');
      setTimeslotDetail(true);
    }
    setSelectedTimeslot(timeslot);
  }, []);

  const modalOption = useCallback(
    (action) => {
      setActionType(action);
      if (action === 'all' && selectedTimeslot) {
        setSelectedTimeslot({
          ...selectedTimeslot,
          id: selectedTimeslot.recurringEventId,
        });
      }
      setShowVideoCall(false);
      setTimeslotDetail(true);
      setShowModal(false);
    },
    [selectedTimeslot],
  );

  useEffect(() => {
    requestTimeslots({
      id: provider.id,
      query: `?month=${formatDate()}`,
    });
    setMonthRedux(moment().month());
    requestAllTimeslots({ id: provider.id, query: `?all=0` });
  }, []);

  const getTitleClassName = ({ date }: { date: Date }) =>
    timeslots
      .map((timeslot) => formatDate(timeslot.start.dateTime))
      .includes(moment(date).format('YYYY-MM-DD'))
      ? 'react-calendar__marked-date'
      : '';

  const onActiveStartDateChange = ({ activeStartDate, action, view }) => {
    if (shouldTriggerOnChange(action, view)) {
      onChangeTimeslots(checkValueForCurrentMonth(activeStartDate));
    }
  };

  return (
    <Container fluid className="dashboard-border">
      <Row className="pt-4">
        <Col className="text-center mb-3" xs={12}>
          <h2>Scheduled appointments</h2>
        </Col>
        <Col
          xs={12}
          className="text-center justify-content-center vcenter position-relative flex-column"
        >
          <Calendar
            value={selectedDate}
            minDate={new Date()}
            calendarType="gregory"
            onChange={onChangeTimeslots}
            tileClassName={getTitleClassName}
            onActiveStartDateChange={onActiveStartDateChange}
          />

          {bottomComponentSlot}
        </Col>
        <Col className="mt-2 mb-2" xs={12}>
          <Row className="mt-2 mb-2">
            <Col xs={12} className="appointment-item pt-2 pr-0 pb-2 pl-0">
              <MMDAccordion
                data={['Add video call']}
                renderToggle={(title, idx, _, expandedIdx) => (
                  <>
                    {title}
                    {idx === expandedIdx && (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right"
                        style={{ color: 'red' }}
                      />
                    )}

                    {idx !== expandedIdx && (
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right"
                        style={{ color: 'red' }}
                      />
                    )}
                  </>
                )}
                renderCollapse={() => {
                  if (provider.profile) {
                    return (
                      <>
                        <TimeslotButton
                          onClick={addTimeslotHandler}
                          isPlus
                          text="Add timeslot"
                        />
                        {timeslotDetail && (
                          <TimeslotDetail
                            selectedDate={selectedDate}
                            selectedTimeslot={selectedTimeslot}
                            actionType={actionType}
                            requestEndEdition={addVideoCallHandler}
                          />
                        )}
                        {timeslotsByDay.map((tms) =>
                          moment(tms.start.dateTime).isAfter(moment()) ? (
                            <TimeslotButton
                              key={tms.id}
                              onClick={editTimeslotHandler}
                              isEdit
                              isTimeslot
                              timeslot={tms}
                            />
                          ) : null,
                        )}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '15px',
                          }}
                        >
                          <Button variant="primary" onClick={deleteTimeslots}>
                            Delete timeslots by day
                          </Button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '15px',
                          }}
                        >
                          <Button
                            variant={'primary'}
                            onClick={deleteAllTimeslots}
                          >
                            Delete all time slots
                          </Button>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <Col xs={12} className="mx-auto">
                        Complete{' '}
                        <Link to={'/edit-profile'}>
                          <u>your profile</u>
                        </Link>{' '}
                        before adding timeslots.
                      </Col>
                    );
                  }
                }}
                styleToggle="time-slot-toggle mb-1"
                styleContainer="justify-content-center mb-2"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit recurrent timeslot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to edit only this event or all the recurring events?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => modalOption('all')}>
            All timeslots
          </Button>
          <Button variant="primary" onClick={() => modalOption('only')}>
            Only this timeslot
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  timeslots: timeslotsSelectors.selectAll(state.timeslots),
  allTimeslots: state.timeslots.allTimeSlots,
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...timeslotsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlotCalendar);
