import moment from 'moment';
import Video from 'twilio-video';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Button, Image } from 'react-bootstrap';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { LocalTracks } from '../../typings';
import FileInput from './video-call-view/FileInput';
import SwitchVideoDevice from './SwitchVideoDevice';
import { selectErrorState } from '../../modules/errors.module';
import { TOO_MANY_PARTICIPANTS_ERROR } from '../../modules/videocall-client-public.module';
import MuteAudio, { MuteProps } from './MuteAudio';

type Props = {
  booking?: any;
  saveFile: any;
  extended: boolean;
  cleanup: () => void;
  fullScreen: boolean;
  isPatientClient: boolean;
  muteProps: MuteProps;
  setFullScreen: () => void;
  room: Video.Room | undefined;
  shrinkHorizontally: boolean;
  isSidePanelVisible: boolean;
  onRequestSidePanel: () => void;
  changeVideoTrackClientPublic: any;
  subscribeExtensionClientPublic: any;
  eventLogger: (event: string) => void;
  localTracks: LocalTracks;
};

const VideoCallViewClientPublic: FC<Props> = ({
  room,
  booking,
  cleanup,
  extended,
  isPatientClient,
  saveFile,
  fullScreen,
  muteProps,
  localTracks,
  eventLogger,
  setFullScreen,
  isSidePanelVisible,
  onRequestSidePanel,
  shrinkHorizontally = false,
  changeVideoTrackClientPublic,
  subscribeExtensionClientPublic,
}) => {
  const [isProviderInTheCall, setIsProviderInTheCall] = useState(false);

  const { error } = useSelector(selectErrorState);

  const navigate = useNavigate();

  const localTrackRef = useRef(null);
  const remoteTrackRef = useRef(null);

  const timerRef = useRef(null);

  useEffect(() => {
    if (error?.message === TOO_MANY_PARTICIPANTS_ERROR) {
      cleanup();
      alert('Room is full!');
      eventLogger('Leaving the meeting by disconnect');
      window.location.href = 'https://momentmd.com/';
    }
  }, [error?.message]);

  const participantConnected = useCallback(
    (participant) => {
      const isParticipantProvider = participant.identity === booking.doctorId;

      if (isParticipantProvider) {
        setIsProviderInTheCall(true);
      }

      eventLogger(
        `${
          isParticipantProvider ? 'Provider' : 'Patient'
        } added to the meeting`,
      );

      const trackSubscribed = (track) => {
        if (track && track.attach) {
          remoteTrackRef.current?.appendChild(track.attach());
        }
      };
      const trackUnsubscribed = (track) => {
        if (isParticipantProvider) {
          setIsProviderInTheCall(false);
        }
        if (track && track.detach) {
          track.detach().forEach((element) => element.remove());
        }
      };
      participant.on('trackSubscribed', trackSubscribed);
      participant.on('trackUnsubscribed', trackUnsubscribed);

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          trackSubscribed(publication.track);
        }
      });
    },
    [eventLogger],
  );

  const participantDisconnected = useCallback(
    () => eventLogger('Patient removed from the meeting'),
    [eventLogger],
  );

  const leave = useCallback(() => {
    eventLogger('Leaving the meeting by disconnect');
    navigate('/public-appointment/feedback', { state: { fromCall: true } });
  }, [eventLogger]);

  const formatTimer = () => {
    const endTime = moment(booking.event.end.dateTime);
    const min = moment.duration(endTime.diff(moment())).minutes();
    const sec = moment.duration(endTime.diff(moment())).seconds();
    return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
  };

  const startTimer = () =>
    setInterval(() => {
      timerRef.current.innerText = formatTimer();
    }, 1000);

  useEffect(() => {
    if (!room || !localTracks?.video) {
      return;
    }

    subscribeExtensionClientPublic({ booking });
    eventLogger('onRoomDidConnect');
    room.participants.forEach(participantConnected);
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    eventLogger(`Client added to the meeting`);

    localTrackRef.current?.appendChild(localTracks.video.attach());

    const onClose = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    const timerIntervalId = startTimer();

    window.addEventListener('beforeunload', onClose);
    window.addEventListener('unload', cleanup);

    return () => {
      eventLogger('onRoomDidDisconnect');
      cleanup();
      clearInterval(timerIntervalId);
      window.removeEventListener('beforeunload', onClose);
    };
  }, [
    room,
    cleanup,
    localTracks?.video,
    eventLogger,
    participantConnected,
    participantDisconnected,
  ]);

  return (
    <Col
      sm={shrinkHorizontally ? 6 : 12}
      className={`${shrinkHorizontally ? 'd-none d-sm-block' : ''} p-0`}
    >
      <div
        id="remote-track"
        className="remote-track remote-black"
        ref={remoteTrackRef}
      />

      <div id="local-track" className="local-track" ref={localTrackRef} />
      <div className="btn-leave-background" onClick={leave}>
        <div className="btn-leave" />
      </div>

      <h6 className="video-call-title text-center">
        {!isProviderInTheCall &&
          !isSidePanelVisible &&
          'Waiting for provider...'}
      </h6>

      {room && localTracks && (
        <>
          <div className="btn-countdown-background" ref={timerRef} />

          <div className="btnVideoCall__actions">
            <MuteAudio {...muteProps} />

            <SwitchVideoDevice
              changeVideoTrackClientPublic={changeVideoTrackClientPublic}
            />
          </div>
        </>
      )}

      {isPatientClient && (
        <FileInput saveFile={saveFile}>
          <div
            className={`btn-plus-background ${
              shrinkHorizontally ? 'btn-plus-background__left' : ''
            }`}
          >
            <Image src="/assets/plus-icon.png" alt="plus icon" />
          </div>
        </FileInput>
      )}

      <Button
        variant="secondary"
        onClick={setFullScreen}
        className={`btn-full-view-background ${
          shrinkHorizontally ? 'btn-full-view-background__left' : ''
        }`}
      >
        {fullScreen ? 'Small View' : 'Full View'}
      </Button>

      {isPatientClient && (
        <div
          className={`right-panel-arrow ${
            shrinkHorizontally ? 'right-panel-arrow__side-open' : ''
          }`}
          onClick={onRequestSidePanel}
        >
          <div />
        </div>
      )}
    </Col>
  );
};

export default VideoCallViewClientPublic;
