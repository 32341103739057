import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import { TemplateNote } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface ActionTypes {
  REQUEST_TEMPLATES: string;
  COMMIT_TEMPLATES: string;
  SUCCESS_TEMPLATES: string;
  ERROR_TEMPLATES: string;
}

interface ActionCreators {
  requestTemplates: () => MyAction<void>;
  commitTemplates: (payload: TemplateNote[]) => MyAction<TemplateNote[]>;
}

export interface templatesState extends EntityState<TemplateNote> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestTemplates: [],
  commitTemplates: ['payload'],
});

const entityAdapter = createEntityAdapter<any>();
const initialState = entityAdapter.getInitialState({});
export const TemplatesSelectors = entityAdapter.getSelectors();

function commitTemplates(
  state: templatesState,
  action: MyAction<TemplateNote[]>,
): templatesState {
  return {
    ...entityAdapter.addAll(action.payload, state),
  };
}

export const TemplatesReducer = createReducer(initialState, {
  [Types.COMMIT_TEMPLATES]: commitTemplates,
});

async function downloadTemplates({
  headers,
}: ArgsWithHeaders<any>): Promise<TemplateNote[]> {
  // TODO remove when templates will be fixed
  return [];

  const result = await fetch(`${AUTH_API_URL}/templates`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
  const temp = await result.json();
  return temp.data;
}

const requestTemplatesWatcher = createSingleEventSaga<
  object,
  TemplateNote[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_TEMPLATES,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTemplates,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadTemplates,
  beforeAction: putAuthInfoInArgs,
});

export const templatesSagas = [requestTemplatesWatcher];
