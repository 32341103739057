export enum WEBSOCKET_EVENT {
  //Common
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  //---
  // For participant
  REQUEST_DECLINED = 'request declined',
  REQUEST_APPROVED = 'request approved',
  JOIN_REQUEST = 'join request',
  //---
  //For host
  USER_LIST = 'user list',
  GET_USER_LIST = 'get user list',
  ACCEPT_REQUEST = 'accept request',
  DECLINE_REQUEST = 'decline request',
}
