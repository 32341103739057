import { put, takeEvery } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import { MyAction, createSingleEventSaga } from '@mrnkr/redux-saga-toolbox';

import { AUTH_API_URL } from '../config';
import { onRoute } from '../utils/onRoute';
import { firestore } from '../utils/Firebase';
import { PatientTransaction } from '../typings';
import { doc, getDoc } from 'firebase/firestore';
import { noOpAction } from '../utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { ArgsWithHeaders } from '../utils/typings';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import { Creators as CardsActions } from './cards.module';
import { Creators as BankAccountActions } from './bank-account.module';

export interface PaymentState {
  transactions: PatientTransaction[];
}

const initialState = {
  transactions: [],
};

interface DoctorId {
  id: string;
}

interface ActionTypes {
  REQUEST_TRANSACTIONS: string;
  COMMIT_REQUEST_TRANSACTIONS: string;
  SUCCESS_TRANSACTIONS: string;
  REQUEST_SET_DEFAULT_PAYMENT_METHOD: string;
  COMMIT_SET_DEFAULT_PAYMENT_METHOD: string;
}

interface ActionCreators {
  requestTransactions: (payload: DoctorId) => MyAction<PatientTransaction[]>;
  commitRequestTransactions: (
    payload: PatientTransaction[],
  ) => MyAction<PatientTransaction[]>;
  successTransactions: (
    payload: PatientTransaction[],
  ) => MyAction<PatientTransaction[]>;
  requestSetDefaultPaymentMethod: (payload: {
    id: string;
    method: 'card' | 'bank_account';
  }) => MyAction<{
    id: string;
    method: 'card' | 'bank_account';
  }>;
  commitSetDefaultPaymentMethod: () => MyAction<void>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestTransactions: ['payload'],
  commitRequestTransactions: ['payload'],
  successTransactions: ['payload'],
  requestSetDefaultPaymentMethod: ['payload'],
  commitSetDefaultPaymentMethod: [],
});

export const paymentsReducer = createReducer<PaymentState>(initialState, {
  [Types.COMMIT_REQUEST_TRANSACTIONS]: commitTransactions,
});

function commitTransactions(
  state: PaymentState,
  action: MyAction<PatientTransaction[]>,
): PaymentState {
  return {
    ...state,
    transactions: action.payload,
  };
}

const requestTransactionsWatcher = createSingleEventSaga<
  object,
  PatientTransaction[],
  MyAction<object>
>({
  takeEvery: onRoute('/payments'),
  loadingAction: LoadingActions.setLoading,
  beforeAction: putAuthInfoInArgs,
  action: downloadTransactions,
  commitAction: Creators.commitRequestTransactions,
  successAction: Creators.successTransactions,
  errorAction: ErrorActions.setError,
});

async function requestSetDefaultPaymentMethod({
  headers,
  ...payload
}: ArgsWithHeaders<{
  id: string;
  method: 'card' | 'bank_account';
}>): Promise<void> {
  const result = await fetch(
    `${AUTH_API_URL}/payments/set-default-payment-method`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({
        externalAccountId: payload.id,
        method: payload.method,
      }),
    },
  );

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

const requestSetDefaultPaymentMethodWatcher = createSingleEventSaga<
  { id: string },
  void,
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_SET_DEFAULT_PAYMENT_METHOD,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: Creators.commitSetDefaultPaymentMethod,
  errorAction: ErrorActions.setError,
  action: requestSetDefaultPaymentMethod,
  beforeAction: putAuthInfoInArgs,
});

async function downloadTransactions({
  headers,
  ...payload
}: ArgsWithHeaders<DoctorId>): Promise<PatientTransaction[]> {
  const result = await fetch(
    `${AUTH_API_URL}/transactions/doctor?doctorId=${payload.id}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }

  const { transactions } = await result.json();

  if (transactions) {
    await Promise.all(
      transactions.map(async (item: PatientTransaction) => {
        const firestorePatient = await requestUserFromFirestore(
          item.patient.id,
        );
        item.patient = {
          ...item.patient,
          ...firestorePatient,
        };
      }),
    );
  }

  return transactions;
}

const requestUserFromFirestore = async (uid) => {
  const res = await getDoc(doc(firestore, 'users', uid));
  return res.data();
};

function* commitSetDefaultPaymentMethodWatcher() {
  yield takeEvery(Types.COMMIT_SET_DEFAULT_PAYMENT_METHOD, function* () {
    yield put(BankAccountActions.requestBankAccounts());
    yield put(CardsActions.requestCreditCards());
  });
}

export const paymentsSagas = [
  requestTransactionsWatcher,
  requestSetDefaultPaymentMethodWatcher,
  commitSetDefaultPaymentMethodWatcher,
];
