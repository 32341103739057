import { createActions, createReducer } from 'reduxsauce';
import { createSingleEventSaga, MyAction } from '@mrnkr/redux-saga-toolbox';

import { MyState } from '../store';
import { AUTH_API_URL } from '../config';
import { noOpAction } from '../utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { ArgsWithHeaders } from '../utils/typings';
import { BankAccountInformation, Nullable } from '../typings';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface ActionTypes {
  REQUEST_BANK_ACCOUNTS: string;
  COMMIT_BANK_ACCOUNTS: string;
  REQUEST_SWITCH_BANK_ACCOUNT: string;
}

interface ActionCreators {
  requestBankAccounts: () => MyAction<void>;
  commitBankAccounts: (
    payload: BankAccountInformation,
  ) => MyAction<BankAccountInformation>;
  requestSwitchBankAccount: (payload: {
    id: string;
  }) => MyAction<{ id: string }>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestBankAccounts: [],
  commitBankAccounts: ['payload'],
  requestSwitchBankAccount: ['payload'],
});

export type BankAccountState = {
  account: Nullable<BankAccountInformation>;
};

const initialState = {
  account: null,
};

function commitBankAccounts(
  state: BankAccountState,
  action: MyAction<BankAccountInformation>,
): BankAccountState {
  return { account: action.payload ?? state.account };
}

export const bankAccountReducer = createReducer(initialState, {
  [Types.COMMIT_BANK_ACCOUNTS]: commitBankAccounts,
});

async function requestBankAccountProvider({
  headers,
}: ArgsWithHeaders<object>): Promise<BankAccountInformation[]> {
  const result = await fetch(`${AUTH_API_URL}/payments/bank-accounts`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.message);
  }

  return result.json();
}

async function requestSwitchBankAccountProvider({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string; makeDefault: boolean }>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/payments/bank-accounts`, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      bankAccount: { tokenId: payload.id },
      makeDefault: payload.makeDefault,
    }),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
}

const requestBankAccountsWatcher = createSingleEventSaga<
  void,
  BankAccountInformation,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_BANK_ACCOUNTS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitBankAccounts,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: requestBankAccountProvider,
  beforeAction: putAuthInfoInArgs,
  // eslint-disable-next-line require-yield
  *afterAction(res) {
    return res && res.data && res.data.length && res.data[0];
  },
});

const switchBankAccountWatcher = createSingleEventSaga<
  { id: string },
  void,
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_SWITCH_BANK_ACCOUNT,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: Creators.requestBankAccounts,
  errorAction: ErrorActions.setError,
  action: requestSwitchBankAccountProvider,
  beforeAction: putAuthInfoInArgs,
});

export const bankAccountSagas = [
  requestBankAccountsWatcher,
  switchBankAccountWatcher,
];

export const selectBankAccountState = (state: MyState) => state.bankAccount;
