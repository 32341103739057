import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { Route as RouteDefinition } from './routes';
import { MyState } from '../../store';
import { FC } from 'react';

interface Props {
  routes: RouteDefinition[];
  isStudent?: boolean;
}

// The routes are declared in this way './routes/routes'
const AppRoutes: FC<Props> = ({ routes, isStudent }) => (
  <Routes>
    {routes
      .filter((r) => (isStudent ? r.forStudent : true))
      .map(({ path, component: RouteComponent }, i) => (
        <Route key={i} path={path} element={<RouteComponent />} />
      ))}
  </Routes>
);

const mapStateToProps = (state: MyState) => {
  return {
    isStudent: !!(state.provider && state.provider.teacher),
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
