export const PICTURE_FORMATS = ['png', 'jpg', 'jpeg'];

export const VIDEO_FORMATS = ['mp4', 'mov', 'avi', 'mkv'];

export const DOCUMENT_FORMATS = ['pdf'];

function extractFileExtension(fileName: string): string {
  return fileName.split('.').pop().toLowerCase();
}

export const isAnImage = (fileName: string) => {
  return PICTURE_FORMATS.includes(extractFileExtension(fileName));
};

export const isADocument = (fileName: string) => {
  return DOCUMENT_FORMATS.includes(extractFileExtension(fileName));
};

export const isAVideo = (fileName: string) => {
  return VIDEO_FORMATS.includes(extractFileExtension(fileName));
};
