import { FC, useEffect } from 'react';
import { FormLabel } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { FormControlProps } from '../../utils/formControlProps';

type PropTypes = {
  type?: string;
  showFieldError?: boolean;
  validate?: any;
  label?: string;
  autocomplete?: string;
  setFieldValue: any;
  list: string;
} & Omit<FormControlProps, 'label'>;

const PLACES_TYPES = {
  address: ['street_address'],
  city: ['(cities)'],
};

const MMDTextInputAutocomplete: FC<PropTypes> = ({
  list,
  values,
  fieldKey,
  label,
  placeholder,
  type = 'text',
  maxLength,
  validate,
  showFieldError,
  setFieldValue,
}) => {
  const { placePredictions, getPlacePredictions, placesService } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    });

  useEffect(() => {
    getPlacePredictions({
      input: values[fieldKey],
      language: 'en',
      types: PLACES_TYPES[list] ?? null,
      componentRestrictions: { country: 'us' },
    });

    if (!placePredictions.length) {
      return;
    }

    placePredictions.forEach((option) => {
      const mainMatchText = option.structured_formatting.main_text;

      if (mainMatchText === values['address']) {
        setFieldValue(
          'city',
          option.structured_formatting.secondary_text.split(',')[0],
        );
        setFieldValue(
          'state',
          option.structured_formatting.secondary_text.split(',')[1].trim(),
        );
        placesService.getDetails({ placeId: option.place_id }, (placeDetails) =>
          placeDetails.address_components.map((comp) => {
            if (comp.types[0] === 'postal_code') {
              setFieldValue('zipCode', comp.long_name);
            }
          }),
        );
      }
    });
  }, [values[fieldKey]]);

  return (
    <>
      {label && (
        <FormLabel className="ml-3 form-label" htmlFor={fieldKey}>
          {label}
        </FormLabel>
      )}
      {!label && <div className="spacer-input-base" />}
      <Field
        className="w-100 form-control"
        list={list}
        type={type}
        placeholder={placeholder || label}
        name={fieldKey}
        value={values[fieldKey]}
        maxLength={maxLength}
        validate={validate}
        autoComplete="off"
      />

      {placePredictions.length !== 0 ? (
        <datalist id={list}>
          {placePredictions.map((options) => (
            <div key={options.place_id}>
              <option
                key={options.place_id}
                value={options.structured_formatting.main_text}
              >
                {options.description}
              </option>
            </div>
          ))}
        </datalist>
      ) : null}

      {showFieldError && (
        <ErrorMessage
          name={fieldKey}
          render={(msg) => (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {msg}
            </div>
          )}
        />
      )}
    </>
  );
};

export default MMDTextInputAutocomplete;
