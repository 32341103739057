import moment from 'moment';
import { Dispatch, FC, SetStateAction } from 'react';
import { Row, Col, Image, Tab, Tabs } from 'react-bootstrap';

import AppointmentRow from './AppointmentRow';
import { Booking, BookingPublic } from '../../typings';
import PublicAppointments from './public-appointments';
import ProviderPublicVisitHistory from '../visit-history-public/ProviderPublicVisitHistory';

type Props = {
  isStudent?: boolean;
  appointments: Booking[];
  setPublicBookingToEdit: Dispatch<SetStateAction<BookingPublic>>;
};

const EmptyListComponent = (
  <Row className="justify-content-center text-center">
    <Col className="justify-content-center mb-3" xs={12}>
      <h6>No Upcoming Appointments</h6>
    </Col>
    <Col xs={12}>
      <Image src="/assets/ic_phone.png" width="60" height="60" />
    </Col>
  </Row>
);

const sortAppointments = (appointments: Booking[]) =>
  appointments
    .filter((appointment) =>
      moment(appointment.event?.end?.dateTime).isAfter(moment()),
    )
    .toSorted(
      (appointmentOne, appointmentTwo) =>
        new Date(appointmentOne?.startDate).getTime() -
        new Date(appointmentTwo?.startDate).getTime(),
    );

const UpcomingList: FC<Props> = ({
  isStudent,
  appointments,
  setPublicBookingToEdit,
}) => (
  <Tabs
    justify
    defaultActiveKey="appointments"
    className="mt-5 mb-3 pr-0 custom-tabs"
  >
    <Tab eventKey="appointments" title="Appointments" className="mt-2">
      {!appointments?.length
        ? EmptyListComponent
        : sortAppointments(appointments).map((appointment) => (
            <AppointmentRow
              appointment={appointment}
              key={appointment.id}
              isStudent={isStudent}
            />
          ))}
    </Tab>

    <Tab eventKey="public-appointments" title="Public Appointments">
      <PublicAppointments setPublicBookingToEdit={setPublicBookingToEdit} />

      {!isStudent && <ProviderPublicVisitHistory />}
    </Tab>
  </Tabs>
);

export default UpcomingList;
