import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  information: {
    title: string;
    color: string;
    data?: string;
    onEdit?: () => void;
    editable?: boolean;
  };
};

const ProfileInformationList: React.FC<Props> = ({
  information: { editable = true },
  information,
}) => (
  <Col className="information-row-container mt-3" xs={12}>
    <Row>
      <Col className="doctor-title-information" xs={12}>
        <div className="dot" style={{ backgroundColor: information.color }} />{' '}
        {information.title}
      </Col>
      <Col className="doctor-information" xs={12}>
        {information.data}
      </Col>
      <Col xs={{ offset: 11 }} className="text-right">
        {editable && (
          <div onClick={information.onEdit} className="cursor-pointer">
            <FontAwesomeIcon icon={faEdit} color="#F11B42" />
          </div>
        )}
      </Col>
    </Row>
  </Col>
);

export default ProfileInformationList;
