import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.scss';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const root = createRoot(document.getElementById('root')!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
