import moment from 'moment';
import { connect } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { bindActionCreators, Dispatch } from 'redux';

import { MyState } from '../../store';
import UpcomingList from './UpcomingList';
import { useToggle } from '../../hooks/useToggle';
import { Booking, BookingPublic, Nullable } from '../../typings';
import { Creators as BookingsPublicActions } from '../../modules/bookings-public.module';
import {
  bookingsSelectors,
  Creators as BookingsActions,
} from '../../modules/bookings.module';
import {
  timeslotsSelectors,
  Creators as timeslotsActions,
} from '../../modules/timeslot.module';
import GeneratePublicVideoLinkModal from './public-appointments/component/GeneratePublicVideoLinkModal';

type Props = {
  appointments: Booking[];
  isStudent?: boolean;
  timeslots: any;
  requestBookingsPublic: () => any;
  provider: any;
  requestTimeslots: any;
} & typeof BookingsActions &
  typeof BookingsPublicActions;

const UpcomingAppointments: FC<Props> = ({
  appointments,
  requestAppointments,
  isStudent,
  commitAppointments,
  requestBookingsPublic,
}) => {
  const [isGenerateVideLinkModalOpen, toggleGenerateVideoLink] = useToggle();
  const [publicBookingToEdit, setPublicBookingToEdit] =
    useState<Nullable<BookingPublic>>(null);

  useEffect(() => {
    const now = moment.utc();
    commitAppointments([]);
    requestBookingsPublic();
    requestAppointments({
      from: now.toString(),
      to: now.add(3, 'months').toString(),
    });
  }, []);

  useEffect(() => {
    if (publicBookingToEdit && !isGenerateVideLinkModalOpen) {
      toggleGenerateVideoLink();
    }
  }, [publicBookingToEdit]);

  const onCloseModal = () => {
    if (publicBookingToEdit) {
      setPublicBookingToEdit(null);
    }

    toggleGenerateVideoLink();
  };

  return (
    <Container fluid>
      <div className="pt-2 justify-content-center">
        {!isStudent && (
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-1 text-center sub-title">
              Here you can choose your availability time and dates
            </p>

            <Button
              title="Create a public appointment"
              onClick={toggleGenerateVideoLink}
            >
              Generate Video Link
            </Button>
          </div>
        )}

        <UpcomingList
          isStudent={isStudent}
          appointments={appointments}
          setPublicBookingToEdit={setPublicBookingToEdit}
        />
      </div>

      <GeneratePublicVideoLinkModal
        onClose={onCloseModal}
        isOpen={isGenerateVideLinkModalOpen}
        publicBookingToEdit={publicBookingToEdit}
      />
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  appointments: bookingsSelectors.selectAll(state.bookings),
  timeslots: timeslotsSelectors.selectAll(state.timeslots),
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...BookingsActions,
      ...BookingsPublicActions,
      ...timeslotsActions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingAppointments);
