import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { useBoundedActions } from '../hooks/useBoundedActions';
import {
  selectPatientById,
  Creators as patientsActions,
} from '../modules/patients.module';

type Props = {
  addBorder?: boolean;
  patientId: string;
};

const PatientProfile: FC<Props> = ({ addBorder = true, patientId }) => {
  const patient = useSelector((state) => selectPatientById(state, patientId));

  const { requestPatient } = useBoundedActions(patientsActions);

  useEffect(() => {
    requestPatient({ id: patientId });
  }, [patientId]);

  if (!patient) {
    return null;
  }

  const normalizeStringHelper = (text: string | undefined) =>
    text
      ? text.replace(new RegExp(String.fromCharCode(160), 'g'), ' ')
      : undefined;

  return (
    <Container fluid>
      <Row
        className={` patient-profile-query patient-profile-layout py-3 ${
          addBorder ? 'dashboard-border' : ''
        }`}
      >
        <div className={'patient-image vcenter'}>
          <Image
            roundedCircle
            className="mx-auto"
            style={{ height: '120px', width: '120px', objectFit: 'cover' }}
            src={
              patient.avatar ||
              (!patient.relativeType || patient.relativeType === 'children'
                ? '/assets/default-child.png'
                : '/assets/default-pet.png')
            }
          />
        </div>
        <Col className={'patient-details'}>
          <Row className="w-100">
            <Col xs={12}>
              <p className="mb-1 font-weight-bold">
                {patient.relativeType === 'pets'
                  ? patient.name
                  : `${patient.firstName} ${patient.lastName}`}
              </p>
            </Col>
          </Row>
          <Row className="h-space-between-column">
            {patient.relativeType === 'pets' ? (
              <Col xs={6}>Type:</Col>
            ) : (
              <Col xs={6}>Gender:</Col>
            )}
            <Col className="text-right font-weight-bold " xs={6}>
              {patient.relativeType === 'pets'
                ? patient.type
                : patient.gender || (patient as any).sex}
            </Col>
          </Row>
          <Row className="h-space-between-column">
            <Col xs={6}>Birthday:</Col>
            <Col className="text-right font-weight-bold " xs={6}>
              {patient.birthday}
            </Col>
          </Row>
          {patient.ssn && (
            <Row className="h-space-between-column">
              <Col xs={6}>SSN:</Col>
              <Col className="text-right font-weight-bold " xs={6}>
                {patient.ssn}
              </Col>
            </Row>
          )}
          <Row className="h-space-between-column">
            <Col xs={3}>Address:</Col>
            <Col className="text-right font-weight-bold " xs={9}>
              {normalizeStringHelper(patient.address)}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PatientProfile;
