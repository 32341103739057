import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

type Props = {
  title: string;
  value: string;
};

const PublicVisitHistoryItem: FC<Props> = ({ title, value }) => (
  <>
    <Row className="padding-h-40">
      <h2 className="mb-3 w-100 font-weight-normal">{title}</h2>

      <div className="w-100 padding-vertical-10 division-border"></div>
    </Row>

    <Row className="mb-3 padding-h-40">
      <Col className="p-0">{value}</Col>
    </Row>

    <div className="w-100 padding-vertical-10 division-border-items"></div>
  </>
);

export default PublicVisitHistoryItem;
