import { Moment } from 'moment/moment';
import { useSelector } from 'react-redux';
import momentTimezone from 'moment-timezone';
import { FC, useEffect, useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';

import ModalDatePicker from './ModalDatePicker';
import ModalTimePicker from './ModalTimePicker';
import MMDModal from '../../../../components/MMDModal';
import { BookingPublic, Nullable } from '../../../../typings';
import MMDSelect from '../../../../components/forms/MMDSelect';
import MMDTextInput from '../../../../components/forms/MMDTextInput';
import { selectErrorState } from '../../../../modules/errors.module';
import MMDPhoneInput from '../../../../components/forms/MMDPhoneInput';
import { useBoundedActions } from '../../../../hooks/useBoundedActions';
import {
  validationSchema,
  PARTICIPANTS_OPTIONS,
  getInitialVideoLinkValues,
  getBookingDurationOptions,
} from './helpers';
import {
  BookingDate,
  RECURRENCE_ERROR,
  INVALID_PHONE_ERROR,
  selectBookingsPublicState,
  Creators as BookingsPublicActions,
} from '../../../../modules/bookings-public.module';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  publicBookingToEdit: Nullable<BookingPublic>;
};

type GenerateLinkFormValues = {
  email: string;
  phone: string;
  startDate: Moment;
  duration: string;
  amountOfParticipants: string;
};

const GeneratePublicVideoLinkModal: FC<Props> = ({
  isOpen,
  onClose,
  publicBookingToEdit,
}) => {
  const {
    requestBookingsPublic,
    requestEditBookingPublic,
    requestCreateBookingPublic,
    requestPublicBookingDurations,
  } = useBoundedActions(BookingsPublicActions);

  const formikRef = useRef<FormikProps<GenerateLinkFormValues> | null>(null);

  const { error } = useSelector(selectErrorState);

  const { publicBookingDurations } = useSelector(selectBookingsPublicState);

  useEffect(() => {
    if (!publicBookingDurations) {
      requestPublicBookingDurations();
    }
  }, []);

  useEffect(() => {
    if (!error?.message) {
      return;
    }

    formikRef.current?.setSubmitting(false);
    switch (error?.message) {
      case RECURRENCE_ERROR:
        formikRef.current?.setFieldError('startDate', RECURRENCE_ERROR);
        break;
      case INVALID_PHONE_ERROR: {
        onClose();
        requestBookingsPublic();
      }
    }
  }, [error?.message]);

  const publicBookingDurationsOptions = getBookingDurationOptions(
    publicBookingDurations,
    publicBookingToEdit,
  );

  const initialValues = getInitialVideoLinkValues(
    publicBookingToEdit,
    publicBookingDurations,
  );

  const handleSubmit = (values: GenerateLinkFormValues) => {
    const bookingData: BookingDate = {
      startDate: values.startDate.clone().utc().format(),
      duration: values.duration,
      numberParticipants: values.amountOfParticipants,
      timeZone: momentTimezone.tz.guess(),
      patientEmail: values.email,
    };

    if (values.phone.length) {
      bookingData.patientPhone = values.phone;
    }

    if (publicBookingToEdit) {
      requestEditBookingPublic({
        bookingData,
        onSuccess: onClose,
        eventId: publicBookingToEdit.eventId,
      });
    } else {
      requestCreateBookingPublic({
        bookingData,
        onSuccess: onClose,
      });
    }
  };

  const renderBody = () => {
    if (!publicBookingDurations) {
      return (
        <div className="text-center">
          Error fetching booking durations. Try again later
        </div>
      );
    }

    if (publicBookingDurations?.length === 0) {
      return (
        <div className="text-center">
          No available booking durations. Please, contact admin
        </div>
      );
    }

    return (
      <Formik<GenerateLinkFormValues>
        innerRef={formikRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <MMDSelect
              required
              className="mb-2"
              name="amountOfParticipants"
              options={PARTICIPANTS_OPTIONS}
              label="Number of participants"
            />

            <MMDTextInput
              required
              showFieldError
              values={values}
              fieldKey="email"
              className="mb-2"
              placeholder="E-mail"
              label="Patient email"
            />

            <MMDPhoneInput label="Patient phone" name="phone" />

            <div className="d-flex justify-content-between align-items-center my-2">
              <ModalDatePicker name="startDate" required />

              <ModalTimePicker name="startDate" required />

              <MMDSelect
                required
                name="duration"
                label="Duration"
                options={publicBookingDurationsOptions}
              />
            </div>

            <Row className="w-100 center-footer">
              <Col xs={5} className="text-center modal-footer-btn">
                <Button
                  type="submit"
                  className="w-100 primary mt-4"
                  disabled={isSubmitting || !isValid}
                >
                  <h6 className="mb-0">
                    {publicBookingToEdit ? 'Edit' : 'Generate'} link
                  </h6>
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <MMDModal
      show={isOpen}
      withoutFooter
      title="Public link"
      handleClose={onClose}
      renderBody={renderBody}
      classNameContainer="modal-container-padding"
    />
  );
};

export default GeneratePublicVideoLinkModal;
