import { MyAction } from '@mrnkr/redux-saga-toolbox';
import { FC, useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import MMDModal from '../../components/MMDModal';
import { UpdateProfilePayload } from '../../modules/provider.module';
import { Provider } from '../../typings';
import InformationRow from './InformationRow';
import TextProfileInfo from './TextProfileInfo';
import AffiliationList from './AffiliationList';
import Footer from './Footer';
import { PSYCHOLOGY } from '../../utils/constants';

type Props = {
  provider: Provider;
  requestUpdateProviderProfile: (
    payload: UpdateProfilePayload,
  ) => MyAction<UpdateProfilePayload, Error>;
  setPricePage: () => void;
};

enum ModalType {
  'education',
  'experience',
  'affiliations',
  'awards',
}

const ProfileInformationList: FC<Props> = ({
  provider,
  requestUpdateProviderProfile,
  setPricePage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType | undefined>();
  const [education, setEducation] = useState<string | undefined>();
  const [experience, setExperience] = useState<string | undefined>();
  const [awards, setAwards] = useState<string | undefined>();
  useEffect(() => {
    if (provider && provider.profile) {
      setEducation(provider.profile.education);
      setExperience(provider.profile.experience);
      setAwards(provider.profile.awards);
    }
  }, [provider, showModal]);
  if (!provider) return null;

  const informationRows = [
    {
      title: 'EDUCATION & QUALIFICATION',
      titleModal: 'Education & Qualification',
      color: 'orange',
      data: (provider.profile && provider.profile.education) || '',
      onEdit: () => {
        setModalType(ModalType.education);
        setShowModal(true);
      },
    },
    {
      title: 'EXPERIENCE',
      titleModal: 'Experience',
      color: 'blue',
      data: (provider.profile && provider.profile.experience) || '',
      onEdit: () => {
        setModalType(ModalType.experience);
        setShowModal(true);
      },
    },
    {
      title: 'AFFILIATION',
      titleModal: 'Select Affiliation',
      color: 'yellow',
      data: `${
        provider.profile
          ? provider.profile.affiliations.map((o) => ` ${o.name}`)
          : ''
      }`,
      onEdit: () => {
        setModalType(ModalType.affiliations);
        setShowModal(true);
      },
    },
    {
      title: 'CERTIFICATION & AWARDS',
      titleModal: 'Certification & Awards',
      color: 'pink',
      data: (provider.profile && provider.profile.awards) || '',
      onEdit: () => {
        setModalType(ModalType.awards);
        setShowModal(true);
      },
    },
    {
      title: 'SPECIALITY',
      titleModal: 'Speciality',
      color: 'green',
      data:
        provider.profile && provider.profile.specialities.length > 0
          ? provider.profile.specialities[0].name
          : '',
      editable: false,
    },
    {
      title: 'PRICE',
      titleModal: 'Price',
      color: 'red',
      data: `One time price: $${provider.oneTimePrice}${
        provider.isPsychiatrist && provider.firstTimePrice
          ? `,
      ${
        provider.profile &&
        provider.profile.specialities.length > 0 &&
        provider.profile.specialities[0].doctorType === PSYCHOLOGY
          ? 'Extended time price'
          : 'First time price'
      }: $${provider.firstTimePrice}`
          : ''
      }
      , Monthly price: $${provider.monthlyPrice}`,
      onEdit: () => {
        setPricePage();
      },
    },
  ];

  return (
    <Row className="w-100">
      <Col xs={12} className="mb-1 mt-1">
        <h2 className="mb-2 sub-title col-12">Profile information</h2>
      </Col>
      <Col xs={12}>
        <Row>
          {informationRows.map((rowInformation) => (
            <InformationRow
              key={rowInformation.title}
              information={rowInformation}
            />
          ))}
        </Row>
      </Col>
      <Footer />
      <MMDModal
        show={showModal}
        title={
          modalType !== undefined ? informationRows[modalType].titleModal : ''
        }
        classNameContainer="modal-container-padding modal-container-radius"
        handleClose={() => setShowModal(false)}
        renderBody={() => {
          switch (modalType) {
            case ModalType.education:
              return (
                <TextProfileInfo
                  text={education || ''}
                  setText={setEducation}
                />
              );
            case ModalType.experience:
              return (
                <TextProfileInfo
                  text={experience || ''}
                  setText={setExperience}
                />
              );
            case ModalType.awards:
              return (
                <TextProfileInfo text={awards || ''} setText={setAwards} />
              );
            case ModalType.affiliations:
              return (
                <AffiliationList
                  providerAffiliations={provider.profile.affiliations}
                  addItem={(item) => {
                    requestUpdateProviderProfile({
                      diff: { affiliations: { name: item } },
                    });
                  }}
                  placeHolder={'Enter a new affiliation'}
                  error={'That affiliation already exists'}
                />
              );
            default:
              return null;
          }
        }}
        renderFooter={() => (
          <Row className="w-100 center-footer">
            <Col xs={5} className="text-center modal-footer-btn">
              <Button
                className="w-100 btn-secondary height-30"
                variant="outline-secondary"
                onClick={() => {
                  setModalType(undefined);
                  setShowModal(false);
                }}
              >
                <h6>Cancel</h6>
              </Button>
            </Col>
            <Col xs={5} className="text-center modal-footer-btn">
              <Button
                className="w-100 primary height-30"
                onClick={() => {
                  setShowModal(false);
                  if (modalType === 0) {
                    requestUpdateProviderProfile({ diff: { education } });
                    setModalType(undefined);
                  }
                  if (modalType === 1) {
                    requestUpdateProviderProfile({ diff: { experience } });
                    setModalType(undefined);
                  }
                  if (modalType === 2) {
                    setModalType(undefined);
                  }
                  if (modalType === 3) {
                    requestUpdateProviderProfile({ diff: { awards } });
                    setModalType(undefined);
                  }
                }}
              >
                <h6>Done </h6>
              </Button>
            </Col>
          </Row>
        )}
      />
    </Row>
  );
};

export default ProfileInformationList;
