import { useSelector } from 'react-redux';
import { Col, Image } from 'react-bootstrap';
import { Dispatch, FC, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { BookingPublic } from '../../../typings';
import MMDAccordion from '../../../components/MMDAccordion';
import PublicAppointmentsList from './PublicAppointmentsList';
import { selectBookingsPublicState } from '../../../modules/bookings-public.module';

type Props = {
  setPublicBookingToEdit: Dispatch<SetStateAction<BookingPublic>>;
};

const PublicAppointments: FC<Props> = ({ setPublicBookingToEdit }) => {
  const { bookings } = useSelector(selectBookingsPublicState);

  if (!bookings?.length) {
    return (
      <div className="justify-content-center text-center mt-5 mb-5">
        <Col
          className="justify-content-center no-public-bookings__title"
          xs={12}
        >
          <h6>No Upcoming Appointments</h6>
        </Col>
        <Col xs={12}>
          <Image src="/assets/calendar.png" width="60" height="60" />
        </Col>
      </div>
    );
  }

  const accordionData = [
    {
      title: `${bookings.length} Appointment${
        bookings.length === 1 ? '' : 's'
      }`,
      type: 'public-appointments',
    },
  ];

  return (
    <MMDAccordion
      defaultKey={0}
      data={accordionData}
      style="w-100 public-bookings"
      renderToggle={({ title }, idx, _, expandedIdx) => (
        <>
          {title}

          <FontAwesomeIcon
            className="float-right"
            style={{ color: '#C5C5C5' }}
            icon={idx === expandedIdx ? faChevronDown : faChevronRight}
          />
        </>
      )}
      renderCollapse={({ type }) => {
        switch (type) {
          case 'public-appointments':
            return (
              <PublicAppointmentsList
                publicAppointments={bookings}
                setPublicBookingToEdit={setPublicBookingToEdit}
              />
            );
          default:
            return null;
        }
      }}
    />
  );
};

export default PublicAppointments;
