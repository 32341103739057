import moment from 'moment';
import Switch from 'react-switch';
import { connect } from 'react-redux';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import momentTimezone from 'moment-timezone';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { PSYCHOLOGY } from '../../utils/constants';
import { Timeslot, Provider, Speciality } from '../../typings';
import { Creators as timeslotsActions } from '../../modules/timeslot.module';

type Props = {
  selectedTimeslot: Timeslot;
  timeslot: Timeslot;
  provider: Provider;
  actionType: any;
  selectedDate: Date;
  requestEndEdition: any;
} & typeof timeslotsActions;

const format = 'h:mm A';

const initialDays = [
  { day: 'SU', selected: false },
  { day: 'MO', selected: false },
  { day: 'TU', selected: false },
  { day: 'WE', selected: false },
  { day: 'TH', selected: false },
  { day: 'FR', selected: false },
  { day: 'SA', selected: false },
];

const TimeslotDetail: FC<Props> = ({
  selectedTimeslot,
  timeslot,
  provider,
  actionType,
  selectedDate,
  requestTimeslot,
  requestCreateTimeslot,
  requestUpdateTimeslot,
  requestDeleteTimeslot,
  //requestAllTimeslots,
  requestEndEdition,
}) => {
  const daySelectedInitial = moment(selectedDate).format('dd').toUpperCase();
  const [isRepeat, setIsRepeat] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [isFirstTime, setFirstTime] = useState(false);
  const [days, setDays] = useState(initialDays);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());
  const [isWeekly, setIsWeekly] = useState(false);
  const [allDisabled, setAllDisabled] = useState(false);
  const [monthlyDisabled, setIsMonthlyDisabled] = useState(false);
  const [disabledMinutes, setDisabledMinutes] = useState([]);
  const [timeslotDuration, setTimeslotDuration] = useState(0);

  const bookingDuration = useMemo<number>(() => {
    const specialty: Speciality | object = provider.profile
      ? provider.profile.specialities.length
        ? provider.profile.specialities[0]
        : {}
      : {};
    return isFirstTime
      ? (specialty as Speciality).firstTimeLength
      : (specialty as Speciality).timeSlotLength;
  }, [provider, isFirstTime]);

  const doctorType = useMemo<string>(() => {
    const specialty: Speciality | object = provider.profile
      ? provider.profile.specialities.length
        ? provider.profile.specialities[0]
        : {}
      : {};
    return (specialty as Speciality).doctorType;
  }, [provider]);

  const setupTimeslot = useCallback(() => {
    if (timeslot) {
      if (actionType === 'all') {
        setStartTime(moment(timeslot.start.dateTime));
        setEndTime(moment(timeslot.end.dateTime));
        setIsRepeat(true);
        const weeklyTimeslot =
          !!timeslot.recurrence && timeslot.recurrence[0].includes('WEEKLY');
        setIsWeekly(weeklyTimeslot);
        setIsMonthly(
          !!timeslot.recurrence && timeslot.recurrence[0].includes('MONTHLY'),
        );
        if (weeklyTimeslot) {
          const daysSelected =
            !!timeslot.recurrence &&
            timeslot.recurrence[0].split('BYDAY=')[1].split(',');
          setDays(
            days.map(({ day }) => ({
              day,
              selected: daysSelected.includes(day),
            })),
          );
          setIsMonthlyDisabled(true);
        }
      }
      if (actionType === 'only') {
        setStartTime(moment(timeslot.start.dateTime));
        setEndTime(moment(timeslot.end.dateTime));
        setAllDisabled(true);
      }
    }
  }, [actionType, days, timeslot]);

  const onChangeStart = useCallback(
    (value) => {
      const speciality: Speciality | object = provider.profile
        ? provider.profile.specialities.length
          ? provider.profile.specialities[0]
          : {}
        : {};

      const endTime = isFirstTime
        ? (speciality as Speciality).firstTimeLength
        : (speciality as Speciality).timeSlotLength;
      setTimeslotDuration(endTime);

      const momentStartTime = moment(value, 'h:mm A');
      setStartTime(momentStartTime);

      setEndTime(momentStartTime.clone().add(endTime, 'minutes'));
    },
    [provider, setStartTime, setEndTime, isFirstTime],
  );

  useEffect(() => {
    onChangeEnd();
  }, [startTime, endTime, timeslotDuration]);

  const onChangeEnd = useCallback(
    (value = null) => {
      const newEndTime = value ? moment(value, 'h:mm A') : endTime;
      setEndTime(newEndTime);

      const timestampExactEndHour = parseInt(
        newEndTime.clone().endOf('hour').format('X'),
      );

      const totalAvailableMinutes = {};
      const timeToAdd = startTime.clone();
      let hourCounter = 0;

      while (hourCounter < timestampExactEndHour && timeslotDuration > 0) {
        timeToAdd.add(timeslotDuration, 'minutes');

        if (totalAvailableMinutes[timeToAdd.hours()]) {
          totalAvailableMinutes[timeToAdd.hours()].push(timeToAdd.minutes());
        } else {
          totalAvailableMinutes[timeToAdd.hours()] = [timeToAdd.minutes()];
        }
        hourCounter = parseInt(timeToAdd.format('X')); // new hour counter
      }

      if (
        Object.keys(totalAvailableMinutes).length > 0 &&
        totalAvailableMinutes[newEndTime.hours()]
      ) {
        generateDisabledMinutes(totalAvailableMinutes[newEndTime.hours()]);
      }
    },
    [endTime, startTime, timeslotDuration],
  );

  const generateDisabledMinutes = useCallback((availableMinutes) => {
    const totalMinutes = [];

    for (let i = 0; i <= 60; i++) {
      totalMinutes.push(i);
    }

    setDisabledMinutes(
      totalMinutes.filter((minute) => !availableMinutes.includes(minute)),
    );
  }, []);

  const getDisabledHours = useCallback(() => {
    const totalHours = [];
    for (let i = 0; i <= 23; i++) {
      totalHours.push(i);
    }
    return totalHours.filter((hour) => hour < startTime.hours());
  }, [startTime]);

  useEffect(() => {
    onChangeStart(moment());
    switch (actionType) {
      case 'only':
        requestTimeslot({ id: selectedTimeslot.id });
        break;
      case 'all':
        requestTimeslot({ id: selectedTimeslot.recurringEventId });
        break;
    }
    setupTimeslot();
  }, [timeslot?.id, actionType, selectedTimeslot]);

  const isRepeatHandler = useCallback(
    (value) => {
      setIsRepeat(!isRepeat);
      setIsWeekly(!isWeekly);
      if (value) {
        const newDay = days.map(({ day }) => ({
          day,
          selected: day === daySelectedInitial ? value : !value,
        }));
        setDays(newDay);
      } else {
        setDays(initialDays);
        setIsWeekly(value);
        setIsMonthly(value);
      }
    },
    [daySelectedInitial, days, isRepeat, isWeekly],
  );

  const isMonthlyHandler = useCallback(
    (value) => {
      setIsMonthly(!isMonthly);
      if (value) {
        setDays(initialDays);
        setIsRepeat(value);
        setIsWeekly(!value);
      } else {
        setIsRepeat(value);
      }
    },
    [isMonthly],
  );

  const isFirstTimeHandler = useCallback(
    (value) => {
      setFirstTime(value);

      const speciality: Speciality | object = provider.profile
        ? provider.profile.specialities.length
          ? provider.profile.specialities[0]
          : {}
        : {};

      const endTime = value
        ? (speciality as Speciality).firstTimeLength
        : (speciality as Speciality).timeSlotLength;
      setEndTime(moment(startTime, 'h:mm A').add(endTime, 'minutes'));
      setTimeslotDuration(endTime);
    },
    [setFirstTime, startTime, provider, setEndTime],
  );

  const selectDay = useCallback(
    (index) => {
      if (isRepeat && isWeekly) {
        const newDays = [...days];
        newDays[index].selected = !newDays[index].selected;
        setDays(newDays);
      }
    },
    [days, isRepeat, isWeekly],
  );

  const createTimeslotItem = useCallback(
    (selectedStartDate, selectedEndDate) => {
      const week = [];
      const newTimeslot: any = {
        startDate: selectedStartDate.utc().format(),
        endDate: selectedEndDate.utc().format(),
        type: 'VIDEO',
        isFirstTimeConsultationSlot: isFirstTime,
        timeZone: momentTimezone.tz.guess(),
        bookingDuration,
      };

      if (isRepeat) newTimeslot.freq = isWeekly ? 'WEEKLY' : 'MONTHLY';

      if (isWeekly) {
        days.forEach((day) => day.selected && week.push(day.day));
        newTimeslot.week = week;
      }
      return newTimeslot;
    },
    [days, isFirstTime, isRepeat, isWeekly, bookingDuration],
  );

  const sendTimeslotHandler = useCallback(async () => {
    const selectedStartDate = moment(selectedDate).set({
      hour: startTime.get('hour'),
      minutes: startTime.get('minute'),
    });
    const selectedEndDate = moment(selectedStartDate).add(
      bookingDuration,
      'minutes',
    );

    if (actionType === 'all') {
      const newTimeslot: any = createTimeslotItem(
        selectedStartDate,
        selectedEndDate,
      );
      newTimeslot.id = selectedTimeslot.id;
      newTimeslot.recurringEventId = selectedTimeslot.recurringEventId;

      requestUpdateTimeslot(newTimeslot);
    } else if (actionType === 'only') {
      const newTimeslot: any = createTimeslotItem(
        selectedStartDate,
        selectedEndDate,
      );
      newTimeslot.id = selectedTimeslot.id;

      requestUpdateTimeslot(newTimeslot);
    } else {
      const numberOfSlots = Math.floor(
        endTime.diff(startTime, 'minutes') / timeslotDuration,
      );

      const newTimeslot: any = createTimeslotItem(
        selectedStartDate,
        numberOfSlots > 1
          ? moment(selectedDate).set({
              hour: endTime.get('hour'),
              minutes: endTime.get('minute'),
            })
          : selectedEndDate,
      );

      requestCreateTimeslot(newTimeslot);
    }
    requestEndEdition();
  }, [
    actionType,
    days,
    endTime,
    isRepeat,
    isWeekly,
    isFirstTime,
    selectedDate,
    selectedTimeslot,
    startTime,
  ]);

  const deleteTimeslotHandler = useCallback(() => {
    if (actionType === 'all') {
      requestDeleteTimeslot({ id: selectedTimeslot.recurringEventId });
    } else {
      requestDeleteTimeslot({ id: timeslot.id });
    }
    requestEndEdition();
  }, [requestDeleteTimeslot, timeslot, selectedTimeslot, actionType]);

  return (
    <Container fluid>
      <Row className="pt-2">
        <Col
          className="justify-content-center text-center vcenter mb-4"
          xs={12}
        >
          <small>Video Consultation Time</small>
        </Col>
        <Col xs={2} className="align-self-center">
          Start:
        </Col>
        <Col xs={4}>
          <TimePicker
            showSecond={false}
            onChange={onChangeStart}
            format={format}
            use12Hours
            inputReadOnly
            value={startTime}
            allowEmpty={false}
          />
        </Col>
        <Col xs={2} className="align-self-center">
          End:
        </Col>
        <Col xs={4}>
          <TimePicker
            showSecond={false}
            format={format}
            use12Hours
            inputReadOnly
            value={endTime}
            disabledHours={getDisabledHours}
            disabledMinutes={() => disabledMinutes}
            hideDisabledOptions
            allowEmpty={false}
            onChange={onChangeEnd}
          />
        </Col>
        {!allDisabled && (
          <>
            <Col
              xs={12}
              style={{
                borderTop: '1px solid black',
                marginTop: 20,
                marginBottom: 20,
              }}
            />
            <Col xs={8}>Repeat:</Col>
            <Col xs={4}>
              <Switch
                onChange={isRepeatHandler}
                onColor="#F81942"
                checked={isRepeat}
              />
            </Col>
            <Col xs={12}>
              <div className="cell-day-button">
                {days.map((item, index) => (
                  <Col
                    xs={1}
                    key={index}
                    className="day-view"
                    style={{
                      cursor: 'pointer',
                      backgroundColor: item.selected ? '#F81942' : '#E9E9E9',
                    }}
                    onClick={() => selectDay(index)}
                  >
                    <span
                      style={{
                        color: item.selected ? '#ffffff' : 'black',
                        fontSize: 12,
                      }}
                    >
                      {item.day[0]}
                    </span>
                  </Col>
                ))}
              </div>
            </Col>
            {!(allDisabled || monthlyDisabled) && (
              <>
                <Col xs={8}>Monthly:</Col>
                <Col xs={4}>
                  <Switch
                    onChange={isMonthlyHandler}
                    disabled={monthlyDisabled}
                    onColor="#F81942"
                    checked={isMonthly}
                  />
                </Col>
              </>
            )}
            {/* <>
       <Col xs={8}>Private:</Col>
       <Col xs={4}>
        <Switch onChange={() => console.log("Isprivate", isPrivate)} disabled={privateDisabled} onColor="#F81942" checked={isPrivate} />
       </Col>
      </> */}

            {!allDisabled && provider.isPsychiatrist && (
              <>
                <Col xs={8}>
                  {doctorType === PSYCHOLOGY
                    ? 'Use for extended time consultation:'
                    : 'Use for first time consultation:'}
                </Col>
                <Col xs={4}>
                  <Switch
                    onChange={isFirstTimeHandler}
                    disabled={!provider.isPsychiatrist}
                    onColor="#F81942"
                    checked={isFirstTime}
                  />
                </Col>
              </>
            )}
          </>
        )}
        <Col xs={12} className="mt-4">
          <Button
            variant="primary"
            className="w-100"
            onClick={sendTimeslotHandler}
          >
            Done
          </Button>
          <Button
            variant="outline-primary"
            className="w-100 mt-2"
            onClick={requestEndEdition}
          >
            Cancel
          </Button>
        </Col>
        {timeslot?.id && (
          <Col xs={12} className="mt-4">
            <Button
              variant="primary"
              className="w-100"
              onClick={deleteTimeslotHandler}
            >
              Delete timeslot
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  timeslot: state.timeslots.timeslot,
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...timeslotsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimeslotDetail);
