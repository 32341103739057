import moment from 'moment';
import { FC } from 'react';
import { Row, Image } from 'react-bootstrap';
import { getFirebaseImage } from '../../utils/Firebase';
import { truncateText } from '../../utils/helper';
import { TRUNCATE_FILE_NAME } from '../../utils/constants';

type Props = {
  files: any[];
  doctorId?: string;
  patientId?: string;
};

const DocumentsDetails: FC<Props> = ({ files, doctorId, patientId }) => {
  if (!files) return null;

  const getPath = (doc: any) =>
    doctorId
      ? `bookings/${doc.bookingId}/${doctorId}/${patientId}/${doc.fileName}`
      : `bucket/${patientId}/${doc.fileName}`;

  const getExt = (doc: any) => doc.fileName.split('.').pop() || 'jpg';

  const renderContent = () => {
    if (files.length === 0) {
      return (
        <div className="vcenter-column">
          <p>No documents</p>
          {/* <FontAwesomeIcon icon={faImage} className={'bound-image-medium'} style={{ color: "#F11B42" }} /> */}
          <p>Here you will find: lab tests, surgeries, x-rays, ct scans, etc</p>
        </div>
      );
    }

    return files.map((doc) => (
      <a
        key={doc.id}
        className="margin-10 vcenter-column"
        style={{ cursor: 'pointer' }}
        target="_blank"
        onClick={() => getFirebaseImage(getPath(doc)).then(window.open)}
      >
        <Image
          style={{ color: '#F11B42' }}
          src="/assets/file.png"
          className="mr-3"
          width="80"
          height="80"
        />
        <small>
          {truncateText(doc.fileName, TRUNCATE_FILE_NAME).concat(getExt(doc))}
        </small>

        {doc.date && <small>{moment(doc.date).format('MM-DD-YYYY')}</small>}
      </a>
    ));
  };

  return (
    <Row className="margin-left-15 w-100 padding-10 ">{renderContent()}</Row>
  );
};

export default DocumentsDetails;
