import React from 'react';
import moment from 'moment';

import FileMsgBubble from './message-bubble/FileMsgBubble';
import ImgMsgBubble from './message-bubble/ImgMsgBubble';
import { isFile, isImage } from './message-bubble/helpers';

type Props = {
  body: string;
  className?: string;
  received: boolean;
  timestamp: string;
};

const MessageBubble: React.FC<Props> = ({
  body,
  className,
  received,
  timestamp,
}) => {
  if (isFile(body)) {
    return (
      <FileMsgBubble
        body={body}
        received={received}
        timestamp={timestamp}
        className={className}
      />
    );
  }

  if (isImage(body)) {
    return (
      <ImgMsgBubble
        body={body}
        received={received}
        timestamp={timestamp}
        className={className}
      />
    );
  }

  return (
    <div className="msg-row">
      {!received && <div className="placeholder"></div>}

      <div
        className={`msg-bubble ${received ? 'received' : 'sent'} ${className}`}
      >
        <p className="mb-0">{body}</p>

        <div className="msg-details">
          <span>{moment(timestamp).fromNow()}</span>
        </div>
      </div>

      {received ? <div className="placeholder"></div> : null}
    </div>
  );
};

export default MessageBubble;
