import React from 'react';
import moment from 'moment';

type Props = {
  startDate: string;
};

const AwaitingPatientMessage: React.FC<Props> = ({ startDate }) => (
  <div className="video-center-message">
    {startDate
      ? `Your meeting is scheduled for ${moment(startDate).format(
          'MMMM DD h:mm a',
        )}`
      : 'Waiting for the Patient'}
  </div>
);

export default AwaitingPatientMessage;
