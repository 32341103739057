import { Accordion, Card } from 'react-bootstrap';
import { FC, ReactElement, ReactNode, useState } from 'react';

type Props = {
  data: any[];
  renderToggle: (
    item: any,
    index: number,
    data: any[],
    expandedIdx: number,
  ) => ReactNode;
  renderCollapse: (
    item: any,
    index: number,
    data: any[],
  ) => ReactElement & ReactNode;
  style?: string;
  styleContainer?: string;
  styleToggle?: string;
  styleCollapse?: string;
  defaultKey?: number;
};

const MMDAccordion: FC<Props> = ({
  data,
  renderToggle,
  renderCollapse,
  style = '',
  styleContainer = '',
  styleToggle = '',
  styleCollapse = '',
  defaultKey = 0,
}) => {
  const [expandedIdx, setExpandedIdx] = useState(defaultKey);

  const expandedHandler = (newIdx: number) =>
    setExpandedIdx(newIdx === expandedIdx ? -1 : newIdx);

  if (data.length === 0) return null;

  return (
    <Accordion defaultActiveKey={defaultKey.toString()} className={style}>
      {data.map((item, index, data) => (
        <Accordion.Item
          key={index}
          eventKey={index.toString()}
          className={styleContainer}
        >
          <Accordion.Button
            className={`w-100 ${styleToggle}`}
            as={Card.Header}
            variant="link"
            onClick={() => expandedHandler(index)}
          >
            {renderToggle(item, index, data, expandedIdx)}
          </Accordion.Button>
          <Accordion.Collapse
            eventKey={index.toString()}
            className={`w-100 ${styleCollapse}`}
          >
            {renderCollapse(item, index, data)}
          </Accordion.Collapse>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default MMDAccordion;
