import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FILE_MSG_PREFIX, IMAGE_MSG_PREFIX } from '../message-bubble/constants';
import { storage } from '../../../utils/Firebase';
import { ref, uploadBytes } from 'firebase/storage';

type Props = {
  chatroomId: string;
  patientId: string;
  doctorId: string;
  sendMessage?: (body: string) => void;
};

const FileInput: React.FC<Props> = ({
  chatroomId,
  patientId,
  doctorId,
  sendMessage = () => {},
}) => {
  const uploadFile = React.useCallback(
    (e: React.FormEvent) => {
      const target = e.target as HTMLInputElement;

      if (!target.files.length) {
        return;
      }

      const file = target.files.item(target.files.length - 1);

      let extension: string = null;
      let prefix: string = null;
      if (file.name.endsWith('.pdf')) {
        extension = '.pdf';
        prefix = FILE_MSG_PREFIX;
      } else {
        extension = '.jpg';
        prefix = IMAGE_MSG_PREFIX;
      }

      const reference = `chats/${chatroomId}/${doctorId}/${patientId}/${uuidv4()}${extension}`;
      uploadBytes(ref(storage, reference), file).then(() =>
        sendMessage(`${prefix}${file.name}${prefix}${reference}`),
      );
    },
    [chatroomId, doctorId, patientId, sendMessage],
  );

  return (
    <>
      <label style={{ display: 'inline-flex' }} htmlFor="file">
        <img className="send-btn" src="/assets/file.png" alt="File uploader" />
      </label>

      <input
        className="d-none"
        type="file"
        id="file"
        name="file"
        accept="application/pdf, image/*"
        onChange={uploadFile}
      />
    </>
  );
};

export default FileInput;
