import { useSelector } from 'react-redux';
import { FC, useCallback, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { MyState } from '../../../store';
import NotesPublic from './side-panel-public/NotesPublic';
import PatientProfile from '../../../components/PatientProfile';
import { useBoundedActions } from '../../../hooks/useBoundedActions';
import AttachmentsPublic from './side-panel-public/AttachmentsPublic';
import PatientInfoAccordion from '../../myappts/PatientInfoAccordion';
import { FileReference, BookingPublic, Nullable } from '../../../typings';
import VisitHistoryDetails from '../../visit-history/VisitHistoryDetails';
import { Creators as visitHistoryActions } from '../../../modules/visitHistory.module';
import VisitHistoryDetailsPublic from '../../visit-history-public/VisitHistoryDetailsPublic';
import { PatientVisitHistory } from '../../myappts/patient-visit-history/BasePatientVisitHistoryTable';
import {
  selectPublicVisitHistoryState,
  Creators as publicVisitHistoryActions,
} from '../../../modules/visit-history-public.module';

type Props = {
  booking: BookingPublic;
  patientId?: string;
  doctorId?: string;
  visible: boolean;
  documents: FileReference[];
  pictures: FileReference[];
  notes: Nullable<string>;
  files: any;
  sendNotePublic: (note: string) => void;
  onRequestClose: () => void;
  withNotes?: boolean;
  forPatient?: boolean;
};

const SidePanelPublic: FC<Props> = ({
  sendNotePublic,
  withNotes,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState('attachments');
  const [step, setStep] = useState(1);

  const {
    requestVisitHistory,
    requestSinglePublicVisitHistory,
    commitSinglePublicVisitHistory,
  } = useBoundedActions({
    ...visitHistoryActions,
    ...publicVisitHistoryActions,
  });

  const { singlePublicVisitHistory: publicBooking } = useSelector(
    selectPublicVisitHistoryState,
  );

  const visitHistory = useSelector((state: MyState) => state.visitHistory);
  const provider = useSelector((state: MyState) => state.provider);

  const isStudent = !!provider?.teacher;

  const prescriptionPreviousStep = useCallback(() => {
    if (step === 1) {
      setCurrentPage('attachments');
    } else {
      setStep(step - 1);
    }
  }, [setCurrentPage, step, setStep]);

  const gotoAttachments = () => setCurrentPage('attachments');

  const gotoNotes = () => setCurrentPage('notes');

  const gotoEPrescription = () => setCurrentPage('eprescribing');

  const isPatientExist = !!props.booking.patient;

  const patientId = props.booking?.patient?.id;

  const gotoPatientChart = useCallback(
    () => setCurrentPage('patientChart'),
    [setCurrentPage],
  );

  const closeVisitHistoryItem = () => {
    gotoPatientChart();
  };

  const getOnVisitHistoryClick =
    () =>
    ({ id }: PatientVisitHistory) => {
      requestVisitHistory({
        patientId,
        visitId: id,
      });

      setCurrentPage('visitHistoryItem');
    };

  const getOnVisitHistoryPublicClick =
    () =>
    ({ eventId }: PatientVisitHistory) => {
      commitSinglePublicVisitHistory(null);
      requestSinglePublicVisitHistory({ eventId });
      setCurrentPage('publicVisitHistoryItem');
    };

  switch (currentPage) {
    case 'attachments':
      return (
        <>
          <div className="close-panel-arrow" onClick={props.onRequestClose}>
            <img src="/assets/down-arrow-light.png" />
          </div>
          <AttachmentsPublic
            {...props}
            withNotes={withNotes}
            onRequestNotes={gotoNotes}
            isPatientExist={isPatientExist}
            gotoPatientChart={gotoPatientChart}
            onRequestEPrescription={gotoEPrescription}
          />
        </>
      );
    case 'patientChart':
      return (
        <Col className="gray-background">
          <Row>
            <img
              width="32"
              height="32"
              alt="Previous step"
              className="mx-2 my-3"
              onClick={gotoAttachments}
              src="/assets/left-arrow.png"
              style={{ objectFit: 'contain', cursor: 'pointer', width: '64px' }}
            />
          </Row>

          <div className="notes-videochat-wrapper">
            <PatientProfile patientId={patientId} />

            <PatientInfoAccordion
              isPatientChart
              patientId={patientId}
              onVisitHistoryClick={getOnVisitHistoryClick()}
              onVisitHistoryPublicClick={getOnVisitHistoryPublicClick()}
            />
          </div>
        </Col>
      );
    case 'visitHistoryItem':
      return (
        <Col className="gray-background">
          <Row>
            <img
              width="32"
              height="32"
              alt="Previous step"
              className="mx-2 my-3"
              onClick={closeVisitHistoryItem}
              src="/assets/left-arrow.png"
              style={{ objectFit: 'contain', cursor: 'pointer', width: '64px' }}
            />
          </Row>

          <div className="notes-videochat-wrapper">
            <VisitHistoryDetails
              isStudent={isStudent}
              visitHistory={visitHistory}
            />
          </div>
        </Col>
      );
    case 'publicVisitHistoryItem':
      return (
        <Col className="gray-background">
          <Row>
            <img
              width="32"
              height="32"
              alt="Previous step"
              className="mx-2 my-3"
              onClick={closeVisitHistoryItem}
              src="/assets/left-arrow.png"
              style={{ objectFit: 'contain', cursor: 'pointer', width: '64px' }}
            />
          </Row>

          <div className="notes-videochat-wrapper">
            <Container>
              {publicBooking?.id && (
                <VisitHistoryDetailsPublic {...publicBooking} />
              )}
            </Container>
          </div>
        </Col>
      );
    case 'notes':
      return (
        <>
          <div className="close-panel-arrow" onClick={props.onRequestClose}>
            <img src="/assets/down-arrow-light.png" />
          </div>
          <NotesPublic
            {...props}
            commitNotePublic={sendNotePublic}
            onRequestClose={gotoAttachments}
          />
        </>
      );
    case 'eprescribing':
      return (
        <>
          <div className="close-panel-arrow">
            <img
              src="/assets/down-arrow-light.png"
              onClick={props.onRequestClose}
              alt="arrow"
            />
          </div>
          <Col className="gray-background">
            <Row>
              <img
                className="mx-2 my-3"
                style={{ objectFit: 'contain', cursor: 'pointer' }}
                height="32"
                width="32"
                src="/assets/left-arrow.png"
                alt="Previous step"
                onClick={prescriptionPreviousStep}
              />
            </Row>
          </Col>
        </>
      );
  }
};

export default SidePanelPublic;
