import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Timeslot } from '../../typings';
import { FC } from 'react';

type Props = {
  isTimeslot?: boolean;
  timeslot?: Timeslot;
  text?: string;
  isPlus?: boolean;
  onClick?: any;
  isEdit?: boolean;
};

const formatTime = (time) => moment(time).format('h:mm A');

const TimeslotButton: FC<Props> = ({
  timeslot,
  isTimeslot = false,
  isPlus = false,
  isEdit = false,
  text,
  onClick,
}) => (
  <Col xs={12} className="appointment-item pt-2 pb-2">
    <Row style={{ cursor: 'pointer' }} onClick={() => onClick(timeslot)}>
      <Col xs={10}>
        {isTimeslot && timeslot ? (
          <span style={{ fontSize: 14 }}>
            {`${formatTime(timeslot.start.dateTime)} - ${formatTime(
              timeslot.end.dateTime,
            )}  `}
            {timeslot.recurringEventId && (
              <span style={{ color: '#DB386C' }}>REPEAT</span>
            )}
          </span>
        ) : (
          <strong>{text}</strong>
        )}
      </Col>
      <Col xs={2}>
        {isPlus && <span className="is-plus">+</span>}
        {isEdit && <span className="is-edit">✎</span>}
      </Col>
    </Row>
  </Col>
);

export default TimeslotButton;
