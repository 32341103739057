import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { useBoundedActions } from '../hooks/useBoundedActions';
import {
  selectPatientConsent,
  Creators as PatientConsentActions,
} from '../modules/patient-consent.module';

const PatientConsent = () => {
  const patientConsent = useSelector(selectPatientConsent);

  const { requestPatientConsent } = useBoundedActions(PatientConsentActions);

  useEffect(() => {
    requestPatientConsent();
  }, []);

  return (
    <Row className="justify-content-center">
      <Col xs={12} className="text-center">
        <img
          width="350"
          height="200"
          alt="MomentMD"
          src="/assets/momentMDLogo.png"
        />
      </Col>
      <Col xs={12}>
        {patientConsent && (
          <span
            className="terms-and-conditions w-100"
            dangerouslySetInnerHTML={{
              __html: patientConsent.content || '',
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default PatientConsent;
