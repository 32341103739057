import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
  title: string;
  patient: string;
  medications?: string;
  linkTo: {
    path: string;
    state?: any;
  };
  textButton: string;
  onClose: () => void;
};

const RefillNotification: React.FC<Props> = ({
  title,
  patient,
  medications,
  linkTo,
  textButton = 'View more',
  onClose,
}) => {
  return (
    <Container className="mb-3">
      <Row className="pt-3 pr-3 pl-3 dashboard-border">
        <Col xs={12} className="h-end-column">
          <div onClick={onClose}>
            <FontAwesomeIcon
              size={'1x'}
              icon={faTimes}
              style={{ color: '#000', cursor: 'pointer' }}
            />
          </div>
        </Col>
        <Col xs={12} className=" mb-3 vcenter-column center-text-align">
          {title}
        </Col>
        <Col xs={12} className=" vcenter-column mb-4">
          <h6 className=" font-weight-bold center-text-align">
            Patient: {patient}
          </h6>
        </Col>
        {medications && (
          <Col xs={12} className=" vcenter-column mb-4">
            <h6 className=" font-weight-bold center-text-align">
              Medications: {medications}
            </h6>
          </Col>
        )}
        <Col xs={12} className=" mb-4 vcenter-column">
          <Link
            className=" w-100 vcenter-column"
            to={{
              pathname: linkTo.path,
            }}
            state={linkTo.state}
          >
            <Button
              className=" w-75 btn-medium-v text-normal"
              variant="outline-primary"
            >
              <small>{textButton}</small>
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default RefillNotification;
