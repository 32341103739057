import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
  title: string;
  patient: string;
  apptDateTime: string;
  conditions?: string;
  linkTo: {
    path: string;
    state?: any;
  };
  textButton: string;
  onClose: () => void;
};

const NotificationItem: React.FC<Props> = ({
  title,
  patient,
  apptDateTime,
  conditions,
  linkTo,
  textButton = 'View more',
  onClose,
}) => (
  <Container className="mb-3">
    <Row className="pt-3 pr-3 pl-3 dashboard-border">
      <Col xs={12} className="h-end-column">
        <div onClick={onClose}>
          <FontAwesomeIcon
            size={'1x'}
            icon={faTimes}
            style={{ color: '#000', cursor: 'pointer' }}
          />
        </div>
      </Col>
      <Col xs={12} className=" mb-3 vcenter-column center-text-align">
        {title}
      </Col>
      <Col xs={12} className="mb-4">
        <h6 className=" font-weight-bold">{patient}</h6>
        <p>
          {conditions && (
            <>
              <small style={{ color: '#67b2e1', fontWeight: 600 }}>
                {conditions}
              </small>
              <br />
            </>
          )}
          <small>Appointment: Today, {apptDateTime}</small>
        </p>
      </Col>
      <Col xs={12} className=" mb-4 vcenter-column">
        <Link
          className=" w-100 vcenter-column"
          replace
          to={linkTo.path}
          state={linkTo.state}
        >
          <Button
            className=" w-75 btn-medium-v text-normal"
            variant="outline-primary"
          >
            <small>{textButton}</small>
          </Button>
        </Link>
      </Col>
    </Row>
  </Container>
);

export default NotificationItem;
