import moment from 'moment/moment';
import { Action, View } from 'react-calendar/dist/cjs/shared/types';

const CALENDAR_MOVE_EVENTS: Action[] = ['next', 'next2', 'prev', 'prev2'];

export const shouldTriggerOnChange = (action: Action, view: View) =>
  view === 'month' &&
  (CALENDAR_MOVE_EVENTS.includes(action) || action === 'drillDown');

export const checkValueForCurrentMonth = (date: Date) => {
  if (
    moment().isSame(moment(date), 'month') &&
    moment().isSame(moment(date), 'year')
  ) {
    return moment().toDate();
  }

  return date;
};
