import React from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';

type Props = {
  label: string;
  cash: string;
  lastUpdate: string;
};

const EarningsFieldCell: React.FC<Props> = ({ label, cash, lastUpdate }) => {
  return (
    <div className={`xs-12 item-transactions`}>
      <div className={`xs-12 vcenter p-2`}>
        <Col xs="4">{label}</Col>
        <Col xs="8" className="text-right">
          <span className="text-success font-weight-bold">$</span>
          {cash}
        </Col>
      </div>
      <hr />
      <div className={`xs-12 vcenter p-2`}>
        <Col xs="4">Last update</Col>
        <Col xs="8" className="text-right">
          {moment(lastUpdate).format('MMM DD, YYYY  h:mm A')}
        </Col>
      </div>
    </div>
  );
};

export default EarningsFieldCell;
