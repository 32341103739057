import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import MMDAccordion from '../../components/MMDAccordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  Creators as publicVisitHistoryActions,
  selectPublicVisitHistory,
  selectPublicVisitHistoryState,
} from '../../modules/visit-history-public.module';

import { Link } from 'react-router-dom';

import { useBoundedActions } from '../../hooks/useBoundedActions';
import { useSelector } from 'react-redux';
import { VisitHistoryBookingPublicInList } from '../../typings';
import moment from 'moment';
import debounce from 'lodash.debounce';

const ProviderPublicVisitHistory = () => {
  const columns = useMemo<MRT_ColumnDef<VisitHistoryBookingPublicInList>[]>(
    () => [
      {
        accessorKey: 'endDate',
        accessorFn: (data) => [data.startDate, data.endDate],
        header: 'Date & Time',
        size: 200,
        Cell: (data) => {
          const [startDate, endDate] = data.renderedCellValue as string[];

          return (
            <div className="d-flex flex-column">
              <span className="fw-bold">
                {moment(startDate).format('D MMM YYYY')}
              </span>

              <span className="text-grey">
                {moment(startDate).format('h:mm A')} -{' '}
                {moment(endDate).format('h:mm A')}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: 'numberParticipant',
        header: 'Participants',
        size: 100,
        enableSorting: false,
      },
      {
        accessorKey: 'patientEmail',
        header: 'Patient Email',
        enableClickToCopy: true,
        size: 100,
        enableSorting: false,
      },
      {
        accessorKey: 'studentEmail',
        header: 'Student Email',
        enableClickToCopy: true,
        size: 100,
        enableSorting: false,
        Cell: (data) => <div>{data.renderedCellValue ?? '-'}</div>,
      },
      // {
      //   accessorKey: '-',
      //   header: '',
      //   visibleInShowHideMenu: false,
      //   size: 20,
      //   enableColumnOrdering: false,
      //   enableSorting: false,
      //   Cell: () => (
      //     <FontAwesomeIcon
      //       className="float-right"
      //       icon={faChevronRight}
      //       style={{ color: '#F11B42' }}
      //     />
      //   ),
      // },
    ],
    [],
  );

  const { requestPublicVisitHistory } = useBoundedActions(
    publicVisitHistoryActions,
  );

  const { count, isLoading } = useSelector(selectPublicVisitHistoryState);

  const publicVisitHistory = useSelector(selectPublicVisitHistory);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');

  const updateQuery = useCallback(debounce(setQuery, 400), []);

  const handleSearch = (value = '') => {
    setSearchValue(value);

    updateQuery(value);
  };

  useEffect(() => {
    requestPublicVisitHistory({
      limit: pagination.pageSize,
      search: query,
      offset: pagination.pageIndex * pagination.pageSize,
    });
  }, [pagination.pageIndex, pagination.pageSize, query]);

  const isDataLoading = isLoading && !publicVisitHistory.length;
  const isDataFetching = isLoading && publicVisitHistory.length > 0;

  const table = useMaterialReactTable({
    columns,
    enableColumnActions: false,
    positionActionsColumn: 'last',
    manualPagination: true,
    positionGlobalFilter: 'left',
    rowCount: count,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20],
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: handleSearch,
    state: {
      pagination,
      globalFilter: searchValue,
      isLoading: isDataLoading,
      showProgressBars: isDataFetching,
    },
    muiTableBodyRowProps: ({ row }) => ({
      component: (data) => (
        <Link
          to={`/my-appts/public-visit-history/${row.original.eventId}`}
          className={data.className}
        >
          {data.children}
        </Link>
      ),
      sx: {
        cursor: 'pointer',
      },
    }),
    data: publicVisitHistory,
  });

  return (
    <MMDAccordion
      defaultKey={-1}
      data={[
        {
          title: 'Visit History',
          type: 'visit-history',
        },
      ]}
      style="w-100 public-bookings mt-4"
      renderToggle={({ title }, idx, _, expandedIdx) => (
        <>
          {title}

          <FontAwesomeIcon
            className="float-right"
            style={{ color: '#C5C5C5' }}
            icon={idx === expandedIdx ? faChevronDown : faChevronRight}
          />
        </>
      )}
      renderCollapse={({ type }) => {
        switch (type) {
          case 'visit-history':
            return <MaterialReactTable table={table} />;
          default:
            return null;
        }
      }}
    />
  );
};

export default ProviderPublicVisitHistory;
