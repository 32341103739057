import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';
import { take, put } from 'redux-saga/effects';

import { MyState } from '../store';

interface ActionTypes {
  SET_LOADING: string;
  SET_NOT_LOADING: string;
  SET_APPOINTMENTS_LOADING: string;
  SET_NOT_APPOINTMENTS_LOADING: string;
}

interface ActionCreators {
  setLoading: () => Action;
  setNotLoading: () => Action;
  setAppointmentsLoading: () => Action;
  setNotAppointmentsLoading: () => Action;
}

export interface LoadingState {
  loading: boolean;
  app_loading: boolean;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  setLoading: [],
  setNotLoading: [],
  setAppointmentsLoading: [],
  setNotAppointmentsLoading: [],
});

const initialState = {
  loading: false,
  app_loading: false,
};

function setLoading(state): LoadingState {
  return { ...state, loading: true };
}

function setNotLoading(state): LoadingState {
  return { ...state, loading: false };
}

function setAppointmentsLoading(): LoadingState {
  return { loading: true, app_loading: true };
}

function setNotAppointmentsLoading(): LoadingState {
  return { loading: false, app_loading: false };
}

export const loadingReducer = createReducer(initialState, {
  [Types.SET_LOADING]: setLoading,
  [Types.SET_NOT_LOADING]: setNotLoading,
  [Types.SET_APPOINTMENTS_LOADING]: setAppointmentsLoading,
  [Types.SET_NOT_APPOINTMENTS_LOADING]: setNotAppointmentsLoading,
});

function* stopLoading(): SagaIterator {
  const shouldStopLoading = (action: Action) =>
    action.type.startsWith('COMMIT') ||
    action.type.startsWith('SUCCESS') ||
    action.type.includes('ERROR');

  while (yield take(shouldStopLoading)) {
    yield put(Creators.setNotLoading());
  }
}

function* hardStopLoading(): SagaIterator {
  const shouldStopLoading = (action: Action) => action.type.includes('ERROR');

  while (yield take(shouldStopLoading)) {
    yield put(Creators.setNotAppointmentsLoading());
  }
}

export const selectLoadingState = (state: MyState) => state.loading;

export const loadingSagas = [stopLoading, hardStopLoading];
