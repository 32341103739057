import React, { ReactNode } from 'react';

type Props = {
  saveFile?: (file: File) => void;
  children?: ReactNode;
};

const FileInput: React.FC<Props> = ({ children, saveFile = () => {} }) => {
  const uploadFile = React.useCallback(
    (e: React.FormEvent) => {
      const target = e.target as HTMLInputElement;

      if (!target.files.length) {
        return;
      }

      const file = target.files.item(target.files.length - 1);
      saveFile(file);
    },
    [saveFile],
  );

  return (
    <>
      <label style={{ display: 'inline-flex' }} htmlFor="file">
        {children}
      </label>

      <input
        className="d-none"
        type="file"
        id="file"
        name="file"
        accept="application/pdf, image/png, image/jpeg"
        onChange={uploadFile}
      />
    </>
  );
};

export default FileInput;
