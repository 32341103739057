import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import { BookingPublic } from '../../typings';
import PatientProfile from '../../components/PatientProfile';
import PublicVisitHistoryItem from './PublicVisitHistoryItem';
import DocumentsDetailsPublic from '../myappts/DocumentsDetailsPublic';

const VisitHistoryDetailsPublic: FC<BookingPublic> = ({
  eventId,
  patient,
  canceled,
  doctorId,
  filesPublic,
  patientEmail,
  patientPhone,
  studentEmail,
  doctorAttended,
  bookingNotePublic,
  numberParticipant,
}) => (
  <Col className="w-100 padding-10">
    {canceled ? (
      <h2 className="mb-5 w-100 font-weight-normal text-danger">
        Booking was canceled
      </h2>
    ) : (
      <h2
        className={`mb-5 w-100 font-weight-normal ${
          doctorAttended ? 'text-success' : 'text-danger'
        }`}
      >
        Doctor {doctorAttended ? 'was' : "wasn't"} attended
      </h2>
    )}

    {patient ? (
      <div className="mb-5">
        <PatientProfile patientId={patient.id} />
      </div>
    ) : (
      <PublicVisitHistoryItem title="Patient email" value={patientEmail} />
    )}

    {patientPhone && (
      <PublicVisitHistoryItem title="Patient Phone" value={patientPhone} />
    )}

    {studentEmail && (
      <PublicVisitHistoryItem title="Student email" value={studentEmail} />
    )}

    <PublicVisitHistoryItem
      title="Notes and Diagnosis"
      value={bookingNotePublic?.note ?? 'N/A'}
    />

    <PublicVisitHistoryItem
      title="Number of participant"
      value={numberParticipant}
    />

    <Row className="padding-h-40 ">
      <h2 className="mb-3 w-100 font-weight-normal">Documents</h2>

      <div className="w-100 padding-vertical-10 division-border"></div>
    </Row>

    {filesPublic?.length > 0 ? (
      <Row>
        <DocumentsDetailsPublic
          eventId={eventId}
          files={filesPublic}
          doctorId={doctorId}
        />
      </Row>
    ) : (
      <Row className="mb-3 padding-h-40"> "N/A"</Row>
    )}
  </Col>
);

export default VisitHistoryDetailsPublic;
