import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';

import AchForm from './ACHForm';
import { useToggle } from '../../hooks/useToggle';
import { useBoundedActions } from '../../hooks/useBoundedActions';
import { Creators as PaymentActions } from '../../modules/payments.module';
import {
  selectBankAccountState,
  Creators as BankAccountActions,
} from '../../modules/bank-account.module';

const AchAccount = () => {
  const [isEditAchVisible, toggleAchVisible] = useToggle();

  const { requestBankAccounts, requestSetDefaultPaymentMethod } =
    useBoundedActions({
      ...BankAccountActions,
      ...PaymentActions,
    });

  const { account } = useSelector(selectBankAccountState);

  useEffect(() => {
    requestBankAccounts();
  }, []);

  const isACHAccountDefaultPaymentMethod = account?.default_for_currency;

  const onSetDefaultBankAccount = (e) => {
    e.preventDefault();

    if (isACHAccountDefaultPaymentMethod) {
      return;
    }
    if (!account) {
      alert('Add account first');
      return;
    }

    requestSetDefaultPaymentMethod({ id: account.id, method: 'bank_account' });
  };

  return (
    <div className="mr-2">
      <div className="d-flex justify-content-between">
        <Form.Check
          color="red"
          reverse
          id="check"
          type="radio"
          className="ml-2 mb-2 custom-radio"
          checked={isACHAccountDefaultPaymentMethod}
        >
          <Form.Check.Label>ACH Account</Form.Check.Label>
          <Form.Check.Input
            type="radio"
            onClick={onSetDefaultBankAccount}
            checked={isACHAccountDefaultPaymentMethod}
          />
        </Form.Check>

        {isACHAccountDefaultPaymentMethod && (
          <span className="text-grey">Default</span>
        )}
      </div>

      {!!account && (
        <div>
          <div className="d-flex justify-content-between mb-1">
            <span className="text-grey">Account Holder Name:</span>
            <span>{account.account_holder_name}</span>
          </div>

          <div className="d-flex justify-content-between  mb-1">
            <span className="text-grey">Routing Number:</span>
            <span>{account.routing_number}</span>
          </div>

          <div className="d-flex justify-content-between  mb-1">
            <span className="text-grey">Account Number</span>
            <span>*****{account.last4}</span>
          </div>
        </div>
      )}

      <Button
        variant="primary"
        onClick={toggleAchVisible}
        className="my-3 mb-auto"
      >
        {account ? 'Edit' : 'Add Account'}
      </Button>
      {isEditAchVisible && (
        <AchForm
          onHideForm={toggleAchVisible}
          isACHAccountDefaultPaymentMethod={isACHAccountDefaultPaymentMethod}
        />
      )}
    </div>
  );
};

export default AchAccount;
