import { Action } from 'redux';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from '../store';
import { API_URL } from '../config';
import { PatientConsent } from '../typings';
import { MMDError } from '../utils/MMDError';
import { noOpAction } from '../utils/noOpAction';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import { LocationChangeActionPayload } from '../utils/typings';
import { compareByCreationTimestamp } from '../utils/compareByCreationTimestamp';

interface ActionTypes {
  COMMIT_PATIENT_CONSENT: string;
  REQUEST_PATIENT_CONSENT: string;
}

interface ActionCreators {
  requestPatientConsent: () => Action;
  commitPatientConsent: (payload: PatientConsent) => MyAction<PatientConsent>;
}

export interface PatientConsentState extends EntityState<PatientConsent> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  commitPatientConsent: ['payload'],
  requestPatientConsent: [],
});

const entityAdapter = createEntityAdapter<PatientConsent>({
  selectId: (item) => item.id.toString(),
  sortComparer: compareByCreationTimestamp,
});

const initialState = entityAdapter.getInitialState();

const patientConsentSelectors = entityAdapter.getSelectors();

function commitPatientConsent(
  state: PatientConsentState,
  action: MyAction<PatientConsent>,
) {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
  };
}

export const patientConsentReducer = createReducer(initialState, {
  [Types.COMMIT_PATIENT_CONSENT]: commitPatientConsent,
});

async function downloadPatientConsent(): Promise<PatientConsent> {
  const result = await fetch(`${API_URL}/patient-consents?userType=Patient`, {
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError('Something went wrong downloading your patient consent');
  }
  const { patientConsents } = await result.json();

  return patientConsents;
}

const requestPatientConsentWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  PatientConsent,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_PATIENT_CONSENT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPatientConsent,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPatientConsent,
});

export const patientConsentSagas = [requestPatientConsentWatcher];

export const selectPatientConsentState = (state: MyState) =>
  state.patientConsent;

export const selectPatientConsent = createSelector(
  selectPatientConsentState,
  (patientConsentState) =>
    patientConsentSelectors.selectAll(patientConsentState)[0],
);
