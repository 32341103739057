import pjson from '../../package.json';

export const CREDENTIALS = [
  { key: 0, label: 'MD', value: 'MD' },
  { key: 1, label: 'DO', value: 'DO' },
  { key: 2, label: 'DNP', value: 'DNP' },
  { key: 3, label: 'PA', value: 'PA' },
  { key: 4, label: 'NP', value: 'NP' },
  { key: 5, label: 'DC', value: 'DC' },
  { key: 6, label: 'DVM', value: 'DVM' },
  { key: 7, label: 'DDS', value: 'DDS' },
  { key: 8, label: 'DMD', value: 'DMD' },
  { key: 9, label: 'ND', value: 'ND' },
  { key: 10, label: 'SLP', value: 'SLP' },
];

//ND and SPL

export const PAGE_LEN = parseInt(process.env.REACT_APP_PAGE_LEN, 10);

export const TRUNCATE_FILE_NAME = 20;

export const INVALID_METHOD_REFILL_INPUT = 'The Method Refills cannot be empty';

export const version: string = pjson.version;

export const PSYCHOLOGY = 'psychology';

export const PSYCHIATRIST = 'psychiatrist';

export const PUBLIC_USER_CUSTOM_TOKEN = 'postgresUser.customToken';
