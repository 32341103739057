import { FC, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import { Creators as patientsActions } from '../../modules/patients.module';
import { MyState } from '../../store';
import ToggleAccordion from './ToggleAccordion';

type Props = {
  patientId: string;
  lifestyles: any[];
  weight: any;
  height: any;
  inches: any;
} & typeof patientsActions;

const calculateBmi = (weight, height, inches) => {
  const feets = Number(height) + Number(inches) * 0.0833;
  const meters = feets * 0.3048;
  const kg = Number(weight) * 0.453592;
  const bmi = Number(kg) / Math.pow(meters, 2);
  const totalBmi = Math.round(bmi * 100) / 100;
  if (totalBmi > 0.0 && totalBmi !== Infinity) return totalBmi;
  return '0.00';
};

const LifestyleDetails: FC<Props> = ({
  patientId,
  lifestyles,
  weight,
  height,
  inches,
  requestAppointmentLifestyle,
}) => {
  useEffect(() => {
    requestAppointmentLifestyle({ id: patientId });
  }, [patientId, requestAppointmentLifestyle]);

  if (!lifestyles) return null;

  return (
    <Row className="margin-left-15 w-100 padding-10">
      <Row className=" w-100 padding-10">
        <p className="text-center mb-3 w-100">
          {'Your basic health information'}
        </p>
      </Row>
      <Row className=" w-100 vcenter">
        <Col className="mb-4 text-center">{weight} (lbs)</Col>
        <Col className="mb-4 text-center">{height} (feets)</Col>
        <Col className="mb-4 text-center">{inches} (inches)</Col>
      </Row>
      <Row className=" w-100 padding-10">
        <Col className="text-center font-weight-bold" xs={12}>
          Your BMI
        </Col>
      </Row>
      <Row className=" w-100 padding-10">
        <Col
          className="text-center font-large font-weight-bold"
          style={{ color: '#F11B42' }}
          xs={12}
        >
          {calculateBmi(weight, height, inches)}
        </Col>
      </Row>
      <Row className=" w-100 padding-10">
        {lifestyles.map((item) => (
          <ToggleAccordion
            key={item.id}
            title={item.label}
            value={item.value}
            className="w-100 border-bottom border-top padding-10"
          />
        ))}
      </Row>
    </Row>
  );
};

const mapStateToProps = (state: MyState) => {
  if (state.patients.lifestyles.length === 0) return {};
  const lifestyleState = state.patients.lifestyles;
  const weight = lifestyleState.find((item) => item.label === 'Weight').value;
  const height = lifestyleState.find((item) => item.label === 'Height').value;
  const inches = lifestyleState.find((item) => item.label === 'inches').value;
  const lifestyles = lifestyleState.filter(
    (item) =>
      item.checked &&
      item.label !== 'Height' &&
      item.label !== 'Weight' &&
      item.label !== 'inches',
  );
  return {
    lifestyles,
    weight,
    height,
    inches,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...patientsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LifestyleDetails);
