import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

import PrescriptionForm from './e-prescribing/PrescriptionForm';
import PreviewPdfStep from './e-prescribing/PreviewPdfStep';
import VerificationStep from './e-prescribing/VerificationStep';
import { submitPrescription } from './e-prescribing/submitPrescriptionHelper';
import { Creators as LoadingActions } from '../modules/loading.module';
import { Creators as ErrorActions } from '../modules/errors.module';
import { Creators as PrescriptionsRefillsActions } from '../modules/prescription-refills.module';
import { Creators as ProviderActions } from '../modules/provider.module';
import { MyState } from '../store';
import { Prescription, RefillRequest } from '../typings';
import { push } from 'redux-first-history';
import { API_URL } from '../config';
import VetDeaForm from './e-prescribing/VeterinaryDEAForm';

type Props = {
  doctorInfo: any;
  className?: string;
  refillRequest?: RefillRequest;
  bookingId?: string;
  patientId?: string;
  step: number;
  onChangeStep: (nextStep: number) => void;
  push: (path: string) => void;
  isNewPrescription: boolean;
} & typeof PrescriptionsRefillsActions &
  typeof LoadingActions &
  typeof ErrorActions &
  typeof ProviderActions;

const EPrescribing: React.FC<Props> = ({
  isNewPrescription,
  className,
  refillRequest,
  patientId,
  bookingId,
  step,
  doctorInfo,
  onChangeStep,
  setLoading,
  setNotLoading,
  setError,
  push,
  requestProvider,
}) => {
  const [prescriptionForm, setPrescriptionForm] = React.useState<
    Prescription | undefined
  >();
  const [submitResultMsg, setSubmitResultMsg] = React.useState(null);
  const [isVet, setIsVet] = React.useState(false);
  const [DEA, setDea] = React.useState('');
  console.log(doctorInfo);

  const checkIfVet = async () => {
    const res = await fetch(
      `${API_URL}/credentials?label=${doctorInfo.credential}`,
    );
    if (res.ok) {
      const resOBj = await res.json();
      const categories: any[] = resOBj.data[0].category;
      categories.map((category) => {
        if (category.name == 'Veterinary') {
          setIsVet(true);
        }
      });
    }
  };

  React.useMemo(() => {
    requestProvider();
  }, []);

  React.useEffect(() => {
    setLoading();
    if (doctorInfo) {
      checkIfVet().then(setNotLoading);
    }
  }, [doctorInfo.id]);

  const sendPrescription = React.useCallback(
    (values: Dictionary<any>) => {
      setLoading();
      let submitPromise;
      if (refillRequest) {
        submitPromise = submitPrescription(
          values['securityCode'],
          {
            ...prescriptionForm,
            patientId: refillRequest.patient.id,
            bookingId: refillRequest.prescription.booking!.id,
          },
          refillRequest.id,
        );
      } else if (patientId) {
        submitPromise = submitPrescription(
          values['securityCode'],
          {
            ...prescriptionForm,
            patientId: patientId,
            bookingId,
          },
          null,
        );
      }
      submitPromise
        .then((result) => {
          setSubmitResultMsg(result.message);
          onChangeStep(1);
          setNotLoading();

          if (refillRequest) {
            push('/refills');
          }
        })
        .catch(setError);
    },
    [
      prescriptionForm,
      refillRequest,
      patientId,
      bookingId,
      setLoading,
      setNotLoading,
      setError,
      setSubmitResultMsg,
    ],
  );

  if (submitResultMsg) {
    return <div className="text-center">{submitResultMsg}</div>;
  } else {
    return (
      <Col className={className}>
        {isVet && doctorInfo && !doctorInfo['dea'] ? (
          <>
            {step === 1 && (
              <VetDeaForm
                setDea={setDea}
                Continue={() => {
                  onChangeStep(2);
                }}
                doctorInfo={doctorInfo}
              />
            )}
            {step === 2 && (
              <PrescriptionForm
                isNewPrescription={isNewPrescription}
                onSubmitForm={setPrescriptionForm}
                onClickPreview={() => onChangeStep(3)}
                prescription={
                  refillRequest ? refillRequest.prescription : prescriptionForm
                }
                dea={isVet ? DEA : undefined}
              />
            )}
            {step === 3 && (
              <PreviewPdfStep
                goToVerification={() => onChangeStep(4)}
                patientId={
                  refillRequest ? refillRequest.patient.id : patientId!
                }
                prescription={prescriptionForm}
              />
            )}
            {step === 4 && (
              <VerificationStep onSubmitPrescription={sendPrescription} />
            )}
          </>
        ) : (
          <>
            {step === 1 && (
              <PrescriptionForm
                isNewPrescription={isNewPrescription}
                onSubmitForm={setPrescriptionForm}
                onClickPreview={() => onChangeStep(2)}
                prescription={
                  refillRequest ? refillRequest.prescription : prescriptionForm
                }
              />
            )}
            {step === 2 && (
              <PreviewPdfStep
                goToVerification={() => onChangeStep(3)}
                patientId={
                  refillRequest ? refillRequest.patient.id : patientId!
                }
                prescription={prescriptionForm}
              />
            )}
            {step === 3 && (
              <VerificationStep onSubmitPrescription={sendPrescription} />
            )}
          </>
        )}
      </Col>
    );
  }
};

const mapStateToProps = (state: MyState) => ({
  doctorInfo: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...ProviderActions,
      ...PrescriptionsRefillsActions,
      ...LoadingActions,
      ...ErrorActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EPrescribing);
