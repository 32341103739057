import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Booking, Provider } from '../../typings';
import moment from 'moment';
type Props = {
  appointment: Booking;
  provider: Provider;
  sendSubscriberStudent: ({ id }: { id: string }) => void;
  sendUnsubscriberStudent: ({ id }: { id: string }) => void;
};

const ActionsAppointment: React.FC<Props> = ({
  appointment,
  provider,
  sendSubscriberStudent,
  sendUnsubscriberStudent,
}) => {
  const pharmacy = (appointment as any).pharmacy;

  const showCancel = React.useMemo(() => {
    if (
      provider.teacher &&
      appointment.studentId &&
      appointment.studentId === provider.id
    ) {
      return true;
    }
    return false;
  }, [appointment, provider]);

  const text = React.useMemo(() => {
    if (!provider.teacher) {
      return 'Start Consultation';
    }
    if (appointment.studentId && appointment.studentId === provider.id) {
      return 'Start Consultation';
    }
    if (!appointment.studentId) {
      return 'Subscribe to this Consultation';
    }
  }, [appointment, provider]);

  const isBooked = React.useMemo(() => {
    if (
      provider.teacher &&
      appointment.studentId &&
      appointment.studentId !== provider.id
    ) {
      return true;
    }
    return false;
  }, [appointment, provider]);

  const onClick = React.useCallback(
    (event) => {
      const isBeforeNow = (dateString) => {
        return new Date(dateString).getTime() >= Date.now();
      };
      const startTime = appointment.event.start.dateTime;
      if (!isBooked && !appointment.studentId && provider.teacher) {
        sendSubscriberStudent({ id: appointment.id });
        event.preventDefault();
        return;
      }

      if (isBeforeNow(startTime)) {
        alert(
          `You're too early! Your call starts ${moment(startTime).fromNow()}`,
        );
        event.preventDefault();
        return;
      }
    },
    [appointment, provider, sendSubscriberStudent, isBooked],
  );

  const onClickCancel = React.useCallback(() => {
    sendUnsubscriberStudent({ id: appointment.id });
  }, [sendUnsubscriberStudent, appointment]);

  const renderActionButton = () => {
    if (
      appointment.studentId &&
      appointment.studentId === provider.id &&
      !appointment.allowStudent
    ) {
      return (
        <span className="mt-3 fw-bold text-center d-block">
          The patient has declined your participating in this consultation
        </span>
      );
    }

    return (
      <Row className="border-bottom vertical-buffer justify-content-center">
        <Link
          className="w-100"
          to={{
            pathname: `/video-call/${appointment.id}`,
          }}
          state={appointment}
          onClick={onClick}
        >
          <Button className="w-100 btn-green" variant="primary">
            {text}
          </Button>
        </Link>
      </Row>
    );
  };

  return (
    <Container>
      <Row className="pt-4 pr-4 pl-4 dashboard-border">
        <Col className="justify-content-center w-100" xs={12}>
          <Row className="border-bottom justify-content-center pb-4">
            <Col xs="8" className="text-center mx-auto w-100">
              <h2>Video Call</h2>
            </Col>
          </Row>
          <Row className="border-bottom justify-content-center vertical-buffer">
            <Col className="text-center mb-1 font-weight-bold" xs={12}>
              {pharmacy.name}
            </Col>
            <Col className="text-center mb-3" xs={12}>
              {pharmacy.address}
            </Col>
            <a
              target="_blank"
              className="w-100 vcenter-column"
              href={`https://www.google.com/maps/search/?api=1&query=${pharmacy.lat},${pharmacy.lng}`}
              rel="noreferrer"
            >
              <Button
                className="w-75 btn-tertiary"
                style={{ paddingBottom: '4px' }}
                variant="primary"
              >
                <small>Pharmacy Location</small>
              </Button>
            </a>
          </Row>
          {isBooked ? (
            <small>This appointment has been booked by another student</small>
          ) : (
            renderActionButton()
          )}
          {showCancel && (
            <Row className="vertical-buffer justify-content-center">
              <Button
                className="w-100 btn-tertiary"
                variant="primary"
                onClick={onClickCancel}
              >
                Unsubscribe from this appointment
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ActionsAppointment;
