import { Patient } from '../typings';
import { firestore } from './Firebase';
import { AuthResult } from '../modules/auth.module';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { DocumentData } from '@firebase/firestore';

export const truncateText = (text: string, max: number) => {
  if (!text || text.length <= max - 3) {
    return text;
  }

  return `${text.substring(0, max - 3)}...`;
};

export const requestPatientFromFirestore = async (
  patient: Patient,
): Promise<Patient> => {
  let docRef = null;
  if (patient.parentId) {
    docRef = doc(
      firestore,
      'users',
      patient.parentId,
      patient.relativeType,
      patient.id,
    );
  } else {
    docRef = doc(firestore, 'users', patient.id);
  }

  const loadedDoc = await getDoc<Patient, DocumentData>(docRef);

  return loadedDoc.data();
};

export const requestSectionFromFirestore = async (
  patient: Partial<Patient>,
  section: string,
) => {
  if (!patient.id) {
    return {
      section,
      values: [],
    };
  }

  const data = [];
  let raw;
  if (patient.parentId) {
    raw = await getDocs(
      collection(
        firestore,
        'users',
        patient.parentId,
        patient.relativeType,
        patient.id,
        section,
      ),
    );
  } else {
    raw = await getDocs(collection(firestore, 'users', patient.id, section));
  }

  raw.forEach((doc) => data.push(doc.data()));
  const values = data.map((data) => data.name);
  return {
    section,
    values,
  };
};

export const loggedIn = () => {
  const authInfo: AuthResult = JSON.parse(
    localStorage.getItem('moment.session'),
  );
  return authInfo && authInfo.firebase_token && authInfo.expiration;
};
