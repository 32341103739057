import moment from 'moment/moment';
import { Dispatch, FC, SetStateAction } from 'react';

import { BookingPublic } from '../../../typings';
import PublicAppointmentRow from './PublicAppointmentRow';
import WaitingModal from '../../public-appointment/join-videocall/WaitingModal';

const sortAppointments = (appointments: BookingPublic[]) =>
  appointments
    .filter((appointment) => moment(appointment.endDate).isAfter(moment()))
    .toSorted(
      (appointmentOne, appointmentTwo) =>
        new Date(appointmentOne?.startDate).getTime() -
        new Date(appointmentTwo?.startDate).getTime(),
    );

type Props = {
  publicAppointments: BookingPublic[];
  setPublicBookingToEdit: Dispatch<SetStateAction<BookingPublic>>;
};

const PublicAppointmentsList: FC<Props> = ({
  publicAppointments,
  setPublicBookingToEdit,
}) => (
  <>
    {sortAppointments(publicAppointments).map((publicAppointment) => (
      <PublicAppointmentRow
        key={publicAppointment.id}
        publicAppointment={publicAppointment}
        onEditPublicAppointment={() =>
          setPublicBookingToEdit(publicAppointment)
        }
      />
    ))}

    <WaitingModal />
  </>
);

export default PublicAppointmentsList;
