import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import DoctorProfile from '../components/DoctorProfile';
import DashboardMenu from './dashboard/DashboardMenu';
import NotificationCenter from './dashboard/NotificationCenter';
import {
  Creators as BookingsActions,
  bookingsSelectors,
} from '../modules/bookings.module';
import { chatroomSelectors } from '../modules/chatrooms.module';
import { Booking } from '../typings';
import moment from 'moment';
import { MyState } from '../store';
import {
  Creators as prescriptionRefillActions,
  prescriptionRefillSelectors,
} from '../modules/prescription-refills.module';

type Props = {
  appointments: Booking[];
  unreadMessageCount: number;
  prescriptionsRefillsCount: number;
  isStudent: boolean;
} & typeof BookingsActions;

const Dashboard: React.FC<Props> = ({
  appointments,
  unreadMessageCount,
  prescriptionsRefillsCount,
  requestAppointments,
  isStudent,
}) => {
  const [todayUpAppoints, setTodayUpAppoints] = React.useState<Booking[]>([]);
  const [shouldRequest, setShouldRequest] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!appointments.length && shouldRequest) {
      const now = moment.utc();
      requestAppointments({
        from: moment(now).toString(),
        to: moment(now).add(3, 'months').toString(),
      });
      setShouldRequest(false);
    } else {
      const todayUpAppoints = appointments.filter((ap) => {
        if (ap.event) {
          return moment(ap.event.start.dateTime).isBetween(
            moment(),
            moment().endOf('day'),
          );
        }
      });
      setTodayUpAppoints(todayUpAppoints);
    }
  }, [appointments, shouldRequest, setShouldRequest, requestAppointments]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>Dashboard</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <DashboardMenu
            todayUpAppoints={todayUpAppoints.length}
            unreadMessages={unreadMessageCount}
            prescriptionsRefillsCount={prescriptionsRefillsCount}
            isStudent={isStudent}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={3}>
          <NotificationCenter todayUpAppoints={todayUpAppoints} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => {
  const prescriptionRefills = prescriptionRefillSelectors.selectAll(
    state.prescriptionRefills,
  );
  return {
    appointments: bookingsSelectors.selectAll(state.bookings),
    unreadMessageCount: chatroomSelectors.getUnreadMessageCount(
      state.chatrooms,
    ),
    prescriptionsRefillsCount: prescriptionRefills.filter(
      (item) => item.prescription.booking,
    ).length,
    isStudent: !!(state.provider && state.provider.teacher),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...BookingsActions,
      ...prescriptionRefillActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
