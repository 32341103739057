import states from 'states-us';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FC, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { AUTH_API_URL } from '../../../config';
import MMDSelect from '../../../components/forms/MMDSelect';
import MMDCheckbox from '../../../components/forms/MMDCheckbox';
import MMDTextInput from '../../../components/forms/MMDTextInput';
import { SignUpFormValues } from '../../../modules/signup.module';
import MMDPhoneInput from '../../../components/forms/MMDPhoneInput';
import MMDDatePickerFly from '../../../components/forms/MMDDatePickerFly';
import MMDTextInputAutocomplete from '../../../components/forms/MMDTextInputAutocomplete';

type Props = {
  values: SignUpFormValues;
  setFieldValue: any;
  credentials: any[];
};

const StepOne: FC<Props> = ({ values, setFieldValue, credentials }) => {
  const [msgNpi, setMsgNpi] = useState('');
  const [, setInvalidNpi] = useState('');
  const [isVet, setIsVet] = useState<boolean>(false);

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const getNPI = async (values) => {
      const result = await fetch(`${AUTH_API_URL}/validate-npi`, {
        headers,
        method: 'POST',
        body: JSON.stringify(values),
      });
      const response = await result.json();
      setMsgNpi(response.msg);
    };
    getNPI(values);
    if (msgNpi.includes('INVALID')) {
      setInvalidNpi(msgNpi);
    }
  }, [values, msgNpi]);

  const checkIfVet = (credentialLabel: string) => {
    const credential = credentials.find((c) => c.label == credentialLabel);
    if (credential) {
      let found = false;
      credential.category.map((cat) => {
        if (cat.name == 'Veterinary') {
          found = true;
        }
      });
      return found;
    }
    return false;
  };

  useEffect(() => {
    setIsVet(checkIfVet(values['credential']));
  }, [values.credential]);

  const STATES_OPTIONS = states.map(({ abbreviation }) => ({
    label: abbreviation,
    value: abbreviation,
  }));

  return (
    <Row className="justify-content-md-center">
      <Col xs lg="5">
        <MMDTextInput
          values={values}
          showFieldError
          fieldKey="firstName"
          placeholder="First Name"
        />
        {msgNpi.includes('INVALID') && msgNpi.includes('FIRSTNAME') ? (
          <p
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#dc3545',
              marginLeft: '15px',
            }}
          >
            First name doesn't match NPI
          </p>
        ) : null}

        <MMDTextInput
          values={values}
          showFieldError
          fieldKey="lastName"
          placeholder="Last Name"
        />
        {msgNpi.includes('INVALID') && msgNpi.includes('LASTNAME') ? (
          <p
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#dc3545',
              marginLeft: '15px',
            }}
          >
            Last name doesn't match NPI
          </p>
        ) : null}

        <MMDDatePickerFly
          setFieldValue={setFieldValue}
          values={values}
          fieldKey="birthday"
          placeholder="Birthday"
          showFieldError
        />

        <MMDTextInput
          values={values}
          fieldKey="email"
          placeholder="E-mail"
          showFieldError
        />

        <MMDPhoneInput name="phone" placeholder="Phone number" />

        <MMDTextInputAutocomplete
          values={values}
          fieldKey="address"
          placeholder="Work address"
          list="address"
          showFieldError
          setFieldValue={setFieldValue}
        />
        {msgNpi.includes('INVALID') && msgNpi.includes('ADDRESS') ? (
          <p
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#dc3545',
              marginLeft: '15px',
            }}
          >
            Address doesn't match NPI
          </p>
        ) : null}

        <MMDTextInputAutocomplete
          values={values}
          fieldKey="city"
          placeholder="City"
          list="city"
          showFieldError
          setFieldValue={setFieldValue}
        />
        {msgNpi.includes('INVALID') && msgNpi.includes('CITY') ? (
          <p
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#dc3545',
              marginLeft: '15px',
            }}
          >
            City doesn't match NPI
          </p>
        ) : null}

        <MMDSelect
          required
          reduxForm
          name="state"
          isSearchable
          placeholder="State"
          options={STATES_OPTIONS}
          reduxValue={values.state}
          setFieldValue={setFieldValue}
        />
        {msgNpi.includes('INVALID') && msgNpi.includes('STATE') ? (
          <p
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#dc3545',
              marginLeft: '15px',
            }}
          >
            State doesn't match NPI
          </p>
        ) : null}

        <MMDTextInput
          values={values}
          fieldKey="zipCode"
          showFieldError
          placeholder="ZIP Code"
        />
        {msgNpi.includes('INVALID') && msgNpi.includes('ZIPCODE') ? (
          <p
            style={{
              width: '100%',
              marginTop: '0.25rem',
              fontSize: '80%',
              color: '#dc3545',
              marginLeft: '15px',
            }}
          >
            Zipcode doesn't match NPI
          </p>
        ) : null}

        <MMDSelect
          required
          reduxForm
          name="credential"
          options={credentials}
          placeholder="Credential"
          setFieldValue={setFieldValue}
          reduxValue={values.credential}
        />

        <MMDTextInput
          values={values}
          fieldKey="password"
          placeholder="Password"
          type="password"
          showFieldError
        />

        <MMDTextInput
          values={values}
          fieldKey="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          showFieldError
        />

        {!isVet && (
          <MMDTextInput
            values={values}
            fieldKey="npi"
            placeholder="NPI Number"
            showFieldError
          />
        )}

        {isVet && (
          <MMDTextInput
            values={values}
            fieldKey="dea"
            placeholder="DEA Number"
            showFieldError
          />
        )}

        <MMDTextInput
          values={values}
          fieldKey="pln"
          placeholder="Provider License Number"
          showFieldError
        />

        <MMDTextInput
          values={values}
          fieldKey="ssn"
          type="password"
          autocomplete="off"
          placeholder="Social security number"
          showFieldError
        />

        <hr />
        <MMDCheckbox
          values={values}
          fieldKey="terms"
          label={
            <>
              <label className="mb-0">I agree to MomentMD</label>
              <br />
              <Link className="link-color" to="/terms-and-conditions">
                <u>Terms of Service & Informed Consent</u>
              </Link>
            </>
          }
          showFieldError
        />
      </Col>
    </Row>
  );
};

export default StepOne;
