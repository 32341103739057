import { Container, Col } from 'react-bootstrap';
import LoginForm from './signin/LoginForm';
import { Link, Navigate } from 'react-router-dom';
import { loggedIn } from '../utils/helper';

const Signin = () => {
  if (loggedIn()) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <Container className="pt-5">
      <Col className="mx-auto text-center">
        <h5>Welcome</h5>
        <img
          className="ml-auto"
          height="200"
          src="/assets/momentMDLogo.png"
          alt="MomentMD"
        />
      </Col>

      <LoginForm />

      <p className="text-center">
        Not a member?{' '}
        <Link className="text-primary text-decoration-none" to="signup">
          SIGN UP
        </Link>
      </p>
      <p className="text-center">
        Revisit our{' '}
        <Link to="terms-and-conditions">
          <u>Terms and conditions</u>
        </Link>
      </p>
      <p className="text-center">
        <a className="text-decoration-none" href="mailto:admin@momentmd.com">
          Contact us
        </a>
      </p>
    </Container>
  );
};

export default Signin;
