import { Prescription } from '../../../typings';
import { AUTH_API_URL } from '../../../config';

export async function getPdfUrl(
  prescription: Prescription,
  patientId: string,
): Promise<ArrayBuffer> {
  const authInfo = await JSON.parse(localStorage.getItem('moment.session'));
  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);

  const res = await fetch(
    `${AUTH_API_URL}/prescriptions/preview-prescription?patientId=${patientId}&name=${encodeURIComponent(
      prescription.name,
    )}&dosage=${encodeURIComponent(
      prescription.dosage,
    )}&quantity=${encodeURIComponent(
      prescription.quantity,
    )}&frequency=${encodeURIComponent(
      prescription.frequency,
    )}&methodRefills=${encodeURIComponent(
      prescription.methodRefills,
    )}&daysOfsupply=${encodeURIComponent(
      prescription.daysOfSupply || 1,
    )}&generic=${encodeURIComponent(
      prescription.generic,
    )}&autoRefillsDays=${encodeURIComponent(
      prescription.autoRefillsDays,
    )}&unit=${prescription.unit}&dea=${
      prescription.dea ? prescription.dea : ''
    }`,
    {
      headers,
      method: 'GET',
    },
  );
  return res.arrayBuffer();
}
