import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import MMDModal from '../../../components/MMDModal';
import { useToggle } from '../../../hooks/useToggle';
import { selectPublicWaitingRoomState } from '../../../modules/videocall-public/waiting-room.module';

const WaitingModal = () => {
  const [isModalOpen, toggleModal] = useToggle();

  const { isDeclined, isAskedToJoin } = useSelector(
    selectPublicWaitingRoomState,
  );

  useEffect(() => {
    if (isAskedToJoin) {
      toggleModal();
    }
  }, [isAskedToJoin]);

  return (
    <MMDModal
      centered
      withoutFooter
      keyboard={false}
      show={isModalOpen}
      closeButton={isDeclined}
      handleClose={toggleModal}
      titleClassName="fw-normal mt-2"
      classNameContainer="user-select-none"
      backdrop={isDeclined ? true : 'static'}
      title={isDeclined ? 'Decline' : 'Waiting for host to let you in...'}
      renderBody={() => (
        <div className="text-center mb-3">
          {isDeclined ? (
            <span className="fw-bold">You were declined, try again later</span>
          ) : (
            <Spinner variant="primary" animation="border" />
          )}
        </div>
      )}
    />
  );
};

export default WaitingModal;
