import { FC } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import FilePublic from './attachments-public/FilePublic';
import { FileReference, BookingPublic } from '../../../../typings';

type Props = {
  booking: BookingPublic;
  documents: FileReference[];
  pictures: FileReference[];
  files: any;
  onRequestClose: () => void;
  onRequestNotes: () => void;
  onRequestEPrescription: () => void;
  gotoPatientChart: () => void;
  withNotes?: boolean;
  isPatientExist: boolean;
  forPatient?: boolean;
};

const AttachmentsPublic: FC<Props> = ({
  booking,
  pictures,
  documents,
  onRequestNotes = () => {},
  withNotes,
  gotoPatientChart,
  isPatientExist,
  forPatient,
}) => (
  <Col xs={12} sm={6} className="p-3 gray-background">
    <h6>Pictures</h6>

    {pictures.length ? (
      <div className="documents-container py-3">
        {pictures.map((pic) => (
          <>
            <FilePublic
              key={pic.id}
              fileName={pic.fileNamePublic}
              booking={booking}
              isPicture
              showFileName={false}
              className="border-image"
            />
          </>
        ))}
      </div>
    ) : (
      <p className="text-muted">No pictures to show</p>
    )}

    <h6>Documents</h6>
    {documents.length ? (
      <div className="documents-container py-3">
        {documents.map((doc) => (
          <>
            <FilePublic
              key={doc.id}
              fileName={doc.fileNamePublic}
              booking={booking}
            />
          </>
        ))}
      </div>
    ) : (
      <p className="text-muted">No documents to show</p>
    )}

    {!forPatient && (
      <Row className="mt-3 w-100" style={{ bottom: '85px' }}>
        {withNotes && (
          <Col xs={12} md={6}>
            <Button
              className="w-100 mb-2"
              variant="primary"
              onClick={onRequestNotes}
            >
              Notes
            </Button>
          </Col>
        )}
        <Col xs={12} md={6}>
          {isPatientExist ? (
            <Button
              className="w-100"
              variant="primary"
              onClick={gotoPatientChart}
            >
              Patient Chart
            </Button>
          ) : (
            <div className="mt-2 fw-bold ml-3">No existing patients</div>
          )}
        </Col>
      </Row>
    )}
  </Col>
);

export default AttachmentsPublic;
