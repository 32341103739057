import {
  createSingleEventSaga,
  MyAction,
  composeSagas,
} from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';

import { AUTH_API_URL } from '../../config';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from '../../utils/typings';
import { Creators as LoadingActions } from '../loading.module';
import { Creators as ErrorActions } from '../errors.module';
import { MMDError } from '../../utils/MMDError';
import { putAuthInfoInArgs } from '../auth.module';
import { onRoute, extractRouteParams } from '../../utils/onRoute';
import { noOpAction } from '../../utils/noOpAction';
import { Nullable } from '../../typings';

interface ActionTypes {
  COMMIT_NOTES_VIDEO_CALL_PUBLIC: string;
  REQUEST_UPSERT_NOTES_VIDEO_CALL_PUBLIC: string;
}

interface ActionCreators {
  commitNotesVideoCallPublic: (
    payload: Nullable<string>,
  ) => MyAction<Nullable<string>>;
  requestUpsertNotesVideoCallPublic: (payload: {
    id: string;
    body: string;
  }) => MyAction<{ id: string; body: string }>;
}

interface NotesData {
  bookingPublicId: number;
  createdAt: string;
  deletedAt: Nullable<null>;
  id: number;
  note: string;
  revision: number;
  updatedAt: string;
}

export type VideoCallNotesPublicState = {
  note: string | null;
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  commitNotesVideoCallPublic: ['payload'],
  requestUpsertNotesVideoCallPublic: ['payload'],
});

const initialState: VideoCallNotesPublicState = {
  note: null,
};

function commitNotesVideoCallPublic(
  _,
  action: MyAction<Nullable<string>>,
): VideoCallNotesPublicState {
  return {
    note: action.payload,
  };
}

export const videoCallNotesPublicReducer = createReducer(initialState, {
  [Types.COMMIT_NOTES_VIDEO_CALL_PUBLIC]: commitNotesVideoCallPublic,
});

async function downloadNotesForVideoCallPublic({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<
  { response: true; data: { note: string } } | { response: false }
> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.id}/notes-public`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong downloading notes associated to this call',
    );
  }

  return result.json();
}

const requestVideoCallNotesPublicWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Nullable<string>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/video-call-public/:id'),
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitNotesVideoCallPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadNotesForVideoCallPublic,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: string },
    ArgsWithHeaders<{ id: string }>
  >(
    // @ts-ignore
    extractRouteParams('/video-call-public/:id'),
    putAuthInfoInArgs,
  ),
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(res: {
    response: boolean;
    data: Nullable<NotesData>;
  }): SagaIterator {
    return res?.data?.note ?? null;
  },
});

async function upsertNotesForVideoCallPublic({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string; body: string }>): Promise<void> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.id}/notes-public`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(
        payload.body ? { note: payload.body } : { note: '' },
      ),
    },
  );

  if (!result.ok) {
    throw new MMDError(
      'An error has occurred processing your notes. Try again later...',
    );
  }
}

const upsertVideoCallNotesPublicWatcher = createSingleEventSaga<
  object,
  Nullable<string>,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_UPSERT_NOTES_VIDEO_CALL_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitNotesVideoCallPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: upsertNotesForVideoCallPublic,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(
    _,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { headers, ...payload }: ArgsWithHeaders<{ id: string; body: string }>,
  ): SagaIterator {
    return payload.body;
  },
});

export const videoCallNotesPublicSagas = [
  requestVideoCallNotesPublicWatcher,
  upsertVideoCallNotesPublicWatcher,
];
