import React from 'react';
import moment from 'moment';

import { getSrc } from './helpers';

type Props = {
  body: string;
  className?: string;
  received: boolean;
  timestamp: string;
};

const FileMsgBubble: React.FC<Props> = ({
  body,
  className,
  received,
  timestamp,
}) => {
  const [src, setSrc] = React.useState<string>();

  React.useEffect(() => {
    getSrc(body).then(setSrc);
  }, [body]);

  return (
    <div
      className={`msg-bubble ${received ? 'received' : 'sent'} ${className}`}
    >
      <a
        className="mb-0 text-reset"
        href={src}
        target="_blank"
        rel="noreferrer"
      >
        <span role="img" aria-label="Document">
          📄
        </span>{' '}
        Tap to download file
      </a>

      <div className="msg-details">
        <span>{moment(timestamp).fromNow()}</span>
      </div>
    </div>
  );
};

export default FileMsgBubble;
