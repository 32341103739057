import cloneDeep from 'lodash/cloneDeep';

export const trimStringObjectValues = (object: Record<string, any>) => {
  const cloneObject = cloneDeep(object);

  for (const objectKey in cloneObject) {
    const value = cloneObject[objectKey];
    if (typeof value === 'string') {
      cloneObject[objectKey] = value.trim();
    }
  }

  return cloneObject;
};
