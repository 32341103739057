import { FC, useRef, useMemo, useState, useLayoutEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import DoctorProfile from '../components/DoctorProfile';
import UpcomingAppointments from './myappts/UpcomingAppointments';
import ScheduleCalendar from './myappts/ScheduleCalendar';
import TimeSlotCalendar from './myappts/TimeSlotCalendar';
import { MyState } from '../store';
import { bindActionCreators } from 'redux';

type Props = {
  isStudent: boolean;
  teacher?: any;
};

const MyAppts: FC<Props> = ({ isStudent, teacher }) => {
  const [calendar, setCalendar] = useState('appointments');
  const docProfileElem = useRef(null);
  const [height, setHeight] = useState('');

  useLayoutEffect(() => {
    if (!docProfileElem || !docProfileElem.current) return;
    if (docProfileElem.current.clientHeight !== 0) {
      setHeight(docProfileElem.current.clientHeight + 'px');
    }
  }, []);

  const title = useMemo(() => {
    if (teacher) {
      return `Dr. ${teacher.firstName} ${teacher.lastName}'s Appointments`;
    }
    return 'My Appointments';
  }, [isStudent, teacher]);

  const ChangeCalendarButton = (
    <Button
      className="mt-3 w-100"
      variant="outline-primary"
      onClick={() =>
        setCalendar(calendar === 'appointments' ? 'timeSlots' : 'appointments')
      }
    >
      <span>
        {calendar === 'appointments'
          ? 'Change your availability'
          : 'See your appointments'}
      </span>
    </Button>
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>{title}</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <div ref={docProfileElem}>
            <DoctorProfile />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <UpcomingAppointments isStudent={isStudent} />
        </Col>

        {!isStudent ? (
          <Col style={{ height }} xs={12} sm={12} md={12} lg={3}>
            {calendar === 'appointments' ? (
              <ScheduleCalendar bottomComponentSlot={ChangeCalendarButton} />
            ) : (
              <TimeSlotCalendar bottomComponentSlot={ChangeCalendarButton} />
            )}
          </Col>
        ) : (
          <Col style={{ height }} xs={12} sm={12} md={12} lg={3} />
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  isStudent: !!(state.provider && state.provider.teacher),
  teacher: state.provider && state.provider.teacher,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyAppts);
