import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import VerifyForm from './verify/VerifyForm';
import { Provider } from '../typings';

type Props = {
  provider: Provider;
};

const Verify: React.FC<Props> = ({ provider }) => {
  return (
    <Container fluid>
      <Row>
        <Col className="mb-5 mt-3">Breadcumb</Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12}>
          <h5 style={{ textAlign: 'center' }}>Verify Account</h5>
        </Col>
        <Col className="text-center mt-4" xs={12}>
          <Row className="justify-content-center">
            <Col xs={12} className="mb-3">
              <p>
                The 4 digit code was sent to <br />
                <strong>{provider.phone}</strong>
              </p>
            </Col>
            <VerifyForm />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
