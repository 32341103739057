import { Row, Col, Button } from 'react-bootstrap';
import { FC } from 'react';

interface Props {
  id: number;
  type: string;
  title: string;
  description: string;
  includes: string[];
  price: number;
  onClick: () => void;
}

const ProfilePriceItem: FC<Props> = ({
  id,
  title,
  description,
  includes,
  price,
  type,
  onClick,
}) => (
  <Row>
    <Col xs={12} className=" mt-1 margin-bottom-10">
      <h2 className={'plan-text'}>{`Plan #${id}`}</h2>
    </Col>
    <Col xs={12} className="">
      <h2 className={'price-title'}>{title}</h2>
    </Col>
    <Col xs={12} className="mb-1 ">
      <h2 className={'price-descrption'}>{description}</h2>
      <div className=" border-bottom-solid"></div>
    </Col>
    <Col xs={12} className="">
      <h2 className={'include-text'}>Includes:</h2>
    </Col>
    {includes.map((inc) => (
      <Col xs={12} key={inc}>
        <h2 className={'include-value'}>{`- ${inc}`}</h2>
      </Col>
    ))}
    <Col xs={12} className="mb-1 ">
      <div className=" border-bottom-solid"></div>
    </Col>
    <Col xs={12} className="mb-1 vcenter">
      <div className="mb-1 ">
        <span className={'price-text'}>
          {type === 'oneTime' ? 'Price' : 'Individual Plan'}
        </span>
      </div>
      <div className="mb-1 ">
        <span className="price-value price-money"> $</span>
        <span className="price-value"> {price || '0'}</span>
      </div>
    </Col>
    <div className=" w-100 border-bottom-solid"></div>
    <Col xs={12} className="vcenter-column margin-top-35">
      <Button className=" primary height-30 padding-h-50" onClick={onClick}>
        <h6>Request Custom Price Model </h6>
      </Button>
    </Col>
  </Row>
);

export default ProfilePriceItem;
