import {
  EntityState,
  MyAction,
  createEntityAdapter,
} from '@mrnkr/redux-saga-toolbox';
import { PromiseQueue } from '@mrnkr/promise-queue';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';
import {
  call,
  put,
  fork,
  cancel,
  cancelled,
  select,
  take,
  spawn,
  all,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import pick from 'lodash/pick';
import memoize from 'lodash/memoize';

import { Types as AuthActionTypes } from './auth.module';
import { Types as ProviderActionTypes } from './provider.module';
import { Types as PatientActionTypes } from './patients.module';
import { Creators as ErrorActions } from './errors.module';
import { Chatroom, Patient, Provider } from '../typings';
import { firestore, getFirebaseImage } from '../utils/Firebase';
import { MMDError } from '../utils/MMDError';
import { MyState } from '../store';
import { loggedIn } from '../utils/helper';
import {
  collection,
  doc,
  DocumentChange,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';

interface ActionTypes {
  ACTIVATE_CONNECTION_CHATROOMS: string;
  COMMIT_CHATROOMS: string;
  CLOSE_CONNECTION_CHATROOMS: string;
  CLEAR_CHATROOMS: string;
}

interface ActionCreators {
  activateConnectionChatrooms: () => Action;
  commitChatrooms: (payload: Chatroom) => MyAction<Chatroom>;
  closeConnectionChatrooms: () => Action;
  clearChatrooms: () => Action;
}

export interface ChatroomsState extends EntityState<Chatroom> {
  connectionActive: boolean;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  activateConnectionChatrooms: [],
  commitChatrooms: ['payload'],
  closeConnectionChatrooms: [],
  clearChatrooms: [],
});

const entityAdapter = createEntityAdapter<Chatroom>();
const initialState = entityAdapter.getInitialState({
  connectionActive: false,
});

const getLastChatroomForPatient = memoize((patientId: string) =>
  createSelector(entityAdapter.getSelectors().selectAll, (res) => {
    const candidates = res.filter((room) =>
      Object.keys(room.participants).includes(patientId),
    );

    if (candidates.length) {
      return candidates[0].id;
    }
  }),
);

const getUnreadMessageCount = createSelector(
  entityAdapter.getSelectors().selectAll,
  (res) =>
    res.reduce((acum, cur) => {
      if (cur.lastMessage) {
        const unreadCount =
          cur.lastMessage.sender === cur.otherParticipant.id
            ? cur.unreadCount
            : 0;
        return acum + unreadCount;
      }

      return acum;
    }, 0),
);

export const chatroomSelectors = {
  ...entityAdapter.getSelectors(),
  getLastChatroomForPatient,
  getUnreadMessageCount,
};

function activateConnectionChatrooms(state: ChatroomsState): ChatroomsState {
  return {
    ...state,
    connectionActive: true,
  };
}

function commitChatrooms(
  state: ChatroomsState,
  action: MyAction<Chatroom>,
): ChatroomsState {
  return entityAdapter.upsertOne(action.payload, state);
}

function closeConnectionChatrooms(state: ChatroomsState): ChatroomsState {
  return {
    ...state,
    connectionActive: false,
  };
}

function clearChatrooms(): ChatroomsState {
  return initialState;
}

export const chatroomsReducer = createReducer<ChatroomsState>(initialState, {
  [Types.ACTIVATE_CONNECTION_CHATROOMS]: activateConnectionChatrooms,
  [Types.COMMIT_CHATROOMS]: commitChatrooms,
  [Types.CLOSE_CONNECTION_CHATROOMS]: closeConnectionChatrooms,
  [Types.CLEAR_CHATROOMS]: clearChatrooms,
});

const listenToChatroomsForUser = (userId: string) => ({
  subscribe: (next, error) => {
    const q = query(
      collection(firestore, 'chatrooms'),
      where(`participants.${userId}`, '==', true),
    );
    const unsubscribe = onSnapshot(
      q,
      (snap) => {
        snap.docChanges().forEach((change) => {
          if (change.type === 'added' || change.type === 'modified') {
            next(change);
          }
        });
      },
      error,
    );

    return {
      unsubscribe,
    };
  },
});

function* requestChatrooms(): SagaIterator {
  const { id: profileId }: Provider = yield call(getLoggedUser);
  const promiseQueue = new PromiseQueue<DocumentChange>(
    listenToChatroomsForUser(profileId),
  );

  try {
    yield put(Creators.activateConnectionChatrooms());

    while (true) {
      const { value }: { value: DocumentChange } = yield call(
        promiseQueue.next,
      );
      yield fork(chatroomChangedHandler, { ...value, profileId });
    }
  } catch (err) {
    console.error(err);
    if (loggedIn()) {
      yield put(
        ErrorActions.setError(
          new MMDError(
            'Something went wrong with your connection to the chat service...',
          ),
        ),
      );
    }
  } finally {
    if (loggedIn()) {
      if (yield cancelled()) {
        if (!promiseQueue.cancelled)
          promiseQueue.cancel(new MMDError('Cancelled'));
      }
    }
  }
}

function* requestChatroomsCancel(task): SagaIterator {
  yield cancel(task);
  yield put(Creators.clearChatrooms());
}

function* chatroomChangedHandler({
  type,
  doc,
  profileId,
}: DocumentChange & { profileId: string }): SagaIterator {
  if (!doc) {
    return;
  }

  const data = doc.data() as Chatroom;

  switch (type) {
    case 'added': {
      const otherParticipantId = Object.keys(data.participants).find(
        (uid) => uid !== profileId,
      );

      let otherParticipant: {
        id: string;
        firstName?: string;
        lastName?: string;
        name?: string;
      };
      if (profileId) {
        const patient = yield select((state, patientId) => {
          if (!state.patients.entities) return null;
          return state.patients.entities[patientId];
        }, otherParticipantId);
        if (patient || otherParticipantId) {
          otherParticipant = yield call(
            getChatroomParticipant,
            (patient || otherParticipantId) as any,
          );
        }
      }
      if (otherParticipant) {
        yield put(Creators.commitChatrooms({ ...data, otherParticipant }));
        try {
          const pictureUri: string = yield call(
            getFirebaseImage,
            `profile/${otherParticipantId}.jpeg`,
          );
          yield put(
            Creators.commitChatrooms({
              ...data,
              otherParticipant: { ...otherParticipant, pictureUri },
            }),
          );
        } catch (err) {
          console.log('error', err);
        }
      }

      break;
    }
    case 'modified': {
      const oldData = yield select(
        ({ chatrooms }: MyState) => chatrooms.entities[data.id],
      );
      yield put(Creators.commitChatrooms({ ...oldData, ...data }));
      break;
    }
  }
}

function* getLoggedUser(): SagaIterator {
  const provider: Provider = yield select((state: MyState) => state.provider);
  return provider;
}

const getChatroomParticipant = async (
  participant: Provider | Patient | string,
): Promise<{
  id: string;
  firstName?: string;
  lastName?: string;
  name?: string;
}> => {
  let document: DocumentSnapshot;

  if (typeof participant === 'string') {
    document = await getDoc(doc(firestore, 'users', participant));
  } else if ((participant as Patient).parentId) {
    document = await getDoc(
      doc(
        firestore,
        'users',
        (participant as Patient).parentId,
        (participant as Patient).relativeType,
        participant.id,
      ),
    );
  } else if (participant) {
    document = await getDoc(doc(firestore, 'users', participant.id));
  }

  return pick(document.data(), ['id', 'firstName', 'lastName', 'name']) as {
    id: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  };
};

function* requestChatroomsWatcher(): SagaIterator {
  while (true) {
    yield all([
      take(ProviderActionTypes.SUCCESS_PROVIDER),
      take(AuthActionTypes.COMMIT_AUTH),
    ]);
    const arePatientsInState = yield select((state) => {
      return (
        state.patients.entities &&
        Object.keys(state.patients.entities).length > 1
      );
    });
    if (!arePatientsInState) {
      yield put({ type: PatientActionTypes.REQUEST_PATIENTS });
      yield take(PatientActionTypes.COMMIT_PATIENTS);
    }
    const task = yield spawn(requestChatrooms);
    yield take(AuthActionTypes.COMMIT_AUTH_OUT);
    yield fork(requestChatroomsCancel, task);
  }
}

export const chatroomsSagas = [requestChatroomsWatcher];
