import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import {
  Creators as AffiliationsActions,
  affiliationSelectors,
} from '../../modules/affiliations.module';
import { Creators as ProviderActions } from '../../modules/provider.module';
import { MyState } from '../../store';
import { Affiliation } from '../../typings';
import AffiliationItem from './ItemList';

type Props = {
  addItem: (item: any) => void;
  providerAffiliations: Affiliation[];
  allAffiliations: Affiliation[];
  placeHolder?: string;
  error?: string;
} & typeof AffiliationsActions;

const ListProfileInfo: FC<Props> = ({
  allAffiliations,
  providerAffiliations,
  placeHolder,
  requestAffiliations,
  addItem,
  error,
}) => {
  const [text, setText] = useState<string | undefined>();
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    requestAffiliations();
  }, [providerAffiliations]);

  //validate
  useEffect(() => {
    setIsInvalid(
      text !== '' ? allAffiliations.some((org) => org.name === text) : true,
    );
  }, [text, allAffiliations]);

  const affiliations = allAffiliations.map((item) => ({
    ...item,
    checked: providerAffiliations.some(({ id }) => id === item.id),
  }));
  const up = affiliations.filter((org) => org.approved);
  const down = affiliations.filter((org) => !org.approved);

  return (
    <Row className="modal-org-body">
      <Col xs={12} className="mb-1 mt-1">
        {up.map((org, i) => (
          <AffiliationItem
            key={org.id}
            className={`short-bottom-separator padding-vertical-10 ${
              i === 0 ? 'short-top-separator' : ''
            }`}
            check={org.checked}
            title={org.name}
            onclick={() => addItem(org.name)}
          />
        ))}
      </Col>

      <Col xs={12} className=" w-100 padding-vertical-10">
        <Col xs={12} className=" w-100 padding-vertical-10">
          <h4 className="affiliation-sub-title margin-left-15">
            Or you can add new Affiliation here
          </h4>
        </Col>
        <Col xs={12} className="vcenter w-100 padding-vertical-10">
          <Col xs={9}>
            <FormControl
              isInvalid={isInvalid}
              value={text}
              className={'btn-under-border'}
              placeholder={placeHolder ? placeHolder : ''}
              onChange={(e) => {
                setText(e.currentTarget.value);
              }}
            />
          </Col>
          <Col xs={1}>
            <div
              onClick={() => {
                if (text) {
                  addItem(text);
                  setText(undefined);
                }
              }}
              className="vcenter center-footer icon-medium margin-top-35 cursor-pointer"
            >
              <FontAwesomeIcon
                size={'1x'}
                icon={faPlus}
                style={{ backgroundColor: '#A2D773', color: 'white' }}
              />
            </div>
          </Col>
        </Col>
      </Col>
      {isInvalid && (
        <Col xs={12} className="vcenter w-100">
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {text ? error : 'The name must not be empty'}
          </div>
        </Col>
      )}
      <Col xs={12} className="mb-1 mt-1">
        {down.map((org, i) => (
          <AffiliationItem
            key={org.id}
            className={`short-bottom-separator padding-vertical-10 ${
              i === 0 ? 'short-top-separator' : ''
            }`}
            check={org.checked}
            title={org.name}
            onclick={() => {
              addItem(org.name);
            }}
          />
        ))}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: MyState) => ({
  allAffiliations: affiliationSelectors.selectAll(state.affiliations),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...AffiliationsActions,
      ...ProviderActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListProfileInfo);
