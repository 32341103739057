import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import DoctorProfile from '../components/DoctorProfile';
import ScheduleCalendar from './myappts/ScheduleCalendar';
import PrescriptionRefillList from './prescriptions-refill/PrescriptionRefillList';
import {
  Creators as prescriptionRefillActions,
  prescriptionRefillSelectors,
} from '../modules/prescription-refills.module';
import { RefillRequest } from '../typings';
import { MyState } from '../store';

type Props = {
  prescriptionsRefills: RefillRequest[];
} & typeof prescriptionRefillActions;

const PrescriptionRefills: React.FC<Props> = ({ prescriptionsRefills }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h1>E-Prescription Refill</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <DoctorProfile />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <PrescriptionRefillList prescriptionRefills={prescriptionsRefills} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={3}>
          <ScheduleCalendar />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  prescriptionsRefills: prescriptionRefillSelectors.selectAll(
    state.prescriptionRefills,
  ),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...prescriptionRefillActions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrescriptionRefills);
